import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './venteComptoir.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js';

const VenteComptoir = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  
  const thead = [
    {name:"Code Art.", width: 190},
    {name:"Quantite", width: 190},
    {name:"Prix Vente", width: 190},
    {name:"Total", width: 190},
    {name:"Designation", width: 390},
    {name:"Qte Stock", width: 190}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Vente Comptoir'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
            labelPosition={true} holderText={"Chercher Par Designation"}  label={'Chercher Article'}
          margin={'0px 0px 0px 15px'}
          icon={icons.search}/>
          <div className={styles['operations-buttons']}>
            <Button link={true} path={"../ajouter_article"} name={'Insere Nov Article'} icon={icons.insere}/>
              <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
          </div>
        </div>
        <div className={styles['periode-input-container']}>
          <div className={styles['periode']}>
              <InputText 
                width={'max-content'} 
                type={"date"}  
                label={'Date'}
                margin={'0px 0px 0px 15px'}/>
          </div>
        </div>
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} 
            edit={user.permissions.includes(permissions.canEditInTable)} 
            remove={user.permissions.includes(permissions.canDeleteFromTable)} />
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
            <div></div>
            <InputText label={"Total"}  width={"30%"} type={"number"} holderText={"Montant"}/>
        </div>
      </div>
    </PageLayout>
  )
}

export default VenteComptoir;

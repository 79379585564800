import React, { useEffect, useState, useRef } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './articlesVendu.json'
import styles from './articlesVendu.module.scss'
import { icons} from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import articleLivreFinder from '../../api/articleLivreFinder.js'
import articlesAvoirFinder from '../../api/articlesAvoirFinder.js'

const ArticlesNoVendu = () => {

  
  const thead = [
    {name:"Vent/Avr",width: 190},
    {name:"Code Art.",width: 190},
    {name:"Article",width: 490},
    {name:"Qtè Vendu",width: 190},
    {name:"Montant",width: 190}
  ]

  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([])

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const total_vente = useRef();
  const total_retour = useRef();
  const total_comptoir = useRef();
  const global_amount = useRef();


  useEffect(() => {
    let id_bon = -1;
    let montant_ventes = 0.0;
    let montant_retour = 0.0;
    let remise_vente = .0;
    let remise_avoir = .0;

    let bonsAvoir =[],bonsVente = [];

    const fetchBonsVenteAvoir = async () => {
      const articles_vendue = await articleLivreFinder.get('/');
      const bons_avoir = await articlesAvoirFinder.get('/vente');

      const { max_id } = articles_vendue.data.data;

      articles_vendue.data.data.articles.map((article)=>{
        // CALCULATE THE TOTAL QUANTITY OF THE ARTICLE INCLUDING IT UG
        let quantity = Math.round(article.quantity *(1 - (article.ug/100)));
        // 

        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
        let montant = (quantity*article.prix) * (1 - (article.remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF THE PURCHASES INCLUDING THE RECEIPTS PROMOTION
        if(parseInt(id_bon) !== parseInt(article.id_bl)){
          remise_vente += article.bl_remise;
          id_bon = article.id_bl;
        }
        montant_ventes += montant;
        

        bonsVente.push({
              id : article.id,
              Vente_av: 'Vente',
              lot: article.lotNbr,
              code_article: article.code,
              code_bon: article.bl_code,
              expire_date: article.expire_date.substring(0,10),
              quantity,
              prix:article.prix,
              montant
            });
        });

        id_bon = -1;
        bons_avoir.data.data.articles.map((article)=>{
          
          let montant = parseFloat(article.article_avoir_prix_achat * article.article_avoir_quantity) * (1 - (parseFloat(article.article_avoir_remise) / 100));

          if(parseInt(id_bon) !== parseInt(article.id_bon_avoir)){
            remise_avoir += article.bon_avoir_remise;
            id_bon = article.id_bon_avoir;
          }

          montant_retour += montant;
          let id = parseInt(article.id) + parseInt(max_id);

          bonsAvoir.push({
            id,
            Vente_av: 'Avoir',
            lot: article.lotNbr,
            code_article: article.code,
            code_bon: article.bon_avoir_code,
            expire_date: article.expire_date.substring(0,10),
            quantity: article.article_avoir_quantity,
            prix: article.article_avoir_prix_achat,
            montant
          });
        });
        montant_ventes = montant_ventes * (1 - (remise_vente/100));
        montant_retour = montant_retour * (1 - (remise_avoir/100));
        total_vente.current?.setValue(montant_ventes);
        total_retour.current?.setValue(montant_retour);  
        total_comptoir.current?.setValue('0');
        global_amount.current?.setValue(montant_ventes - montant_retour);

        setData([...bonsVente,...bonsAvoir]);
    }
    fetchBonsVenteAvoir();
  }, []);

  return (
        <PageLayout icon={icons.article} nameLayout={'Articles Vendu'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div className={styles['periode']}>
                <InputText 
                  width={'20%'} 
                  type={"date"}  
                  label={'Jour 1'}
                  margin={'0px 0px 0px 15px'}
                  labelPosition={true}
                  reportChange={reportDate1Change}
                   />
                <InputText 
                  width={'20%'} 
                  type={"date"}  
                  label={'Jour 2'}
                  margin={'0px 0px 0px 15px'} 
                  labelPosition={true}
                  reportChange={reportDate2Change}
                  />
          </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          
          <div className={styles['table']}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} edit={true} remove={true} date1={date1} date2={date2}/>
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Des Vente'}
                  margin={'0px 15px 0px 0px'}
                  ref={total_vente}
                  disabled
                />
            </div>
            <div>
                <InputText 
                  width={'100%'}
                  type={"number"}
                  holderText={"Montant"} 
                  label={'Montant Des Retours'}
                  margin={'0px 15px 0px 0px'}
                  ref={total_retour}
                  disabled/>
            </div>
            <div>
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Des Comptoirs'}
                  margin={'0px 15px 0px 0px'}
                  ref={total_comptoir}
                  disabled/>
            </div>
            <div style={{display: "flex"}}>
                
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Global'}
                  margin={'0px 15px 0px 0px'}
                  ref={global_amount}
                  disabled/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default ArticlesNoVendu

import React from 'react';
import styles from './userCard.module.scss';
import { bgImgs, icons } from '../../media/mediaHolder';
import { spacedPhoneNumFormat } from '../../utils/numberFormatter';
import { dateFormatter } from '../../utils/dateFormatter';

const UserCard = (props) => {
  const { userInfo } = props;

  return (
    <div className={styles['container']}>

        <div>
          <img
            src={bgImgs.defaultProfilePic} 
            alt='profile'
            className={styles['profile-pic']}
          />
        </div>
        <p className={styles['name']}>{userInfo.name}</p>
        <p className={styles['role']}>{userInfo.role}</p>

        <div className={styles['card-details']}>

          <div className={styles['hire-date']}>
            <p>Hire Date</p>
            <p>{userInfo.accountCreationTS ? dateFormatter(userInfo.accountCreationTS) : 'undefined'}</p>
          </div>

          <div>
            <div className={styles['set-in-row']}>
              <img src={icons.email} alt='email' />
              <p>{userInfo.email}</p>
            </div>

            <div className={styles['set-in-row']}>
              <img src={icons.phone} alt='phone' />
              <p>{userInfo.phone ? spacedPhoneNumFormat(userInfo.phone) : 'undefined'}</p>
            </div>
          </div>

        </div>

    </div>
  )
}

export default UserCard;

import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './creditFournisseur.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import SelectInput from '../../components/select-input/SelectInput';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions'

const CreditFournisseurClientwilaya = () => { 
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, SetValue] = useState(0);

  const options = [
    "Tout Les Fournisseurs",
    "Fournisseurs Soldes",
    "Fournisseurs Non Soldes"
  ]

  const thead = [
    [
      {name:"Code Fournisseur", width: 190},
      {name:"Fournisseur", width: 290},
      {name:"Montant BL Achat", width: 190},
      {name:"Montant Verser", width: 190},
      {name:"Solde", width: 190},
      {name:"TEL/FAX",width: 190}
    ],[
      {name:"Code Fournisseur", width: 190},
      {name:"Fournisseur", width: 290},
      {name:"Montant BL Achat", width: 190},
      {name:"Montant Verser", width: 190},
      {name:"Solde", width: 190},
      {name:"TEL/FAX",width: 190}
    ],[
      {name:"Code Fournisseur", width: 190},
      {name:"Fournisseur", width: 290},
      {name:"Montant BL Achat", width: 190},
      {name:"Montant Verser", width: 190},
      {name:"Solde", width: 190},
      {name:"TEL/FAX",width: 190}
    ]
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Credit Des Founisseur'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <div style={{marginLeft: "15px"}}>
            <Button name={'Fournisseur-Client'} path={'/credit_fournisseur_client'} height={'100%'} icon={icons.impr} width={"250px"}/>
          </div>
          <div className={styles['operations-buttons']}>
              <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
          </div>
        </div>
        <div className={styles['periode']}>
          <div style={{display: "flex" , width:"50%"}}>
              <InputText width={'60%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
              labelPosition={true} holderText={"Chercher"}  label={'Chercher'}
              margin={'0px 0px 0px 15px'}/>
          </div>
          <div style={{display: "flex" , width:"30%" , justifyContent: "flex-end"  ,
                      marginRight: "15px"}}>
              <SelectInput options={options} 
                           value={value} 
                           setOption={SetValue} 
                           />
          </div>
          
        </div>
        <div className={styles['table']}>
          <Table thead={thead[value]} inputValue={inputValue} tbody={data} 
            edit={user?.permissions.includes(permissions.canEditInTable)} 
            remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
          />
        </div>
        <div className={styles['mantant-chargers']}>
          <div>
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total BL'}
              margin={'0px 15px 0px 15px'}/>
          </div>
          <div>
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Verser'}
              margin={'0px 15px 0px 0px'}/>
          </div>
          <div style={{display: "flex"}}>
              
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Rester'}
              margin={'0px 15px 0px 0px'}/>
          </div>
          
        </div>
      </div>
    </PageLayout>
  )
}

export default CreditFournisseurClientwilaya;

import { bgImgs } from '../../media/mediaHolder';
import permissions from '../../utils/permissions';

const gestions = [
    {
        assignedPermissions: [
            permissions.canManagePurchases,
            permissions.canManageSales,
            permissions.canManageSalesOrders
        ],
        modItemContainer: [
            {
                name: "Article",
                mainBgUrl: bgImgs.article.main,
                secBgUrl: bgImgs.article.secondary,
                linkUrl: "articles",
                assignedPermissions: [permissions.canManagePurchases]
            },
            {
                name: "Achats",
                mainBgUrl: bgImgs.achat.main,
                secBgUrl: bgImgs.achat.secondary,
                linkUrl: "achat",
                assignedPermissions: [permissions.canManagePurchases]
            },
            {
                name: "Ventes",
                mainBgUrl: bgImgs.vents.main,
                secBgUrl: bgImgs.vents.secondary,
                linkUrl: "bon_de_livraison",
                assignedPermissions: [permissions.canManageSales]
            },
            {
                name: "Facture",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "facture",
                assignedPermissions: [permissions.canManageSales]
            },
            {
                name: "Bon De Commande",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "bon_de_commande",
                assignedPermissions: [permissions.canManagePurchases]
            },
            {
                name: "Bon d'avoir Achat",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "bon_avoire_achat",
                assignedPermissions: [permissions.canManagePurchases]
            },
            {
                name: "Bon Retour",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "bon_avoir_vente",
                assignedPermissions: [permissions.canManageSales]
            },
            {
                name: "Avoir Facture",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "bons_avoir_facture",
                assignedPermissions: [permissions.canManagePurchases]
            },
            {
                name: "Facture Proforma",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "facture_proforma"
            },
            {
                name: "Stock",
                mainBgUrl: bgImgs.facture.main,
                secBgUrl: bgImgs.facture.secondary,
                linkUrl: "stock",
                assignedPermissions: [permissions.canManagePurchases]
            }
        ],
        modName : "Gestion Commercial",
        main: true
    },
    {
        assignedPermissions: [permissions.canManageProduction],
        modItemContainer: [
            {
                name: "Stock Matiere Premiere",
                mainBgUrl: bgImgs.production.main,
                secBgUrl: bgImgs.production.secondary,
                linkUrl: "stock_matiere_premiere"
            },
            {
                name: "Stock Matiere Semi Fini",
                mainBgUrl: bgImgs.production.main,
                secBgUrl: bgImgs.production.secondary,
                linkUrl: "stock_matiere_semi_fini"
            },
            {
                name: "Stock Produit Fini",
                mainBgUrl: bgImgs.production.main,
                secBgUrl: bgImgs.production.secondary,
                linkUrl: "stock_matiere_fini"
            },
            {
                name: "Consultation Production",
                mainBgUrl: bgImgs.production.main,
                secBgUrl: bgImgs.production.secondary,
                linkUrl: "consultation_production"
            }
        ],
        modName : "Gestion Production",
        main: false
    }
]; 

export default gestions;

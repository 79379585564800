import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styles from './validatedMessages.module.scss'
import { useNavigate } from 'react-router-dom';
const ValidatedMessages = forwardRef((props,ref) => {

    const navigate = useNavigate();
    const [validate, setValidate] = useState([])
    const [applyClass, setApplyClass] = useState(false);
    const cancelValidate = (name) =>{
    const updatedArr = validate?.filter(obj => obj.name !== name); 
      setValidate(updatedArr)
    }
    const myFunction = () => {
        setValidate([])
    };
    
    useEffect(() => {
        const CtimeoutId = setTimeout(()=> {
          setApplyClass(true);
          }, 5000);

        const FtimeoutId = setTimeout(()=> {
            myFunction();
            setApplyClass(false)
          }, 5500);

        return () => {
          clearTimeout(CtimeoutId);
          clearTimeout(FtimeoutId);
        };
      }, [validate]); 
    
    useImperativeHandle(ref, () => ({
        validateHandler: (val)=> {(val.length > 0) ? setValidate(val) : setValidate([])},
      }))
  return (
    <div className={styles['position']}>
        <div className={styles['container']}>
            {validate && validate.map((element, index)=>{
                return (
                  <div key={index} className={`${applyClass ? styles['destroy'] : styles['container_red']}`}>
                  <p id='message'>{element.message}</p>
                  <span className={styles['closebtn']} onClick={()=> cancelValidate(element.name)}>&times;</span> 
                  </div>
                );
            })}
        </div>
    </div>
  )
})

export default ValidatedMessages
import React, { useEffect, useRef, useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './listArticalsTransfer.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import articlesTransfereFinder from '../../api/articlesTransfereFinder'

const ListArticalsTransfer = () => {
  const [ data, setData ] = useState([]);

  useEffect(()=>{
    const getArticlesTransfere = async ()=>{
        try{
            const articles = await articlesTransfereFinder.get("/");
            setData(articles.data.data.map((article)=>{
              return{
                id: article.id,
                date: article.BonDetailes.date.substring(0, 10),
                nbBon: article.BonDetailes.code,
                codeArt: article.articleInfo.code,
                designation: article.articleInfo.designation,
                quantity: article.quantity,
                prixAchat: article.prix_achat,
                total: article.quantity * article.prix_achat
              }
            }))
          }catch(err){
            console.log(err)
          }
          }
        getArticlesTransfere();
    
},[])
  
  const thead = [
    {name:"Date", width: 190},
    {name:"N Bon", width: 190},
    {name:"Code Art.", width: 190},
    {name:"Designation", width: 390},
    {name:"Qte", width: 190},
    {name:"Pu", width: 190},
    {name:"Total", width: 190}
  ]
      
  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['designation'];
  const handleSearch = (val) => {
      setInputValue(val);
  }
  const articleTable = useRef([]);

  const handleDate = (val)=>{
    const temp = articleTable.current.getAllData().filter((article)=>{
      return new Date(article.date) >= new Date(val)
    })
    console.log(temp)
  }

  return (
    <PageLayout icon={icons.article} nameLayout={'List Des Article Transferes'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <InputText width={'50%'} tbody={data}
            labelPosition={true} holderText={"Chercher Par Designation"}  label={'Chercher'}
            margin={'0px 0px 0px 15px'}
            icon={icons.search}
            reportChange={(val) => handleSearch(val)}
            />
          <div className={styles['operations-buttons']}>
              <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
          </div>
        </div>
        <div className={styles['periode-input-container']}>
          <div className={styles['periode']}>
              <InputText 
                width={'max-content'} 
                type={"date"}  
                label={'Periode du'}
                margin={'0px 0px 0px 15px'}
                reportChange={(val) => handleDate(val.substring(0,10))}/>
              <InputText 
                width={'max-content'} 
                type={"date"}  
                label={'Au'}
                margin={'0px 0px 0px 15px'}/>
          </div>
          <div className={styles['input']}>
              <div></div>
          <InputText width={'40%'} holderText={"chercher"}  label={'Chercher'} margin={"0px 15px 0px 0px"}
          />
          </div>
        </div>
        <div className={styles['table']}>
          <Table thead={thead}
                  tbody={data} 
                  ref={articleTable}
                  filterInput={inputValue}
                  filterByArr={filterByArr}
                  edit={true} remove={true} />
        </div>
      </div>
    </PageLayout>
  )
}

export default ListArticalsTransfer;

import React, { useEffect, useState , useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './ajouterarticle.module.scss'
import { icons } from '../../media/mediaHolder'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import articlesFinder from '../../api/articlesFinder';
import { useNavigate } from 'react-router-dom';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import famillesAndSousFamillesFinder from '../../api/famillesAndSousFamillesFinder'
import depotFinder from '../../api/depotFinder'
import depotsStockFinder from '../../api/depotsStockFinder'
import SelectInput from '../../components/select-input/SelectInput'
const AjouterArticle = () => {
    const navigate = useNavigate();
    const articleType = useRef();
    const [familleData , setFamilleData] = useState([]);
    const [sousFamilleData , setSousFamilleData] = useState([]);
    const options = [
        "Matiere Premiere",
        "Produit Semi Fini",
        "Produit Fini"
    ]

    const goBack = () => {
        navigate(-1);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let type = (articleType.current.getSelectedOption()).toLowerCase().replace(/\s/g, '');

        const response = await articlesFinder.post('/', {
            famille_name: document.getElementById('famille_name').value,
            sous_famille_name: document.getElementById('sous_famille_name').value,
            code: document.getElementById('code').value,
            designation: document.getElementById('designation').value,
            pu_achat: document.getElementById('pu_achat').value,
            prix_vente_gros: document.getElementById('prix_vente_gros').value,
            prix_vente_semi_gros: document.getElementById('prix_vente_semi_gros').value,
            pu_detailer: document.getElementById('pu_detailer').value,
            pu_special: document.getElementById('pu_special').value,
            unit_mes: document.getElementById('unit_mes').value,
            qte_cart: document.getElementById('quantity_in_boxes').value,
            nb_cart: document.getElementById('qty_cart').value,
            stockInitial: document.getElementById('stockInitial').value,
            tva: document.getElementById('tva').value,
            bar_code: document.getElementById('bar_code').value,
            code_qr:document.getElementById('code_qr')?.value,
            stock_alert: document.getElementById('stock_alert').value,
            weight: document.getElementById('weight').value,
            volume: document.getElementById('volume').value,
            observation: document.getElementById('observation').value,
            ug: document.getElementById('UG').value,
            type:(type).replace('produit','matiere'),
            pu_fact: parseInt(pufactRef.current.getValue())
        });

        let stk_init = stockInitial.current?.getValue();
        if(stk_init >= 0 && stockInitial.current?.getValue())
            await depotsStockFinder.post(`/`, {
                id_depot: depot[0].id,
                id_article: response.data.data.article.id,
                quantity: stk_init,
                lot_nbr: document.getElementById('lot_nbr').value,
                expire_date: expireDate.current.getValue(), 
                purchase_date: new Date().toISOString().substring(0,10),
                operation: 'add'
            });
        console.log(stockInitial.current?.getValue())
        goBack();
    }

    const checkCode = async (value)=>{
        const response = await articlesFinder.get(`/codes/${value}`);
        if(response.data.exist){
          return true;
        }else
          return false;
    }

    const errorsHandler= useRef([]);
    const displayConfirmationDialog = useRef();
    const codeInput = useRef();
    const familleName = useRef();
    const prixVenteGros = useRef();
    const designationR = useRef();
    const unitMes = useRef();
    const stockInitial = useRef();
    const expireDate = useRef();
    const nbrLot = useRef();
    const quantity_of_boxes = useRef();
    const quantity_in_boxes = useRef();

    const [quantityOfBoxes,setQuantityOfBoxes] = useState();
    const [quantityInBoxes,setQuantityInBoxes] = useState();
    const [quantityInitial,setQuantityInitial] = useState();

    const handleQuantityInBoxes = (val)=>{
        if(quantityInitial){
            (val > 0 ) ? quantity_in_boxes.current?.setValue(Math.floor(quantityInitial/val)) : quantity_in_boxes.current?.setValue(0);
        }else if(quantityInBoxes){
            let qty = stockInitial.current?.setValue(Math.floor(val*quantityInBoxes));
            setQuantityInitial(qty);
        }

        setQuantityOfBoxes(val);   
    }
    const handleNombreDeCarton = (val)=>{
        if(quantityInitial ){
            (val > 0 ) ? quantity_of_boxes.current?.setValue(Math.floor(quantityInitial/val)) : quantity_of_boxes.current?.setValue(0);
        }else if(quantityOfBoxes){
            let qty = stockInitial.current?.setValue(Math.floor(quantityOfBoxes*val));
            setQuantityInitial(qty);
        }
        setQuantityInBoxes(val);
    }
    const handleOnInitStock = (val) => {
        setQuantityInitial(val);
        quantity_in_boxes.current?.setValue('0');
        quantity_of_boxes.current?.setValue('0');
    }
    const checkErrs = async ()=>{
        const alerts = [];
        const code = (document.getElementById('code').value).trim();
        const designation = (document.getElementById('designation').value).trim();
        const prix_vente_gros = (document.getElementById('prix_vente_gros').value).trim();
        const stockInitial_quantity = (document.getElementById('stockInitial').value).trim();
        const depot = (document.getElementById('depot').value).trim();
        const lot_number = (document.getElementById('lot_nbr').value).trim();

        console.log((articleType.current.getSelectedOption()).toLowerCase().replace(/\s/g, ''))

        if(!code){
            alerts.push({name:'code',message:'Assurez que le Code est bien saisez'});
            codeInput.current.setBorderRed();
        }
        if(!designation){
            alerts.push({name:'designation',message:'Assurez que la Designation est bien saisez'});
            designationR.current.setBorderRed();
        }
        if(code && await checkCode(code) === true){
            alerts.push({name:'code',message:'le Code est deja exister'});
            codeInput.current.setBorderRed();
        }
        if(!prix_vente_gros){
            alerts.push({name:'prixGros',message:'Assurez que Le Prix Vente Gros est bien saisez'});
            prixVenteGros.current.setBorderRed();
        }
        if(!depot && (stockInitial_quantity !== '')){
            alerts.push({name:'depot',message:'Assurez que Le Depot est bien saisez'});
            depotR.current.setBorderRed(); 
        }
        if(!lot_number && (stockInitial_quantity !== '') ){
            alerts.push({name:'N° de Lot', message:'Assurez que le N° de Lot est bien saisez'});
            nbrLot.current.setBorderRed();
        }
        if(alerts.length > 0) {
        errorsHandler.current.errorsHandler(alerts);
        return;
        }
        handelValidate();
    } 
    const handelValidate= () => {
        displayConfirmationDialog.current.display();
    }
    const [depot , setDepot] = useState();
    const depotR = useRef();
    useEffect(()=>{
            const getFamille = async ()=>{
                const response = await famillesAndSousFamillesFinder.get("/");
                setFamilleData(response.data.data.fnsf)
            }
            const depots = async ()=>{
                try{
                    const response = await depotFinder.get('/');
                    setDepot(response.data.data.depots);
                }catch(err){
                    console.log(err)
                }
            }
            depots();
            getFamille();
        },[]);
        const handleSousFamille = (item)=>{
            setSousFamilleData(item.children)
        }
        const pufactRef = useRef();
        const ppaRef = useRef();
        const tvaRef = useRef();
        const prixPhacrmacie = useRef();
        
        const handleCalcule = (val) => {
            const tva= parseInt(val)/100
            const ppa = parseInt(ppaRef.current.getValue())
            pufactRef.current.setValue(
                ((ppa/(1+tva))/1.32).toFixed(2)
            )
        }
        const handleCalculeType2 = (val) => {
            const ppa = parseInt(val)
            prixPhacrmacie.current.setValue(
                (ppa/1.2).toFixed(2)
            )
            prixVenteGros.current.setValue((ppa/1.32).toFixed(2))
        }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Ajouter article'}>
        <form onSubmit={handleSubmit}>
            <div className={styles['body-container']}>
                <div className={styles['main-body']}>
                {/* Article Description Section */}
                    <DetailsSectionContainer name={"Famile et Sous Famile"}>
                        <InputText 
                            label={"Code *"}
                            ref={codeInput} 
                            width={"100%"} 
                            id={'code'} 
                            type={"text"} 
                            holderText={"001"}/>
                        <InputText 
                            label={"Famile"} 
                            btnName={'Ajouter Famille'} 
                            path={'/famille_et_sous_famille'} 
                            onSelectItem={handleSousFamille} 
                            searchData={familleData} 
                            ref={familleName} 
                            width={"100%"} 
                            id={'famille_name'} 
                            type={"text"} 
                            holderText={"Famile"}/>
                        <InputText 
                            label={"Sous famille"} 
                            btnName={'Ajouter Sous Famille'} 
                            path={'/famille_et_sous_famille'} 
                            searchData={sousFamilleData} 
                            width={"100%"} 
                            id={'sous_famille_name'} 
                            type={"text"} 
                            holderText={"Sous famille"}/>
                    </DetailsSectionContainer>

                    {/* Dèsignation et observation Section */}
                    <DetailsSectionContainer name={"Dèsignation et observation"} >
                        <InputText 
                            label={"Dèsignation *"} 
                            ref={designationR} 
                            id={'designation'} 
                            width={"100%"} 
                            type={"text"} 
                            holderText={"Dèsignation"}
                        />
                        <SelectInput 
                            id={"typeArticle"}
                            options={options} 
                            width={'100%'} 
                            label={"Type d'Article"}
                            ref={articleType}
                        />
                        <InputText 
                            label={"Observation"} 
                            id={'observation'} 
                            width={"100%"} type={"text"} 
                            holderText={"Observation"}
                        />
                        <InputText 
                            label={"Unite Gratuite"} 
                            id={'UG'} width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"UG"} 
                            currency={true} 
                            currencyValue={"%"} 
                        />
                        <InputText 
                            label={"Stock alert"}
                            id={'stock_alert'} 
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"Stock alert"}
                         />
                    </DetailsSectionContainer>

                    {/* configuration de la quantity Section*/}
                    <DetailsSectionContainer name={"Configuration de la Quantitè"} >
                        <InputText 
                            id={'stockInitial'} 
                            label={"Quantitè initial"} 
                            ref={stockInitial} 
                            width={"100%"} 
                            type={'number'}
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"Quantitè initial"} 
                            reportChange={handleOnInitStock}
                            />
                        <InputText 
                            id={'quantity_in_boxes'} 
                            label={"Quantitè en Carton"} 
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"Quantitè En Carton"}
                            type={'number'}
                            ref={quantity_in_boxes}
                            reportChange = {handleNombreDeCarton}
                            />
                        <InputText 
                            id={'qty_cart'} 
                            label={"Nombre de Carton"} 
                            holderText={"Nombre de Carton"} 
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/}
                            ref={quantity_of_boxes}
                            type={'number'}
                            reportChange = {handleQuantityInBoxes} />
                        <InputText 
                            label={"Depot"} 
                            id={'depot'} 
                            width={"100%"} 
                            ref={depotR} 
                            searchData={depot} 
                            holderText={"Ajouter Depot"} 
                            />
                        <InputText 
                            label={"N° Lot"} 
                            id={'lot_nbr'}
                            ref={nbrLot}
                            width={"100%"} 
                            holderText={"N° Lot"} 
                            />
                        <InputText 
                            label={"Date peremption"} 
                            id={'expire_date'} 
                            ref={expireDate} 
                            width={"100%"} 
                            type={'date'} 
                            />
                    </DetailsSectionContainer>

                    {/* Prices Section */}
                    <DetailsSectionContainer name={"Prix Unitare"} >
                        <InputText 
                            label={"Pu Achat"} 
                            id={'pu_achat'} 
                            width={"100%"} 
                            type={'number'} 
                            holderText={"Pu Achat"}
                        />
                        <InputText 
                            label={"Prix Grossiste"} 
                            id={'prix_vente_gros'} 
                            ref={prixVenteGros} 
                            width={"100%"} 
                            type={'number'} 
                            holderText={"Prix Grossiste"}/>
                        <InputText 
                            label={"Prix Pharmacie"} 
                            id={'prix_vente_semi_gros'} 
                            width={"100%"} 
                            type={'number'} 
                            ref={prixPhacrmacie}
                            holderText={"Prix Pharmacie"}/>
                        <InputText 
                            label={"Pu Vente Special"} 
                            id={'pu_special'} 
                            width={"100%"} 
                            type={'number'} 
                            holderText={"Pu Vente Special"}/>
                        <InputText 
                            label={"PPA"} 
                            id={'pu_detailer'} 
                            width={"100%"} 
                            type={'number'} 
                            ref={ppaRef}
                            reportChange={handleCalculeType2}
                            holderText={"PPA"}/>
                        <InputText 
                            label={"Pu à Fact"} 
                            id={'pu_a_fact'} 
                            width={"100%"} 
                            type={'number'} 
                            ref={pufactRef}
                            holderText={"Pu à Fact"}/>
                    </DetailsSectionContainer>

                    { /* Units Section */}
                    <DetailsSectionContainer name={"Unité de mesure / Poids / Volume"} >
                        <InputText 
                            label={"Unité de mesure"} 
                            ref={unitMes} 
                            id={'unit_mes'} 
                            width={"100%"} 
                            type={"text"} 
                            holderText={"Unité de mesure"}/>
                        <InputText 
                            label={"Poids"} 
                            id={'weight'} 
                            width={"100%"} 
                            type={'number'} 
                            holderText={"Poids"}/>
                        <InputText 
                            label={"Volume"} 
                            id={'volume'}
                            width={"100%"}  
                            type={'number'} 
                            holderText={"Volume"}/>
                    </DetailsSectionContainer>

                    { /* Taxes Section*/}
                    <DetailsSectionContainer name={"TVA / Code Barre"} >
                        <InputText 
                            label={"TVA"} 
                            id={'tva'} 
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"TVA"}
                            ref={tvaRef}
                            reportChange={handleCalcule}
                            />
                        <InputText 
                            label={"Code Barre"} 
                            id={'bar_code'} 
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"Code Barre"}/>
                        <InputText 
                            label={"Désignation code barre"}  
                            width={"100%"} 
                            pattern={/^-?(0|[1-9]\d*)$/} 
                            holderText={"Désignation code barre"}/>
                    </DetailsSectionContainer>

                    <div className={`${styles['row-around']} ${styles['cancel-validate']}`}>
                        <Button width={"20%"} 
                                name={"Annuler"}  
                                switchbtn={true} 
                                onclick={goBack}
                        /> 
                        <Button onclick={checkErrs}
                                width={"30%"} 
                                name={"Valider"} 
                        />
                    </div>
                </div>
            </div>
            <ConfirmationButton ref={displayConfirmationDialog}/>
        </form>    
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default AjouterArticle
import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import styles from './stockInventaire.module.scss';
import Button from '../../components/button/Button';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const StockInvetaire = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const [ data, setData ] = useState([]);
    const [ inputValue , setInputValue ] = useState('');
    
    const thead = [
    {name:"Code", width: 190},
    {name:"Designation", width: 390},
    {name:"Qte en Stk", width: 190},
    {name:"Stk Inv.", width: 190},
    {name:"Ecart", width: 190},
    {name:"Montant Ecart/Achat", width: 190},
    {name:"Montant Ecart/Vente", width: 190},
    {name:"PU Achat", width: 190},
    {name:"PU Vente", width: 190},
    {name:"Prix Achat Init.", width: 190},
    {name:"Prix Vente Init.", width: 190},
    {name:"Montant en PU Acht.", width: 190},
    {name:"Montant en PU Ven.", width: 190},
    {name:"Montant des Achats", width: 190},
    {name:"Montant des Ventes", width: 190},
    {name:"Stk Initial", width: 190}
  ]
    
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Le Stock Inventaire'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                    <InputText 
                        icon={icons.search}
                        type={"text"}
                        holderText={"Chercher"}
                        label={"Chercher"}
                        labelPosition={true}
                        width={"50%"}
                        />
                    <div className={styles['row-end']} style={{width: 'max-content'}}>
                        <Button 
                            icon={icons.impr}
                            width={"150px"}
                            name={'Imprimer'}
                        />
                        <Button 
                            icon={icons.exporter}
                            width={"max-content"}
                            name={'Exporter Vers Excel'}
                        />
                    </div>
                  </div>
                    <div className={styles['row-end']} style={{gap: '2rem'}}>
                        <Button 
                            name={"Fiche de Stock"}
                            width={"max-content"}
                            path={'/fiche_stock_produit'}
                        />
                        <Button 
                            name={"Fiche de Stock Detaillèe"}
                            width={"max-content"}
                            path={'/fiche_stock_detaillee'}
                            />
                        <Button 
                            name={"Détail Stock Inventaire"}
                            width={"max-content"}
                            path={'/detail_stock_inventaire'}
                        />
                    </div>
            <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={data} 
                    edit={user.permissions.includes(permissions.canEditInTable)} 
                    remove={user.permissions.includes(permissions.canDeleteFromTable)}
                />
            </div>
            </div>
        </div>
    </PageLayout>
  )
}

export default StockInvetaire;

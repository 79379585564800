import React, { useEffect,useState,useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVentesGlobalParClient.module.scss'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import bonVentesFinder from '../../api/bonVentesFinder'
import clientsFinder from '../..//api/clientsFinder'
import metaData from './EtatVentesGlobalParClient.json'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVentesGlobalParClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [ inputValue , setInputValue ] = useState('');
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const total_vente = useRef();
  const total_retour = useRef();
  const global_amount = useRef();
  const getSelectedClient = useRef();

  useEffect(() => {
    const getClient = async () =>{
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
    }
    
    getClient();
  }, []);

  const displayData = async (client) =>{
    let articlesAchete = [], articlesAvoir = [];
    let montant_ventes = 0.0;
    let montant_retour = 0.0;
    let max_id = -1;
    const fetchArticlesVentesAvoir = async () =>{
      
      const bons_vente = await bonVentesFinder.get('/');
      const bons_avoir = await bonsAvoirFinder.get('/vente');

      // FILTER THE TABLE BY THE CLIENT ID
      const filtred_ventes = bons_vente.data.data.bons.filter( bon => bon.id_client === client.id);
      const filtred_avoirs = bons_avoir.data.data.bons.filter( bon => bon.id_client === client.id);

      max_id = bons_vente.data.data.code.max;

      filtred_ventes.map((bon)=>{

        let montant = bon.amount * (1 - (bon.remise/100));
        montant_ventes += montant;

        articlesAchete.push({
          id: bon.id,
          code: bon.code,
          date: bon.date.substring(0,10),
          Vente_av: 'Vente',
          montant
        });
      });

      filtred_avoirs.map((bon)=>{
        // calculate the montant by remise
        let montant = bon.amount * (1 - (bon.remise/100));
        montant_retour += montant; 

        let id = bon.id + max_id;
          articlesAvoir.push({
            id,
            code: bon.code,
            date: bon.date.substring(0,10),
            Vente_av: 'Avoir',
            montant
          });
      });

      total_vente.current?.setValue(montant_ventes);
      total_retour.current?.setValue(montant_retour);  
      global_amount.current?.setValue(montant_ventes - montant_retour);
      setData([...articlesAvoir,...articlesAchete]);
    }

    fetchArticlesVentesAvoir();
  };

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Par Clients ( global )'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              id={'client'}
              label={"Code Client"}
              holderText={"Client"}
              type={"text"}
              labelPosition={true}
              width={"50%"}
              searchData={searchData}
              onSelectItem={displayData}
              ref={getSelectedClient}
              displaySearchOption={true}
              path={"/ajouter_client"}
              btnName={'Ajouter Nouveau client'}
              />  

            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"250px"}
                name={'Imprimer'} 
                />
            </div>
          </div>
          <div className={styles['row']}>
            <InputText
              label={"Jour 1"}
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              reportChange={reportDate1Change}
              />
              <InputText 
                label={"Jour 2"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate2Change}
                />
          </div>
          
          <div style={{height: "300px"}}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
              date1={date1} date2={date2} />
          </div>

          <div className={styles['row-between']} style={{width:'100%'}}>
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant des Ventes'}
                ref={total_vente}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant des Retours'}
                ref={total_retour}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant Global'}
                ref={global_amount}
                disabled
                />
          </div>
        </div>
        
    </div>
    </PageLayout>
  );
}

export default EtatVentesGlobalParClient;
import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import styles from './bonDeLivraison.module.scss'
import InputText from '../../components/input-text/InputText'
import SubInfo from '../../components/sub-info/SubInfo'
import Button from '../../components/button/Button'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import SelectInput from '../../components/select-input/SelectInput'
import Table from '../../components/table/Table'
import bonVentesFinder from '../../api/bonVentesFinder'
import clientsFinder from '../../api/clientsFinder'
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import articleLivreFinder from '../../api/articleLivreFinder'
import { useNavigate } from 'react-router-dom'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import metaData from './ventes.json';
import depotFinder from '../../api/depotFinder'
import depotsStockFinder from '../../api/depotsStockFinder'
import Printing from '../../components/printing/Printing'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import creditClientFinder from '../../api/creditClientFinder'
import { fixDecimalDigits } from '../../utils/numberFormatter'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'

const BonDeLivraison = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const navigate = useNavigate();

  // Get related clients
  const [ clientsList, setClientsList ] = useState([]);
  const clientsInputRef = useRef([]);

  useEffect(() => {
    const getClients = async ()=>{
      try {
        const response = await clientsFinder.get("/");
        setClientsList(response.data.data.clients);
      }
      catch (err) {
        console.log(err.message);
      }
    }
    
    getClients();
  }, []);

  // Set code after initial render
  const codeInputRef = useRef(null);

  useEffect(() => {
    const setBonCode = async () => {
      try {
        const response = await bonVentesFinder.get('/');
        const code = response.data.data.bons.length > 0 ? parseInt(response.data.data.max) + 1 : 1;
        codeInputRef.current.setValue(code);
      }
      catch (err) {
        console.log(err.message);
      }
    }
    
    setBonCode();
  }, []);
  
  // Set date input ref and intialize its value after first render
  const dateInputRef = useRef(null);
  useEffect(() => dateInputRef.current.setValue(new Date().toISOString().split('T')[0]), []);
  
  // Get related depots
  const [ depotsList, setDepotsList ] = useState([]);
  const depotsInputRef = useRef(null);
  
  useEffect(() => {
    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepotsList(response.data.data.depots); 
      }
      catch (err) {
        console.log(err.message);
      }
    }
    
    fetchDepots();
  }, []);

  // The rest of side page elements
  const totalSansRM = useRef();
  const remiseR = useRef();
  const discountTotalRef = useRef();
  const total = useRef();
  const paymentInput = useRef(null);

  // Other elements
  const articlesRef = useRef();
  const adderTableRef = useRef([]);
  const tableRef = useRef([]);

  // Set table's columns
  useEffect(() => {
    const livreCol = metaData.table.find(el => el.name === 'Bloque ?');
    if (livreCol) livreCol.children = [{id: 0, name: 'Oui'}, {id: 1, name: 'No'}];
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      const response = await depotsStockFinder.get("/");

      const filtredArticles = response.data.data.articles.filter((article) => article.type === 'matierefini')
        .map(({ type, ...rest }) => reorderArticleProps(rest));

      articlesRef.current = filtredArticles;
    } 
    
    fetchArticles();
  }, [])

  const reorderArticleProps = (article) => {
    return {
      id: article.id,
      codeArticle: article.code,
      codeFamille: article.famille_code || '-',
      designation: article.designation,
      lotNbr: article.ds_lot_nbr,
      expireDate: dateFormatter(article.ds_expire_date, 3),
      quantity: article.ds_quantity || 0,
      ug: article.ug,
      puAchat: article.pu_achat,
      prixVenteGros: article.prix_vente_gros,
      puDetail: article.pu_detailer,
      prixVenteDemiGros: article.prix_vente_semi_gros,
      puSpecial: article.pu_special,
      unitMes: article.unit_mes || '-',
      qtyCart: article.qte_cart,
      nbCart: article.nb_cart,
      puFact: article.pu_fournisseur || 0,
      tva: article.tva,
      stkInit: article.stq_init,
      prixAchatInit: article.prix_achat_init,
      prixVenteInit: article.prix_vente_init,
      stockAlert: article.stock_alert,
      weight: article.weight,
      volume: article.volume,
      blocked: article.blocked ? 'Oui' : 'No',
      depotName: article.depot_name || '-',
      observation: article.observation || '-'
    }
  }

  const reorderArticleProps2 = (article) => {
    return {
      id: article.id,
      codeArticle: article.codeArticle,
      codeFamille: article.codeFamille || '-',
      designation: article.designation,
      lotNbr: article.lotNbr,
      expireDate: dateFormatter(article.expireDate, 3),
      quantity: article.quantity || 0,
      ug: article.ug,
      puAchat: article.puAchat,
      prixVenteGros: article.prixVenteGros,
      puDetail: article.puDetail,
      prixVenteDemiGros: article.prixVenteDemiGros,
      puSpecial: article.puSpecial,
      unitMes: article.unitMes || '-',
      qtyCart: article.qtyCart,
      nbCart: article.nbCart,
      puFact: article.puFact || 0,
      tva: article.tva,
      stkInit: article.stkInit,
      prixAchatInit: article.prixAchatInit,
      prixVenteInit: article.prixVenteInit,
      stockAlert: article.stockAlert,
      weight: article.weight,
      volume: article.volume,
      blocked: article.blocked ? 'Oui' : 'No',
      depotName: article.depotName || '-',
      observation: article.observation || '-'
    }
  }

  // Calculate client credit upon client selection
  const clientCreditElRef = useRef();

  const calclateCredit = async (client) => {
    try {
      const response = await creditClientFinder.get(`/${client.id}`);
      const { credit } = response.data.data;
      clientCreditElRef.current.setValue(credit);
    }
    catch (err) {
      console.log(err.message);
    }
  }
  
  // Handle article addition and removal into and from table
  const handleDepot = (selectedDepot) => {
    adderTableRef.current.setAllData(articlesRef.current.filter((article) => article.depotName === selectedDepot.name))

    if(selectedDepot.id !== depotsInputRef.current.getSelectedItem().id) {
      tableRef.current.setAllData([]);
    }
  }

  const adderTableDialogRef = useRef([]);

  const showAdderTable = () => {
    // Check first if depot is selected
    if(!document.getElementById('depot').value){
      errorsHandler.current.errorsHandler([{name: 'depot', message: 'Assurez que le Depot est bien selectionez'}]);
      depotsInputRef.current.setBorderRed();
      return
    }

    adderTableDialogRef.current.display()
  }

  const adjestArticlePropsToTable = (article) => {
    return {
      id: article.id,
      codeArticle: article.codeArticle,
      designation: article.designation,
      lotNbr: article.lotNbr, 
      quantity: article.quantity,
      quantityart: 0,
      ugOp: '-',
      puVente: article.prixVenteGros,
      remise: 0,
      total: 0,
      expireDate: dateFormatter(article.expireDate, 2),
      puRemise: 0,
      weight: article.weight,
      volume: article.volume,
      blocked: article.blocked,
      depotName: article.depotName,
      observation: article.observation || ''
    }
  }

  const addSelectedArticles = async () => {
    const newData = adderTableRef.current.sendSelectedItems().map((article) => adjestArticlePropsToTable(article));
    tableRef.current.setAllData([...tableRef.current.getAllData(), ...newData]);
  }

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    const article = articlesRef.current.find(item => item.id === itemToDeleteId);
    adderTableRef.current.addItem(reorderArticleProps2(article));
  }

  const actOnValidation = async (article, prevArticleVals) => {
    article.puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
    
    // If the quantity to be selled changed after validation use this formula
    if (article.quantityart !== prevArticleVals.quantityart || article.quantityart === '-') {
      const articleUg = articlesRef.current.find(item => item.id === article.id).ug;
      article.ugOp = Math.floor(article.quantityart * (articleUg / 100));
    }
    
    article.total = fixDecimalDigits((article.quantityart * article.puVente) * (1 - (article.remise / 100)), 2, true);

    tableRef.current.updateRow({...article, editable: false});

    calculateTotal();
  }

  const calculateTotal = () => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puVente * article.quantityart, 0);

    totalSansRM.current.setValue(amountBeforeDiscounts);

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
    return accumulator += fixDecimalDigits(((article.puVente - article.puRemise) * article.quantityart), 2, true);
    }, 0);

    discountTotalRef.current.setValue(articlesDiscountsTotal);
    // Display the final amount
    total.current.setValue(fixDecimalDigits(amountBeforeDiscounts - articlesDiscountsTotal), 2, true);

    updateRMInput(articles)
  }
  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
      article.total = (article.puVente * article.quantityart - ((article.puVente * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  // Check for errors
  const errorsHandler= useRef([]);

  const codeExists = async (value) => {
    const response = await bonVentesFinder.get(`/${value}`)
    return response.data.data[0]?.code === value;
  }

  const checkQuantityStq = (articles) => {
    const errM = []
    articles.filter((article) => {
      if(article.quantityart !== '')
        return parseInt(article.quantityart) + parseInt((article.ugOp) / 100) > parseInt(article.quantity)
      else
        errM.push({name:'quantity', message:`la quantité de code d'article: ${article.codeArticle}/Lot: ${article.lotNbr} que vous voulez vendre est vide`})
    }).map((article) => {
       errM.push({name:'quantity', message:`la quantité de code d'article: ${article.codeArticle}/Lot: ${article.lotNbr} que vous voulez vendre est supérieure à la quantité que vous avez`})
    });
    return errM
  }

  const checkErrs = async () => {
    const alerts = [];
    const code = document.getElementById("code").value;
    const client = document.getElementById("Client").value;
    const idClient = client && clientsInputRef.current.getSelectedItem().id;
    const remise = document.getElementById("remise").value
    const depots = document.getElementById("depot").value
    
    if(!remise) {
      alerts.push({name: 'remise', message: 'Assurez que la remise is bien saisez'});
      remiseR.current.setBorderRed();
    }

    if(!code) {
      alerts.push({name: 'code', message: 'Assurez que la code is bien saisez'});
      codeInputRef.current.setBorderRed();
    }

    if(!idClient) {
      alerts.push({name: 'client' , message: 'Assurez que la client est bien selectionez'});
      clientsInputRef.current.setBorderRed();
    }

    if(await codeExists(code)) {
      alerts.push({name: 'code', message: 'le N° du bon est deja exister'});
      codeInputRef.current.setBorderRed();
    }

    if(!depots) {
      alerts.push({name: 'depot' , message: 'Assurez que le Depot est bien selectionez'});
      depotsInputRef.current.setBorderRed();
    }

    if(tableRef.current.getAllData().length === 0) {
      alerts.push({name: 'articles' , message: 'Assurez que la Articles est bien selectionez'});
    }

    const checkQuantity = checkQuantityStq(tableRef.current.getAllData());
    if(checkQuantity.length > 0) {
      alerts.push(...checkQuantity);
    }
    
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return;
    }

    handelValidate();
  }

  const handleSubmit = async (e) => {
    try {
        e.preventDefault();

        // const depotId = parseInt(depotsInputRef.current.getSelectedItem().id)

        // const response = await bonVentesFinder.post("/", {
        //   clientId: clientsInputRef.current.getSelectedItem().id,
        //   depotId,
        //   code: document.getElementById("code").value,
        //   date: dateInputRef.current.getValue(),
        //   typePayment: paymentInput.current.getSelectedOption(),
        //   typeRemise: 'parcentage',
        //   remise: document.getElementById("remise").value,
        //   amount: total.current?.getValue()
        // });
  
        // tableRef.current.getAllData().forEach(async (articleData) => {
        //   await depotsStockFinder.post(`/`, {
        //     id_depot: depotId,
        //     id_depot_stock: articleData.id,
        //     quantity: parseInt(articleData.quantityart) + parseInt(articleData.ugOp),
        //     lot_nbr: articleData.lotNbr,
        //     operation: 'sub'
        //   });

        //   await articleLivreFinder.post('/', {
        //     bonLivraisonId: response.data.data.bon.id, 
        //     id_depot_stock: articleData.id, 
        //     quantity: parseInt(articleData.quantityart), 
        //     ug: parseFloat(articleData.ugOp),
        //     remise: parseInt(articleData.remise),
        //     delivered: articleData.delivered === "Oui" ? true : false,
        //     observation: articleData.observation,
        //     prixVente: parseInt(articleData.puVente)
        //   });
        // });
  
        validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
        setTimeout(()=> {
          navigate(-1)
        }, 1000);
      } 
      catch (err) {
        console.log(err.message)
      }
  } 
  const validateHandler = useRef([]);

  const displayConfirmationDialog = useRef();
  const handelValidate = () => displayConfirmationDialog.current.display();

   // To be changed >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
   const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "10%"},
    {name: "Qte",width: "5%"},
    {name: "UG",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]
  const printRef = useRef();

  const [ isActive, setIsActive ] = useState(false);
  const [ info, setInfo ] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getAllData();
    
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }

    setIsActive(true);

    const articlesAdded = rowInfo.map((art) => {
      return {
        code: art.codeArticle,
        designation: art.designation,
        lotNbr: art.lotNbr,
        expireDate: art.expireDate,
        quantity: art.quantityart,
        ug: art.ugOp,
        PUVente: fixDecimalDigits(art.puVente),
        remise: art.remise,
        total: fixDecimalDigits(art.total)
      }
    });

    const ROWS_PER_PAGE = 15; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    const totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);

    const pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );

    const data = {
      rowInfo: {
        code: document.getElementById("code").value,
        client_name: clientsInputRef.current.getSelectedItem().name,
        date: document.getElementById("date").value,
        client_address: clientsInputRef.current.getSelectedItem().address,
        client_activite: clientsInputRef.current.getSelectedItem().activity,
        payment_type: paymentInput.current.getSelectedOption(),
        client_n_register: clientsInputRef.current.getSelectedItem().n_register,
        client_n_article: clientsInputRef.current.getSelectedItem().n_article,
        client_nif: clientsInputRef.current.getSelectedItem().nif,
        client_n_fiscal: clientsInputRef.current.getSelectedItem().n_fiscal,
        client_nis: clientsInputRef.current.getSelectedItem().nis
      },
      articlesAdded: pages,
      saisiPar: user.name,
      globalInfo: {
        totalBeforeDiscounts: totalSansRM.current.getValue(),
        totalDiscounts: discountTotalRef.current.getValue(),
        totalAfterDiscounts: total.current.getValue()
      }
    }
    
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
  }
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  return (
    <PageLayout icon={icons.achat} nameLayout={'Vente'}>
      <form onSubmit={handleSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row-evenly']}>
              <InputText  
                holderText={"Chercher un Client"} 
                width={"50%"} 
                labelPosition={true}
                type={"text"} 
                label={'Client'}
                id={'Client'}
                onSelectItem={calclateCredit}
                path={"/ajouter_client"}
                btnName={'Ajouter Nouveau Client'} 
                ref={clientsInputRef}
                searchData={clientsList}
              />
                <SubInfo  
                  lined={true}
                  label={"Credit Client: "} 
                  unit={"DZD"}
                  ref={clientCreditElRef}
                />

                <Button 
                  link={true} 
                  path={"../reglement_client"}  
                  name={'Reglement Client'} 
                  width={"300px"} 
                  icon={icons.reglementClient}
                />
              </div>

              <div className={styles['row-evenly']}>
                <Button 
                  width={"250px"} 
                  name={"Ajouter Article"}
                  onclick={showAdderTable}
                />
                <Button 
                  icon={icons.impr}
                  width={"24%"}
                  name={'Imprimer'}
                  onclick={handlePrint}
                />
              </div>

              <div style={{height: "450px"}}>
                <Table 
                  tbody={articlesRef.current ? tableRef.current?.getAllData() : []}
                  thead={metaData.table} 
                  actOnValidation={actOnValidation} 
                  confirmBeforeDeletion={false}
                  ref={tableRef} 
                  edit={user.permissions.includes(permissions.canEditInTable)} 
                  remove={user.permissions.includes(permissions.canDeleteFromTable)} 
                  handleDelete={handleDelete}
                />
                  <div style={{display: 'flex',gap: '50px',paddingTop:'10px'}}> 
                    <SubInfo lable={"Poids"} inline unit={"KG"}/>
                    <SubInfo lable={"Volume"} inline unit={"L"}/>
                  </div>
              </div>
            </div>
          </div>
          <div className={styles['side-body']}>
          <PageSideBar validateFunc={checkErrs}>
            <div className={styles['col-around']}>

              <div className={styles['col-evenly']}>
                <InputText  id={"code"}
                            label={"N° du bon"} 
                            holderText={"0001"}
                            width={"100%"}
                            type={"number"}
                            ref={codeInputRef}
                            disabled
                            />

                <InputText  
                  id={'date'}
                  label={"Date de bon"}
                  width={"100%"}
                  type={"date"}
                  ref={dateInputRef}
                />
                
                <SelectInput  id={'type_payment'}
                              options={["Espece", "Cheque", "Verement", "Autre"]}
                              label={"Type de paiement"}
                              width={"100%"}
                              ref={paymentInput}
                            />
                <InputText label={"Depot"} 
                  width={"100%"}
                  holderText={'Depot'}
                  id={'depot'}
                  searchData={depotsList}
                  onSelectItem={handleDepot}
                  ref={depotsInputRef}/>
              </div>

              <div className={styles['col-evenly']}>

                <SubInfo  
                  label={"Total sans remise"}
                  unit={"DZD"}
                  ref={totalSansRM}
                />

                <div className={styles['row-between']}>
                  <InputText  
                    id={"remise"}
                    label={"Remise"} 
                    labelPosition={true}
                    currency={true} 
                    width={"100%"} 
                    type={"number"} 
                    defaultValue={'0'}
                    holderText={"0.00"}
                    currencyValue={"%"}
                    ref={remiseR}
                    reportChange={updateTaxes}
                  />
                </div>

                <SubInfo
                  label={"Total de remise"} 
                  unit={"DZD"}
                  ref={discountTotalRef}
                />

                <SubInfo
                  label={"Total"} 
                  unit={"DZD"}
                  ref={total}
                />
                
              </div>
            </div>
          </PageSideBar>
        </div>

        <Dialog width={"80%"} table={true} ref={adderTableDialogRef} validateFunc={addSelectedArticles}>
          <AdderTable head={metaData.adderTable} ref={adderTableRef} />
        </Dialog>
      </div> 
      <ConfirmationButton ref={displayConfirmationDialog}/>
    </form>

    <ErrorContainer ref={errorsHandler}/>
    {isActive && <Printing type={'Bon De Livraison'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ValidatedMessages ref={validateHandler}/>
    </PageLayout>
  )
}

export default BonDeLivraison;

import React, { useEffect } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationbonavoirventes.module.scss'
import { useState } from 'react'
import SubInfo from '../../components/sub-info/SubInfo'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const ConsultationBonAvoirVentes = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead_bonLivraison = [
    {name:"Vente/Av",width: 190},
    {name:"N° Bon",width: 190},
    {name:"Date",width: 490},
    {name:"Montant",width: 190},
    {name:"Code Client",width: 190},
    {name:"Client",width: 190},
    {name:"Solde",width: 190},
    {name:"Total Remise",width: 190},
    {name:"Règelement",width: 190},
    {name:"Saisie Par",width: 190},
    {name:"N° impression",width: 190},
    {name:"servie Par" ,width: 190},
    {name:"Bon bloquè?",width: 190},
    {name:"Observation",width: 190}
  ]
  const thead_detailleBon = [
    {name:"Code",width: 190},
    {name:"Quantitè",width: 190},
    {name:"RM%",width: 190},
    {name:"PU",width: 190},
    {name:"Total",width: 190},
    {name:"Dèsingation",width: 490},
    {name:"Qte Stock",width: 190},
    {name:"Livre",width: 190},
    {name:"Date Livr.",width: 190},
    {name:"Date Prèremption",width: 190},
    {name:"Saisie Par",width: 190},
    {name:"Observation ",width: 490}
  ]
    
  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([])

  useEffect(() => {
    const fetchBonsAchat = async () => {
      let vente = 'vente';
      const response = await bonAvoirFinder.get(`/${vente}`);
      console.log(response.data);
      setBonsInfo(response.data.data.bons);
      setData(response.data.data.bons.map((bon)=>{
        return(
          {
            id: bon.id,
            venteAv: 'vente',
            code: bon.code,
            date: bon.date.substring(0, 10),
            amount: bon.amount * (1 - bon.remise >= 1 ? bon.remise/100 : bon.remise),
            codeClient: bon.client_code,
            client: bon.client_name,
            sold: 0,
            totalRemise: bon.remise,
            reglementClient: bon.payment_type,
            saisiPar: bon.articlesAdded[0].user_name ,
            nbImprission: 0,
            serviPar: bon.articlesAdded[0].user_name ,
            blocked: bon.blocked,
            observation: bon.observation
          }
        )
      }))
    }
    fetchBonsAchat();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([]);
      return
    }

     bonsInfo.map((element)=>{
      if(element.id === rowInfo.id)
        setDataSuiv(
         element.articlesAdded?.map((article,index)=>{
          return {
            id: article.id || index,
            code: article.code,
            quantity: article.article_avoir_quantity,
            remise: article.article_avoir_remise,
            PUVente: article.article_avoir_prix_achat,
            total: article.article_avoir_prix_achat * article.article_avoir_quantity,
            designation: article.designation,
            quantityStq: article.ds_quantity,
            delivred: article.delivred,
            delivredDate: article.delivred_date,
            datePreremption: article.expire_date,
            saisiPar: article.user_name,
            observation: article.observation
          }
        })
      )
    })
  }

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['code'];
  const handleSearch = (val) => {
    setInputValue(val);
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Bon Avoir Ventes'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Par N° Bon"}
                label={"Chercher"}
                labelPosition={true}
                width={"30%"}
                reportChange={(val) => handleSearch(val)}
                />  
                <div className={styles['row-end']} style={{minWidth: 'max-content'}}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                    />
                <Button 
                  icon={icons.reglementClient}
                  link={true} 
                  path={"../reglement_client"} 
                  width={"max-content"}
                  name={'Réglement Client'} 
                    />
                </div>
              </div>

              <div style={{height: '300px'}}>
                <Table 
                  tableTitle={"Bon de Livraison"} 
                  thead={thead_bonLivraison} 
                  sendSelectedRowInfo={handleClick} 
                  singular={true}
                  filterInput={inputValue}
                  filterByArr={filterByArr}
                  tbody={data} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
              <div className={styles['row-end']} style={{width:'100%',paddingTop: '2rem'}}>
                <SubInfo inline label={"Sold fournisseur :"} unit={"DZD"}/>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Détail de Bon"} thead={thead_detailleBon} tbody={dataSuiv} />
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ConsultationBonAvoirVentes
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printingReceptionTransfer.module.scss';
import { prints } from '../../media/mediaHolder';
import NumberConverter from '../number-converter/NumberConverter';
import printingFinder from '../../api/printingFinder';

const PrintingReceptionTransfer = forwardRef( (props, ref) => {

  const {dataInfo, thead, type, pageNumber, pageNumbers, printingHeader} = props


  const timeoutRef = useRef();
  const articlesProps = useRef([]);
  useEffect(()=>{
    const handlePrinting = ()=>{
      const articlesAdded = dataInfo.articlesAdded || []
  
      articlesProps.current = [];
      for (const key in articlesAdded[0]) {
        articlesProps.current.push(key);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    handlePrinting();
  },[dataInfo.articlesAdded])
  const [header ,setHeader] = useState();
  useEffect(() => {
    const fetchHeader = async ()=>{
      const printingHead = await printingFinder.get('/');
      console.log(printingHead)
      setHeader(printingHead.data.data.printingHead[0])
    }
    fetchHeader();
  },[])

  return (
    <>
      <div className={style['header-container']}>
        <div className={style['logo-container']}  style={{backgroundImage: `url(${prints.logo})`}}></div>
        <div className={style['info-container']}>
          <p className={style['title-info']}>{printingHeader?.line_1}</p>
          <div className={style['sub-info-container']}>
            <p className={style['sub-title-info']}>{printingHeader?.line_2}</p>
            <p className={style['sub-title-info']}>{printingHeader?.line_3}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_4}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_5}</p>
          </div>
        </div>
      </div>
      <div className={style['Line']}>
        <div className={style['first-line']}></div>
        <div className={style['second-line']}></div>
      </div>
      <div className={style['body-container']}>
        <div className={style['main-title-container']}>
          <p className={style['title-number']}>{type} N°</p>
          <p className={style['title-number']}>{dataInfo.rowInfo.code}/{dataInfo.rowInfo.date.substring(0,4)}</p>
        </div>
        <div className={style['info-container-type2']}>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']} style={{gap: '100px'}}>
              <div className={style['head-lines-container']}>
                <p className={style['head-lines-type1']}>Lieu</p>
                <p className={style['head-lines-type2']}>:</p>
                <p className={style['head-lines-type3']}>{dataInfo.rowInfo.depot_name}</p>
              </div>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Date</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.date.substring(0,10)}</p>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {<div className={style['head-lines-container']}>
                <p className={style['head-lines-type1']}>Destination</p>
                <p className={style['head-lines-type2']}>:</p>
                <p className={style['head-lines-type3']}>{dataInfo.rowInfo.arrivalDepot}</p>
              </div>}
            </div>
            <div className={style['head-lines-container']}>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['client-info-container']}>
              {dataInfo.rowInfo.arrivalDepotAddress && <><p className={style['head-lines-type1']}>Address</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.arrivalDepotAddress}</p></>}
            </div>
            <div className={style['bar-code']}>Bar Code</div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Page</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type1']}>{pageNumber}/{pageNumbers}</p>
            </div>
          </div>
        </div>
        <div className={style['table-container']}>
          <table className={style['table']}>
            <colgroup>
              {thead.map((obj)=>{
                  return <col style={{width: obj.width}} className={style['col1']}/>
              })}
            </colgroup>
            <thead>
              <tr>
                {thead.map((head, index)=>{
                    return(
                        <th key={index} className={style['table-head']}>
                            {head.name} 
                        </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {dataInfo.articlesAdded.map((values, index) => {
                  return(
                    <tr key={index}>
                      {
                        articlesProps.current.map((key, index) => {
                          return(
                              <td className={style['td-cell']} key={index}>
                                {values[key]}
                              </td>
                            )
                        })
                      }
                    </tr>
                  )
                })}
                <tr key={9999} style={{height: "100%"}}></tr>
            </tbody>
          </table>
        </div>
        <div className={style['amounts-container']} style={{marginTop: "40px"}}>
          <div className={style['amounts-letters']}>
            <p className={style['head-lines-type1']}>Moyen De Transport:</p>
            <div className={style['head-lines-container-type3']}>
              <p className={style['head-lines-type1']}>Camion</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.truck}</p>
            </div>
            <div className={style['head-lines-container-type3']}>
              <p className={style['head-lines-type1']}>Chauffeur</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.driver}</p>
            </div>
          </div>
          <div className={style['amounts-numbers']}>
          </div>
        </div>
      </div>
      <div className={style['page-footer']}>
        <div className={`${style['Line']} ${style['footer-line']}`}>
          <div className={style['first-line']}></div>
          <div className={style['second-line']}></div>
        </div>
        <div className={style['head-lines-container']}>
          {dataInfo.saisiPar &&<>
          <p className={style['head-lines-type4']}>Saisi par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.saisiPar}</p></>}
          {dataInfo.validerPar &&<>
          <p className={style['head-lines-type4']}>Valider par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.validerPar}</p></>}
        </div>
      </div>
      </>
  )
})

export default PrintingReceptionTransfer

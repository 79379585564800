import React from 'react';
import Mods from '../../components/mods/Mods';
import PageOutline from '../../components/page-outliner/PageOutliner';

const Home = () => {

  return (
    <PageOutline>
      <Mods />
    </PageOutline>
  )
}

export default Home
import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './bordereauDesCheques.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'

const BordereauDesCheques = () => { 
  const thead = [
    {name:"Date Saisie",width: 190},
    {name:"N° Chèque",width: 190},
    {name:"Titeur",width: 490},
    {name:"Montant",width: 190},
    {name:"Saisi Par",width: 190}
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Bordereau Des Cheques'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div style={{display: 'flex' , width: '100%'}}>
              <InputText width={'20%'} labelPosition={true} holderText={"Chercher"}  label={'Code Compte'}
              margin={'0px 0px 0px 15px'}/>
              <InputText width={'30%'} labelPosition={true} holderText={"Chercher"}  label={'Compte Bancaire'}
              margin={'0px 0px 0px 15px'}/>
              <Button name={'Remise De Chaques a La Banque'} height={'100%'} width={"300px"}
              margin={'0px 0px 0px 15px'}/>
            </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          <div className={styles['periode']}>
            <div style={{display: "flex" , width:"50%"}}>
                <InputText width={'30%'} type={'date'} label={'Date De Remise'} margin={'0px 0px 0px 15px'}/>
            </div>
            <div style={{display: "flex" , width:"30%" , justifyContent: "flex-end"  ,
                        marginRight: "15px"}}>
                <InputText width={'40%'} holderText={'Chercher'} label={'Chercher'} margin={'0px 0px 0px 15px'}/>
            </div>
            
          </div>
          <div className={styles['table']} style={{height: "350px"}}>
            <Table thead={thead} inputValue={inputValue} tbody={search} edit={true} remove={true} />
          </div>
          <div className={styles['mantant-chargers']}>
            <div></div>
            <div style={{display: "flex"}}>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Montant Des Chaques Saisie'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default BordereauDesCheques

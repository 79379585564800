import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Table from '../table/Table';
import InputText from '../input-text/InputText';

const AdderTable = forwardRef((props, ref) => {
  const { head } = props

  const [tableData, setTableData] = useState([]);

  const table = useRef([]);

  useImperativeHandle(ref, () => ({
    /* Get select items only gets you the selected items without clearing them out
      from the table */
    getSelectedItems: () => table.current.getSelectedItems(),
    /* This function gets you the selected items plus it clears out the selected
      items from the table */
    sendSelectedItems: () => {
      setTableData(tableData.filter(item => !table.current.getSelectedItems().find(i => i.id === item.id)));
      return table.current.getSelectedItems();
    },
    addItem: (item) => setTableData([...tableData, item]),
    setAllData: (data) => setTableData(data),
    getAllData: () => tableData
  }));
    
  return (
    <>
      <InputText holderText={'Checher'} width={'30%'} label={'Chercher Un Article'} labelPosition={true} />
      <div style={{height: "250px"}}>
        <Table thead={head} tbody={tableData} ref={table} />
      </div> 
    </> 
  )
})

export default AdderTable;

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './balance.module.scss'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'

const Balance = () => {
  return (
    <PageLayout icon={icons.article} nameLayout={'La Balance'}>
      <div className={styles['balance-container']}>
        <div className={styles['operations-buttons']}>
            <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
        </div>

        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'1. Montant Du Stock (en PU achat)'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'2. Credit Client'}/>
        </div>
        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'3. Credit (Client -- Fournisseurs)'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'4. Credit fournisseurs'}/>
        </div>
        <div className={styles['balance-line3']}>
            <InputText width={'48%'} type={"number"} holderText={"Montant"} label={'Total'}/>
        </div>
      </div>
    </PageLayout>
  )
}

export default Balance

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './listChequeRemiseaBanque.module.scss'
import Button from '../../components/button/Button'

const ListChequeRemiseABanque = () => {
    const thead = [
    {name:"N° Bord.", width: 190},
    {name:"Date", width: 190},
    {name:"Titeur", width: 390},
    {name:"N° Chèque", width: 190},
    {name:"Montant", width: 190},
    {name:"Saisie Par", width: 290}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Liste de cheque remise à la banque'}>
        <div className={styles['body-container']} style={{height: '100vh'}}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                    <InputText 
                        icon={icons.search}
                        type={"text"}
                        holderText={"Chercher"}
                        label={"Compte bencaire"}
                        labelPosition={true}
                        width={"50%"}
                        />  
                    <div className={styles['row-end']}>
                    <Button 
                        icon={icons.impr}
                        width={"250px"}
                        name={'Imprimer'}
                    />
                    </div>
                  </div>
                  <div>
                  <InputText 
                        icon={icons.search}
                        type={"text"}
                        holderText={"Chercher par Titeur"}
                        label={"Cheques"}
                        width={"50%"}
                        labelPosition={true}
                        />  
                  </div>
            <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} edit={true} remove={true}/>
            </div>
                <div className={styles['row-end']}>
                    <InputText 
                        type={"number"}
                        holderText={"Montant"}
                        label={"Montant des Chèques Saisie"}
                        width={"30%"}
                        />  
                </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ListChequeRemiseABanque
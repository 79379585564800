import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatVentesDetailleParFamille.module.scss'
import Button from '../../components/button/Button'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVentesDetailleParFamille = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Stock",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Prix Grossiste",width: 190},
    {name:"PPA",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes détaillé par famille'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Client"}
                label={"Client"}
                labelPosition={true}
                width={"50%"}
                />
                <div className={styles['row-end']} style={{width: 'max-content'}}>
                <Button 
                  icon={icons.reglementClient}
                  width={"max-content"}
                  name={'Exporter Vers Excel'} 
                    />
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                    />
                </div>
              </div>
              <div className={styles['row-between']}>
                  <div className={styles['row']}>
                  <InputText
                    label={"Jour 1"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    />

                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    />

                  </div>
                    <InputText 
                    icon={icons.search}
                    type={"text"}
                    holderText={"Chercher"}
                    label={"Chercher"}
                    labelPosition={true}
                    width={"50%"}
                    />
              </div>
              <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}/>
              </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
                   <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Ventes'}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Achat'}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total du Bénéfice'}
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatVentesDetailleParFamille
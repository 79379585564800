import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatAchatFournisseurDetaille.module.scss'
import metaData from './EtatAchatFournisseurDetaille.json'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatAchatFournisseurDetaille = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const [ inputValue , setInputValue ] = useState('');
    const [data,setData] = useState([]);

    const [searchData, setSearchData] = useState([]);

    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const getSelectedFournisseur = useRef();
    const total_achat = useRef();
    const total_retour = useRef();
    const total_versement = useRef();
    const sold = useRef();
    const global_amount = useRef();
    
    const displayData = async (fournisseur)=>{

      const articles_achat = await articlesAcheteFinder.get(`/`);
      const articles_avoir = await articlesAvoirFinder.get('/achat');
      const detteFournisseur = await detteFournisseurFinder.get(`/${fournisseur.id}`);

      const {total_verser} = detteFournisseur.data.data;
      const filtred_bons_achat = articles_achat.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);
      const filtred_articles_avoir = articles_avoir.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);

      const { max_id } = articles_achat.data.data;

      let articlesAchete = [], articlesAvoir = [];
      let montant_achat = 0.0;
      let montant_retour = 0.0;
      let remise_achat = .0;
      let remise_avoir = .0;
      let id_bon = -1;
      
      filtred_bons_achat.map(article => {
        // CALCULATE THE TOTAL PRICE OF ARTICLE INCLUDING IT PROMOTION
        let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
        //
        

        articlesAchete.push({
          id: article.id,
          achat_av: 'Achat',
          lot:article.lotNbr,
          codeArt: article.code,
          code: article.bon_achat_code,
          date: article.bon_achat_creation_date.substring(0,10),
          qtyAchetee: article.quantity, 
          prix: article.prix,
          remise: article.remise+ "%",
          montant,
          disgnation: article.designation
        });

        // CALCULATE THE TOTAL PRICE OF PURCHASES INCLUDING THE RECEIPTS PROMOTION
        if(parseInt(id_bon) !== parseInt(article.id_bon_achat)){
          // montant =   montant * (1 - (article.bon_achat_remise/100));
          remise_achat += article.bon_achat_remise;
          id_bon = article.id_bon_achat;
        }
        montant_achat += montant;
        // 

      });

      id_bon = -1;
      filtred_articles_avoir.map(article =>{
        // CALCULATE THE TOTAL PRICE OF ARTICLE INCLUDING IT PROMOTION
        let montant = (article.article_avoir_quantity * article.article_avoir_prix_achat) * (1 - (article.article_avoir_remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF PURCHASES INCLUDING THE RECEIPTS 
        if(parseInt(id_bon) !== parseInt(article.id_bon_avoir)){
          remise_avoir += article.bon_avoir_remise;
          id_bon = article.id_bon_avoir;
        }
        montant_retour += montant;
        // 
        
        let id = article.id + max_id;
        
        articlesAvoir.push({
          id,
          achat_av: 'Avoir',
          lot:article.lotNbr,
          codeArt: article.code ,
          code: article.bon_avoir_code,
          date: article.bon_avoir_date.substring(0,10),
          qtyAchetee: article.article_avoir_quantity , 
          prix: article.article_avoir_prix_achat,
          remise: article.article_avoir_remise+ "%",
          montant,
          disgnation: article.designation
        });
      });
      montant_achat = montant_achat*(1 - remise_achat/100);
      montant_retour = montant_retour*(1-remise_avoir/100);

      setData([...articlesAvoir,...articlesAchete]);
      let ga = montant_achat - montant_retour;
      let sold_total = (ga - total_verser).toFixed(2)
      total_achat.current?.setValue(montant_achat.toFixed(2));
      total_retour.current?.setValue(montant_retour.toFixed(2));
      global_amount.current?.setValue(ga.toFixed(2));
      total_versement.current?.setValue(total_verser);

      sold.current?.setValue(sold_total);
  }

  useEffect(() => {
    const getFournisseurs = async () =>{
        const four = await fournisseurFinder.get("/");
        setSearchData(four.data.data.fournisseurs);
    }
    
    getFournisseurs();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achats Par Fournisseur ( Détaillé )'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
              <InputText 
                icon={icons.search}
                id={'fournisseurs'}
                holderText={"fournisseur"}
                label={"Ajouter fournisseur"}
                width={"50%"}
                labelPosition={true}
                searchData={searchData}
                onSelectItem={displayData}
                ref={getSelectedFournisseur}
                displaySearchOption={true}
                path={"/ajouter_fournisseur"}
                btnName={'Ajouter Nouveau fournisseur'}
                />
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"150px"}
                  name={'Imprimer'} 
                    />
                <Button 
                  icon={icons.exporter}
                  width={"250px"}
                  name={'Exporter Vers Excel'} 
                    />
                </div>
              </div>
              <div className={styles['row']}>
                  <InputText
                    label={"Jour 1"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                </div>
                <div style={{height: "300px"}}>
                  <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
                    edit={user?.permissions.includes(permissions.canEditInTable)} 
                    remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                    date1={date1} date2={date2}/>
                </div>
              <div className={styles['row-between']} style={{width:'100%'}}>
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant des Achats'}
                  ref={total_achat}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant des Retours'}
                  ref={total_retour}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant Global'}
                  ref={global_amount}
                  disabled
                  />
              </div>
              <div className={styles['row-end']} style={{width:'100%'}}>
                <InputText  
                  holderText={"Total Verser"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant Verser'}
                  ref={total_versement}
                  disabled
                  />
                <InputText  
                  holderText={"Solde"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Solde'} 
                  ref={sold} 
                  disabled
                  />
              </div>
            </div>
    </div>
    </PageLayout>
  )
}

export default EtatAchatFournisseurDetaille
import React,{ useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import styles from './etatFournisseur.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatFournisseur = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
  
        const thead = [
        {name:"Code",width: 190},
        {name:"Code Familly",width: 190},
        {name:"Designation",width: 490},
        {name:"Stock",width: 190},
        {name:"Pu Achat",width: 190},
        {name:"Prix Grossiste",width: 190},
        {name:"PPA",width: 190}
      ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
    return (        
        <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={`Etat d'un Fournisseur`}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <div className={styles['row']}>
                  <InputText label={"Period du"} 
                    labelPosition={true} 
                    width={"max-content"} 
                    type={"date"}
                    />
                  <InputText label={"Au"} 
                    labelPosition={true} 
                    width={"max-content"} 
                    type={"date"}
                    />  
                </div>
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                    />
                </div>
              </div>
            <div>
            <DetailsSectionContainer name={"Détail de fournisseur"}>
              <InputText  
                  holderText={"001"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Code'}
                  />
                <InputText  
                  holderText={"fournisseur"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"text"} 
                  label={'fournisseur'}
                  />
                <InputText  
                  holderText={"Adresse"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"text"} 
                  label={'Adresse'}
                  />
            </DetailsSectionContainer>
            </div>

            <div style={{height: '300px'}}>
              <Table thead={thead} inputValue={inputValue} tbody={search} />
            </div>

            <div className={styles['row-end']} style={{width:'100%'}}>
              <InputText  
                holderText={"Solde"}
                setInputValue={setInputValue}
                inputValue={inputValue}
                type={"text"}
                label={'Solde'}
                />
            </div>
          </div>
    </div>
    </PageLayout>
  )
}

export default EtatFournisseur
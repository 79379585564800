import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatPeriodiqueDeCaisse.module.scss'
import Button from '../../components/button/Button'
import SubInfo from '../../components/sub-info/SubInfo'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatPeriodiqueDeCaisse = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const thead = [
    {name:"Date",width: 190},
    {name:"Designation",width: 490},
    {name:"Encaissment",width: 190},
    {name:"Sortie De Caisse",width: 190},
    {name:"Solde Caisse",width: 190},
    {name:"Observation",width: 490}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat Périodique de la caisse'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                  <div className={styles['row']} style={{width: '60%'}}>
                  <InputText
                    label={"Journée du "}
                    width={"max-content"}
                    type={"date"}
                    labelPosition={true}
                    />
                    <InputText
                    label={"Au"}
                    width={"max-content"}
                    type={"date"}
                    labelPosition={true}
                    />
                    <SubInfo width={"max-content"} side={false} name={"Solde Précédent :"} total={30000000} currency={"DZD"}/>
                    </div>
                    <div className={styles['row-end']} style={{width: '40%'}}>
                        <Button 
                        icon={icons.impr}
                        width={"150px"}
                        name={'Imprimer'}
                        />
                        <Button 
                        icon={icons.exporter}
                        width={"250px"}
                        name={'Exporter Vers Excel'}
                        />
                    </div>
                  </div>
                  <div style={{height: "350px"}}>
                    <Table thead={thead} inputValue={inputValue} tbody={search} 
                      edit={user?.permissions.includes(permissions.canEditInTable)} 
                      remove={user?.permissions.includes(permissions.canDeleteFromTable)}/>
                  </div>
            <div className={styles['row-between']} style={{gap: '2rem'}}>
                <InputText
                    label={"Montant Encaissé"}
                    holderText={"number"}
                    width={"max-content"}
                    type={"text"}
                    />
                <InputText
                    label={"Montant Sortie Caisse"}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                    />
                <InputText
                    label={"Solde Brut (Montant Encaissé - Montant Sortie Cais )"}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                />
                <InputText
                    label={"Solde Net"}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatPeriodiqueDeCaisse
import React, { useEffect, useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './creditClient.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import SelectInput from '../../components/select-input/SelectInput'
import clientsFinder from '../../api/clientsFinder'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'

const CreditClientFournisseur = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [value, SetValue] = useState(0);
  const [chiledValue, SetChiledValue] = useState(0);
  const [handelClasseSecondSelect , setHandelClasseSecondSelect] =useState("noDisplay")
  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  const [handelClasseSelect , setHandelClasseSelect] =useState("noDisplay")

  useEffect(()=>{
    const fetchData = async ()=>{
      try {
        const response = await clientsFinder.get('/credits')
        setData(response.data.data.credits)
        } catch (error) {
        console.log(error)
      } 
    }
    fetchData();
  },[])
  const options = [
    {
      value: 0,
      name: "Tous les Client"
    },
    {
      value: 1,
      name: "Les Clients Soldes"
    },
    {
      value: 2,
      name: "Les Clients non Soldes",
    }
  ]
   const children = [
    {
      value: 0,
      name: "Les Clients Qui Ont Depaser Leur Delais De Versement Ou Leur Sold Max"
    },{
      value: 1,
      name: "Les Clients Qui Ont Depaser Leur Delais De Versement Pour La Periode"
    },
  ]
  

  const thead = [
      {name:"Code Cli.", width: 190},
      {name:"Client", width: 190},
      {name:"Solde", width: 190},
      {name:"Dernier Montant Vers.", width: 190},
      {name:"Date du Dernier Vers.", width: 190},
      {name:"TEL/FAX", width: 190},
      {name:"Total Ventes", width: 190},
      {name:"Total Versements", width: 190},
      {name:"Wilaya", width: 190},
      {name:"Montant Max Autorise", width: 190},
      {name:"Diff. Solde", width: 190},
      {name:"Jour Limt.", width: 190},
      {name:"Nb de Jour Depasse", width: 190}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Credit Des Client'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div style={{width: "100%" ,display: 'flex' ,justifyContent: 'space-between'}}>
            <div style={{width: "80%" , display: 'flex' , gap: "10px"}}>
              <div className={styles['periode']}>
                <InputText width={'100%'} holderText={'Chercher'} label={'Chercher'}
                           margin={'0px 0px 0px 15px'}labelPosition={true} />
              </div>
              <div className={chiledValue == 1 ? styles['second-select-container'] : styles['noDisplay']}>
                <InputText width={'50%'} type={"date"} label={'Periode Du'}
                           margin={'10px 0px 0px 0px'}labelPosition={true} />
                <InputText width={'50%'} type={"date"} label={'Au'}
                           margin={'10px 0px 0px 0px'}labelPosition={true} />
              </div>
            </div>
              <div className={styles['operations-buttons']}>
                  <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
              </div>
          </div>
          
        <div className={styles['main-search-bar']}>
          <div className={value == 2 ? styles['second-select-container'] : styles['noDisplay']}>
            <SelectInput options={children} width={"100%"}
                         setOption={SetChiledValue}/>
          </div>
          <div className={styles['select-container']}>
              <SelectInput options={options} width={"100%"}
                           setOption={SetValue}/>
          </div>
        </div>
        
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} 
            edit={user?.permissions.includes(permissions.canEditInTable)} 
            remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
          />
        </div>
        <div className={styles['mantant-chargers']}>
          <div>
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total BL'}
              margin={'0px 15px 0px 0px'}/>
          </div>
          <div>
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Totale Verse'}
              margin={'0px 15px 0px 0px'}/>
          </div>
          <div style={{display: "flex"}}>
              
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Reste'}
              margin={'0px 15px 0px 0px'}/>
          </div>
          
        </div>
      </div>
    </PageLayout>
  )
}

export default CreditClientFournisseur;

import React, { useState } from 'react';
import styles from './profileGate.module.scss';
import { bgImgs } from '../../media/mediaHolder';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import permissions from '../../utils/permissions';

const ProfileGate = () => {
    const { state: authState, logout } = useAuth();
    const { user } = authState;
    const [ displayDD, setDisplayDD ] = useState(false);
    const navigate = useNavigate();
    
    const hideDD = () => setDisplayDD(false);

    const handleMouseDown = (e) => {
        const { id } = e.target;

        if (id === 'profile-gate') {
            setDisplayDD(!displayDD);
        }
        else if (id === 'profile') {
            navigate('/profile');
        }
        else if (id === 'settings') {
            navigate('/settings');
        }
        else if (id === 'logout') {
            logout();
        }
    }

    return (
        <>
            <button 
                className={styles['container']} 
                onMouseDown={handleMouseDown} 
                onBlur={hideDD}
                id='profile-gate'
            >
                <img src={bgImgs.defaultProfilePic} alt='profile' className={styles['profile-pic']} />

                <div className={styles['profile-name-role']}>
                    <span>{authState.user.name}</span>
                    <span className={styles['profile-role']}>{authState.user.role || 'Undefined'}</span>
                </div>
            </button>

            {
                <div className={displayDD ? `${styles['dropdown']} ${styles['show']}`
                                          : `${styles['dropdown']} ${styles['hide']}`}>
                    <button onMouseDown={handleMouseDown} id='profile'>Profile</button>
                    {
                        (
                            user.permissions.includes(permissions.canCreateUser) ||
                            user.permissions.includes(permissions.canEditUser) ||
                            user.permissions.includes(permissions.canBlockUser) ||
                            user.permissions.includes(permissions.canDeleteUser)
                        ) && 
                        <button onMouseDown={handleMouseDown} id='settings'>Settings</button>
                    }
                    <button onMouseDown={handleMouseDown} id='logout'>Logout</button>
                </div>
            }
        </>
    );
}

export default ProfileGate;

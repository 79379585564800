import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationfactureproforma.module.scss'
import { useState } from 'react'
import factureProformaFinder from '../../api/factureProformaFinder'
import metaData from './consultationFactureProforma.json'
import Printing from '../../components/printing/Printing'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import { fixDecimalDigits, spacedNumFormat } from '../../utils/numberFormatter'

const ConsultationFactureProforma = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  // const thead_bonLivraison = [
  //   {name:"N° Fact",width: 190},
  //   {name:"Date",width: 190},
  //   {name:"Code Cli",width: 190},
  //   {name:"Client",width: 490},
  //   {name:"Montant TTC",width: 190},
  //   {name:"Fact Bloquèe ?",width: 190},
  //   {name:"Règelement",width: 190},
  //   {name:"Montant HT",width: 190},
  //   {name:"Montant TVA",width: 190},
  //   {name:"Total HT Sand RM",width: 190},
  //   {name:"Saisie Par",width: 490},
  //   {name:"Ligne 1",width: 190},
  //   {name:"Ligne 2",width: 190}
  // ]
  //   const thead_detailleBon = [
  //   {name:"Code",width: 190},
  //   {name:"Dèsingation",width: 490},
  //   {name:"Quantitè",width: 190},
  //   {name:"PU",width: 190},
  //   {name:"TVA",width: 190},
  //   {name:"Total",width: 190},
  //   {name:"Qte en Stock",width: 190},
  // ]
    
  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);

  useEffect(() => {
    const fetchBonsAchat = async () => {
      const response = await factureProformaFinder.get('/');
      setBonsInfo(response.data.data.factures)
      console.log(response.data.data.factures);
      let empty = '-'
      setData(response.data.data.factures.map((bon)=>{
        return(
          {
            id: bon.id,
            code: bon.code,
            date: bon.date.substring(0,10),
            codeClient: bon.client_code,
            client: bon.client_name,
            totalTTC: bon.totalTTC || empty,
            blocked: bon.blocked ? 'Oui' : 'Non',
            reglementClient: bon.payment_type || empty,
            montantHT: bon.montantHT || empty,
            monatntTVA: bon.montantTVA || empty,
            totalHTRM: bon.totalHTRM || empty, 
            saisiPar: bon.user_name || empty,
            line1: '',
            line2: '',
          }
        );
      }));
    }
    fetchBonsAchat();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([])
      return
    }
    
    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id){
        setDataSuiv(
         element.articlesAdded.map((article,index)=>{
          console.log(article)
          return {
            id: article.id,
            code: article.code,
            designation: article.designation ,
            quantity: article.quantity,
            PUVente: spacedNumFormat(article.prix),
            remise: article.remise,
            TVA: article.tva,
            total: spacedNumFormat(article.prix * article.quantity)
          }
          })
        )

      }
    })
      
  }

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['code'];
  const handleSearch = (val) => {
      setInputValue(val);
  }
  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Qte",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "Total",width:"10%"}
  ]

  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const totalSansRM= useRef();
  const discountTotalRef= useRef();
  const tvaRef= useRef();
  const total= useRef();

  const calculateTotal = () => {
    // console.log(tableRefSuiv.current.getAllData())
    // Calculate the total before discounts
    const amountBeforeDiscounts = tableRefSuiv.current.getAllData().reduce((accumulator, article) => accumulator += article.PUVente * article.quantity, 0);
    totalSansRM.current = amountBeforeDiscounts;

    // Calculate all the discounts
    const articlesDiscountsTotal = tableRefSuiv.current.getAllData().reduce((accumulator, article) => {
      const puRemise = fixDecimalDigits(article.PUVente - (article.PUVente * (article.remise / 100)), 2, true);
      return accumulator += fixDecimalDigits(((article.PUVente - puRemise) * article.quantity), 2, true);
    }, 0);

    discountTotalRef.current = articlesDiscountsTotal;

    // Calculate AVT
    const totalAVT = tableRefSuiv.current.getAllData().reduce((accumulator, article) => {
      const puRemise = fixDecimalDigits(article.PUVente - (article.PUVente * (article.remise / 100)), 2, true);
      const puTVA = fixDecimalDigits((puRemise * (article.TVA / 100)), 2, true);
      return accumulator += fixDecimalDigits((article.quantity * puTVA), 2, true);
    }, 0);

    tvaRef.current = totalAVT;

    // Display the final amount
    total.current = fixDecimalDigits(amountBeforeDiscounts + totalAVT - articlesDiscountsTotal);
  }

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    calculateTotal()
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    const articlesAdded = tableRefSuiv.current.getAllData().map((article)=>{
      return({
            code: article.code,
            designation: article.designation,
            quantity: article.quantity,
            PUVente: article.PUVente,
            total: article.total
        })
    });
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar,
      globalInfo: {
        totalBeforeDiscounts: totalSansRM.current,
        totalDiscounts: discountTotalRef.current,
        totalAVT: tvaRef.current,
        totalAfterDiscounts: total.current
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Facture proforma'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Par N° Fact"}
                label={"Chercher"}
                labelPosition={true}
                width={"50%"}
                reportChange={(val) => handleSearch(val)}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  onclick={handlePrint}
                  />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Liste des Facture"} 
                        sendSelectedRowInfo={handleClick} 
                        thead={metaData.thead_facture} 
                        singular={true}
                        tbody={data} 
                        ref={tableRef}
                        filterInput={inputValue}
                        filterByArr={filterByArr}
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                        />
              </div>
              <div className={styles['row-end']} style={{width:'100%'}}><br/><br/></div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Détail du Facture"} ref={tableRefSuiv} thead={metaData.thead_detailleFacture} tbody={dataSuiv} />
              </div>
            </div>
        
    </div>
    {isActive && <Printing type={'Facture Proforma'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationFactureProforma;
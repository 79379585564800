import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import styles from './tableaudeBoard.module.scss';
import Button from '../../components/button/Button';
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer';
import { useAuth } from '../../contexts/AuthContext';

const TableauDeBord = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const [ data1, setData1 ] = useState([]);
    const [ data2, setData2 ] = useState([]);
    const [ data3, setData3 ] = useState([]);
    const [ data4, setData4 ] = useState([]);

    const [ inputValue , setInputValue ] = useState('');

    const thead1 = [
        {name:"Type BL", width: 190},
        {name:"Nb BL", width: 190},
        {name:"Montant", width: 190}
    ]

    const thead2 = [
        {name:"Nb Versement",width: 190},
        {name:"Type Vers.",width: 190},
        {name:"Total Versement",width: 190}
    ]
    
    return (
        <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Sortie de Caisse Périodique'}>
            <div className={styles['body-container']}>
                <div className={styles['main-body']}>
                    <div className={styles['row-between']}>
                        <div className={styles['row']}>
                            <InputText
                                label={"Joure 1"}
                                width={"max-content"}
                                type={"date"}
                                />
                            <InputText
                                label={"Joure 2"}
                                width={"max-content"}
                                type={"date"}
                                />
                        </div>
                        <div className={styles['row-end']}>
                            <Button 
                                icon={icons.impr}
                                width={"max-content"}
                                name={'Imprimer'} 
                                />
                        </div>
                    </div>
                    <div className={styles['row-between']}>
                        <div style={{width: '45%',height: '300px'}}>
                            <DetailsSectionContainer name={"Les Achat"} table={true}>
                                <Table thead={thead1} inputValue={inputValue} tbody={data1} />
                            </DetailsSectionContainer>
                        </div>
                        <div style={{width: '45%',height: '300px'}}>
                            <DetailsSectionContainer name={"Les Ventes"} table={true}>
                                <Table thead={thead2} inputValue={inputValue} tbody={data2} />
                            </DetailsSectionContainer>
                        </div>
                    </div>
                    <div></div>
                    <div className={styles['row-between']}>
                        <div style={{width: '45%',height: '300px'}}>
                            <DetailsSectionContainer name={"Les Versements des Fournisseurs"} table={true}>
                                <Table thead={thead1} inputValue={inputValue} tbody={data3} />
                            </DetailsSectionContainer>
                        </div>
                        <div style={{width: '45%',height: '300px'}}>
                            <DetailsSectionContainer name={"Les Versements des Clients"} table={true}>
                                <Table thead={thead2} inputValue={inputValue} tbody={data4} />
                            </DetailsSectionContainer>
                        </div>
                    </div>
                    <div></div>
                    <div className={styles['row-between']}>
                        <div style={{width: '45%',height: 'fit_content'}}>
                            <DetailsSectionContainer name={"Les Versements des Fournisseurs"} table={true}>
                                <div className={styles['col']} style={{padding: ' 10px 0 10px 0 '}}>
                                <div className={styles['row-between']} style={{width: '100%',height: 'fit_content'}}>
                                    <InputText  
                                        labelPosition={true}
                                        holderText={"Montant"} 
                                        type={"number"} 
                                        label={'Total Encaissement'}
                                        width={"70%"}
                                        />
                                    <InputText  
                                        holderText={"Text"} 
                                        type={"Text"} 
                                        width={"20%"}
                                        />
                                </div>
                                <div className={styles['row-between']} style={{width: '100%',height: 'fit_content'}}>
                                    <InputText  
                                        labelPosition={true}
                                        holderText={"Montant"} 
                                        type={"number"} 
                                        label={'Total Sortie Caisse'}
                                        width={"70%"}
                                        />
                                    <InputText  
                                        holderText={"Text"} 
                                        type={"Text"} 
                                        width={"20%"}
                                        />
                                </div>
                                <div className={styles['row-between']} style={{width: '100%',height: 'fit_content'}}>
                                    <InputText  
                                        labelPosition={true}
                                        holderText={"Montant"} 
                                        type={"number"} 
                                        label={'La Caisse'}
                                        width={"70%"}
                                        />
                                </div>

                                </div>
                            </DetailsSectionContainer>
                        </div>
                    </div>
                </div>
            
        </div>
        </PageLayout>
    )
}

export default TableauDeBord;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import ParametersPage from '../../components/parameters-page/ParametersPage';
import styles from './profile.module.scss';
import { bgImgs, icons } from '../../media/mediaHolder';
import { spacedPhoneNumFormat } from '../../utils/numberFormatter';

const Profile = (props) => {
    const { state: transferedData } = useLocation();
    const { state: authState } = useAuth();

    const { user } = transferedData || authState;

    return (
        <ParametersPage 
            title={'Profile'}
            basePath={'/profile'}
            pagesStruct={[
                {header: 'Personal Profile', pages: [{name: 'General information', path: '/general-information'}]}
            ]}
            ucsAdditionalClasses={styles['upper-page']}
            addUpperElements={
                <div>
                    <img src={icons.edit2} alt='edit profile' />
                </div>
            }
        >
            <div className={styles['container']}>
                    <div className={styles['profile-header']}>
                        <div className={styles['user-info-sec-1']}>
                            <img 
                                src={user.profilePic ? user.profilePic : bgImgs.defaultProfilePic} 
                                alt='profile'
                                className={styles['profile-pic']} 
                            />
                            <div>
                                <p className={styles['uis-item-1']}>{user.name}</p>
                                <p className={styles['uis-item-2']}>{user.role || 'Undefined Role'}</p>
                                <p className={styles['uis-item-3']}>{`Created in ${user.accountCreationTS}`}</p>
                            </div>
                        </div>
                        <div className={styles['user-info-sec-2']}>
                            <p className={styles['prms-header']}>Permissions</p>
                            <div className={styles['prms-display']}>
                                {
                                    user.permissions?.length > 0 ? 
                                    <div>
                                        {user.permissions.map((prm, index) => <p key={index}>{prm}</p>)}
                                    </div>

                                    : <p>No permissions to be display</p>
                                }
                            </div>
                            <div className={styles['contact-info']}>
                                <div className={`${styles['contact-info-item']} ${styles['email']}`}>
                                    <img src={icons.email} alt='email address' />
                                    <p>{user.email}</p>
                                </div>
                                <div className={`${styles['contact-info-item']} ${styles['phone']}`}>
                                    <img src={icons.phone} alt='phone number' />
                                    <p>{user.phone ? spacedPhoneNumFormat(user.phone) : spacedPhoneNumFormat('0600000000')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
        </ParametersPage>
    );
}

export default Profile;

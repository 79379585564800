import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationDesBordereauxEnvoiDeCheque.module.scss'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ConsultationDesBordereauxEnvoiDeCheque = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead = [
    [
      {name:"N° Bord.",width: 190},
      {name:"Compte",width: 490},
      {name:"Date Bord",width: 190},
      {name:"Montant Bord.",width: 190},
      {name:"Saisie Par",width: 190}
    ],
    [
      {name:"N° Chèque",width: 190},
      {name:"Etat",width: 190},
      {name:"Montat",width: 290},
      {name:"Titeur.",width: 590}
    ]
    ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.achat} nameLayout={"Consultation des bordereaux d'envoi de chèque"}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Les Bordereaux"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  />
                </div>
              </div>
              <div style={{height: "300px"}}>
                <Table thead={thead[0]} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
              <div className={styles['row-between']} style={{marginTop: "40px"}}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Le détail des bordereaux"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  height={"100%"}
                  width={"200px"}
                  name={'Ajouter un Cheque'} 
                  />
                </div>
              </div>
            
            <div className={styles['row-end']} style={{width:'100%'}}>
            </div>
            <div style={{height: "300px"}}>
                <Table thead={thead[1]} inputValue={inputValue} tbody={search} />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ConsultationDesBordereauxEnvoiDeCheque;

import React from 'react';
import ModItem from '../mod-item/ModItem';
import styles from './mod.module.scss';
import { useAuth } from '../../contexts/AuthContext';

const Mod = (props) => {
  const { modInfo } = props;
  const { state: authState } = useAuth();
  const { user } = authState;

  return (
    <div className={styles['mod']}> 
        <h2 className={styles['mod-name']}>{modInfo.modName}</h2>
        <div className={styles['mod-items-container']}>
            {
              modInfo.modItemContainer.filter(item => {
                if (item.assignedPermissions && item.assignedPermissions.length > 0) {
                  return item.assignedPermissions.find(permission => user?.permissions.includes(permission));
                }
                else return true;
              })
              .map((item,index) => <ModItem key={index} main={modInfo.main} ModItemInfo={item} />)
            }
        </div>
    </div>
  )
}

export default Mod;

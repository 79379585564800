import React from 'react';
import { BrowserRouter as Router , Routes , Route, Navigate } from 'react-router-dom';

import styles from './app.scss';
import Home from './routes/home/Home';
import PageNotFound from './routes/page-not-found/PageNotFound';
import Menubar from './components/menubar/Menubar';
import Achats from './routes/achats/Achats';
import Bon_de_livraison from './routes/ventes/BonDeLivraison';
import ArticleEtStock from './routes/articles/ArticleEtStock';
import Facture from './routes/factures/Facture';
import Production from './routes/production/Production';
import StockMatierePremiere from './routes/stock-matiere-premiere/StockMatierePremiere';
import Stock_matiere_fini from './routes/stock-matiere-finit/StockMatiereFinit';
import AjouterArticle from './routes/ajouter-article/AjouterArticle';
import FicheStockProduit from './routes/fiches-stock-produit/FicheStockProduit';
import FichePeriodique from './routes/fiches-periodique/FichePeriodique';
import FamilleEtSousFamille from './routes/familles-et-sous-familles/FamilleEtSousFamille';
import AjouterClient from './routes/ajouter-client/AjouterClient';
import AjouterFournisseur from './routes/ajouter-fournisseur/AjouterFournisseur';
import ListClient from './routes/clients/ListClient';
import ListFournisseur from './routes/fournisseurs/ListFournisseur';
import ReglementClient from './routes/reglements-client/ReglementClient';
import EtatClient from './routes/etats-client/EtatClient';
import ReglementFournisseur from './routes/reglements-fournisseur/ReglementFournisseur';
import EtatFournisseur from './routes/etats-fournisseur/EtatFournisseur';
import ListArticleCommandes from './routes/articles-commandes/ListArticleCommandes';
import ListCharge from './routes/charges/ListCharge';
import ListeTousArticlesVendusNonLivres from './routes/tous-articles-vendus-non-livres/ListeTousArticlesVendusNonLivres';
import ListeTousArticlesVendusNonLivresParClient from './routes/tous-articles-vendus-non-livres-par-client/ListeTousArticlesVendusNonLivresParClient';
import ConsultationAchats from './routes/consultations-achat/ConsultationAchat';
import ConsultationBonAvoirVentes from './routes/consultations-bon-avoir-ventes/ConsultationBonAvoirVentes';
import ConsultationBonCommande from './routes/consultations-bon-commande/ConsultationBonCommande';
import ConsultationBonReceptionTransfert from './routes/consultations-bon-reception-transfert/ConsultationBonReceptionTransfert';
import ConsultationBonTransfert from './routes/consultations-bon-transfert/ConsultationBonTransfert';
import ConsultationDevis from './routes/consultations-devis/ConsultationDevis';
import ConsultationFactureProforma from './routes/consultations-facture-proforma/ConsultationFactureProforma';
import ConsultationFacture from './routes/consultations-facture/ConsultationFacture';
import ConsultationVentesComptoirs from './routes/consultations-ventes-comptoirs/ConsultationVentesComptoirs';
import ConsultationVentes from './routes/consultations-ventes/ConsultationVentes';
import EtatDeCaisse from './routes/etats-de-caisse/EtatDeCaisse';
import EtatRemiseParTout from './routes/etats-remise-par-tout/EtatRemiseParTout';
import EtatRemiseParMontant from './routes/etats-remise-par-montant/EtatRemiseParMontant';
import EtatRemiseParParcentage from './routes/etats-remise-par-pourcentage/EtatRemiseParParcentage';
import EtatAchatDetaille from './routes/etats-achat-detailles/EtatAchatDetaille';
import EtatAchatGlobal from './routes/etats-achat-global/EtatAchatGlobal';
import EtatAchatFournisseurDetaille from './routes/etats-achat-fournisseur-detaille/EtatAchatFournisseurDetaille';
import EtatAchatFournisseurGlobal from './routes/etats-achat-fournisseur-global/EtatAchatFournisseurGlobal';
import EtatVentesDetaille from './routes/etats-vente-detaille/EtatVenteDetaille';
import EtatVentesDetailleParClient from './routes/etats-ventes-detaille-par-client/EtatVentesDetailleParClient';
import BonAvoirAchat from './routes/bons-avoir-achat/BonAvoirAchat';
import EtatVentesDetailleParArticle from './routes/etats-ventes-detaille-par-article/EtatVentesDetailleParArticle';
import EtatVentesDetailleParFamille from './routes/etats-ventes-detaille-par-famille/EtatVentesDetailleParFamille';
import EtatVentesDetailleParSousFamille from './routes/etats-ventes-detaille-par-sous-famille/EtatVentesDetailleParSousFamille';
import EtatVentesGlobal from './routes/etats-ventes-global/EtatVentesGlobal';
import EtatVentesParClientEtParArticle from './routes/etats-ventes-par-client-et-par-article/EtatVentesParClientEtParArticle';
import EtatVentesGlobalParClient from './routes/etats-ventes-global-par-client/EtatVentesGlobalParClient';
import EtatBenifice from './routes/etats-benifice/EtatBenifice';
import EtatStockaDateDonne from './routes/etats-stock-a-date-donnee/EtatStockaDateDonnee';
import EtatParAgentCommercial from './routes/etats-par-agen-commercial/EtatParAgenCommercial';
import EtatParUtilisateur from './routes/etats-par-utilisateur/EtatParUtilisateur';
import EtatPeriodiqueDeCaisse from './routes/etats-periodique-de-caisse/EtatPeriodiqueDeCaisse';
import EncaisementPeriodiqueGroupeParJourrnee from './routes/encaissements-periodique-groupe-par-journee/EncaisementPeriodiqueGroupeParJournee';
import EncaissementPeriodique from './routes/encaissements-periodique/EncaissementPeriodique';
import ListArticleVenduesParQuantite from './routes/articles-vendues-par-quantite/ListArticleVenduesParQuantite';
import SortieCaissePeriodique from './routes/sorties-caisse-periodique/SortieCaissePeriodique';
import TableauDeBord from './routes/tableau-de-board/TableauDeBoard';
import Encaissement from './routes/encaissements/Encaissement';
import SortiesDeEncaissement from './routes/sorties-encaissement/SortiesDeEncaissement';
import BonAvoirVente from './routes/bons-avoir-vente/BonAvoirVente';
import BonDeCommande from './routes/bons-de-commande/BonDeCommande'
import BonReceptionTransfer from './routes/bons-reception-transfer/BonReceptionTrensfer';
import BonTransfer from './routes/bons-transfer/BonTransfer';
import Partenair from './routes/partenairs/Partenair';
import Devis from './routes/devis/Devis';
import EtatPeriodiqueCharge from './routes/etats-periodique-charge/EtatPeriodiqueCharge';
import FactureProforma from './routes/factures-proforma/FactureProforma';
import ChargePeriodique from './routes/charges-periodique/ChargePeriodique';
import ClientOuFournisseurTransfer from './routes/client-ou-fournisseur-transfer/ClientOuFournisseurTransfer';
import ListArticalsTransfer from './routes/articles-transfer/ListArticalsTransfer';
import ArticleAcheter from './routes/articles-acheter/ArticlesAcheter';
import ArticlesNoVenduFamille from './routes/articles-non-vendu-famille/ArticlesNoVenduFamille';
import ArticlesNoVendu from './routes/articles-non-vendu/ArticlesNoVendu';
import ArticlesVenduGrouperFamille from './routes/articles-vendu-grouper-famille/ArticlesVenduGrouperFamille';
import ArticlesVenduGrouperSousFamille from './routes/articles-vendu-grouper-sous-famille/ArticlesVenduGrouperSousFamille';
import ArticlesVendu from './routes/articles-vendu/ArticlesVendu';
import Balance from './routes/balances/Balance';
import BalanceDeStock from './routes/balances-de-stock/BalanceDeStock';
import LivreAchats from './routes/livre-achats/LivreAchats';
import LivreDesVentes from './routes/livre-ventes/LivreDesVente';
import VersementClient from './routes/versements-client/VersementClient';
import VersementFournisseur from './routes/versements-fournisseur/VersementFournisseur';
import CreditClientFournisseur from './routes/credits-client-fournisseur/CreditClientFournisseur';
import CreditClient from './routes/credits-client/CreditClient';
import CreditClientwilaya from './routes/credits-client-wilaya/CreditClientWilaya';
import CreditClientwilayaSuiv from './routes/credits-client-wilaya-suiv/CreditClientWilayaSuiv';
import CreditFournisseurWilayla from './routes/credits-fournisseur-wilaya/CreditFournisseurWilaya';
import CreditFournisseurClient from './routes/credits-fournisseur-client/CreditFournisseurClient';
import CreditFournisseur from './routes/credits-fournisseur/CreditFournisseur';
import CreditFournisseurClientWilayaSuiv from './routes/credits-fournisseur-client-wilaya-suiv/CreditFournisseurClientWilayaSuiv';
import StockInvetaire from './routes/stock-inventaire/StockInventaire';
import Depots from './routes/depotes/Depote';
import ListCompteBencaire from './routes/comptes-bencaire/ListCompteBencaire'
import ListChaquePayee from './routes/cheques-payee/ListChequePayee';
import ListChaqueImpayee from './routes/cheques-impayee/ListChequeImpayee';
import ListChequeRemiseABanque from './routes/cheques-remise-a-banque/ListChequeRemiseABanque';
import SortieBanque from './routes/sorties-banque/SortieBanque'
import SuivieBanque from './routes/suivies-banque/SuivieBanque'
import AjouterCompteBancaire from './routes/ajouter-compte-bancaire/AjouterCompteBancaire';
import BordereauDesCheques from './routes/bordeaux-des-cheques/BordereauDesCheques';
import ConsultationDesBonsInventaire from './routes/consultations-des-bons-inventaire/ConsultationDesBonsInventaire';
import ConsultationDesBordereauxEnvoiDeCheque from './routes/consultations-des-bordereaux-envoi-de-cheque/ConsultationDesBordereauxEnvoiDeCheque';
import ConsultationDesSortieDeBanque from './routes/consultations-des-sortie-de-banque/ConsultationDesSortieDeBanque';
import Inventaire from './routes/inventaire/Inventaire';
import DetailStockInventaire from './routes/details-stock-inventaire/DetailStockInventaire';
import FicheStockDetaille from './routes/fiches-stock-detailles/FicheStockDetaille';
import ConsultationVersementRetraitBanque from './routes/consultations-versement-retrait-banque/ConsultationVersementRetraitBanque';
import ControleDatePreremption from './routes/controle-date-preremption/ControleDatePreremption';
import ListArticleReceptionneeParTransfert from './routes/articles-receptionnee-par-transfert/ListArticleReceptionneeParTransfert';
import VenteComptoir from './routes/ventes-comptoir/VenteComptoir';
import VersmentRetraitBanque from './routes/versments-retrait-banque/VersmentRetraitBanque';
import EtatRecapitulatif from './routes/etats-recapitulatif/EtatRecapitulatif';
import BonAvoirFacture from './routes/bons-avoir-facture/BonAvoirFacture';
import ConsultationProduction from './routes/consultation-production/ConsultationProduction'
import StockMatiereFini from './routes/stock-matiere-finit/StockMatiereFinit';
import StockMatiereSemiFini from './routes/stock-matiere-semi-fini/StockMatiereSemiFini';
import Login from './routes/login/Login';
import SignUp from './routes/sign-up/SignUp';
import NonAuthRoutes from './components/non-auth-routes/NonAuthRoutes';
import ProtectedRoutes from './components/protected-routes/ProtectedRoutes';
import Profile from './routes/profile/Profile';
import UsersList from './routes/settings-page/users-list-partial/UsersList';
import AddUser from './routes/settings-page/add-user-partial/AddUser';
import PermissionsAndRoles from './routes/settings-page/permissions-and-roles-partial/PermissionsAndRoles';
import MarginsAndTaxes from './routes/settings-page/margins-and-taxes-partial/MarginsAndTaxes';
import UploadPrints from './routes/settings-page/upload-prints/UploadPrints';
import CheckPrintsExemplars from './routes/settings-page/check-prints-exemplars/CheckPrintsExemplars';
import EtatParProduit from './routes/etat-par-produit/EtatParProduit';
import Stock from '../src/routes/stock/Stock';

const App = () => {
  return (
    <Router>
      <Menubar />
      
      <Routes>
        <Route path='/'>
          <Route element={<ProtectedRoutes />}>

            <Route index element={<Home />} />

            <Route path='profile'>
              <Route path='general-information' element={<Profile />} />
              <Route path='' element={<Navigate to='general-information' replace />} />
            </Route>

            <Route path='settings'>
              <Route path='users-list' element={<UsersList />} />
              <Route path='add-new-user' element={<AddUser />} />
              <Route path='permissions-and-roles' element={<PermissionsAndRoles />} />
              <Route path='margins-and-taxes' element={<MarginsAndTaxes />} />
              <Route path='upload-prints' element={<UploadPrints />} />
              <Route path='check-prints-exemplars' element={<CheckPrintsExemplars />} />
              <Route path='' element={<Navigate to="users-list" replace />} />
            </Route>

            <Route path="achat" element={<Achats />}/>
            <Route path="bon_de_livraison" element={<Bon_de_livraison />}/>
            <Route path='bon_avoire_achat' element={<BonAvoirAchat />}/>
            <Route path='articles' element={<ArticleEtStock />}/>
            <Route path="ajouter_article" element={<AjouterArticle />}/>
            <Route path='ajouter_client' element={<AjouterClient />}/>
            <Route path='list_client' element={<ListClient />}/>
            <Route path='reglement_client' element={<ReglementClient />}/>
            <Route path='ajouter_fournisseur' element={<AjouterFournisseur/>}/>
            <Route path='list_fournisseur' element={<ListFournisseur/>}/>
            <Route path='reglement_fournisseur' element={<ReglementFournisseur/>}/>
            <Route path='etat_fournisseur' element={<EtatFournisseur />}/>
            <Route path="facture" element={<Facture />}/>
            <Route path='famille_et_sous_famille' element={<FamilleEtSousFamille />}/>
            <Route path="fiche_stock_produit" element={<FicheStockProduit />}/>
            <Route path="fiche_periodique" element={<FichePeriodique />}/>
            <Route path="production" element={<Production />}/>
            <Route path="stock_matiere_premiere" element={<StockMatierePremiere />}/>
            <Route path="stock_matiere_fini" element={<Stock_matiere_fini />}/>
            <Route path="list_articles_commandes" element={<ListArticleCommandes />} />
            <Route path="list_charge" element={<ListCharge />} />
            <Route path="liste_tous_articles_vendus_non_livres" element={<ListeTousArticlesVendusNonLivres />} />
            <Route path="liste_tous_articles_vendus_non_libres_par_client" element={<ListeTousArticlesVendusNonLivresParClient />} />
            <Route path='consultation_achats' element={<ConsultationAchats/>}/>
            <Route path='consultation_bon_avoire_ventes' element={<ConsultationBonAvoirVentes/>}/>
            <Route path='consultation_bon_commande' element={<ConsultationBonCommande/>}/>
            <Route path='consultation_bon_reception_transfert' element={<ConsultationBonReceptionTransfert/>}/>
            <Route path='consultation_bon_transfert' element={<ConsultationBonTransfert/>}/>
            <Route path='consultation_devis' element={<ConsultationDevis/>}/>
            <Route path='consultation_facture_proforma' element={<ConsultationFactureProforma/>}/>
            <Route path='consultation_facture' element={<ConsultationFacture/>}/>
            <Route path='consultation_ventes_comptoirs' element={<ConsultationVentesComptoirs/>}/>
            <Route path='consultation_ventes' element={<ConsultationVentes/>}/> 
            <Route path='etat_client' element={<EtatClient />}/>
            <Route path='etat_de_caisse' element={<EtatDeCaisse/>}/>
            <Route path='etat_remise_par_tout' element={<EtatRemiseParTout/>}/>
            <Route path='etat_remise_par_montant' element={<EtatRemiseParMontant/>}/>
            <Route path='etat_remise_par_parcentage' element={<EtatRemiseParParcentage/>}/>
            <Route path='etat_achat_detaille' element={<EtatAchatDetaille/>}/>
            <Route path='etat_achat_global' element={<EtatAchatGlobal/>}/>
            <Route path='etat_achat_fournisseur_detaille' element={<EtatAchatFournisseurDetaille/>}/>
            <Route path='etat_achat_fournisseur_global' element={<EtatAchatFournisseurGlobal/>}/>
            <Route path='etat_ventes_detaille' element={<EtatVentesDetaille/>}/>
            <Route path='etat_ventes_global' element={<EtatVentesGlobal/>}/>
            <Route path='etat_ventes_detaille_par_client' element={<EtatVentesDetailleParClient/>}/>
            <Route path='etat_ventes_global_par_client' element={<EtatVentesGlobalParClient/>}/>
            <Route path='etat_ventes_detaille_par_article' element={<EtatVentesDetailleParArticle/>}/>
            <Route path='etat_ventes_detaille_par_famille' element={<EtatVentesDetailleParFamille/>}/>
            <Route path='etat_ventes_detaille_par_sous_famille' element={<EtatVentesDetailleParSousFamille/>}/>
            <Route path='etat_ventes_par_client_et_par_article' element={<EtatVentesParClientEtParArticle/>}/>
            <Route path='etat_benifice' element={<EtatBenifice/>}/>
            <Route path='etat_stock_a_date_donnee' element={<EtatStockaDateDonne/>}/>
            <Route path='etat_par_agent_commercial' element={<EtatParAgentCommercial/>}/>
            <Route path='etat_par_utilisateur' element={<EtatParUtilisateur/>}/>
            <Route path='etat_periodique_de_caisse' element={<EtatPeriodiqueDeCaisse/>}/>
            <Route path='etat_periodique_charge' element={<EtatPeriodiqueCharge/>}/>
            <Route path='encaissement_periodique_groupee_par_journee' element={<EncaisementPeriodiqueGroupeParJourrnee/>}/>
            <Route path='encaissement_periodique' element={<EncaissementPeriodique/>}/>
            <Route path='list_article_vendues_par_quantite' element={<ListArticleVenduesParQuantite/>}/>
            <Route path='sortie_caisse_periodique' element={<SortieCaissePeriodique/>}/>
            <Route path='tableau_de_bord' element={<TableauDeBord/>}/>
            <Route path='encaissement_par_journee' element={<Encaissement/>}/>
            <Route path='sorties_de_encaissement' element={<SortiesDeEncaissement/>}/>
            <Route path='bon_avoir_vente' element={<BonAvoirVente/>}/>
            <Route path='bon_de_commande' element={<BonDeCommande/>}/>
            <Route path='bon_reception_transfer' element={<BonReceptionTransfer/>}/>
            <Route path='bon_transfer' element={<BonTransfer/>}/>
            <Route path='partenair' element={<Partenair/>}/>
            <Route path='devis' element={<Devis/>}/>
            <Route path='facture_proforma' element={<FactureProforma/>}/>
            <Route path='charge_periodique' element={<ChargePeriodique/>}/>
            <Route path='client_ou_fournisseur_transfer' element={<ClientOuFournisseurTransfer/>}/>
            <Route path='list_articals_transfer' element={<ListArticalsTransfer/>}/>
            <Route path='article_acheter' element={<ArticleAcheter/>}/>
            <Route path='articles_no_vendu_famille' element={<ArticlesNoVenduFamille/>}/>
            <Route path='articles_no_vendu' element={<ArticlesNoVendu/>}/>
            <Route path='articles_vendu_grouper_famille' element={<ArticlesVenduGrouperFamille/>}/>
            <Route path='articles_vendu_grouper_sous_famille' element={<ArticlesVenduGrouperSousFamille/>}/>
            <Route path='articles_vendu' element={<ArticlesVendu/>}/>
            <Route path='balance_de_stock' element={<BalanceDeStock />}/>
            <Route path='balance' element={<Balance />}/>
            <Route path='livre_achats' element={<LivreAchats />}/>
            <Route path='livre_des_ventes' element={<LivreDesVentes />}/>
            <Route path='versement_client' element={<VersementClient />}/>
            <Route path='versement_fournisseur' element={<VersementFournisseur />}/>
            <Route path='creditClient_fournisseur' element={<CreditClientFournisseur />}/>
            <Route path='credit_client' element={<CreditClient />}/>
            <Route path='credit_client_wilaya' element={<CreditClientwilaya />}/>
            <Route path='credit_client_wilaya_suiv' element={<CreditClientwilayaSuiv />}/>
            <Route path='credit_fournisseur_wilaya' element={<CreditFournisseurWilayla />}/>
            <Route path='credit_fournisseur_client' element={<CreditFournisseurClient />}/>
            <Route path='credit_fournisseur' element={<CreditFournisseur />}/>
            <Route path='credit_fournisseur_client_wilaya_suiv' element={<CreditFournisseurClientWilayaSuiv />}/>
            <Route path='stock_inventaire' element={<StockInvetaire/>}/>
            <Route path='depots' element={<Depots/>}/>
            <Route path='list_compte_bencaire' element={<ListCompteBencaire/>}/>
            <Route path='list_cheque_payee' element={<ListChaquePayee/>}/>
            <Route path='list_cheque_impayee' element={<ListChaqueImpayee/>}/>
            <Route path='list_cheque_remise_a_banque' element={<ListChequeRemiseABanque/>}/>
            <Route path='list_article_receptionne_par_transfert' element={<ListArticleReceptionneeParTransfert/>}/>
            <Route path='sortie_banque' element={<SortieBanque/>}/>
            <Route path='suivie_banque' element={<SuivieBanque/>}/>
            <Route path='ajouter_compte_bancaire' element={<AjouterCompteBancaire/>}/>
            <Route path='bordereau_des_cheques' element={<BordereauDesCheques/>}/>
            <Route path='consultation_des_bons_inventaire' element={<ConsultationDesBonsInventaire/>}/>
            <Route path='consultation_des_bordereaux_envoi_de_cheque' element={<ConsultationDesBordereauxEnvoiDeCheque/>}/>
            <Route path='consultation_des_sortie_de_banque' element={<ConsultationDesSortieDeBanque/>}/>
            <Route path='consultation_versement_retrait_banque' element={<ConsultationVersementRetraitBanque/>}/>
            <Route path='inventaire' element={<Inventaire/>}/>
            <Route path='detail_stock_inventaire' element={<DetailStockInventaire/>}/>
            <Route path='fiche_stock_detaillee' element={<FicheStockDetaille/>}/>
            <Route path='controle_date_preropmtion' element={<ControleDatePreremption/>}/>
            <Route path='vente_comptoir' element={<VenteComptoir />} />
            <Route path='versment_retrait_banque' element={<VersmentRetraitBanque/>}/>
            <Route path='etat_recapitulatif' element={<EtatRecapitulatif/>} />
            <Route path='bons_avoir_facture' element={<BonAvoirFacture/>}/>
            <Route path='consultation_Production' element={<ConsultationProduction/>} />
            <Route path='etat_par_produit' element={<EtatParProduit/>}/>
            <Route path='stock_matiere_fini' element={<StockMatiereFini/>} />
            <Route path='stock_matiere_semi_fini' element={<StockMatiereSemiFini/>} />
            <Route path='stock' element={<Stock/>} />
          </Route>
          
          <Route element={<NonAuthRoutes />}>
            <Route path='login' element={<Login />} />
            <Route path='s-sign-up-f-f-t' element={<SignUp />} />
          </Route>

          <Route path='*' element={<PageNotFound />} />

        </Route> 
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState , Component, useRef, useReducer} from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import SelectInput from '../../components/select-input/SelectInput'
import Dialog from '../../components/dialog/Dialog'
import styles from './reglementclient.module.scss'
import reglementsClientFinder from '../../api/reglementsClientFinder'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import clientsFinder from '../../api/clientsFinder'
import creditClientFinder from '../../api/creditClientFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ReglementClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState([]);
  
  const getCurrentDate = () =>{
    const currentDate = new Date();
    return currentDate ;
  }
  
  const [ data, setData ] = useState([]);
  const currentdate =useRef();
  
  const handleSubmit = async (e) =>{
    e.preventDefault();
    const date = new Date(currentdate.current.getValue());
    try {
        const response = await reglementsClientFinder.post("/",
        {
          id_client: getSelectedClient.current.getSelectedItem().id,
          amount: document.getElementById("reglement_client_montant").value,
          type_verser: select_option.current?.getSelectedOption(),
          agent: "agent",
          n_cheque: select_option.current?.getSelectedOption() === "cheque" && document.getElementById("reglement_client_cheque_number").value || null,
          n_compte: select_option.current?.getSelectedOption() === "cheque" && document.getElementById("reglement_client_bank_account").value || null,
          etat_cheque: select_option.current?.getSelectedOption() === "cheque" && "Etat Cheque",
          date_payment_cheq: date,
          observation: document.getElementById("reglement_client_observation").value,
        }
        )
        console.log("insert Success");
        displayData(getSelectedClient.current.getSelectedItem());
    } catch (error) {
        console.log(error);
    }
}
  const [value, SetValue] = useState(0);

  const getSelectedClient = useRef()
  const totalBonLivraison = useRef();
  const totalmonatantVerse = useRef();
  const soldeClient = useRef();
  const select_option = useRef();
  const reg_option = useRef();
  
  // payment type options
  const options =[
    "Espece",
    "Cheque"
  ];
  const [selectedOption,setSelectedOption] = useState(options[0]);
  const reportChange = (val) => setSelectedOption(val);

  const optionsTable =[
    "Reglement",
    "Consultation BL"
  ];


      const thead = [
        [
          {name:"Date",width: 190},
          {name:"Heur",width: 190},
          {name:"Montant",width: 190},
          {name:"Type Ver",width: 190},
          {name:"Agent",width: 190},
          {name:"N° Cheque",width: 190},
          {name:"N° Compte",width: 190},
          {name:"Etat Cheque",width: 190},
          {name:"Observation",width: 490}
        ],
        [
          {name:"Operation",width: 190},
          {name:"Numero BL",width: 190},
          {name:"Date",width: 190},
          {name:"Heur",width: 190},
          {name:"Montant",width: 190},
          {name:"Saisi Par",width: 490}
        ]
      ];


  const displayDialog = useRef();

  const displayData = async (client)=>{
    const response = await creditClientFinder.get(`/${client.id}`);
    const {total_credit, total_verser, credit} = response.data.data ;

    const reglements = await reglementsClientFinder.get(`${client.id}`);
    setSearch(reglements.data.data.reglementsClient.map(reglement => {
      return ({
        id : reglement.id,
        Date : reglement.date?.substring(0,10),
        Heure : reglement.creation_ts?.substring(11,16),
        Montant : reglement.montant,
        Type_verser : reglement.type_ver,
        Agent : reglement.agent,
        Num_cheque : reglement.num_cheque,
        Num_compte : reglement.num_Compte,
        Etat_cheque : reglement.etat_cheque,
        Observation : reglement.observation,
      });
    }));

    // total chat de client
    totalBonLivraison.current.setValue(String(total_credit));
    // total verser par client
    totalmonatantVerse.current.setValue(String(total_verser));
    // credit total de client
    soldeClient.current.setValue(String(credit));
}

  useEffect(()=>{
      const date = new Date();
      currentdate.current.setValue(date.toISOString().substring(0, 10));

      const getClient = async ()=>{
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
      }
      const getReglementsClients = async () =>{
        const reglements = await reglementsClientFinder('/');
      }
    getClient();
  },[]);

  const handeDelete =  (val)=> {
    reglementsClientFinder.delete(`/${val}`);
    const updatedData = data.filter((item) => item['id'] !== val);
    setData(updatedData);
  }
const errorsHandler= useRef([]);

const checkClient = ()=> {
  let alerts = [];
  let client = document.getElementById("client").value;

  if(!client){
    alerts.push({name:'client',message:'Assurez que le client is bien saisez'});
    getSelectedClient.current.setBorderRed();
  }
  if(alerts.length > 0) {
    errorsHandler.current.errorsHandler(alerts);
    return;
  }
  displayDialog.current.display();
}
  
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Règlement Client'}>
    <form onSubmit={handleSubmit}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
            <InputText  
                      id={'client'}
                      holderText={"client"} 
                      labelPosition={true} 
                      type={"text"} 
                      label={'Ajouter Client'}
                      width={"40%"}
                      searchData={searchData}
                      onSelectItem={displayData}
                      ref={getSelectedClient}
                      displaySearchOption={true}
                      path={"/ajouter_client"}
                      btnName={'Ajouter Nouveau Client'}
                    />
                <div className={styles['row-end']}>
                    <Button 
                    icon={icons.insere}
                    width={"250px"}
                    height={"100%"}
                    name='Insérer Règlement' 
                    onclick={()=>checkClient()}
                    />
                    <Button 
                    link={true} 
                    path={"../etat_ventes_detaille_par_client"} 
                    icon={icons.insere}
                    width={"150px"}
                    height={"100%"}
                    name='Etat' 
                    />
                </div>
            </div>

            <div className={styles['row-between']}>
              <div className={styles['row-between']}>
                  <div className={value == 1 ? styles["noDisplay"] : styles["row"]}>
                      <InputText 
                      id={"recherche_reglement_par_date"}
                      label={"Period par date"} 
                      labelPosition={true} 
                      type={"date"}
                      />
                      <InputText  
                      holderText={"Text"} 
                      labelPosition={true} 
                      type={"text"} 
                      label={'Recharche des règlement'}
                      />
                  </div>
              </div>
              <SelectInput  id={"reglement_table_options"}
                          options={optionsTable} 
                          ref={reg_option}
                          width={"33.33%"}/>
            </div>
            <div style={{height: "350px"}}>
              <Table thead={thead[value]} tbody={search} 
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                handleDelete={handeDelete}/>
            </div>
            
            <div className={styles['row-between']}>
            <InputText  
                    holderText={"Montant"} 
                    labelPosition={true} 
                    type={"number"} 
                    ref={totalBonLivraison}
                    currency={true} 
                    currencyValue={"DZD"}
                    label={`Total des bons Livraisons`}
                     width={"23%"}
                    />
                    <InputText  
                    holderText={"Montant"} 
                    labelPosition={true} 
                    type={"number"} 
                    label={'Total des versements'}
                    currency={true} 
                    currencyValue={"DZD"}
                    ref={totalmonatantVerse}
                    width={"23%"}
                    />
                    <InputText  
                    holderText={"Montant"} 
                    labelPosition={true} 
                    type={"number"} 
                    label={'Credit client'}
                    currency={true} 
                    currencyValue={"DZD"}
                    ref={soldeClient}
                    width={"23%"}
                    />
            </div>
            <Dialog ref={displayDialog} type={true}>
            <div className={styles['row-between']}>
                  <InputText
                    id={"reglement_client_date"}
                    width={'40%'}
                    height={'40px'}
                    label={'Date Veresment'} 
                    ref={currentdate}
                    type={'date'}  
                  />
                  <SelectInput 
                    id={"reglement_client_type"}
                    label={"Type de Paiment"}
                    options={options}
                    ref={select_option}
                    width={"60%"}
                    height={'40px'}
                    reportChange={reportChange}
                    // setOption = {setOption}
                    // labelPostion={true}
                  />
                </div>
                <div style={{marginTop: "20px"}}>
                  <InputText 
                    id={"reglement_client_montant"}
                    width={'100%'} 
                    height={'max-content'}
                    label={'Montant'} 
                    holderText={'Montant'}
                    type={'number'}
                  />
                </div>
                
                  {selectedOption === options[1] && <InputText 
                    id={"reglement_client_cheque_number"}
                    width={'100%'} 
                    height={'max-content'}
                    label={'N° Chèque'} 
                    holderText={'N° Chèque'}
                    type={'number'}
                    
                  />}
                  {selectedOption === options[1] && 
                  <InputText 
                    id={"reglement_client_bank_account"}
                    width={'100%'} 
                    height={'max-content'}
                    label={'Compte bancaire'} 
                    holderText={'Compte bancaire'}
                    type={'number'}
                  />
                }
                  <InputText 
                    id={"reglement_client_observation"}
                    width={'100%'} 
                    height={'max-content'}
                    label={'Observation'} 
                    type={'text'}
                    holderText={'Observation'}
                  />
                </Dialog>
                
            </div>
            </div>
            </form>
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  );
}

export default ReglementClient;
import React from 'react';
import Button from '../button/Button';
import styles from './pagesidebar.module.scss';

const PageSideBar = (props) => {

  const {
    validateFunc = () => {}
  } = props

  return (
    <div className={styles['side-bar']}>
      <div className={styles['side-bar-informations']}>
        {props.children}
      </div>
      <div className={styles['row-around']}> 
        <Button width={"100%"} 
                name={'Validate'}
                onclick={validateFunc}
                />
      </div>
    </div>
  )
}

export default PageSideBar;

import React from 'react';
import styles from './pageNotFound.module.scss';

const PageNotFound = () => {
  return (
    <div className={styles['container']}>
      <section className={styles['message-container']}>
        <p className={styles['error-code']}>404</p>
        <h1 className={styles['message']}>Page Not Found</h1>
      </section>
    </div>
  )
}

export default PageNotFound;

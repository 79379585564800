import React, { useEffect, useRef, useState } from 'react';
import styles from './usersList.module.scss';
import usersFinder from '../../../api/usersFinder';
import InputText from '../../../components/input-text/InputText';
import { icons } from '../../../media/mediaHolder';
import Button from '../../../components/button/Button';
import UserCard from '../../../components/user-card/UserCard';
import { useNavigate } from 'react-router-dom';
import SettingsPage from '../SettingsPage';

const UsersList = () => {
    const [ data, setData ] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await usersFinder.get('/');
            setData(response.data.data.users);
        }

        fetchUsers();
    }, []);

    const navigate = useNavigate();

    const navigateOnClick = () => navigate('/settings/add-new-user');

    const searchInputRef = useRef(null);

    return (
        <SettingsPage>
            <div className={styles['container']}>
                <div className={styles['upper-section']}>

                    <div className={styles['set-in-row']}>
                        <p className={styles['users-num']}>{data?.length}</p>
                        <p className={styles['employees']}>{data?.length > 1 ? 'Employees' : 'Employee'}</p>
                    </div>
                    
                    <div className={`${styles['set-in-row']} ${styles['search-and-add']}`}>
                        <InputText 
                            label={""}
                            holderText={"Chercher"} 
                            width={"320px"} 
                            height={'100%'}
                            ref={searchInputRef}
                            icon={icons.search}
                        />
                        <Button 
                            name='Ajouter' 
                            width={"150px"}
                            height={'100%'} 
                            onclick={navigateOnClick}
                        />
                    </div>

            </div>
            <div className={styles['employees-list']}>
                    {
                        data?.map((employee, index) => <UserCard userInfo={employee} key={index} />)
                    }
            </div>
            </div>
        </SettingsPage>
    );
}

export default UsersList;

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styles from './confirmationButton.module.scss';
import Button from '../button/Button';

const ConfirmationButton = forwardRef((props, ref) => {
    const {
        message = 'Voulez-vous Confirmer ?',
        func,
        submitOnConfirm = true
    } = props

    const [ display, setDisplay ] = useState(false);
    const confirmationFunc = useRef(func);

    const handleConfirmation = () => {
        if (confirmationFunc.current) {
            confirmationFunc.current();
            confirmationFunc.current = null;
        }

        setDisplay(false);
    }

    useImperativeHandle(ref, () => ({
        display: () => setDisplay(true),
        setConfirmationFunc: (func) => confirmationFunc.current = func 
    }))

    return (
        <div className={display ? styles['visible'] : styles['hidden']}>
            <div className={styles['background']}>
                <div className={styles['dialog-container']}>
                    <p className={styles['message-container']}>{message}</p>
                    <div className={styles['operation-container']}>
                        <Button name={'annuler'} width={'150px'} switchbtn={true} onclick={() => setDisplay(false)}/>
                        {
                            submitOnConfirm ? <Button name={'confirmer'} type={'submit'} width={'150px'} onclick={handleConfirmation} />
                                            : <Button name={'confirmer'} type={'button'} width={'150px'} onclick={handleConfirmation} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ConfirmationButton;

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './listcomptebencaire.module.scss'
import Button from '../../components/button/Button'

const ListCompteBencaire = () => {
    const thead = [
    {name:"Code Compte",width: 190},
    {name:"Compte Bancaire",width: 690},
    {name:"Montant Actuel",width: 190},
    {name:"Montant Initial",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Liste Compte Bancaire'}>
        <div className={styles['body-container']} style={{height: '100vh'}}>
            <div className={styles['main-body']}>
                <div className={styles['row-end']}>
                    <Button 
                        width={"max-content"}
                        name={'Ajouter Compte Bencaire'}
                    />
                  </div>
            <div style={{height: '30vh'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} edit={true} remove={true}/>
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ListCompteBencaire
import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatStockaDateDonnee.json'
import { useState } from 'react'
import Button from '../../components/button/Button'
import styles from './etatStockaDateDonnee.module.scss'
import SubInfo from '../../components/sub-info/SubInfo'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions';

const EtatVentesParClientEtParArticle = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const thead = [
    {name:"Code Art.",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Qte En Stock",width: 190},
    {name:"Pu Achat PMP",width: 190}
  ]
    const [data,setData] = useState([]);
    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
    const [checked , setChecked ] = useState(false);
    const handelCheckbox = ()=>{
        setChecked(!checked);
    }
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat du stock à une date donnée'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  type={"text"}
                  holderText={"Chercher"}
                  label={"Chercher"}
                  labelPosition={true}
                  width={"50%"}
                  />
                <div className={styles['row-end']}>
                <Button 
                  icon={icons.exporter}
                  width={"max-content"}
                  name={'Exporter Vers Excel'}
                    />
                </div>
              </div>
              <div className={styles['row-between']} >
                  <InputText
                    label={"Date Au"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    />
                    <SubInfo lined={true} label={"Montant du stock en prix Achat :"} unit={"DZD"}/>
              </div>
              <div style={{height: '350px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
                <Table thead={metaData.table} inputValue={inputValue} tbody={search} edit={true} remove={true} date1={date1} date2={date2}/>
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatVentesParClientEtParArticle
import React from 'react';
import { icons } from '../../media/mediaHolder';
import styles from './pageLayout.module.scss';
import { useNavigate } from 'react-router-dom';
import PageOutline from '../page-outliner/PageOutliner';

const PageLayout = (props) => {
  const { icon, nameLayout } = props;
  const navigate = useNavigate();

  return (
    <PageOutline>
      <div className={styles['article-container']}>
        <div className={styles['head-container']}>
          <div className={styles['page-header']}>
            <img src={icon} alt='' />
            <label className={styles['header-title']}>{nameLayout}</label>
          </div>
          <img src={icons.exit} alt='' className={styles['cancel']} onClick={() => navigate(-1)} />
        </div>
        <div className={styles['body-container']}>
          {props.children}
        </div>
      </div>
    </PageOutline>
  )
}

export default PageLayout;

import React, { useState } from 'react'
import styles from './fichePeriodique.module.scss'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import SelectInput from '../../components/select-input/SelectInput'
const FichePeriodique = () => {
  const [ data, setData ] = useState([]);
  const [value, SetValue] = useState(0);
  const options =[
    {
      value : 0,
      name : "Les Ventes"
    },
    {
      value : 1,
      name : "Les Achat"
    },
    {
      value : 2,
      name : "Les Transferts"
    },
    {
      value : 3,
      name : "Les Reception Transferts"
    }
  ]
    const thead = [
    [
      {name:"Opr.",width: 190},
      {name:"Date",width: 190},
      {name:"Qtè",width: 190},
      {name:"Prix",width: 190},
      {name:"Client",width: 490},
      {name:"N° BL",width: 190},
      {name:"Code Cli.",width: 190}
    ],
    [
      {name:"Date",width: 190},
      {name:"Qtè Acheter",width: 190},
      {name:"PU Achat",width: 190},
      {name:"Fournisseur",width: 490},
      {name:"N° BL",width: 190},
      {name:"Code Fourn.",width: 190},
      {name:"Date de Pèremtion",width: 190}
    ],
    [
      {name:"Date",width: 190},
      {name:"Qtè",width: 190},
      {name:"Prix",width: 190},
      {name:"Client",width: 490},
      {name:"N° BL",width: 190},
      {name:"Code CLi.",width: 190}
    ],
    [
      {name:"Date",width: 190},
      {name:"Qtè",width: 190},
      {name:"Prix",width: 190},
      {name:"Client",width: 490},
      {name:"N° BL",width: 190},
      {name:"Code CLi.",width: 190}
    ]
  ]
  return (
    <PageLayout nameLayout={"Fiche Periodique"}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
            <div className={styles['date-container']}>
              <InputText label={"Period du"}  width={"30%"} type={"date"}/>
              <InputText label={"au"}  width={"30%"} type={"date"}/>
            </div>

            <DetailsSectionContainer name={"Détail des Achats"} stockProduit={true}>
                <InputText label={"Quantité globale achetée"} type={"number"} holderText={"001"}/>
                <InputText label={"Quantité globale transférer"}  holderText={"Désignation"}/>
                <InputText label={"Montant des achats"}  holderText={"Désignation"}/>
                <InputText label={"Montant des transfert"}  holderText={"Désignation"}/>
            </DetailsSectionContainer>

            <DetailsSectionContainer name={"Détail des ventes"} stockProduit={true}>
                <InputText label={"Quantité globale Vendues"} type={"text"} holderText={"001"}/>
                <InputText label={"Quantité globale réception transférer"} type={"text"} holderText={"Désignation"}/>
                <InputText label={"Montant des Vente"} type={"text"} holderText={"Désignation"}/>
                <InputText label={"Montant des réception transférer"} type={"text"}  holderText={"Désignation"} />
            </DetailsSectionContainer>

            <div className={`${styles['grid-space-between']}`}>
                <InputText label={"Recharche par client"}  holderText={"Client"} labelPosition={true}/>
                <SelectInput options={options}
                             setOption={SetValue} width={'33.3%'}/>
            </div>
            
            <div style={{height: '300px'}}>
              <Table thead={thead[value]} tbody={data} />
            </div>
        </div>
     </div>
    </PageLayout> 
  )
}

export default FichePeriodique
import React from 'react';
import styles from './checkPrintsExemplars.module.scss';
import SettingsPage from '../SettingsPage';

const CheckPrintsExemplars = () => {
    return (
        <SettingsPage>
            <h1>Check Prints' Exemplars</h1>
        </SettingsPage>
    );
}

export default CheckPrintsExemplars;

import React, { useEffect, useState, useRef } from 'react'
import styles from './stockMatierePremiere.module.scss'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'
import PageLayout from '../../components/page-layout/PageLayout'
import Table from '../../components/table/Table'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import InputText from '../../components/input-text/InputText'
import SelectInput from '../../components/select-input/SelectInput'
import fournisseurFinder from '../../api/fournisseurFinder';
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import articlesFinder from '../../api/articlesFinder'
import { useNavigate } from 'react-router-dom'
import productionFinder from '../../api/productionFinder'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import SubInfo from '../../components/sub-info/SubInfo'
import metaData from './StockMatierePremiere.json';
import depotFinder from '../../api/depotFinder'
import depotsStockFinder from '../../api/depotsStockFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'
const StockMatierePremiere = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
  
  const navigate = useNavigate();

  const [fournisseurs, setFournisseurs] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);
  const [articlesInfo, setArticlesInfo] = useState([]);
  const articleTable = useRef([]);
  const [depots, setDepots] = useState([]);

  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const depot = useRef(null);
  const paymentInput = useRef(null);
  const articlesRef = useRef();

  useEffect(() => {
    dateInput.current.setValue(new Date().toISOString().substring(0, 10));

    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots);
        depot.current.setValue(response.data.data?.mainDepot.name)
        articleTable.current.setAllData(articlesInfo.filter((article)=>{
          return (depot.current.getSelectedItem().name === article.depotName)
        }))
      }
      catch (err) {
        console.log(err);
      }
    }
    
    const fetchBonsAchat = async () => {
      try {

        const response = await productionFinder.get('/matierepremiere');
        let code = 1;
        console.log(response.data.data)
        if(response.data.data.code) {
          code = parseInt(response.data.data.code) + 1;
        }
        codeInput.current.setValue(code);
      }
      catch (err) {
        console.log(err);
      }
    }

    const getArticles = async ()=>{
      const response = await depotsStockFinder.get("/mainDepot")
      const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierepremiere'
      }).map(({ type, ...rest }) => rest);
      articlesRef.current = filtredArticles;
      setArticlesInfo(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lotNbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }));
      articleTable.current.setAllData(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lotNbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }))
   }

    fetchDepots();
    fetchBonsAchat();
    getArticles();
  }, []);


  const displayDialog = useRef(null);

  const addSelectedArticles = async () => {
    const articles = [];
    const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
    for(let i=0;i<data.length;i++){
      let article = data[i];
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle,
        quantityart: article.ds_quantity || 0,
        lotNbr: article.lotNbr, 
        designation: article.designation,
        quantity: article.quantity,
        observation: article.observation || ''
      })
    }
    setSelectedArticlesData(articles)
  }

  
  const getSelectedFournisseur = useRef();
  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const tableRef = useRef(null);

  const handelValidate = () => {
    displayConfirmationDialog.current.display();
  }

  const checkCode = async (value)=>{
    const response = await productionFinder.get(`/${value}/matierePremiere/code`)
    if(parseInt(response.data.data?.code) === parseInt(value)){
      return true
    }else
      return false
  }

  const checkLotNbr = (articles)=>{
    return articles.filter((article)=>{
      return !article.lotNbr.trim()
    }).map((article)=>{
      return {name:"Lot",message:`Assurez que Nombre Lot de code d'article ${article.codeArticle} est bien selectionez`};
    })
  }
  const checkquantitys = (articles)=>{
    return articles.filter((article)=>{
      return parseInt(article.quantityart) <= 0
    }).map((article)=>{
      return {name:"quantity",message:`Assurez que la Quantitie de code d'article ${article.codeArticle} est bien selectionez`};
    })
  }
  const checkErrs = async ()=>{
    const alerts = [];
    
    const code = document.getElementById("code").value;
    const depots = document.getElementById("depot").value
    
    if(!code){
      alerts.push({name:'code',message:'Assurez que le code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(code && (await checkCode(code) === true)){
      alerts.push({name:'code',message:'le N° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0){
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    }
    if(!depots){
      alerts.push({name:'depot' ,message:'Assurez que le Depot est bien selectionez'});
      depot.current.setBorderRed();
    }
    let checkLeau = checkLotNbr(tableRef.current.getAllData());
    if( checkLeau.length > 0){
      alerts.push(...checkLeau)
    }
    let checkquantity = checkquantitys(tableRef.current.getAllData());
    if( checkquantity.length > 0){
      alerts.push(...checkquantity)
    }
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return;
    }
    
    handelValidate();
  }

  const handelSubmit = async (event)=>{
    event.preventDefault();
    try {
      const depotName = depot.current.getValue();
      const depotId = depots.filter(depot => depot.name === depotName.toString())
      let articles = [],articleData;
      for(let i=0;i<tableRef.current.getAllData().length;i++){
        articleData = tableRef.current.getAllData()[i];

        await depotsStockFinder.post(`/`, {
          id_depot_stock: articleData.id,
          quantity: parseInt(articleData.quantityart),
          operation: 'sub'
        });
          articles.push({
            id_depot_stock: articleData.id, 
            quantity: parseInt(articleData.quantityart), 
            date_peremtion: articleData.date_peremtion,
            observation: articleData.observation
        });
      }
      
      await productionFinder.post("/", {
        id_depot: depotId[0].id,
        code : parseInt(document.getElementById('code').value),
        date : new Date(dateInput.current.getValue()),
        type: 'matierepremiere',
        articles
      })

      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);

    } catch (error) {
        console.log(error)
    }
  }
  const validateHandler = useRef([]);

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    articleTable.current.addItem(articlesRef.current.find(item => item.id === itemToDeleteId));
  }
  const actOnValidation = async (obj) => {

    tableRef.current.updateRow({
      id: obj.id,
      codeArticle: obj.codeArticle,
      quantityart: obj.quantityart || 0,
      lotNbr: obj.lotNbr,
      designation: obj.designation,
      quantity: obj.quantity,
      observation: obj.observation
    });
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Stock Matiere Premiere'}>
      <form onSubmit={handelSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row-between']}>
                  <InputText holderText={"Chercher"}
                             icon={icons.search}
                             width={"50%"} 
                             labelPosition={true}
                             type={"text"} 
                             label={'Rechercher'} 
                             />
              </div>

              <Button width={"200px"} name={"Ajouter Article"} onclick={()=>displayDialog.current.display()} />

              <div style={{height: "394px"}}>
                <Table thead={metaData.table} 
                        tbody={selectedArticlesData} 
                        confirmBeforeDeletion={false}
                        ref={tableRef} 
                        edit={user.permissions.includes(permissions.canEditInTable)} 
                        remove={user.permissions.includes(permissions.canDeleteFromTable)}
                        actOnValidation={actOnValidation}
                        handleDelete={handleDelete} 
                />
              </div>
            </div>
          </div>
          
          <div className={styles['side-body']}>
            <PageSideBar validateFunc={checkErrs}>
              <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                  <InputText label={"N° d'operation *"}
                             holderText={"0001"} 
                             width={"100%"} 
                             type={"number"} 
                             id={'code'}
                             ref={codeInput}
                             disabled
                             />

                  <InputText label={"Date d'operation"} 
                             width={"100%"}
                             type={"date"}
                             id={'date'}
                             ref={dateInput}/>

                  <InputText label={"Depot"} 
                             width={"100%"}
                             holderText={'Depot'}
                             id={'depot'}
                             searchData={depots}
                             ref={depot}
                             disabled
                             />
                </div>
                <div style={{marginTop: "235px"}} ></div>
              </div>
            </PageSideBar>
          </div>
          <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
            <AdderTable head={metaData.adderTable} ref={articleTable} />
          </Dialog>
          
        </div>
        <ConfirmationButton ref={displayConfirmationDialog}/>
      </form>
      <ErrorContainer ref={errorsHandler}/>
      <ValidatedMessages ref={validateHandler}/>
    </PageLayout>
  )
}

export default StockMatierePremiere;

import React from 'react'
import styles from'./ajouterCompteBancaire.module.scss'
import InputText from '../../components/input-text/InputText'
import PageLayout from '../../components/page-layout/PageLayout'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'

const AjouterCompteBancaire = () => {
  return (
    <PageLayout nameLayout={'Ajouter Compte Bancaire'} icon={icons.reglementClient.secondary}>
        <div className={styles['ajouter-client-container']}>
        <div className={styles['ajouter-client']}>
            <div className={styles['ajouter-client-line-1']}>
                <InputText width={'40%'} holderText={'001'} type={'number'} label={'Code'}/>
                <InputText width={'60%'} holderText={'Fournisseur'} type={'number'} label={'Compte Bancaire'}/>
            </div>
            <div className={styles['ajouter-client-line-1']}>
                <InputText width={'60%'} holderText={'Telephone'} type={'text'} label={'Montant Actuel'}/>
                <InputText width={'40%'} holderText={'Fax'} type={'text'} label={'Montant Initial'}/>
            </div>
        </div>
        <div className={styles['ajouter-client-operation-container']}>
            <div></div>
            <div className={styles['ajouter-client-operation']}>
                <Button name={'Annuler'} switchbtn={true} width={120}/>
                <Button name={'Ajouter'} width={150}/>
            </div>
        </div>
        </div>
    </PageLayout>
  )
}

export default AjouterCompteBancaire

import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import Table from '../../components/table/Table';
import styles from './listArticleCommandes.module.scss';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';

const ListArticleCommandes = () => {
  const theaderData = [
    {name:"Code Art.",width: 190},
    {name:"Designation",width: 490},
    {name:"Qte Stock",width: 190},
    {name:"PU Achat",width: 190},
    {name:"Qte Par Caton",width: 190},
    {name:"Code Famille",width: 190},
    {name:"Famille Article",width: 490},
    {name:"Saisi Par",width: 190}
  ]

  const [tbodyData, setTbodyData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <PageLayout icon={icons.achat} nameLayout={'La liste des articles a commandes'}>
      <div className={styles['container']}>
        <div className={`${styles['fill-width']} ${styles['upper-section']}`}>
            <InputText holderText={"Article"} 
                       label={'Chercher un article'}
                       type={"text"}
                       inputValue={searchTerm}
                       setInputValue={setSearchTerm}
                       labelPosition={true}  
                       width={"50%"}  
                       icon={icons.search} />

            <div className={styles['print-export']}>
                <Button name='Imprimer' 
                        width={"150px"} 
                        icon={icons.impr}/>

                <Button link={true} 
                        path={"#"}  
                        name='Exporter Vers Exel' 
                        width={"200px"} 
                        icon={icons.exporter}/>
            </div>
        </div>
        <div className={styles['fill-width']} style={{height: "450px"}}>
            <Table thead={theaderData} tbody={tbodyData} remove={true} inputValue={searchTerm} />
        </div>
      </div>
    </PageLayout>
  )
}

export default ListArticleCommandes;
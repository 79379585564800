import React, { useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import OutputText from '../../components/output-text/OutputText'
import styles from './detailStockInventaire.module.scss'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const DetailStockInventaire = () => {
    const { state: authState } = useAuth();
    const { user } = authState;
  
    const thead = [
        {name:"Code",width: 190},
        {name:"Code Familly",width: 190},
        {name:"Designation",width: 490},
        {name:"Stock",width: 190},
        {name:"Pu Achat",width: 190},
        {name:"Prix Grossiste",width: 190},
        {name:"PPA",width: 190}
      ]
    let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
  return (
    <PageLayout nameLayout={"Detail Stock Inventaire"} icon={icons.article}>
        <div className={styles['page-container']} style={{gap: "40px"}}>
            <div style={{display: 'flex' , width: "100%" , justifyContent: 'space-between'}}>
                <div style={{width: "30%"}}>
                    <OutputText value={"001"} label={"Code Article"} width={"100%"}/>
                </div>
                <div style={{width: "60%"}}>
                    <OutputText value={"20000DZD"} label={"Designation"} width={"100%"}/>
                </div>
            </div>
            <div style={{display: 'flex' , width: "100%" , justifyContent: 'space-between'}}>
                <div style={{width: "30%"}}>
                    <OutputText value={"001"} label={"Stock Micro"} width={"100%"}/>
                </div>
                <div style={{display: 'flex' , width: "60%" , justifyContent: 'space-between'}}>
                    <div style={{width: "45%"}}>
                        <OutputText value={"20000DZD"} label={"Stock Inventair"} width={"100%"}/>
                    </div>
                    <div style={{width: "45%"}}>
                        <OutputText value={"20000DZD"} label={"Ecart"} width={"100%"}/>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex' , width: "100%" , justifyContent: 'space-between' }}>
                <div style={{width: "30%"}}>
                    <OutputText value={"001"} label={"PU Achat"} width={"100%"}/>
                </div>
                <div style={{width: "60%"}}>
                    <div style={{width: "45%"}}>
                        <OutputText value={"001"} label={"PU Vente"} width={"100%"}/>
                    </div>
                </div>
            </div>
            <div>
                <InputText width={'25%'} holderText={"Chercher"} label={'Chercher'}
                margin={'0px 15px 15px 0px'}/>
                <div className={styles['table']}>
                    <Table thead={thead} inputValue={inputValue} tbody={search} 
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                    />
                </div>
            </div>
            
        </div>
    </PageLayout>
  )
}

export default DetailStockInventaire

import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import styles from './inventaire.module.scss';
import InputText from '../../components/input-text/InputText';
import SubInfo from '../../components/sub-info/SubInfo';
import Button from '../../components/button/Button';
import PageSideBar from '../../components/page-side-bar/PageSideBar';
import Table from '../../components/table/Table';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const Inventaire = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const total = 9999999;

  const thead = [
    {name:"Code", width: 190},
    {name:"Quantite", width: 190},
    {name:"Prix Achat", width: 190},
    {name:"PU Vente", width: 190},
    {name:"Designation", width: 390},
    {name:"Stk Micro", width: 190},
    {name:"Stock Inv.", width: 190},
    {name:"Total Ach.", width: 190},
    {name:"Total Ven.", width: 190},
  ]

  return (
    <PageLayout icon={icons.achat} nameLayout={"Inventaire"}>
      <div className={styles['body-container']}>
      <div className={styles['main-body']}>
        <div className={styles['actions-container']}>
          <div className={styles['row-evenly']}>
            <InputText  holderText={"Depot"} labelPosition={true} setInputValue={setInputValue} inputValue={inputValue}
            width={"50%"} type={"text"} label={'Depot'} icon={icons.search}/>
          </div>
          <div className={styles['ajouter-articles-container']}>
              <Button name='Ajouter Des Article' width={"200px"}/>
              <InputText  holderText={"Chercher"} width={"30%"} type={"text"} icon={icons.search}/>
          </div>
          <div style={{height: "400px"}}>
            <Table thead={thead} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
            />
          </div>
        </div>
      </div>
      <div className={styles['side-body']}>
        <PageSideBar >
            <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                <InputText label={"N° du bon"} holderText={"0001"} width={"100%"} type={"number"}/>
                <InputText label={"Date de bon"}  width={"100%"} type={"date"}/>
                </div>
                <div className={styles['col-evenly']} style={{marginTop: "150px"}}>
                  <SubInfo label={"Total en Prix Achat"} unit={"DZD"}/>
                  <SubInfo label={"Total en Prix Vente"} unit={"DZD"}/>
                </div>
            </div>
        </PageSideBar>  
      </div>
    </div>
   </PageLayout>
  )
}

export default Inventaire;

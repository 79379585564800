import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationversementretraitbanque.module.scss'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ConsultationVersementRetraitBanque = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Stock",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Prix Grossiste",width: 190},
    {name:"PPA",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des versements / Retrait de la Banque'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                    <InputText 
                        icon={icons.search}
                        type={"text"}
                        holderText={"Chercher"}
                        label={"Chercher"}
                        labelPosition={true}
                        width={"50%"}
                        />  
                    <div className={styles['row-end']}>
                        <Button 
                            icon={icons.impr}
                            name={"Imprimer"}
                            width={"250px"}
                        />
                    </div>
                </div>
                <div className={styles['row']}>
                    <InputText label={"Period du"}
                      width={"max-content"} 
                      type={"date"}
                      />
                    <InputText label={"Au"}
                      width={"max-content"} 
                      type={"date"}
                      />  
                </div>
              
                <div  style={{height:'300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
                </div>

                <div style={{width: '100%'}} className={styles['row-end']}>
                    <InputText label={"Total des Versements"} width={"25%"} type={"number"} holderText={"Montant"}/>
                </div>

                <div style={{width: '100%'}} className={styles['row-end']}>
                    <InputText label={"Total des retraits"} width={"25%"} type={"number"} holderText={"Montant"}/>
                </div>

            </div>

        
    </div>
    </PageLayout>
  )
}

export default ConsultationVersementRetraitBanque;
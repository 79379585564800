import React, { useState } from 'react';
import styles from './modItem.module.scss';
import { Link } from 'react-router-dom';

const ModItem = (props) => {
  const { ModItemInfo , main } = props;
  const [ bgImag, setBgImg ] = useState(ModItemInfo.mainBgUrl);

  const handleMouseOver = () => setBgImg(ModItemInfo.secBgUrl);
  const handleMouseLeave = () => setBgImg(ModItemInfo.mainBgUrl);

  return (
    <Link to={ModItemInfo.linkUrl}>
      <div 
        className={styles['mod-item-container']} 
        style={{backgroundImage: `url(${bgImag})`}}
        onMouseLeave={handleMouseLeave} 
        onMouseOver={handleMouseOver}
      >

        <p className={ main ? styles['mod-item-name'] : styles['mod-item-name-seconde']}>
          {ModItemInfo.name}
        </p>

      </div>
    </Link>
  )
}

export default ModItem;

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printingFactureProforma.module.scss';
import { prints } from '../../media/mediaHolder';
import NumberConverter from '../number-converter/NumberConverter';
import printingFinder from '../../api/printingFinder';
import { fixDecimalDigits } from '../../utils/numberFormatter';

const PrintingFactureProforma = forwardRef( (props, ref) => {

  const {dataInfo, thead, type, pageNumber, pageNumbers, printingHeader, globalInfo} = props

  const wordRef = useRef();

  const timeoutRef = useRef();
  const [totalHT, setTotalHT] = useState(0);
  const [totalRemise, setTotalRemise] = useState(0);
  const [totalAVTax, setTotalAVTax] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const articlesProps = useRef([]);
  useEffect(()=>{
    const handlePrinting = ()=>{
      const articlesAdded = dataInfo.articlesAdded || [];

      // const { totalBeforeDiscounts, totalDiscounts, totalAVT, totalAfterDiscounts } = globalInfo;

      const totalBeforeDiscounts = globalInfo?.totalBeforeDiscounts;
      const totalDiscounts = globalInfo?.totalDiscounts;
      const totalAVT = globalInfo?.totalAVT;
      const totalAfterDiscounts = globalInfo?.totalAfterDiscounts;

      let totalBD = String(totalBeforeDiscounts);
      let totalD = String(totalDiscounts);
      let totalA = String(totalAVT);
      let totalAD = String(totalAfterDiscounts);

      if (!totalBD) {
        totalBD = articlesAdded.reduce((accumulator, article) => accumulator += article.PUVente * article.quantity, 0);
      }

      if (!totalD) {
        totalD = articlesAdded.reduce((accumulator, article) => {
          const puRemise = fixDecimalDigits(article.PUVente - (article.PUVente * (article.remise / 100)), 2, true);
          return accumulator += fixDecimalDigits(((article.PUVente - puRemise) * article.quantity), 2, true);
        }, 0);
      }

      if (!totalA) {
        totalA = articlesAdded.reduce((accumulator, article) => {
          const puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
          const puTVA = fixDecimalDigits((puRemise * (article.tva / 100)), 2, true);
          return accumulator += fixDecimalDigits((article.quantityart * puTVA), 2, true);
        }, 0);
      }

      if (!totalAD) {
        totalAD = fixDecimalDigits(totalBD + totalA - totalD, 2, true);
      }

      setTotalHT(totalBD);
      setTotalRemise(totalD);
      setTotalAVTax(totalA);
      setTotalTTC(totalAD);

      wordRef.current.convert(parseFloat(totalAD));
  
      articlesProps.current = [];
      for (const key in articlesAdded[0]) {
        articlesProps.current.push(key);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    handlePrinting();
  },[dataInfo.articlesAdded])

  const numberWithSpaces = (num) => {
    return (Math.floor(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  };
  const [header ,setHeader] = useState();
  useEffect(() => {
    const fetchHeader = async ()=>{
      const printingHead = await printingFinder.get('/');
      setHeader(printingHead.data.data.printingHead[0])
    }
    fetchHeader();
  },[])

  return (
    <>
      <div className={style['header-container']}>
        <div className={style['logo-container']}  style={{backgroundImage: `url(${prints.logo})`}}></div>
        <div className={style['info-container']}>
          <p className={style['title-info']}>{printingHeader?.line_1}</p>
          <div className={style['sub-info-container']}>
            <p className={style['sub-title-info']}>{printingHeader?.line_2}</p>
            <p className={style['sub-title-info']}>{printingHeader?.line_3}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_4}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_5}</p>
          </div>
        </div>
      </div>
      <div className={style['Line']}>
        <div className={style['first-line']}></div>
        <div className={style['second-line']}></div>
      </div>
      <div className={style['body-container']}>
        <div className={style['main-title-container']}>
          <p className={style['title-number']}>{type} N°</p>
          <p className={style['title-number']}>{dataInfo.rowInfo.code} /{dataInfo.rowInfo.date.substring(0,4)}</p>
        </div>
        <div className={style['info-container-type2']}>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {<><p className={style['head-lines-type1']}>Doit</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.client_name}</p></>}
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Date</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.date.substring(0,10)}</p>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {dataInfo.rowInfo.client_address && <>
              <p className={style['head-lines-type1']}>Adresse</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.client_address}</p></>}
            </div>
            <div className={style['head-lines-container']}></div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {dataInfo.rowInfo.client_activite && <>
              <p className={style['head-lines-type1']}>Activite</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.client_activite}</p></>}
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Reglement</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.payment_type}</p>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['client-info-container']}>
              <div className={style['client-info']}>
                <div className={style['head-lines-container']}>
                  {dataInfo.rowInfo.client_n_register && <>
                  <p className={style['head-lines-type4']}>RC</p>
                  <p className={style['head-lines-type4']}>:</p>
                  <p className={style['head-lines-type5']}>{dataInfo.rowInfo.client_n_register}</p></>}
                </div>
                <div className={style['head-lines-container']}>
                  {dataInfo.rowInfo.client_n_article && <>
                  <p className={style['head-lines-type4']}>ART</p>
                  <p className={style['head-lines-type4']}>:</p>
                  <p className={style['head-lines-type5']}>{dataInfo.rowInfo.client_n_article}</p></>}
                </div>
                <div className={style['head-lines-container']}>
                  {dataInfo.rowInfo.client_nif &&<>
                  <p className={style['head-lines-type4']}>NIF</p>
                  <p className={style['head-lines-type4']}>:</p>
                  <p className={style['head-lines-type5']}>{dataInfo.rowInfo.client_nif}</p></>}
                </div>
              </div>
              <div className={style['client-info']}>
                <div className={style['head-lines-container']}>
                    {dataInfo.rowInfo.client_n_fiscal &&<>
                    <p className={style['head-lines-type4']}>IF</p>
                    <p className={style['head-lines-type4']}>:</p>
                    <p className={style['head-lines-type5']}>{dataInfo.rowInfo.client_n_fiscal}</p></>}
                </div>
                <div className={style['head-lines-container']}>
                  {dataInfo.rowInfo.client_nis && <>
                  <p className={style['head-lines-type4']}>NIS</p>
                  <p className={style['head-lines-type4']}>:</p>
                  <p className={style['head-lines-type5']}>{dataInfo.rowInfo.client_nis}</p></>}
                </div>
              </div>
            </div>
            <div className={style['bar-code']}>Bar Code</div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Page</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type1']}>{pageNumber}/{pageNumbers}</p>
            </div>
          </div>
        </div>
        <div className={style['table-container']}>
          <table className={style['table']}>
            <colgroup>
              {thead.map((obj)=>{
                  return <col style={{width: obj.width}} className={style['col1']}/>
              })}
            </colgroup>
            <thead>
              <tr>
                {thead.map((head, index)=>{
                    return(
                        <th key={index} className={style['table-head']}>
                            {head.name} 
                        </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {dataInfo.articlesAdded.map((values, index) => {
                  return(
                    <tr key={index}>
                      {
                        articlesProps.current.map((key, index) => {
                          return(
                              <td className={style['td-cell']} key={index}>
                                {values[key]}
                              </td>
                            )
                        })
                      }
                    </tr>
                  )
                })}
                <tr key={9999} style={{height: "100%"}}></tr>
            </tbody>
          </table>
        </div>
        <div className={style['amounts-container']}>
          <div className={style['amounts-letters']}>
            <p className={style['head-lines-type1']}>Arrete la Presente Facture a la Somme de:</p>
            <p className={style['head-lines-type3']}><NumberConverter ref={wordRef} /></p>
          </div>
          <div className={style['amounts-numbers']}>
            <div className={style['head-lines-container-type2']}>
                  <p className={style['head-lines-type1']}>Total HT</p>
                  <p className={style['head-lines-type2']}>:</p>
                  <p className={style['head-lines-type6']}>{numberWithSpaces(totalHT)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
                  <p className={style['head-lines-type1']}>Total Remise</p>
                  <p className={style['head-lines-type2']}>:</p>
                  <p className={style['head-lines-type6']}>{numberWithSpaces(totalRemise)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
                  <p className={style['head-lines-type1']}>Total TVA</p>
                  <p className={style['head-lines-type2']}>:</p>
                  <p className={style['head-lines-type6']}>{numberWithSpaces(totalAVTax)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
                  <p className={style['head-lines-type1']}>Total TTC</p>
                  <p className={style['head-lines-type2']}>:</p>
                  <p className={style['head-lines-type6']}>{numberWithSpaces(totalTTC)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style['page-footer']}>
        <div className={`${style['Line']} ${style['footer-line']}`}>
          <div className={style['first-line']}></div>
          <div className={style['second-line']}></div>
        </div>
        <div className={style['head-lines-container']}>
          {dataInfo.saisiPar &&<>
          <p className={style['head-lines-type4']}>Saisi par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.saisiPar}</p></>}
          {dataInfo.validerPar &&<>
          <p className={style['head-lines-type4']}>Valider par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.validerPar}</p></>}
        </div>
      </div>
      </>
  )
})

export default PrintingFactureProforma

import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './consultationbonreceptiontransfert.module.scss';
import bonReceptionTransferFinder from '../../api/bonReceptionTransferFinder';
import articlesReceptionTransfereFinder from '../../api/articlesReceptionTransfereFinder';
import Printing from '../../components/printing/Printing';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';

const ConsultationBonReceptionTransfert = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead1 = [
    {name:"N Bon",width: 190},
    {name:"Date",width: 190},
    {name:"Saisie Par",width: 190},
    {name:"Bon Bloque?",width: 190},
    {name:"Observation",width: 390}
  ]
  
  const thead2 = [
    {name:"Code", width: 190},
    {name:"Designation", width: 390},
    {name:"Quantite", width: 190},
    {name:"Lot", width: 190},
    {name:"Date de Peremption", width: 190},
    {name:"PU", width: 190},
    {name:"Total", width: 190},
    {name:"Qte/Cart", width: 190},
    {name:"Nb Carton", width: 190},
    {name:"Article Bloque?", width: 190},
    {name:"Observation", width: 390}
  ]

  const [data, setData] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([])
  const [dataSuiv, setDataSuiv] = useState([]);

  useEffect(() => {
    const fetchBonsAchat = async () => {
      try{
        const response = await bonReceptionTransferFinder.get('/');
        setBonsInfo(response.data.data.bons);
        setData(response.data.data.bons.map((bon)=>{
          return(
            {
              id: bon.id,
              code: bon.code,
              date: bon.date.substring(0, 10),
              saisiPar: bon.user_name,
              blocked: '',
              observation: ''
            }
          )
        }))
      }catch(err){
        console.log(err)
      }
      
    }
    fetchBonsAchat();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([])
      return
    }
    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id)
        setDataSuiv(
         element.articlesAdded.map((article)=>{
          return {
            id: article.id,
            code: article.code,
            designation: article.designation,
            quantity: article.quantity,
            lotNbr: article.lotNbr,
            expireDate: String(article.expireDate.substring(5,7)+'/'+article.expireDate.substring(0,4)),
            PUVente: article.prix_achat,
            total: article.prix_achat * article.quantity,
            qteCart: article.qte_cart,
            nbCart: article.nb_cart,
            blocked: article.blocked ? 'Oui' : 'Non',
            observation: article.observation
          }
        })
      )
    })
  }

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['code'];
  const handleSearch = (val) => {
      setInputValue(val);
  }
  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "5%"},
    {name: "Qte",width: "5%"},
    {name: "Total",width: "10%"},
  ]
  
  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const printRef = useRef ();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    const articlesAdded = tableRefSuiv.current.getAllData().map((art)=>{
      return{
        code: art.code,
        designation: art.designation,
        lotNbr: art.lotNbr,
        expireDate: art.expireDate,
        quantity: art.quantity,
        total: art.total.toFixed(2),
      }
    });
    // const articlesAdded = temp;
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar
    }
    console.log(data)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }
    
  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des bons de Réception des transferts'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
            icon={icons.search}
            type={"text"}
            holderText={"Chercher Par N° Bon"}
            label={"Chercher"}
            labelPosition={true}
            width={"50%"}
            reportChange={(val) => handleSearch(val)}
            />  
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
              onclick={handlePrint}
              />
            </div>
          </div>
          <div style={{height: '300px'}}>
            <Table tableTitle={"Bon du Réception d'un transfert"}
              singular={true} 
              filterInput={inputValue}
              filterByArr={filterByArr}
              ref={tableRef}
              sendSelectedRowInfo={handleClick}  
              thead={thead1} 
              tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)}
            />
          </div>
          <div><br/><br/></div>
          <div style={{height: '300px'}}>
            <Table tableTitle={"Détail du bon du Réception d''un transfert"} ref={tableRefSuiv} thead={thead2} tbody={dataSuiv} />
          </div>
        </div>
      </div>
    {isActive && <Printing type={'Bon Reception De Transfer'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationBonReceptionTransfert;

import React, { useEffect, useRef, useState } from 'react';
import styles from'./ajouterClient.module.scss';
import InputText from '../../components/input-text/InputText';
import SelectInput from '../../components/select-input/SelectInput';
import PageLayout from '../../components/page-layout/PageLayout';
import Button from '../../components/button/Button';
import { icons } from '../../media/mediaHolder';
import clientsFinder from '../../api/clientsFinder';
import { useNavigate } from 'react-router-dom';
import wilayasFinder from '../../api/wilayasFinder';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';

const AjouterClient = () => {
    const [ searchData, setSearchData] = useState([]);
    
    const navigate = useNavigate();

    const handelCancel = () => navigate(-1);

    const options = [
        "Gros",
        "Demi Gros",
        "Special",
        "Detail"
    ]
    const [ option , setOption ]=useState(0)

    useEffect(()=>{
        const getwilaya = async ()=>{
          const response = await wilayasFinder.get("/");
          setSearchData(response.data.data.wilayas);
        }
        const fetchFournisseurs = async () => {
            const response = await clientsFinder.get('/');
            let code=1;
            if(response.data.data.maxCode.max){
            code = parseInt(response.data.data.maxCode.max) + 1;
            }
            codeInput.current.setValue(code);   
        }
        fetchFournisseurs();
        getwilaya();
      },[])

    const middleWare = async ()=>{
        const wilayaName = document.getElementById('client_wilaya').value;
        const selectedWilaya = await wilayasFinder.get(`/${wilayaName}`);
        // handleSubmit(selectedWilaya.data.data.wilayas[0].id)
        return selectedWilaya.data.data.wilayas[0].id
    }
    const handleSubmit = async (event) =>{
        event.preventDefault();
        try {
            clientsFinder.post("/",
            {
                "id_wilaya":parseInt(getSlectedWilaya.current.getSelectedItem().id),
                "code": parseInt(document.getElementById("client_code").value),
                "name": document.getElementById("client_name").value,
                "address": document.getElementById("client_address").value,
                "activity": document.getElementById("client_activity").value,
                "fax": document.getElementById("client_fax").value,
                "phone": document.getElementById("client_phone").value,
                "email": document.getElementById("client_email").value,
                "n_register": document.getElementById("client_register_nbr").value,
                "n_article": document.getElementById("client_article_nbr").value,
                "n_fiscal": document.getElementById("client_fiscal_nbr").value,
                "nis": document.getElementById("client_nis").value,
                "nif": document.getElementById("client_nif").value,
                "type_client": client_type.current.getSelectedOption(),
                "blocked": false,
                "credit_max": parseInt(document.getElementById("client_credit_max").value),
                "delais_vers_max": parseInt(document.getElementById("client_delais_vers_max").value)
            }
            )
            // console.log("insert Success");
            navigate(-1);
        } catch (error) {
            console.log(error)
        }
    } 

    const errorsHandler= useRef([]);
    const displayConfirmationDialog = useRef();
    const codeInput = useRef();
    const clientR = useRef();
    const addressR = useRef();
    const phoneR = useRef();
    const client_type = useRef();
    const getSlectedWilaya = useRef();

    const checkCode = async (value)=>{
        const response = await clientsFinder.get(`/${value}`)
        if(response.data.data.code == value){
          return true
        }else
          return false
      }

    const checkErrs = async ()=>{
        
        let alerts = [];
        let code = document.getElementById('client_code').value
        let client = document.getElementById('client_name').value
        
        if(!code){
            alerts.push({name:'code',message:'Assurez que le Code est bien saisez'});
            codeInput.current.setBorderRed();
        }
        if( await checkCode(code) === true){
            alerts.push({name:'code',message:'le N° du bon est deja exister'});
            codeInput.current.setBorderRed();
        }
        if(!client){
            alerts.push({name:'client',message:'Assurez que Client est bien saisez'});
            clientR.current.setBorderRed();
        }
        
        if(alerts.length > 0) {
            errorsHandler.current.errorsHandler(alerts);
            return
        }
        handelValidate();
    } 
    const handelValidate= () => {
        displayConfirmationDialog.current.display();
    }
    
  return (
    <PageLayout nameLayout={'Ajouter Client'} icon={icons.reglementClient.secondary}>    
    <form onSubmit={handleSubmit}>
    <div className={styles['ajouter-client-container']}>
        <div className={styles['ajouter-client']}>
            <div className={styles['ajouter-client-line-1']}>
                <InputText 
                    id={"client_code"}
                    width={'33.3%'} 
                    holderText={'001'} 
                    type={'number'} 
                    label={'Code'} 
                    ref={codeInput}
                />

                <InputText 
                    id={"client_name"}
                    width={'33.3%'} 
                    holderText={'Client'} 
                    type={'text'} 
                    label={'Client'}
                    ref={clientR}
                />

                <SelectInput 
                    id={"client_type"}
                    options={options} 
                    width={'33.3%'} 
                    label={'Type Client'}
                    ref={client_type}
                />
            </div>
            <div className={styles['ajouter-client-line-2']}>
                <InputText 
                    id={"client_address"}
                    width={'100%'} 
                    holderText={'Address'} 
                    type={'text'} 
                    label={'Addresse'}
                    ref={addressR}
                />
            </div>
            <div className={styles['ajouter-client-line-3']}>
                <InputText 
                    id={"client_wilaya"}
                    width={'32.3%'} 
                    holderText={'Wilaya'} 
                    type={'text'} 
                    label={'Wilaya'}
                    searchData={searchData}
                    displaySearchOption={true}
                    ref={getSlectedWilaya}
                />
                <InputText 
                    id={"client_activity"}
                    width={'70%'} 
                    holderText={'Activite'} 
                    type={'text'} 
                    label={'Activite'}
                />
            </div>
            <div className={styles['ajouter-client-line-4_8-container']}>
                <div className={styles['ajouter-client-line-4_8']}>
                    <InputText 
                        id={"client_credit_max"}
                        width={'50%'} 
                        holderText={'Crédit Max'} 
                        type={'number'} 
                        label={'Crédit Max'}
                    />
                    <InputText 
                        id={"client_delais_vers_max"}
                        width={'50%'} 
                        holderText={'Délais Vers Max ( En Nb Jour )'} 
                        type={'number'} 
                        label={'Délais Vers Max ( En Nb Jour )'}
                    />
                </div>
                <div className={styles['ajouter-client-line-4_8']}>
                    <InputText 
                        id={"client_phone"}
                        width={'50%'} 
                        holderText={'Téléphone'} 
                        type={'text'} 
                        label={'Téléphone'}
                        ref={phoneR}
                    />
                    <InputText 
                        id={"client_fax"}
                        width={'50%'} 
                        holderText={'Fax'} 
                        type={'text'} 
                        label={'Fax'}
                    />
                </div>
                <div className={styles['ajouter-client-line-4_8']}>
                    <InputText 
                        id={"client_email"}
                        width={'50%'}
                        holderText={'E-mail'}
                        type={'email'}
                        label={'E-mail'}
                    />
                    <InputText 
                        id={"client_register_nbr"}
                        width={'50%'} 
                        holderText={'Register'} 
                        label={'Register'}  
                    />
                </div>
                <div className={styles['ajouter-client-line-4_8']}>
                    <InputText 
                        id={"client_fiscal_nbr"}
                        width={'50%'} 
                        holderText={'Fiscal'} 
                        label={'Fiscal'}
                    />
                    <InputText
                        id={"client_article_nbr"}
                        width={'50%'} 
                        holderText={'N° Article'} 
                        label={'N° Article'}
                     />
                </div>
                <div className={styles['ajouter-client-line-4_8']}>
                    <InputText
                        id={"client_nis"}
                        width={'50%'} 
                        holderText={'NIS'} 
                        label={'NIS'}
                     />
                    <InputText 
                        id={"client_nif"}
                        width={'50%'} 
                        holderText={'NIF'} 
                        label={'NIF'}
                    />
                </div>
            </div>
        </div>
        <div className={styles['ajouter-client-operation-container']}>
            <div>
            </div>
            <div className={styles['ajouter-client-operation']}>
                <Button name={'Annuler'} switchbtn={true} width={120} onclick={()=>{handelCancel()}}/>
                <Button onclick={checkErrs} name={'Ajouter'} width={150}/>
            </div>
        </div>
        </div>
        <ConfirmationButton ref={displayConfirmationDialog}/>
        </form>
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default AjouterClient;

import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './creditClientFournisseur.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import SelectInput from '../../components/select-input/SelectInput';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js'

const CreditClientFournisseur = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const options = [
    {
      value: 0,
      name: 'Tous les Clients-Fournisseurs'
    },
    {
      value: 1,
      name: 'Clients-Fournisseurs Soldes'
    },
    {
      value: 2,
      name: 'Clients-Fournisseurs non Soldes'
    }
  ]

  const thead = [
    {name: "Code Client", width: 190},
    {name: "Client", width: 190},
    {name: "Solde", width: 190},
    {name: "Montant BL", width: 190},
    {name: "Verser", width: 190},
    {name: "Montant BL Vente", width: 190},
    {name: "Verser Client", width: 190},
    {name: "Solde Client", width: 190},
    {name: "Montant BL Achat", width: 190},
    {name: "Verser Four.", width: 190},
    {name: "Solde Four.", width: 190},
    {name: "TEL/FAX", width: 190}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Credit Des Client-Fournisseur'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div style={{width: "100%" ,display: 'flex' ,justifyContent: 'space-between'}}>
              <div></div>
              <div className={styles['operations-buttons']}>
                  <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
              </div>
          </div>
          
        <div className={styles['main-search-bar']}>
          <div className={styles['periode']}>
              <InputText width={'40%'} holderText={'Chercher'} label={'Chercher'}
                      margin={'0px 0px 0px 15px'}labelPosition={true} />
          </div>
          <div className={styles['select-container']}>
              <SelectInput options={options} width={"100%"} />
          </div>
        </div>
        
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} 
            edit={user?.permissions.includes(permissions.canEditInTable)} 
            remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
          />
        </div>
        <div className={styles['mantant-chargers']}>
          <div>
              <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total BL'}
              margin={'0px 15px 0px 15px'}/>
          </div>
          <div>
            <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Totale Verse'}
            margin={'0px 15px 0px 0px'}/>
          </div>
          <div style={{display: "flex"}}>
            <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Reste'}
            margin={'0px 15px 0px 0px'}/>
          </div>
          
        </div>
      </div>
    </PageLayout>
  )
}

export default CreditClientFournisseur;

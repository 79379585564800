export const spacedNumFormat = (num) => {
    if (num === null || num === undefined) {
        console.error(`The value passed to spacedNumFormat function is either null or undefined`);
        return;
    }

    return (Math.floor(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                        .replace(/,/g, ' ');
}

export const spacedPhoneNumFormat = (num) => {
    if (num === null || num === undefined) {
        console.error(`The value passed to spacedNumFormat function is either null or undefined`);
        return;
    }
    
    return num.replace(/(\d)(?=(\d{2})+$)/g, '$1 ');
}

export const fixDecimalDigits = (num, fractionDigitsNum = 2, asNumber = false) => {
    if (num === null || num === undefined) {
        console.error('The passed number to fixDecimalDigits function is null or undefined');
        return;
    }

    let numStr = num.toString();

    /* Check if the number doesn't include a point at all like 7 
        and check if it doesn't have at least 'fractionDigitsNum' digits after the point like 7.1
    */
    if (!numStr.includes('.') || (numStr.split('.')[1] || '').length < fractionDigitsNum) {
        numStr = typeof num === 'number' ? num.toFixed(fractionDigitsNum) : Number(num).toFixed(fractionDigitsNum);
    }

    return !asNumber ? numStr.slice(0, (numStr.indexOf(".")) + fractionDigitsNum + 1) : Number(numStr.slice(0, (numStr.indexOf(".")) + fractionDigitsNum + 1));
}

export const capNumFractionAtTwo = (num) => (Math.floor(num * 100) / 100);

import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatRemiseParParcentage.json'
import styles from './etatRemiseParParcentage.module.scss'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'

const EtatRemiseParParcentage = () => {
  const [ inputValue , setInputValue ] = useState('');

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [data,setData] = useState([]);

  const totalRemisePM = useRef();
  const totalBLAvecRemise = useRef();
  const totalBLSansRemise = useRef();

  useEffect(() => {
  let bonsAchat = [];


  const fetchBonsVente = async () =>{
    let totalVenteSansRemise = .0;
    let totalVenteRemise = .0;
    let totalRemise = .0;
    
    let montantAvecRemise = .0;
    let montantSansRemise = .0;
    let remiseParParcentage = .0;
    const response = await bonVentesFinder.get('/');
    
    response.data.data.bons.map((element)=>{
      console.log(element)
      montantAvecRemise = element.amount * (1 - (element.remise/100));
      montantSansRemise = element.amount;
      remiseParParcentage = element.remise;

      totalRemise += remiseParParcentage;
      totalVenteRemise += montantAvecRemise;
      totalVenteSansRemise += montantSansRemise;
      bonsAchat.push(
          {
            id: element.id,
            code: element.code,
            dateBL: element.date.substring(0,10),
            montantAvecRemise,
            remiseParParcentage: remiseParParcentage + " %",
            montantSansRemise,
            client: element.client_name,
            codeClient: element.client_code,
            type_bon: element.payment_type,
            typeR:element.payment_type,
            saisezPar:element.user_name
          });
      }
    );
    
    totalBLAvecRemise.current?.setValue(totalVenteRemise.toFixed(3));
    totalBLSansRemise.current?.setValue(totalVenteSansRemise.toFixed(3));
    totalRemisePM.current?.setValue(remiseParParcentage);
    
    setData(bonsAchat);

  }
  fetchBonsVente();
}, []);
return (
  <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat de la Remise par Montant'}>
      <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['row-between']}>
              <div className={styles['row']}>
                <InputText label={"Journee du"} 
                  labelPosition={true}
                  width={"max-content"} 
                  type={"date"}
                  />
                  <InputText label={"Au"} 
                  labelPosition={true}
                  width={"max-content"} 
                  type={"date"}
                  />
              </div>
            </div>
            <div style={{height: '300px'}}>
              <Table thead={metaData.table} inputValue={inputValue} tbody={data} edit={true} remove={true}/>
            </div>
          <div className={styles['row-end']} style={{width:'100%'}}>
                 <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total BL sans Remise'}
                  ref={totalBLSansRemise}
                  disabled
                  />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total Remise par Parcentage'}
                ref={totalRemisePM}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total BL avec Remise'}
                ref={totalBLAvecRemise}
                disabled
                />

          </div>
          </div>
      
  </div>
  </PageLayout>
)
}

export default EtatRemiseParParcentage
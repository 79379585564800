import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styles from './ErrorContainer.module.scss'
const ErrorContainer = forwardRef((props,ref) => {

    const [errors, setErrors] = useState([])
    const [applyClass, setApplyClass] = useState(false);
    const cancelError = (name) =>{
    const updatedArr = errors?.filter(obj => obj.name !== name); 
      setErrors(updatedArr)
    }
    const myFunction = () => {
        setErrors([])
    };
    
    useEffect(() => {
        const CtimeoutId = setTimeout(()=> {
          setApplyClass(true);
          }, 5000);

        const FtimeoutId = setTimeout(()=> {
            myFunction();
            setApplyClass(false)
          }, 5500);

        return () => {
          clearTimeout(CtimeoutId);
          clearTimeout(FtimeoutId);
        };
      }, [errors]); 
    
    useImperativeHandle(ref, () => ({
        errorsHandler: (err)=> {(err.length > 0) ? setErrors(err) : setErrors([])},
      }))
  return (
    <div className={styles['position']}>
        <div className={styles['container']}>
            {errors && errors.map((element, index)=>{
                return (
                  <div key={index} className={`${applyClass ? styles['destroy'] : styles['container_red']}`}>
                  <p id='message'>{element.message}</p>
                  <span className={styles['closebtn']} onClick={()=> cancelError(element.name)}>&times;</span> 
                  </div>
                );
            })}
        </div>
    </div>
  )
})

export default ErrorContainer
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printingArticles.module.scss';
import { prints } from '../../media/mediaHolder';
import NumberConverter from '../number-converter/NumberConverter';
import printingFinder from '../../api/printingFinder';

const PrintingArticles = forwardRef( (props, ref) => {

  const {dataInfo, thead, type, pageNumber, pageNumbers ,printingHeader} = props

  const wordRef = useRef();

  const timeoutRef = useRef();
  const articlesProps = useRef([]);
  useEffect(()=>{
    const handlePrinting = ()=>{
      const articlesAdded = dataInfo || []
      articlesProps.current = [];
      for (const key in articlesAdded[0]) {
        articlesProps.current.push(key);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    handlePrinting();
  },[dataInfo])

  const numberWithSpaces = (num) => {
    return (Math.floor(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  };
  const [header ,setHeader] = useState();
  useEffect(() => {
    const fetchHeader = async ()=>{
      const printingHead = await printingFinder.get('/');
      console.log(printingHead)
      setHeader(printingHead.data.data.printingHead[0])
    }
    fetchHeader();
  },[])

  return (
    <>
      <div className={style['header-container']}>
        <div className={style['logo-container']}  style={{backgroundImage: `url(${prints.logo})`}}></div>
        <div className={style['info-container']}>
          <p className={style['title-info']}>{printingHeader?.line_1}</p>
          <div className={style['sub-info-container']}>
            <p className={style['sub-title-info']}>{printingHeader?.line_2}</p>
            <p className={style['sub-title-info']}>{printingHeader?.line_3}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_4}</p>
            <p className={style['info-enterprise']}>{printingHeader?.line_5}</p>
          </div>
        </div>
      </div>
      <div className={style['Line']}>
        <div className={style['first-line']}></div>
        <div className={style['second-line']}></div>
      </div>
      <div className={style['body-container']}>
        <div className={style['main-title-container']}>
          <p className={style['title-number']}>{type}</p>
        </div>
        <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Page</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type1']}>{pageNumber}/{pageNumbers}</p>
            </div>
        </div>
        <div className={style['table-container']}>
          <table className={style['table']}>
            <colgroup>
              {thead.map((obj)=>{
                  return <col style={{width: obj.width}} className={style['col1']}/>
              })}
            </colgroup>
            <thead>
              <tr>
                {thead.map((head, index)=>{
                    return(
                        <th key={index} className={style['table-head']}>
                            {head.name} 
                        </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {dataInfo.map((values, index) => {
                  return(
                    <tr key={index} style={{height: "20px"}}>
                      {
                        articlesProps.current.map((key, index) => {
                          return(
                              <td className={style['td-cell']} key={index}> 
                                {values[key]}
                              </td>
                            )
                        })
                      }
                    </tr>
                  )
                })}
                <tr key={9999} style={{height: "100%"}}></tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div style={{height: "1000px"}}></div> */}
      </>
  )
})

export default PrintingArticles

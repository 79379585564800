import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './balanceDeStock.module.scss'

const BalanceDeStock = () => {
  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  
  const thead = [
    {name:"Code",width: 190},
    {name:"Designation",width: 490},
    {name:"Qte Initial",width: 190},
    {name:"Pu Achat Init.",width: 190},
    {name:"Montant PU Ach. Init",width: 190},
    {name:"Pu Ven. Init.",width: 190},
    {name:"Montant En PU Ven. Init.",width: 190},
    {name:"Qte Actuel",width: 190},
    {name:"PU Achat Actual",width: 190},
    {name:"Montant PU Ach. Actu.",width: 190},
    {name:"PU Vente Actuel",width: 190},
    {name:"Montant PU Vent. Act.",width: 190},
    {name:"Qte Acheter",width: 190},
    {name:"Qtè Vendu",width: 190},
    {name:"Montant Des Ventes",width: 190}
  ]
  return (
        <PageLayout icon={icons.article} nameLayout={'Balance De Stock'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div className={styles['periode']}>
                <InputText width={'20%'} type={"date"}  label={'Jour 1'}
                        margin={'0px 0px 0px 15px'}labelPosition={true} />
                <InputText width={'20%'} type={"date"}  label={'Jour 2'}
                        margin={'0px 0px 0px 15px'} labelPosition={true}/>
          </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Exporte Vers Excel'} icon={icons.exporter} width={"250px"}/>
            </div>
          </div>
          <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue} holderText={"Chercher"}  label={'Chercher'}
            margin={'10px 0px 0px 15px'}
            icon={icons.search}/>
          <div className={styles['table']}>
            <Table thead={thead} inputValue={inputValue} tbody={data} edit={true} remove={true} />
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText width={'100%'} type={"number"} holderText={"Montant"} label={'Montant En Pu Achat Initial'}
                margin={'0px 15px 0px 15px'}/>
            </div>
            <div>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Montant En Pu Achat Act'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            <div style={{display: "flex"}}>
                
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Montant Des Achat'}
                margin={'0px 15px 0px 0px'}/>
            </div>
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText width={'100%'} type={"number"} holderText={"Montant"} label={'Montant En Pu Vents Initial'}
                margin={'0px 15px 0px 15px'}/>
            </div>
            <div>
                <InputText width={'100%'} type={"number"} holderText={"Montant"} label={'Montant En Pu Vents Act'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            <div style={{display: "flex"}}>
                
                <InputText width={'100%'} type={"number"} holderText={"Montant"} label={'Montant Des Vente'}
                margin={'0px 15px 0px 0px'}/>
            </div>
        </div>   
        </div>
        </PageLayout>
  )
}

export default BalanceDeStock

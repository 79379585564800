import styles from './taxes.module.scss'
const Taxes = (props) => {
    const {width , name , value , total , side , fontSize , fontWeight , unit , currency} = props
    const amount = value?.toLocaleString("en-US", {
        style: "decimal"
      });
    return(
      <>
        {
          value && side &&
          <div style={{width }} className={styles['col-start']}>
            <h1 className={styles['txt-third-clr']} style={{fontSize , fontWeight }}>{name}</h1>
            <div className={styles['row-end']}>
              <p className={styles['row-end']} style={{fontSize , fontWeight }}>{value}</p>
              { currency ? <h1 className={styles['row-end']} style={{fontSize , fontWeight }}>{currency}</h1> : <h1 className={styles['row-end']}>{unit}</h1>}
            </div>
          </div>
        }
        {
          side && total &&
          <div style={{width}} className={styles['row-between']}>
            <h1 className={styles['boldy']}style={{fontSize , fontWeight }}>{name}</h1>
            <div className={styles['row-end']}>
              <h1 className={styles['boldy']} style={{fontSize , fontWeight }}>{total}</h1>
              { currency ? <h1 className={styles['boldy']} style={{fontSize , fontWeight }}>{currency}</h1> : <h1>{unit}</h1>}
            </div>
          </div>
        }

        {
          !side && 
          <div style={{width}} className={styles['row-start']}>
            <h1 className={styles['boldy']} style={{fontSize , fontWeight }}>{name}</h1>
            <div className={styles['row-end']}>
              <p style={{fontSize , fontWeight }}>{total}</p>
              { currency ? <h1 className={styles['boldy']} style={{fontSize , fontWeight }}> {currency}</h1> : <h1 className={styles['boldy']}> {unit}</h1>}
            </div>
          </div>
        }
      </>
    );
}

export default Taxes
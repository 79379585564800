import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './partenair.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'

const ArticleEtStock = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
  
  const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Stock",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Prix Vente Gros",width: 190},
    {name:"Pu Detail",width: 190}
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Partenair'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'50%'} tbody={search} setInputValue={setInputValue} inputValue={inputValue}
             labelPosition={true} holderText={"Chercher"}  label={'chercher'}
            margin={'0px 0px 0px 15px'}
            icon={icons.search}/>
            <div className={styles['operations-buttons']}>
                <Button name={'Ajouter'} icon={icons.exporter} width={"150px"}/>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          <div className={styles['table']}>
            <Table thead={thead} inputValue={inputValue} tbody={search} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
            />
          </div>
        </div>
        </PageLayout>
  )
}

export default ArticleEtStock

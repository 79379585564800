import React from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import { useAuth } from '../../contexts/AuthContext';
import styles from './login.module.scss';
import { Link } from 'react-router-dom';

const Login = () => {
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        login({
            username: document.getElementById('username').value,
            password: document.getElementById('password').value
        });
    }

    return (
        <div className={styles['container']}>
            <div className={styles['form-container']}>

                <div>
                    <h1 className={styles['page-h1']}>Login</h1>
                    <p className={styles['extra-msg']}>Welcome Back</p>
                </div>

                <form onSubmit={handleSubmit} className={styles['form']}>
                    <div className={styles['form-item']}>
                        <InputText 
                            width={'100%'}
                            label={'Email *'} 
                            id={'username'}
                        />
                    </div>

                    <div className={styles['form-item']}>
                        <InputText 
                            width={'100%'}
                            label={'Password *'} 
                            id={'password'}
                            type={'password'}
                        />
                    </div>

                    <div className={styles['form-item']}>
                        <Button 
                            width={'100%'}
                            name={'Login'}
                            type={'submit'} 
                        />
                    </div>
                </form>

                <div className={styles['links-section']}>
                    <Link to={'/contact-us'}>Contact Us</Link>
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    <Link to={'terms-of-service'}>Terms of Service</Link>
                </div>

            </div>
        </div>
    );
}

export default Login;

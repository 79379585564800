import React, { forwardRef, useImperativeHandle, useState } from 'react'
import styles from './dialog.module.scss'
import { icons } from '../../media/mediaHolder';
import Button from '../button/Button';

const Dialog = forwardRef((props,ref) => {
  const {
    width, 
    table = false,
    validateFunc = () => {},
    type = false,
    padding,
    switchStyle = false
  } = props;

  const [ display, setDisplay ] = useState(false);

  useImperativeHandle(ref, () => ({
    display: () => setDisplay(true),
    noDisplay: () => setDisplay(false)  
  }))

  return (
    <div className={display ? styles['visible'] : styles['hidden']}>

      <div className={styles[`grey-screen`]}></div>

      <div className={table ? styles['dialog-table'] : styles['dialog']} style={!switchStyle ?{width,padding}:{width,padding: "25px 25px 15px 25px"}}>
        {props.children}
        {!switchStyle &&<div className={styles['row-end']}>
          <Button name={'Annuler'} switchbtn={true} width={120} onclick={() => setDisplay(false)} />
          
          {(type ? <Button name={'Valider'} type={'submit'} width={150} onclick={() => {setDisplay(false)}} />
                : <Button name={'Valider'} width={150} onclick={() => {validateFunc(); setDisplay(false)}} />)}
        </div>}
        {switchStyle &&<div className={styles['row-end']}>
          <div className={styles['cancel']} onClick={()=> setDisplay(false)}>
            <p>Annuler</p>
          </div>
        </div>}
      </div>
      
    </div>
  )
})

export default Dialog

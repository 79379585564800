import React, {useState} from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './etatDeCaisse.module.scss';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'

const EtatDeCaisse = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const thead = [
    {name:"Designation", width: 310},
    {name:"Encaissement", width: 190},
    {name:"Sortie de Caisse", width: 190},
    {name:"Solde", width: 190},
    {name:"Heure", width: 190},
    {name:"Observation", width: 390}
  ]
    
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat De Caisse'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText label={"Journee du"} 
                labelPosition={true}
                width={"max-content"} 
                type={"date"}
                />
            </div>
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
                />
            </div>
          </div>
          <div style={{height: "350px"}}>
            <Table thead={thead} inputValue={inputValue} tbody={data}
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)}
            />
          </div>
        <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant Encaisse'}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant Sortie Caisse'}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Solde'}
                  />
            </div>
          </div>
      </div>
    </PageLayout>
  )
}

export default EtatDeCaisse;

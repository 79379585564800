import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatRemiseParMontant.json'
import styles from './etatRemiseParMontant.module.scss'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatRemiseParMontant = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const thead = [
    {name:"N° BL",width: 190},
    {name:"Date BL",width: 190},
    {name:"Montant BL",width: 190},
    {name:"RM Par Montant",width: 190},
    {name:"Total BL sans RM",width: 190},
    {name:"Client",width: 490},
    {name:"Code Cli.",width: 190},
    {name:"Type Bon",width: 190},
    {name:"Type Reglement",width: 190},
    {name:"Saisie Par:",width: 190}
  ]

    const [ inputValue , setInputValue ] = useState('');

    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const [data,setData] = useState([]);

    const totalRemisePM = useRef();
    const totalBLAvecRemise = useRef();
    const totalBLSansRemise = useRef();

    useEffect(() => {
    let bonsAchat = [];


    const fetchBonsVente = async () =>{
      let totalVenteSansRemise = .0;
      let totalVenteRemise = .0;
      let totalRemise = .0;
      
      let montantAvecRemise = .0;
      let montantSansRemise = .0;
      let montantRemise = .0;
      const response = await bonVentesFinder.get('/');
      
      response.data.data.bons.map((element)=>{
        console.log(element)
        montantAvecRemise = element.amount * (1 - (element.remise/100));
        montantSansRemise = element.amount;
        montantRemise = (element.amount * (element.remise/100));

        totalRemise += montantRemise;
        totalVenteRemise += montantAvecRemise;
        totalVenteSansRemise += montantSansRemise;
        bonsAchat.push(
            {
              id: element.id,
              code: element.code,
              dateBL: element.date.substring(0,10),
              montantAvecRemise,
              montantRemise,
              montantSansRemise,
              client: element.client_name,
              codeClient: element.client_code,
              type_bon: element.payment_type,
              typeR:element.payment_type,
              saisezPar:element.user_name
            });
        }
      );
      
      totalBLAvecRemise.current?.setValue(totalVenteRemise.toFixed(3));
      totalBLSansRemise.current?.setValue(totalVenteSansRemise.toFixed(3));
      totalRemisePM.current?.setValue(totalRemise.toFixed(3));
      
      setData(bonsAchat);

    }
    fetchBonsVente();
  }, []);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat de la Remise par Montant'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <div className={styles['row']}>
                  <InputText label={"Journee du"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    />
                    <InputText label={"Au"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={data} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
            <div className={styles['row-end']} style={{width:'100%'}}>
                   <InputText  
                    holderText={"Montant"} 
                    setInputValue={setInputValue} 
                    inputValue={inputValue} 
                    type={"number"} 
                    label={'Total BL sans Remise'}
                    ref={totalBLSansRemise}
                    disabled
                    />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total Remise par Montant'}
                  ref={totalRemisePM}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total BL avec Remise'}
                  ref={totalBLAvecRemise}
                  disabled
                  />

            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatRemiseParMontant
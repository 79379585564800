import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect }  from 'react'
import styles from './inputText.module.scss'
import { useNavigate } from 'react-router-dom'

const InputText = forwardRef((props, ref) => {
  const {
    holderText, 
    icon, 
    width, 
    label, 
    id, 
    name, 
    searchData = [], 
    btnName, 
    margin, 
    currency, 
    labelPositionAvailable, 
    labelPosition, 
    onSelectItem = () => {},
    path, 
    currencyValue, 
    type = 'text',
    defaultValue = '',
    height,
    pattern = null,
    onblur = () => {},
    reportChange = () => {},
    autoComplete = 'off',
    disabled 
  } = props;

  const navigate = useNavigate();

  const [ status , setStatus ] = useState('input-text-container');
  const [ inputValue , setInputValue ] = useState(defaultValue);
  const [ searchOptions , setSearchOptions ] = useState('search-filter-noDisplay');
  // const [ searchDataList, setSearchDataList ] = useState(searchData);

  useEffect(() => {}, [searchData]);

  const lastAcceptedInput = useRef(defaultValue);

  const handleFocus = () => {
    searchData?.length > 0 && setSearchOptions('search-filter-display');
  }

  const handleBlur = ({ target }) => {
    // Deal with the case of having options to select from 
    if (searchData?.length > 0) {

      // Imidiately disable the select option on blur 
      setSearchOptions('search-filter-noDisplay');

      if (searchData?.find(el => el.name.toLowerCase() === target.value.toLowerCase())) {
        const item = searchData?.find(el => el.name.toLowerCase() === target.value.toLowerCase());

        setInputValue(item.name);
        setSelectedItem(item);
        onSelectItem(item);

        lastAcceptedInput.current = item.name
      }
      else {
        /* If the text inside the input is not from the select list if and only if the list exist
          then reset the value of the input to the old one */
        setInputValue(lastAcceptedInput.current)
      }

      onblur(lastAcceptedInput.current);
      return;
      /* /!\ You should not go beyond here because bellow that is a code to deal with other things */
    }

    /* This function is used with other components (like Table component) when they need  
      to get the value of the input after hitting the blur event  */
    onblur(target.value);
  }

  const handleInputChange = ({ target }) => {
    if (pattern && target.value !== '' && !pattern.test(target.value)) {
      return;
    }

    setInputValue(target.value);
    
    // This method reports the changes in the input in real time to other components
    reportChange(target.value);
  };

  // Handle the selection of an option
  const handleMouseDown = (item) => {
    setInputValue(item.name);
    setSelectedItem(item);
    onSelectItem(item);
  }

  /* This part is to be able to access the values inside the input 
    without the need to connect this component to others through props
    or states and state handlers */
  const [ selectedItem, setSelectedItem ] = useState({});
  
  useImperativeHandle(ref, () => ({
    getSelectedItem: () => selectedItem,
    getValue: () => inputValue,
    setValue: (val) => setInputValue(val),
    setBorderRed: () => setStatus('input-text-container_red'),
    setBorderMain: () => setStatus('input-text-container'),
    // setSearchDataList: (data) => setSearchDataList(data)
  }));
  // ---------------------------------------------------

  return (
    <>
      <div style={{width , margin , height }} className={styles['input-container']}>
          {!labelPositionAvailable &&
          (labelPosition ? <label className={styles['custom-label']}>
                             <p className={styles['label-text']}>{label}</p>
                           </label>
                         :
                           <label className={styles['label-text']}>
                             <p>{label}</p>
                           </label>
          )}
          <div className={styles[`${status}`]} onClick={()=>setStatus('input-text-container')}>
          <input type={type}
                 placeholder={holderText} 
                 value={inputValue ? inputValue : ''}
                 id={id}
                 name={name}
                 onChange={handleInputChange}
                 className={styles['input-text']}
                 onFocus={handleFocus}
                 onBlur={handleBlur}
                 autoComplete={autoComplete}
                 disabled = {disabled}
          />
          { currency ? 
          <p style={{fontWeight: 'bold'}} className={styles['search-icon']}>
              {currencyValue}
          </p> 
          : 
              icon && 
              <img src={icon} alt='icon' className={styles['search-icon']}/>
          }
          <div className={ searchOptions && styles[`${searchOptions}`]} >
            <ul>
            {searchData && searchData.filter((item)=>{
              return item.name.toLowerCase().includes(inputValue.toLowerCase())
            }).map((item)=>{
                return(
                    <li key={item.id} 
                        className={styles['search-items']}
                        onMouseDown={() => handleMouseDown(item)}>
                      {item.name}
                    </li>
                )
              })
            }
            </ul>
                  {searchOptions && 
                    <button onMouseDown={()=>navigate(path)} className={styles['ajouter']} >
                        {btnName}
                    </button>
                  }
            </div>
          </div>
      </div>
    </>
  )
})

export default InputText;

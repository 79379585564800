import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styles from './selectInput.module.scss';
import { icons } from '../../media/mediaHolder';

const SelectInput = forwardRef((props, ref) => {

  const {
    label, 
    options, 
    labelPosition,
    width,
    height,
    heightBorder,
    reportChange = () => {}
  } = props;

  const [ status , setStatus ] = useState('select-container');

const handleChange = (e) => reportChange(options[e.target.value]);

  useImperativeHandle(ref, () => ({
    getSelectedOption: () => options[document.getElementById('select_input').value],
    setBorderRed: () => setStatus('select-container-red'),
    setBorderMain: () => setStatus('select-container')
  }));

  return (
    <div style={{width, height}} className={styles['select-body-container']}>
        {
          label &&  <label className={labelPosition ? styles['custom-label']:styles['label']}>{label}</label>
        }
        
        <div className={styles[`${status}`]} style={{height: heightBorder}}>
          <select id='select_input' 
                  className={styles['select']}
                  onChange={handleChange} >
            {options.map((item, index) => <option key={index} value={index}>{item}</option>)}
          </select>
          
          <img src={icons.select} style={labelPosition && {marginBottom: "20px"}} alt='icon' className={styles['select-icon']}/>
        </div>
    </div>
  )
})

export default SelectInput;

import React, { useState, useRef, useEffect } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import styles from './familleEtSousFamille.module.scss';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import { icons } from '../../media/mediaHolder';
import famillesAndSousFamillesFinder from '../../api/famillesAndSousFamillesFinder';

const FamilleEtSousFamille = () => {
  const inputRef = useRef(null);
  const addedData = useRef([]);

  const [familleData, setFamilleData] = useState([]);
  const [sousFamilleData, setSousFamilleData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await famillesAndSousFamillesFinder.get('/');
        const data = response.data.data.fnsf;

        setFamilleData(data.map((item, index) => {
          return {name: item.name, isChecked: index === 0, children: item.children};
        }));

        if (data[0].children.length > 0) {
          setSousFamilleData(data[0].children.map((item) => {
            return {name: item.name, isChecked: false};
          }));
        }
        else {
          setSousFamilleData([]);
        }
      } 
      catch (err) {
        console.log(err);
      }
    }

    fetchData();

    // const containers = document.getElementsByClassName('container');
    // const stickyElements = document.getElementsByClassName('sticky-element');

    // const stickClass = styles['stick'];
    // console.log(stickClass)

    // for (let i = 0; i < containers.length; i++) {
    //   console.log('here')
    //   window.addEventListener('scroll', () => {
    //     if (containers[i].scrollTop > 0) {
    //       console.log('add');
    //       stickyElements[i].classList.add(stickClass);
    //     } else {
    //       console.log('remove');
    //       stickyElements[i].classList.add(stickClass);
    //     }
    //   });
    // }

  }, []);

  // console.log(document.getElementsByClassName('.table-outer-container'))

  // Treat familles
  const handleFamilleClick = (e) => {
    const selectedName = e.target.innerHTML;

    setFamilleData(familleData.map((el) => el.name === selectedName ? {...el, isChecked: true} : {...el, isChecked: false}));

    const childrenData = familleData.find(el => el.name === selectedName).children;
    setSousFamilleData(childrenData.map((item) => ({name: item.name, isChecked: false})));

    inputRef.current.setValue(selectedName);
  }

  const handleFamilleAdd = () => {
    if (!document.getElementById('main-input').value) return;

    const newDataObject = {name: document.getElementById('main-input').value, isChecked: false, children: []};

    setFamilleData([...familleData, newDataObject]);
    addedData.current = [...addedData.current, newDataObject];
    inputRef.current.setValue('');
  }

  // Treat sous-familles
  const handleSousFamilleClick = (e) => {
    const selectedName = e.target.innerHTML;
    setSousFamilleData(sousFamilleData.map((el) => el.name === selectedName ? {...el, isChecked: true} : {...el, isChecked: false}));
    inputRef.current.setValue(selectedName);
  }

  const handleSousFamilleAdd = () => {
    if (!document.getElementById('main-input').value) return;

    const newDataObject = {name: document.getElementById('main-input').value, isChecked: false, parent: familleData.find(el => el.isChecked).name};

    const newData = familleData.map((item) => {
      if (item.name === familleData.find(el => el.isChecked).name) {
        item.children = [...item.children, newDataObject]
      }
      
      return item;
    });

    setFamilleData(newData);
    setSousFamilleData(newData.find(el => el.isChecked).children);

    addedData.current = [...addedData.current, newDataObject];
    inputRef.current.setValue('');
  }

  // Other
  const saveAllChangesToDB = async () => {
    try{
      await famillesAndSousFamillesFinder.post('/', {fnsfArr: addedData.current});
      addedData.current = [];
    }catch (err){
      console.log(err)
    }
  }

  const handleDelete = async () => {
    const name = document.getElementById('main-input').value;

    if (!familleData.find(el => el.name === name) && !sousFamilleData.find(el => el.name === name)) {
      return;
    }

    const table = familleData.find(el => el.name === name) ? 'famille' : 'sous-famille';

    await famillesAndSousFamillesFinder.delete(`${name}/${table}`);

    if (table === 'famille') {
      setFamilleData(familleData.filter(el => el.name !== name));
      setSousFamilleData([]);
    }
    else {
      setSousFamilleData(sousFamilleData.filter(el => el.name !== name));
    }

    inputRef.current.setValue('');
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Famille Et Sous Famille'}>
      <div className={styles['page-container']}>
        <div className={styles['tables-holder']}>
          <div className={`${styles['table-outer-container']} container`}>
            <table className={styles['table-container']}>
              <thead>
                  <tr className={styles['table-head-container']} > 
                    <th className={styles['table-head']}>
                      Familles
                    </th>
                  </tr>
              </thead>
              <tbody>
                {familleData.map((item, index)=>{
                    return(
                        <tr key={index}  
                            className={`
                              ${styles['table-body-row']}
                              ${item.isChecked ? styles['selected-row'] 
                                              : index % 2 === 0 ? styles['row-bg-1'] 
                                                                : styles['row-bg-2']}
                            `}
                        >

                          <td className={`${styles['table-column']} ${styles['point-hover']}`} key={index} onClick={handleFamilleClick}>
                                  {item.name}
                          </td>

                    </tr>
                    )
                })}
                <tr className={`${styles['table-button-row']} sticky-element`} >
                  <td>
                    <button className={styles['table-button']} 
                            onClick={handleFamilleAdd}
                            >
                      + Ajouter Famille
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div className={`${styles['table-outer-container']} container`}>
            <table className={styles['table-container']}>
              <thead>
                  <tr className={styles['table-head-container']} > 
                    <th className={styles['table-head']}>
                      Sous Familles
                    </th>
                  </tr>
              </thead>
              <tbody>
                {sousFamilleData.map((item, index)=>{
                    return(
                      <tr key={index}  
                          className={`
                            ${styles['table-body-row']}
                            ${item.isChecked ? styles['selected-row'] 
                                            : index % 2 === 0 ? styles['row-bg-1'] 
                                                              : styles['row-bg-2']}
                          `}
                      >

                        <td className={styles['table-column']} key={index} onClick={handleSousFamilleClick}>
                              {item.name}
                        </td>

                    </tr>
                    )
                })}
                <tr className={`${styles['table-button-row']} sticky-element`}>
                  <td>
                    <button className={styles['table-button']} 
                            onClick={handleSousFamilleAdd}
                            >
                      + Ajouter Sous Famille
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles['add-family-container']}>
            <InputText width={'75%'} 
                       id={'main-input'} 
                       holderText={'Famille'} 
                       height={"35px"} 
                       labelPosition={true} 
                       labelPositionAvailable={true}
                       ref={inputRef} 
                       />

            <Button name={"Supprimer"} 
                    switchbtn={true} 
                    width={'25%'} 
                    height={"35px"} 
                    onclick={handleDelete} 
                    />

            <Button name={"Valider"} 
                    width={'25%'} 
                    height={"35px"} 
                    onclick={saveAllChangesToDB} 
                    />
        </div>
      </div>
    </PageLayout>
  )
}

export default FamilleEtSousFamille;

import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatAchatDetaille.module.scss'
import Button from '../../components/button/Button'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import metaData from './etatAchatGlobalDetaille.json';
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatAchatDetaille = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('')
  const [data,setData] = useState([]);

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);
  
  const totalRemiseParParcentage = useRef();
  const totalBaSansRemise = useRef(); 
  const totalBaAvecRemise = useRef();

  useEffect(() => {
    let articlesAchete = [], articlesAvoir = [];
    let total_remise = 0.0;
    let total_achat_sans_remise = 0.0;
    let total_achat_avec_remise = 0.0;
    let id_bon = -1;
    let remise_achat = .0;
    let remise_avoir = .0;

    const fetchArticlesAcheteAvoir = async () =>{
      const articles_achete = await articlesAcheteFinder.get('/');
      // const articles_avoir = await articlesAvoirFinder.get('/achat');

      // const  {max_id } = articles_achete.data.data;
      articles_achete.data.data.articles.map((element)=>{
        
        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
        let montant = (element.quantity * element.prix) * (1 - (element.remise/100));
        // 

        // CALCULATE THE TOTAL AMOUNT OF THE PURCHASES WITHOUT ARTICLE PROMOTION
        total_achat_sans_remise += (element.quantity * element.prix);
        // 

        // CALCULATE THE TOTAL AMOUNT OF THE ARTICLE PROMOTIONS
        total_remise += element.remise;
        // 

        // CALCULATE THE TOTAL PRICE OF THE PURCHASES INCLUDING RECEIPT PROMOTINOS
        if(parseInt(id_bon) !== parseInt(element.id_bon_achat)){
          
          remise_achat += element.bon_achat_remise;
          total_remise += element.bon_achat_remise;
          id_bon = element.id_bon_achat;
        }
        total_achat_avec_remise += montant;
        // 

        articlesAchete.push(
            {
              id: element.id,
              achat_avoir : 'Achat',
              lot : element.lotNbr,
              codeArt: element.code,
              nbr_bon:element.bon_achat_code,
              codeFamille: element.familleCode,
              disgnation: element.designation,
              qtyEnStk: element.ds_quantity,
              pu_achat: element.pu_achat,
              prix_vente_gros: element.prix_vente_gros,
              pu_detaille: element.pu_detailer,
              montant,
              observation: element.observation
            });
        }
      );
      
      setData(articlesAchete);
      total_achat_avec_remise = total_achat_avec_remise * (1 - remise_achat/100);
      totalBaAvecRemise.current?.setValue(total_achat_avec_remise);
      totalBaSansRemise.current?.setValue(total_achat_sans_remise);
      totalRemiseParParcentage.current?.setValue(total_remise);
    }
    
    
    fetchArticlesAcheteAvoir();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achat détaillé'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.data}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />  
            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'}
                />
              <Button 
                icon={icons.exporter}
                width={"250px"}
                name={'Exporter Vers Excel'}
                />
            </div>
            </div>
            <div className={styles['row']}>
              <InputText
                label={"Joure 1"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                />
              <InputText 
                label={"Joure 2"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                />
            </div>
            <div style={{height: "350px"}}>
              <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)}
              />
            </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total BA sans Remise'}
                width={"25%"}
                ref={totalBaSansRemise}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total remise par %'}
                width={"25%"}
                ref={totalRemiseParParcentage}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total BA avec remise'}
                ref={totalBaAvecRemise}
                width={"25%"}
                disabled
                />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatAchatDetaille
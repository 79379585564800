import React, { useEffect, useRef, useState } from 'react';
import styles from './listeTousArticlesVendusNonLivresParClient.module.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import clientsFinder from '../../api/clientsFinder';
import articleLivreFinder from '../../api/articleLivreFinder'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const ListeTousArticlesVendusNonLivresParClient = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const theaderData = [
        {name:"N Bon",width: 190},
        {name:"Date",width: 190},
        {name:"Code",width: 490},
        {name:"Designation",width: 190},
        {name:"Quantite",width: 190},
        {name:"PU",width: 190},
        {name:"Montant",width: 190}
    ]
    
    const [data, setData] = useState([]);
    const [clientList, setClientList ] = useState([]);

    const getSelectedClient = useRef();

    useEffect(() => {
        const fetchClient = async () => {
          const response = await clientsFinder.get('/');
          console.log(response)
          setClientList(response.data.data.clients);
        }
        fetchClient();
    }, []);

    const handleClick = (item)=>{
        console.log(item)
        const temp = async ()=>{
        const response = await articleLivreFinder.get(`/${item.id}/articleParClient`);
        console.log(response.data.data.articles)
        setData(response.data.data.articles.map((article)=>{
            return(
                {
                id: article.id,
                NbBon: article.bl_code,
                date: article.bl_creation_ts.substring(0, 10),
                codeArt: article.code,
                designation: article.designation,
                quantity: article.quantity,
                PUVente: article.prix,
                total: (article.prix * article.quantity) * (1 - article.remise >= 1 ? article.remise/100 : article.remise)
                }
            )
        }))
        }
        temp();
    }

    return (
        <PageLayout icon={icons.achat} nameLayout={'Liste de tous les article vendus non livres par client'}>
            <div className={styles['container']}>
            <div className={`${styles['fill-width']} ${styles['upper-section']}`}>
                <InputText holderText={"Chercher un Client"}
                             width={"50%"} 
                             labelPosition={true}
                             type={"text"} 
                             label={'Client'}
                             id={'client'}
                             onSelectItem={handleClick}
                             searchData={clientList}
                             path={"/ajouter_fournisseur"}
                             btnName={'Ajouter Nouveau fournisseur'} 
                             ref={getSelectedClient} 
                            icon={icons.search} />

                <Button name='Imprimer' 
                        width={"150px"} 
                        icon={icons.impr}/>
            </div>
            <div className={styles['table-height']}>
                <Table thead={theaderData} tbody={data} 
                    remove={user.permissions.includes(permissions.canDeleteFromTable)} />
            </div>
            </div>
        </PageLayout>
    )
}

export default ListeTousArticlesVendusNonLivresParClient;

import React, { useState, useEffect, useRef } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './articleEtStock.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import articlesFinder from '../../api/articlesFinder';
import metaData from './articleEtStock.json'
import depotsStockFinder from '../../api/depotsStockFinder.js'
import Printing from '../../components/printing/Printing';
import Dialog from '../../components/dialog/Dialog.js';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js';
import { dateFormatter } from '../../utils/dateFormatter.js';
import ErrorContainer from '../../components/errorContainer/ErrorContainer.js';

const ArticleEtStock = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  
  const tableRef = useRef(null);
  const printRef = useRef();
  const errorsHandler= useRef([]);

  useEffect(() => {
    const getArticles = async () => {
      try {
        // const response = await articlesFinder.get('/');
        const response = await articlesFinder.get("/");
        // console.log(response.data.data.articles)
        setBonsInfo(response.data.data.articles)

        let articles = response.data.data.articles.map(item => {

          item.blocked = item.blocked ? 'Oui' : 'Non';
          item.type = item.type === "matierepremiere" ? "Matiere Premiere": 
            item.type === "matierefini" ? "Produit Fini" :"Produit Semi Fini";
          return item;
        })
        
        articles = articles.map(({status,...rest})=>rest)
        setData(articles);

      } catch (err) {
        console.log(err);
      }
    }

    getArticles();
  }, []);
  
  useEffect(()=>{
    metaData.find(el => el.name === "Bloque?").children = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
    metaData.find(el => el.name === "Type d'Article").children = [{id: 0, name: 'Matiere Premiere'}, 
      {id: 1, name: 'Produit Semi Fini'},{id: 3, name: 'Produit Fini'}];
  },[])

  const handleDelete = (id) => {
    const article = data.find((article)=> article.id === id);
    // if(article.ds_quantity >= 0){
    //   return
    // }
    depotsStockFinder.delete(`/${id}`);
    const updatedData = data.filter((item) => item['id'] !== id);
    setData(updatedData);
  }
  // Here you should check how the field are named comming from the backend
  const filterByArr = ['designation','code','famille_code'];
  
  const handleSearch = (val) => tableRef.current.setFilterInput(val)
  
  const actOnValidation = async (obj) => {
    let type = obj.type.toLowerCase().replace(/\s/g, '');
    type = (type).replace('produit','matiere');
    try {
        const article = await articlesFinder.put(`${parseInt(obj.id)}`,{
          code_famille: obj.codeFamille,
          codeArticle: obj.codeArticle,
          ug: parseFloat(obj.ug), 
          designation: obj.designation,
          type,
          puAchat: parseFloat(obj.puAchat),
          prixVenteGros: parseFloat(obj.prixVenteGros),
          prixVenteSemiGros: parseFloat(obj.prixVenteSemiGros),
          puDetailer: parseFloat(obj.puDetailer),
          puSpecial: parseFloat(obj.puSpecial),
          unitMes: obj.unitMes,
          qteCart: parseInt(obj.qteCart),
          nbCart: parseInt(obj.nbCart),
          tva: parseFloat(obj.tva),
          prixAchatInit: parseFloat(obj.prixAchatInit),
          prixVenteInit: parseFloat(obj.prixVenteInit),
          puFact: parseFloat(obj.puFact),
          stockAlert: parseInt(obj.stockAlert),
          weight: parseFloat(obj.weight),
          volume: parseFloat(obj.volume),
          blocked: obj.blocked,
          observation: obj.observation,
          stqInit: parseInt(obj.stqInit)
        });
        console.log(article);
    } catch (err) {
      console.log(err);
    }
  }
  const displayDialog = useRef([]);
  const check = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    initCheckBoxs()
    displayDialog.current.display()
  }
  const initCheckBoxs = ()=>{
    const updatedTodos = todos.map((todo) =>{
      return {...todo, checked: false }
    });
    setMainCheckBox({checked: false})
    setTodos(updatedTodos)
  }
  const PrintingColumn = [
    {name: "Code",width: "10%",key: 'codeArticle'},
    {name: "Designation",width: "40%",key: 'designation'},
    {name: "Prix d'Achat",width: "5%",key: 'puAchat'},
    {name: "Prix d'Vente",width: "5%",key: 'prixVenteGros'},
    {name: "TVA",width: "5%",key: 'tva'},
    {name: "Stock Initial",width: "5%",key: 'stqInit'},
  ]
  
  const [todos, setTodos] = useState([
    { id: 1, text: "Prix d'Achat", checked: false },
    { id: 2, text: "Prix d'Vente", checked: false },
    { id: 3, text: "Stock Initial", checked: false },
    { id: 4, text: "TVA", checked: false },
  ]);
  const [PrintingColumns, setPrintingColumns] = useState([]);

  const [bonsInfo,setBonsInfo] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    setIsActive(true);
    const checked = todos.filter((el)=> el.checked === true)
    const selectedColumns = PrintingColumn.filter((col,index)=>{
      return checked.find((el)=> el.text === col.name) || index < 2
    })
    const displayColumns = selectedColumns.map((col)=> col.key)
    const articlesAdded = tableRef.current.getSelectedItems().map((article)=>{
      const obj = {}
      for(let key in displayColumns){
        obj[displayColumns[key]] = article[displayColumns[key]]
      }
      return obj
    })

    const ROWS_PER_PAGE = 30; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages
    }
    setPrintingColumns(selectedColumns)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    displayDialog.current.noDisplay()
    
  }

  const [mainCheckBox, setMainCheckBox] = useState({checked: false})
  const handleCheckboxChange = (todoId) => {
    const updatedTodos = todos.map((todo) =>
      todo.id === todoId ? { ...todo, checked: !todo.checked } : todo
    );
    setTodos(updatedTodos);
  };
  const handleChecAllkboxChange = () => {
    const updatedTodos = todos.map((todo) =>{
      return {...todo, checked: !mainCheckBox.checked }
    });
    setMainCheckBox({checked: !mainCheckBox.checked})
    setTodos(updatedTodos);
  };
  return (
        <PageLayout icon={icons.article} nameLayout={'Articles'}>
          <div className={styles['main-search-bar']}>
            <InputText width={'40%'} 
                       labelPosition={true} 
                       holderText={"Chercher Par Designation"} 
                       label={'Articles'}
                       margin={'0px 0px 0px 15px'}
                       icon={icons.search} 
                       reportChange={(val) => handleSearch(val)} />
            <div className={styles['operations-buttons']}>
              <Button link={true} path={"../ajouter_article"} name={'Insere Nouveau Article'} icon={icons.insere}/>
              <Button name={'Imprimer'} 
                  onclick={check} 
                  icon={icons.impr}/>
              <Button name={'Exporter Vers Excel'} icon={icons.exporter}/>
            </div>
          </div>
          <div className={styles['table']}>
            <Table thead={metaData} 
                   tbody={data} 
                   edit={user?.permissions.includes(permissions.canEditInTable)} 
                   remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                   handleDelete={handleDelete}
                   actOnValidation={actOnValidation}
                   ref={tableRef}
                   defaultSearchBy={filterByArr}
            />
          </div>
          {isActive && <Printing type={'List des Articles'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
          <Dialog padding={"25px 25px 20px 25px"} ref={displayDialog} width={"20%"} validateFunc={handlePrint}>
            <div style={{display: 'flex',flexDirection: "column", gap: "20px", marginBottom: '10px'}}>
              <div style={{display: 'flex', gap: "10px"}}>
                <img src={mainCheckBox.checked ? icons.checkboxActive : icons.checkboxInactive} 
                  alt=''
                  onClick={() => handleChecAllkboxChange()}
                  id='checkbox' />
                <p style={{fontWeight: "600",color: "black",fontSize: "16px"}}>Selectionner Tout</p>
              </div>
              {todos.map((todo,index) => (
                <div key={index}>
                  <label style={{display: "flex",alignItems: "center",gap: "10px",marginLeft: "15px"}}>
                    <div>
                      <img src={todo.checked ? icons.checkboxActive : icons.checkboxInactive} 
                            className={styles['checkbox-icon']} 
                            alt=''
                            onClick={() => handleCheckboxChange(todo.id)}
                            id='checkbox' />
                    </div>
                    <p style={{fontWeight: "500",color: "black",fontSize: "16px"}}>{todo.text}</p>
                  </label>
                </div>
              ))}
            </div>
          </Dialog>
        <ErrorContainer ref={errorsHandler}/>
      </PageLayout>
  )
}

export default ArticleEtStock;

import React, { useState } from 'react';
import styles from './menubarItem.module.scss';
import { icons } from '../../media/mediaHolder';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const MenubarItem = (props) => {
  const {
    name,
    link = '',
    children = [],
    dropToBottom = false,
    text
  } = props;

  const { state: authState } = useAuth();
  const user = authState.user;

  const [ showDropDown, setShowDropDown ] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => link ? navigate(link) : setShowDropDown(true);
  const handleHover = () => !dropToBottom && setShowDropDown(true);
  const handleMouseLeave = () => setShowDropDown(false);

  return (
    <div className={styles['container']} 
         onClick={handleClick} 
         onMouseOver={handleHover}
         onMouseLeave={handleMouseLeave}>

        {
            name !== 'divider-obj' && name !== 'header-obj' && 
            <span className={`${styles['item']} ${styles['added-vertical-padding']}`}>{name}</span>
        }
        {
            children.length > 0 && !dropToBottom &&
            <img src={icons.listIndicatorIcon} alt='' className={styles['list-indicator']} />
        }
        
        {
            name !== 'divider-obj' && name !== 'header-obj' && children.length > 0 && showDropDown &&
            <div className={`
                ${styles['dropdown']} 
                ${dropToBottom ? styles['stick-to-left-bottom'] : styles['go-to-right-top']}
            `}>

                {
                    children.filter(item => {
                        if (item.permissionsAssigned && item.permissionsAssigned.length > 0) {
                          return item.permissionsAssigned.find(permission => user?.permissions.includes(permission));
                        }
                        else {
                          return true;
                        }
                    })
                    .map((child, index) => <MenubarItem key={index} 
                                                                name={child.name}
                                                                link={child.link}
                                                                children={child.children} 
                                                                text={child.text}
                                                                />)                    
                }

            </div>
        }

        {
            name === 'divider-obj' && <div className={styles['divider']}></div>
        }

        {
            name === 'header-obj' && <span className={styles['section-header']}>{text}</span>
        }
    </div>
  )
}

export default MenubarItem;

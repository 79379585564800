import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatParProduit.json'
import styles from './etatParProduit.module.scss'
import Button from '../../components/button/Button'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import bonTransferFinder from '../../api/bonTransferFinder'
import bonReceptionTransferFinder from '../../api/bonReceptionTransferFinder'

import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesTransfereFinder from '../../api/articlesTransfereFinder'
import articlesReceptionTransfereFinder from '../../api/articlesReceptionTransfereFinder' 

import depotsStockFinder from '../../api/depotsStockFinder'
const EtatParProduit = () => {

  const [ inputValue , setInputValue ] = useState('')
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);
  
  
  const getSelectedArticle = useRef();
  const codeDeProduit = useRef();
  const designationDeProduit = useRef(); 
  const stockInitialDeProduit = useRef();
  const quantityEnStockDeProduit = useRef();
  const quantityGlobalTransfereDeProduit = useRef();
  const quantityGlobalAcheteDeProduit = useRef();
  const quantityGlobalReceptionTransfereDeProduit = useRef();
  const quantityGlobalVenduesDeProduit = useRef();
  const prixAchatInitialDeProduit = useRef();
  
  const displayData = async (article) => {
    const articlesAchete = await articlesAcheteFinder.get('/');
    const articlesVendues = await articleLivreFinder.get('/');
    const articlesT = await articlesTransfereFinder.get('/');
    const articlesRT = await articlesReceptionTransfereFinder.get('/');

    const filteredArticlesAchete = articlesAchete.data.data.articles.filter((element => article.id === element.id_depot_stock));
    const filteredArticlesVendues = articlesVendues.data.data.articles.filter((element => article.id === element.id_depot_stock));
    const filteredArticlesTransfere = articlesT.data.data.articles.filter((element => article.id === element.id_depot_stock));
    const filteredArticlesRecepTransfere = articlesRT.data.data.articles.filter((element => article.id === element.id_depot_stock));

    let quantityGlobalAchete = 0;
    let quantityGlobalVedues = 0;
    let quantityGlobalTransfere = 0;
    let quantityGlobalRT = 0;


    filteredArticlesAchete.map(element =>{
      quantityGlobalAchete += element.quantity;
      console.log(element)
    });

    filteredArticlesRecepTransfere.map(element =>{
      quantityGlobalRT += element.quantity; 
    })


    codeDeProduit.current?.setValue(article.code);
    designationDeProduit.current?.setValue(article.designation);
    stockInitialDeProduit.current?.setValue(article.stockInitial);
    prixAchatInitialDeProduit.current?.setValue(article.prixAchatInit || '0');
    quantityEnStockDeProduit.current?.setValue(article.quantityEnStock);
    quantityGlobalAcheteDeProduit.current?.setValue(quantityGlobalAchete || '0');
    

  };

  useEffect(() => {
    const getArticles = async () =>{
        const articles = await depotsStockFinder.get("/");
        console.log(articles.data.data.articles)

        setSearchData(articles.data.data.articles.map(element =>{
          return ({
            id:element.id,
            idArticle:element.id_article,
            name:element.designation +'  Lot:'+ element.ds_lot_nbr,
            code:element.code,
            observation:element.observation,
            designation:element.designation,
            quantityEnStock:element.ds_quantity,
            prixAchatInit:element.prix_achat_init,
            prixVenteInit:element.prix_vente_init,
            prixVenteGros:element.prix_vente_gros,
            prixVenteSemiGros:element.prix_vente_semi_gros,
            puAchat:element.pu_achat,
            puDetaile:element.pu_detailer,
            puFacture:element.pu_facture,
            stockInitial:element.stq_init
          });
        }));
    }
    
    getArticles();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat Par Produit'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.data}
              type={"text"}
              holderText={"Chercher un article"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              searchData={searchData}
              onSelectItem={displayData}
              ref={getSelectedArticle}
              displaySearchOption={true}
              path={"/ajouter_fournisseur"}
              btnName={'Ajouter Nouveau fournisseur'}
              />  
            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'}
                />
            </div>
            </div>

            <div className={styles['row-between']} style={{width:'100%'}}>
              <InputText  
                label={'Code'}
                holderText={"Code"} 
                type={"text"} 
                width={"25%"}
                ref={codeDeProduit}
                disabled
                />
            </div>
            <div className={styles['row-between']}>
            <InputText  
                label={'Dèsignation'}
                holderText={"Dèsignation"} 
                type={"text"} 
                width={"100%"}
                ref={designationDeProduit}
                disabled
                />
          </div>
          <div className={styles['row-between']}>
            <InputText  
                label={'Stock initial'}
                holderText={""} 
                type={"number"} 
                ref={stockInitialDeProduit}
                disabled
                />
            <InputText  
                label={'Quantitè Global Achete'}
                holderText={""} 
                type={"text"}
                ref={quantityGlobalAcheteDeProduit}
                disabled
                />
          </div>
          <div className={styles['row-between']}>
              <InputText  
                label={'Prix Achat Initial'}
                holderText={""} 
                type={"number"}
                ref={prixAchatInitialDeProduit}
                disabled
                />
                <InputText  
                label={'Qtè Global Vendues'}
                holderText={""} 
                type={"text"} 
                ref={quantityGlobalVenduesDeProduit}
                disabled
                />
          </div>
          <div className={styles['row-between']}>
              <InputText  
                label={'Quantitè en Stock'}
                holderText={""} 
                type={"text"} 
                ref={quantityEnStockDeProduit}
                disabled
                />
                <InputText  
                label={'Quantitè Global transfere'}
                holderText={""} 
                type={"text"} 
                ref={quantityGlobalTransfereDeProduit}
                disabled
                />
          </div>
          <div className={styles['row-end']}>
              <InputText  
                label={'Quantitè Global Receptioner transfere'}
                holderText={""} 
                type={"text"} 
                ref={quantityGlobalReceptionTransfereDeProduit}
                disabled
                />
          </div>
          <div style={{height: "350px"}}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} edit={true} remove={true} date1={date1} date2={date2}/>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatParProduit
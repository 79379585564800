import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationDesSortieDeBanque.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'

const BordereauDesCheques = () => { 
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead = [
    {name:"Date Sor.",width: 190},
    {name:"Montant",width: 190},
    {name:"N° Chèque",width: 190},
    {name:"Observation",width: 490},
    {name:"Saisie Par:",width: 190}
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Consultation des Sortie de Banque'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div style={{display: 'flex' , width: '100%'}}>
              <InputText width={'50%'} labelPosition={true} holderText={"Chercher"}  label={'Compte Bancaire'}
              margin={'0px 0px 0px 15px'}/>
            </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          <div className={styles['periode']}>
            <div style={{display: "flex" , width:"50%"}}>
                <InputText width={'30%'} type={'date'} label={'Periode Du'} margin={'0px 0px 0px 15px'}/>
                <InputText width={'30%'} type={'date'} label={'Au'} margin={'0px 0px 0px 15px'}/>
            </div>
            <div style={{display: "flex" , width:"30%" , justifyContent: "flex-end"  ,
                        marginRight: "15px"}}>
                <InputText width={'4000%'} holderText={'Chercher'} label={'Chercher'} margin={'0px 0px 0px 15px'}/>
            </div>
            
          </div>
          <div className={styles['table']} style={{height: "300px"}}>
            <Table thead={thead} inputValue={inputValue} tbody={search} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
            />
          </div>
          <div className={styles['mantant-chargers']}>
            <div></div>
            <div style={{display: "flex"}}>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default BordereauDesCheques

import permissions from "../../utils/permissions";

const data = [
    {
        // Menu root item -------------------------------------------------------------------------------
        name: "Fichier",
        // 1.1 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        permissionsAssigned: [permissions.canManagePurchases, permissions.canManageSales],
        children: [
            {
                name: "Famille et sous famille",
                link: "famille_et_sous_famille",
                permissionsAssigned: [permissions.canManagePurchases] 
            },
            {
                name: "Article",
                children: [

                    {
                        name: "Liste", 
                        link: "articles"
                    },
                    {
                        name: "Ajouter", 
                        link: "ajouter_article", 
                        permissionsAssigned: [permissions.canManagePurchases]
                    },
                    {
                        name: "Fiche de Stock", 
                        link: "fiche_stock_produit"
                    },
                    {
                        name: "divider-obj"
                    },
                    {
                        name: "Controle de la Date de Pèremption", 
                        link: "controle_date_preropmtion", 
                        permissionsAssigned: [permissions.canManagePurchases]
                    }

                ]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name:  "Fournisseur",
                // 1.2 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                permissionsAssigned: [permissions.canManagePurchases],
                children: [

                    {
                        name: "Liste", 
                        link: "list_fournisseur"
                    },
                    {
                        name: "Ajouter", 
                        link: "ajouter_fournisseur"
                    },
                    {
                        name: "Règlement", 
                        link: "reglement_fournisseur"
                    },
                    {
                        name: "Crèdit", 
                        children: [

                            {
                                name: "Tous les Fournisseurs", 
                                link: "credit_fournisseur"
                            },
                            {
                                name: "Par Wilaya", 
                                link: "credit_fournisseur_wilaya"
                            }

                        ]
                    },
                    {
                        name: "Versement des Fournisseurs", 
                        link: "versement_fournisseur"
                    }

                ]
                // 1.2 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            },
            {
                name:"Clients",
                // 1.3 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                permissionsAssigned: [permissions.canManageSales],
                children: [  

                    {
                        name:"Liste", 
                        link: "list_client"
                    },
                    {
                        name:"Ajouter", 
                        link: "ajouter_client"
                    },
                    {
                        name: "Règlement", 
                        link: "reglement_client"
                    },
                    {
                        name: "Crèdit", 
                        children: [

                            {
                                name: "Tous les Client", 
                                link: "credit_client"
                            },
                            {
                                name: "Par Wilaya", 
                                link: "credit_client_wilaya"
                            },
                            {
                                name: "divider-obj"
                            },
                            {
                                name: "Clients === Fournisseurs", 
                                link: "creditClient_fournisseur"
                            }

                        ]
                    },
                    {
                        name: "Versement des Client", 
                        link: "versement_client"
                    }

                ]
                // 1.3 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            },
            {
                name: "divider-obj"
            },
            {
                name: "Caisse",
                children: [

                    {name: "Encaissement par Journèe", link: "encaissement_par_journee"},
                    {name: "Encaissement par Pèriode", link: "encaissement_periodique"},
                    {name: "Etat Caisse par Journèe", link: "etat_de_caisse"},
                    {
                        name: "divider-obj"
                    },
                    {name: "Sortie de la Cassie Par journèe", link: "sorties_encaissement"},
                    {name: "Sortie de la Cassie Par Pèriode", link: "sortie_caisse_periodique"},
                    {name: "Etat Caisse par Pèriode", link: "etat_periodique_de_caisse"},
                    {
                        name: "divider-obj"
                    },
                    {name: "Le Journal de Caisse", link: "encaissement_periodique_groupee_par_journee"}

                ]
            },
            {
                name: "divider-obj",
            },
            {
                name: "Les charges",
                link: "list_charge",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "Dèpot",
                link: "depots",
            }
        ]
        // 1.1 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    },
    {
        // Menu root item -------------------------------------------------------------------------------
        name: "Mouvement",
        // 2.1 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        permissionsAssigned: [permissions.canManagePurchases, permissions.canManageSales],
        children: [

            {
                name: "Achat",
                link: "achat",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "Vente",
                link: "bon_de_livraison",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "divider-obj"
            },
            {
                name:  "Facture",
                link: "facture",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "header-obj",
                text: "Commande",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "Nouveau bon de commande",
                link: "bon_de_commande",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "divider-obj"
            },
            {
                name: "header-obj",
                text: "Vente Comptoir"
            },
            {
                name: "Nouvelle vente de comptoir",
                link: "vente_comptoir",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Consultation des ventes comptoirs",
                link: "consultation_ventes_comptoirs",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "divider-obj"
            },
            {
                name: "header-obj",
                text: "Bon D'Avoir"
            },
            {
                name: "Bon avoir achat",
                link: "bon_avoire_achat",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Bon avoir vente",
                link: "bon_avoir_vente",
                permissionsAssigned: [permissions.canManagePurchases]
            }
        ]
        // 2.1 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    },
    {
        // Menu root item -------------------------------------------------------------------------------
        name: "Consultation",
        // 3.1 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        permissionsAssigned: [permissions.canManagePurchases, permissions.canManageSales, permissions.canManageSalesOrders],
        children: [

            {
                name: "Consultation des Bon d'Achats",
                link: "consultation_achats",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManagePurchases],
            },
            {
                name: "header-obj",
                text: "Consultation des Ventes",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Consultation des Bon de ventes",
                link: "consultation_ventes",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "header-obj",
                text: "Les Articles Vendu non Livres",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "List de Tous les Articles non Livrès",
                link: "liste_tous_articles_vendus_non_livres",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "List des Article non livrès par Client",
                link: "liste_tous_articles_vendus_non_libres_par_client",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Consultation des Facture",
                link: "consultation_facture",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Consultation des Bons de Commandes",
                link: "consultation_bon_commande",
                permissionsAssigned: [permissions.canManagePurchases]
            },
            {
                name: "divider-obj",
                permissionsAssigned: [permissions.canManageSales]
            },
            {
                name: "Consultation des Factures Proformas",
                link: "consultation_facture_proforma",
                permissionsAssigned: [permissions.canManageSalesOrders, permissions.canManageSales]
            }

        ]
        // 3.1 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    },
    {
        // Menu root item -------------------------------------------------------------------------------
        name: "Transfert",
        // 4.1 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        permissionsAssigned: [permissions.canManagePurchases, permissions.canManageSales],
        children: [

            {
                name: "Client/Four. Transfert",
                link: "client_ou_fournisseur_transfer"
            },
            {
                name: "divider-obj"
            },
            {
                name: "header-obj",
                text: "Transferer"
            },
            {
                name: "Nouveau Bon de Transferts",
                link: "bon_transfer"
            },
            {
                name: "Consultation des Bon de Transferts",
                link: "consultation_bon_transfert"
            },
            {
                name: "List des Articles Transferes",
                link: "list_articals_transfer"
            },
            {
                name: "divider-obj"
            },
            {
                name: "header-obj",
                text: "Reception des Transfert"
            },
            {
                name: "Nouveau Bon de Reception du Transfert",
                link: "bon_reception_transfer"
            },
            {
                name: "Consultation des Bons de Reception du Transert",
                link: "consultation_bon_reception_transfert"
            },
            {
                name: "Liste des Article Receptionnes par Tranfert",
                link: "list_article_receptionne_par_transfert"
            }

        ]
        // 4.1 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    },
    {
        // Menu root item -------------------------------------------------------------------------------
        name: "Etats",
        // 5.1 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        permissionsAssigned: [permissions.canManagePurchases, permissions.canManageSales],
        children: [

            {
                name: "Les Achats",
                permissionsAssigned: [permissions.canManagePurchases],
                children: [ 

                    {name: "Etat Global", link: "etat_achat_global"},
                    {name: "Etat Dètaillèe", link: "etat_achat_detaille"},
                    {name: "Livre des Achats", link: "livre_achats"},
                    {
                        name: "divider-obj"
                    },
                    {
                        name: "header-obj",
                        text: "Etat Par Fournisseur"
                    },
                    {name: "Global", link: "etat_achat_fournisseur_global"},
                    {name: "Detaillè", link: "etat_achat_fournisseur_detaille"}
                
                ]
            },
            {
                name: "divider-obj"
            },
            {
                name: "Les Ventes",
                permissionsAssigned: [permissions.canManageSales],
                children: [ 

                    {name: "Etat Global", link: "etat_ventes_global"},
                    {name: "Etat Dètaillèe", link: "etat_ventes_detaille"},
                    {name: "Livre des Vents", link: "livre_des_ventes"},
                    {
                        name: "divider-obj"
                    },
                    {
                        name: "header-obj",
                        text: "Etat Par Client"
                    },
                    {name: "Global", link: "etat_ventes_global_par_client"},
                    {name: "Detaillè", link: "etat_ventes_detaille_par_client"},
                    {name: "Par Article", link: "etat_ventes_par_client_et_par_article"},
                    {name: "Liste des Articles Vendus", link: "list_article_vendues_par_quantite"}

                ]

            },
            {
                name: "divider-obj"
            },
            {
                name: "Par Article",
                children: [ 

                    {name: "Article Acheter", link: "article_acheter", permissionsAssigned: [permissions.canManagePurchases]},
                    {
                        name: "divider-obj"
                    },
                    {name: "Article Vendue", link: "articles_vendu", permissionsAssigned: [permissions.canManageSales]},
                    {
                        name: "divider-obj"
                    },
                    {name: "Bènèfice par Article", link: "etat_ventes_detaille_par_article", permissionsAssigned: [permissions.canManageSales]},
                    {
                        name: "divider-obj"
                    },
                    {name: "Global", link: "articles_no_vendu", permissionsAssigned: [permissions.canManageSales]},
                    {name: "Par Famille", link: "articles_no_vendu_famille", permissionsAssigned: [permissions.canManageSales]}

                ]
            },
            {
                name: "divider-obj"
            },
            {
                name: "Etat Global",
                children: [ 
                    {name: "Un Etat rècapitulatif", link: "etat_recapitulatif"},
                    {name: "La Balance", link: "balance"},
                    {name: "Tableau de Bord", link: "tableau_de_bord"},
                    {name: "Bènèfice Par Client", link: "etat_benifice"}

                ]
            },
            {
                name: "divider-obj"
            },
            {
                name: "Etat du Sock a une Date",
                link: "etat_stock_a_date_donnee"
            },
            {
                name: "La Balance du Stock",
                link: "balance_de_stock"
            },
            {
                name: "divider-obj"
            },
            {
                name: "header-obj",
                text: "Etat des Remises"
            },
            {
                name: "Remise par Montant",
                link: "etat_remise_par_montant"
            },
            {
                name: "Remise par Pourcentage",
                link: "etat_remise_par_parcentage"
            },
            {
                name: "Le Tous",
                link: "etat_remise_par_tout"
            },
            {
                name: "divider-obj"
            },
            {
                name: "Etat par Agent Commercial",
                link: "etat_par_agent_commercial"
            },
            {
                name: "divider-obj"
            },
            {
                name: "Etat par Utilisateur",
                link: "etat_par_utilisateur"
            }
        ]
        // 5.1 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    }
];

export default data;

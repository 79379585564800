import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVentesDetailleParClient.module.scss'
import metaData from './EtatVenteDetailleParClient.json'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import clientsFinder from '../../api/clientsFinder'
import creditClientFinder from '../../api/creditClientFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVentesDetailleParClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [ inputValue , setInputValue ] = useState('');
  const [searchData, setSearchData] = useState([]);
    
  const [checked , setChecked ] = useState(false);
  const handelCheckbox = ()=>{
      setChecked(!checked);
  }

  const [data,setData] = useState([]);

  const totalVente = useRef();
  const totalRetour = useRef();
  const globalAmount = useRef();
  const getSelectedClient = useRef();
  const credit = useRef();
  const totalReglement = useRef();

  useEffect(() => {
    const getClient = async () =>{
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
    }
  
  getClient();
  }, []); 

  const displayData = async (client) =>{
    let montantVentes = .0;
    let montantRetour = .0;
    let montantAchat = .0;
    let remiseVente = .0;
    let remiseAvoir = .0;
    let maxId = -1;
    let idBon = -1;
    let articlesAchete = [], articlesAvoirs = [];
    const fetchArticlesVentesAvoir = async () =>{
      const articlesLivre = await articleLivreFinder.get('/');
      const articlesAvoir = await articlesAvoirFinder.get('/vente');
      const creditClient = await creditClientFinder.get(`/${client.id}`)
      const bonsAchat = await bonAchatsFinder.get('/');
      const { total_verser } = creditClient.data.data;
      
      // FILTER THE TABLE BY THE CLIENT ID
      const filtredVentes = articlesLivre.data.data.articles.filter( article => article.id_client === client.id);
      const filtredAvoirs = articlesAvoir.data.data.articles.filter( article => article.id_client === client.id);

      // get the total amount of purchases
      bonsAchat.data.data.bons.map(bon =>{
        montantAchat += bon.amount * ( 1 - (bon.remise/100));
      });
      
      // GET THE MAX ARTICLE VENDUE ID 
      maxId = articlesLivre.max_id;
      

      filtredVentes.map((article)=>{

        let montant = (article.prix * article.quantity) * (1 - (article.remise / 100)) ;
        
        if(parseInt(idBon) !== parseInt(article.id_bl)){

          // montant = montant * (1 - (article.bl_remise/100));
          remiseVente += article.remise;
          idBon = article.id_bl;
        }

        montantVentes += montant;

        articlesAchete.push({
            id:article.id,
            Vente_av: 'Vente',
            code: article.bl_code,
            lot: article.lotNbr,
            designation:article.designation,
            date: article.bl_creation_date.substring(0,10) ,
            qty:article.quantity,
            pu_vente:article.prix,
            montant,
            pu_achat:article.pu_achat,
            benifice:montant - article.pu_achat,
            codeArt:article.code
            });
        }
      );

      filtredAvoirs.map((article)=>{
        // Calculate the article price with the remise
        let quantity = article.article_avoir_quantity;
        let pu_vente = article.article_avoir_prix_achat;
        let montant = (quantity * pu_vente) * (1 - (article.article_avoir_remise/100));
        // 

        // Calculate the total retour with the bon remise
        if(parseInt(idBon) !== parseInt(article.id_bon_avoir)){
          // montant = montant * (1 - (article.bon_avoir_remise/100));
          remiseAvoir += article.bon_avoir_remise;
          idBon = article.id_bon_avoir;
        }
        montantRetour += montant;
        //

        let id= article.id + maxId;
        articlesAvoirs.push({
          id,
          Vente_av: 'Avoir',
          code: article.bon_avoir_code,
          lot: article.lotNbr,
          designation:article.designation,
          date: article.bon_avoir_date.substring(0,10),
          quantity,
          pu_vente,
          montant,
          pu_achat:article.pu_achat,
          benifice:montant-article.pu_achat,
          codeArt:article.code
        });
    });

      montantVentes = montantVentes * (1 - remiseVente/100);
      montantRetour = montantRetour * (1 - remiseAvoir/100);
      let ga = (montantVentes - montantRetour);
      totalVente.current?.setValue(montantVentes.toFixed(2));
      totalRetour.current?.setValue(montantAchat.toFixed(2));  
      globalAmount.current?.setValue(((montantVentes - montantRetour) - montantAchat).toFixed(2));
      totalReglement.current?.setValue(total_verser?.toFixed(2));
      credit.current?.setValue((ga - total_verser).toFixed(2));
      setData([...articlesAvoirs,...articlesAchete]);
      }

      fetchArticlesVentesAvoir();
    };

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Détaillé Par Client'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  id={'client'}
                  label={"Code Client"}
                  holderText={"Client"}
                  type={"text"}
                  labelPosition={true}
                  width={"50%"}
                  searchData={searchData}
                  onSelectItem={displayData}
                  ref={getSelectedClient}
                  displaySearchOption={true}
                  path={"/ajouter_client"}
                  btnName={'Ajouter Nouveau client'}
                  />
                <div className={styles['row-end']}>
                <Button 
                  icon={icons.exporter}
                  width={"250px"}
                  name={'Exporter Vers Excel'} 
                    />
                  <Button 
                  icon={icons.impr}
                  width={"150px"}
                  name={'Imprimer'} 
                    />
                </div>
              </div>
              <div className={styles['row-between']}>
                  <div className={styles['row']}>
                  <InputText
                    label={"Jour 1"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                    <div className={styles['check-input-container']}>
                        <p className={styles['checkbox-name']}>Avec Bénéfice</p>
                        <input type='checkbox' className={styles['check-input']} onClick={handelCheckbox} 
                        style={checked ? {backgroundImage: `url(${icons.checkboxActive})`}:
                        {backgroundImage: `url(${icons.checkboxInactive})`}} 
                        />
                    </div>
                  </div>
                    <InputText 
                    icon={icons.search}
                    type={"text"}
                    holderText={"Chercher"}
                    label={"Chercher"}
                    labelPosition={true}
                    width={"50%"}
                    />
              </div>
              <div style={{height: "300px"}}>
                <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                  date1={date1} date2={date2}/>
              </div>
            
            <div className={styles['row-between']} style={{width:'100%'}}>
                   <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Ventes'}
                  ref={totalVente}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Achats'}
                  ref={totalRetour}
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total du Bénéfice'}
                  ref={globalAmount}
                  />
            </div>
            <div className={styles['row-end']} style={{width:'100%'}}>
              <InputText 
                holderText={"Total Reglement"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total de Reglement'}
                ref={totalReglement}
                disabled
                />
              <InputText 
                holderText={"Credit"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total de Achat'}
                ref={credit}
                disabled
                />
              </div>
            </div>
        
    </div>
    </PageLayout>
  );
}

export default EtatVentesDetailleParClient;
import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './ListArticleVenduesParQuantite.json'
import Button from '../../components/button/Button'
import styles from './listarticlevenduesparquantite.module.scss'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import clientsFinder from '../../api/clientsFinder'

const ListArticleVenduesParQuantite = () => {
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [ inputValue , setInputValue ] = useState('');
  const [checked , setChecked ] = useState(false);
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const handelCheckbox = ()=>{
    setChecked(!checked);
  }

  
  const getSelectedClient = useRef();

  useEffect(() => {
    const getClient = async () =>{
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
    }
  
  getClient();
  }, []); 


  const displayData = (client) =>{
    let articlesVendue = [], articlesAvoir = [];
    let montant_achat = 0.0;
    let montant_vente = 0.0;
    let montant_retour = 0.0;
    let id_bon = -1;
    const fetchArticlesVentesAvoir = async () =>{

      const articles_vendue = await articleLivreFinder.get('/');
      const articles_avoir = await articlesAvoirFinder.get('/vente');
      const bons_achat = await bonAchatsFinder.get('/');
      // FILTER THE TABLE BY THE CLIENT ID
      const filtred_ventes = articles_vendue.data.data.articles.filter( article => article.id_client === client.id);
      const filtred_avoirs = articles_avoir.data.data.articles.filter( article => article.id_client === client.id);

      // GET THE TOTAL AMOUNT OF PURCHASES
      bons_achat.data.data.bons.map(bon =>{
        montant_achat += bon.amount * ( 1 - (bon.remise/100));
      });
      //  

      // CALCULATE THE FREE UNITIES 
      const { max_id } = articles_vendue.data.data;
      // 

      filtred_ventes.map((article)=>{ 
        // CALCULATE THE FREE QUANTITY OF THE ARTICLES
        let quantity = article.quantity * ( 1 - (article.ug/100));
        // 
        
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (article.prix * quantity) * (1 - (article.remise / 100));
        if(parseInt(id_bon) !== parseInt(article.id_bon_livraison)){
          montant_vente =   montant_vente * (1 - (article.remise/100));
          id_bon = article.id_bon_livraison;
        }
        montant_vente += montant;
        // 

        articlesVendue.push({
          id: article.id,
          vente_avoir : 'Vente',
          lot: article.lotNbr,
          code: article.code,
          quantityEnStock:article.ds_quantity,
          quantity,
          montant,
          designation: article.designation
        });
        }
      );

      filtred_avoirs.map((article)=>{
        
        let quantity = article.article_avoir_quantity;
        
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (article.article_avoir_prix_achat * quantity) * (1 - (article.article_avoir_remise / 100));
        if(parseInt(id_bon) !== parseInt(article.id_bon_avoir)){
          montant_retour =   montant_retour * (1 - (article.bon_avoir_remise/100));
          id_bon = article.id_bon_avoir;
        }
        montant_retour += montant;
        // 

        let id = article.id + max_id;

          articlesAvoir.push({
            id,
            vente_avoir : 'Avoir',
            lot:article.lotNbr,
            code: article.code,
            quantityEnStock:article.ds_quantity,
            quantity,
            montant,
            designation: article.designation
          });
      });
      
      setData([...articlesAvoir,...articlesVendue]);
    }
    
    fetchArticlesVentesAvoir();
  }

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'List des Articles Vendus Par Quantité'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  id={'client'}
                  label={"Code Client"}
                  holderText={"Client"}
                  type={"text"}
                  labelPosition={true}
                  width={"50%"}
                  searchData={searchData}
                  onSelectItem={displayData}
                  ref={getSelectedClient}
                  displaySearchOption={true}
                  path={"/ajouter_client"}
                  btnName={'Ajouter Nouveau client'}
                />
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"150px"}
                  name={'Imprimer'} 
                    />
                <Button 
                  icon={icons.exporter}
                  width={"250xpx"}
                  name={'Exporter Vers Excel'} 
                    />
                </div>
              </div>
              <div className={styles['row-between']}>
                  <div className={styles['row']}>
                  <InputText
                    label={"Jour 1"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                    <div className={styles['check-input-container']}>
                        <p className={styles['checkbox-name']}>Avec Bénéfice</p>
                        <input type='checkbox' className={styles['check-input']} onClick={handelCheckbox} 
                        style={checked ? {backgroundImage: `url(${icons.checkboxActive})`}:
                        {backgroundImage: `url(${icons.checkboxInactive})`}} 
                        />
                    </div>
                  </div>
                    <InputText 
                    icon={icons.search}
                    type={"text"}
                    holderText={"Chercher"}
                    label={"Chercher"}
                    labelPosition={true}
                    width={"30%"}
                    />
              </div>
              <div style={{height: "350px"}}>
              <Table thead={metaData.table} inputValue={inputValue} tbody={data} edit={true} remove={true} date1={date1} date2={date2}/>
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ListArticleVenduesParQuantite
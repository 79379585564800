import React, { useEffect, useState, useRef } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './livreDesVente.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import SelectInput from '../../components/select-input/SelectInput'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import metaData from './LivreDeVente.json'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'

const LivreDesVentes = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const options =["Livre Des Vente Global","Cheque"];

      const [inputValue, setInputValue] = useState('');
      const [data,setData] = useState([]);

      const [date1, setDate1] = useState();
      const reportDate1Change = (val) => setDate1(val);

      const [date2, setDate2] = useState();
      const reportDate2Change = (val) => setDate2(val);

      const total_vente = useRef();
      const total_retour = useRef();
      const total_comptoir = useRef();
      const global_amount = useRef();


      useEffect(() => {
        let max_id = -1;
        let montant_ventes = 0.0;
        let montant_retour = 0.0;
        let bonsAvoir =[],bonsVente = [];
        const fetchBonsVenteAvoir = async () => {
          const bons_ventes = await bonVentesFinder.get('/');
          const bons_avoir = await bonsAvoirFinder.get('/vente');

          max_id = bons_ventes.data.data.max;

          bons_ventes.data.data.bons.map((bon)=>{
            let montant = parseFloat(bon.amount) * (1 - (parseFloat(bon.remise) / 100));
            montant_ventes += montant; 

            bonsVente.push({
                  id : bon.id,
                  bon: bon.code,
                  date: bon.date.substring(0,10),
                  Vente_av: 'Vente',
                  montant
                });
            });

            bons_avoir.data.data.bons.map((bon)=>{
              let montant = parseFloat(bon.amount) * (1 - (parseFloat(bon.remise) / 100));
              montant_retour += montant;
              let id = bon.id + max_id;

              bonsAvoir.push({
                id,
                bon: bon.code,
                date: bon.date.substring(0,10),
                Vente_av: 'Avoir',
                montant
              });
            });
            total_vente.current?.setValue(montant_ventes);
            total_retour.current?.setValue(montant_retour);  
            total_comptoir.current?.setValue('0');
            global_amount.current?.setValue(montant_ventes - montant_retour);
            setData([...bonsVente,...bonsAvoir]);
        }
        fetchBonsVenteAvoir();
      }, []);
            
  return (
        <PageLayout icon={icons.article} nameLayout={'Livre Des Ventes'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div className={styles['periode']}>
                <InputText 
                  width={'20%'} 
                  type={"date"}  
                  label={'Jour 1'}
                  margin={'0px 0px 0px 15px'}
                  labelPosition={true}
                  reportChage={reportDate1Change}
                  />
                <InputText 
                  width={'20%'} 
                  type={"date"}  
                  label={'Jour 2'}
                  margin={'0px 0px 0px 15px'} 
                  labelPosition={true}
                  reportChage={reportDate2Change} 
                  />
          </div>
          <div className={styles['operations-buttons']}>
              <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
          </div>
          </div>
          <div className={styles['select-input']}>
            <SelectInput options={options} width={"35%"}/>
          </div>
          <div className={styles['table']}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
              date1={date1} date2={date2}/>
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText 
                  width={'100%'} 
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Des Vente'}
                  margin={'0px 15px 0px 15px'}
                  ref={total_vente}
                  />
            </div>
            <div>
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Des Retours'}
                  margin={'0px 15px 0px 0px'}
                  ref={total_retour}
                  />
            </div>
            <div>
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Des Comptoirs'}
                  margin={'0px 15px 0px 0px'}
                  ref={total_comptoir}
                  />
            </div>
            <div style={{display: "flex"}}>
                <InputText 
                  width={'100%'}
                  type={"number"} 
                  holderText={"Montant"} 
                  label={'Montant Global'}
                  margin={'0px 15px 0px 0px'}
                  ref={global_amount}
                  />
            </div>
          </div>  
        </div>
        </PageLayout>
  )
}

export default LivreDesVentes

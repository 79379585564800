import React, { useState, useEffect, useRef } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './stock.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import articlesFinder from '../../api/articlesFinder';
import metaData from './stock.json'
import depotsStockFinder from '../../api/depotsStockFinder.js'
import Printing from '../../components/printing/Printing';
import Dialog from '../../components/dialog/Dialog.js';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js';
import { dateFormatter } from '../../utils/dateFormatter.js';
import SelectInput from '../../components/select-input/SelectInput.js';
import ErrorContainer from '../../components/errorContainer/ErrorContainer.js';

const Stock = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data , setData] = useState([])
  const tableRef = useRef(null);
  const printRef = useRef();
  const allData = useRef();
  const fistData = useRef();
  useEffect(() => {
    const getArticles = async () => {
      try {
        const response = await depotsStockFinder.get("/stock");

        let articles = response.data.data.articles.map(item => {

          item.ds_expire_date = dateFormatter(item.ds_expire_date, 2);
          item.type = item.type === "matierepremiere" ? "Matiere Premiere": 
            item.type === "matierefini" ? "Produit Fini" :"Produit Semi Fini";
          return item;
        })
        allData.current = articles;
        articles = articles.map(({status,type,...rest})=>rest)

        setData(allData.current.filter((article) => article.type === options[0]).map(({status,type,...rest})=>rest));

      } catch (err) {
        console.log(err);
      }
    }

    getArticles();
  }, []);

  const updatedTable = (item) => {
    tableRef.current.setAllData(allData.current.filter((article) => article.type === item).map(({status,type,...rest})=>rest))

  } 
  // Here you should check how the field are named comming from the backend
  const filterByArr = ['designation'];
  
  const handleSearch = (val) => tableRef.current.setFilterInput(val);
  const PrintingColumn = [
    {name: "Code",width: "10%",key: 'code'},
    {name: "Designation",width: "40%",key: 'designation'},
    {name: "Lot",width: "5%",key: 'ds_lot_nbr'},
    {name: "Date de Peromption",width: "5%",key: 'ds_expire_date'},
  ]
  
  const [PrintingColumns, setPrintingColumns] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const articlesAdded = rowInfo.map((art) => {
      return {
        code: art.code,
        designation: art.designation,
        lotNbr: art.ds_lot_nbr,
        expireDate: art.ds_expire_date,
      }
    });
    const ROWS_PER_PAGE = 30; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages
    }
    setPrintingColumns(PrintingColumn)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }
  const options = [
    "Produit Fini",
    "Produit Semi Fini",
    "Matiere Premiere"
  ]
  return (
        <PageLayout icon={icons.article} nameLayout={'Stock'}>
          <div className={styles['main-search-bar']}>
            <InputText width={'30%'} 
                       labelPosition={true} 
                       holderText={"Chercher Par Designation"} 
                       label={'Articles'}
                       margin={'0px 0px 0px 15px'}
                       icon={icons.search} 
                       reportChange={(val) => handleSearch(val)} />
            <div className={styles['operations-buttons']}>
              <div style={{width: '45%', position: "relative"}}>
                <SelectInput 
                  id={"typeArticle"}
                  options={options} 
                  width={'100%'} 
                  heightBorder={'50px'}
                  labelPosition={true}
                  label={"Type d'Article"}
                  reportChange={updatedTable}
                />
              </div>
              <Button name={'Imprimer'} 
                  onclick={handlePrint} 
                  icon={icons.impr}/>
              <Button name={'Exporter Vers Excel'} icon={icons.exporter}/>
            </div>
          </div>
          <div className={styles['table']}>
            <Table thead={metaData} 
                   tbody={data} 
                   ref={tableRef}
                   defaultSearchBy={filterByArr}
            />
          </div>
          {isActive && <Printing type={'List des Articles'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
        <ErrorContainer ref={errorsHandler}/>
        </PageLayout>
  )
}

export default Stock;

import React from 'react';
import ParametersPage from '../../components/parameters-page/ParametersPage';

const SettingsPage = (props) => {
    const { children } = props;

    return (
        <ParametersPage 
            title={'Profile'}
            basePath={'/settings'}
            pagesStruct={[
                {header: 'Manage Users', pages: [{name: 'Users list', path: '/users-list'}, {name: 'Add a new user', path: '/add-new-user'}, {name: 'Permissions and roles', path: '/permissions-and-roles'}]},
                {header: 'Manage Default Values', pages: [{name: 'Margins and taxes', path: '/margins-and-taxes'}]},
                {header: `Manage Company's Prints`, pages: [{name: 'Upload Prints', path: '/upload-prints'}, {name: 'Check Exemplars', path: '/check-prints-exemplars'}]}
            ]}
        >
            {children}
        </ParametersPage>
    );
}

export default SettingsPage;

import React, { useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import styles from './devis.module.scss'
import InputText from '../../components/input-text/InputText'
import Taxes from '../../components/Taxes/Taxes'
import Button from '../../components/button/Button'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import SelectInput from '../../components/select-input/SelectInput'
import Table from '../../components/table/Table'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const Devis = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const total = 9999999
  const thead = [
    {name:"Code Article",width: 190},
    {name:"Quantite",width: 190},
    {name:"Prix Vente",width: 190},
    {name:"RM%",width: 190},
    {name:"Total",width: 190},
    {name:"Designation",width: 490},
    {name:"Qte Stock",width: 190},
    {name:"Poids",width: 190},,
    {name:"Volume",width: 190},
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');

  return (
    <PageLayout icon={icons.achat} nameLayout={"Devis"}>
    <div className={styles['body-container']}>
     <div className={styles['main-body']}>
      <div className={styles['actions-container']}>
        <div className={styles['row-evenly']}>
          <InputText  holderText={"Client"} labelPosition={true} setInputValue={setInputValue} inputValue={inputValue}
           width={"50%"} type={"text"} label={'Client'} icon={icons.search}/>
        </div>
        <div className={styles['ajouter-articles-container']}>
            <Button name='Ajouter Des Article' width={"200px"}/>
        </div>
        <div style={{height: "450px"}}>
          <Table thead={thead} inputValue={inputValue} tbody={search} 
            edit={user?.permissions.includes(permissions.canEditInTable)} 
          />
        </div>
        
      </div>
      
    </div>
    <div className={styles['side-body']}>
    <PageSideBar >
            <div className={styles['col-around']}>
              <div className={styles['col-evenly']}>
                <InputText label={"N° du bon"} holderText={"0001"} width={"100%"} type={"number"}/>
                <InputText label={"Date de bon"}  width={"100%"} type={"date"}/>
              </div>
              <div className={styles['col-evenly']} style={{marginTop: "50px"}}>
                <Taxes name={"Total sans remise"} value={123005} currency={"DZD"}/>
                <InputText label={"Remise"} currency={true} currencyValue={"DZD"} width={"100%"} type={"number"} holderText={"0.00"}/>
                <Taxes name={"Total remise"} value={10007840} currency={"DZD"}/>
                <Taxes total={total} name={"Total"} side={true} currency={"DZD"}/>
              </div>
            </div>
          </PageSideBar>   
    </div>
    
   </div>
   </PageLayout>
  )
}

export default Devis

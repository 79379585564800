import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printingAchat.module.scss';
import NumberConverter from '../number-converter/NumberConverter';

const PrintingAchat = forwardRef( (props, ref) => {

  const {dataInfo, thead, type, pageNumber, pageNumbers} = props

  const wordRef = useRef();

  const timeoutRef = useRef();
  const [totalHT, setTotalHT] = useState(0);
  const [totalRemise, setTotalRemise] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const articlesProps = useRef([]);
  useEffect(()=>{
    const handlePrinting = ()=>{
      let totalHT=0;
      let totalRM=0;
      const articlesAdded = dataInfo.articlesAdded || []
      dataInfo.articlesAdded.map((article)=>{
        totalHT+= article.total;
        totalRM+= article.remise
      })
      setTotalHT(totalHT)
      setTotalRemise((totalRM/100)*totalHT)
      setTotalTTC(totalHT-((totalRM/100)*totalHT))
      wordRef.current.convert(parseFloat(totalHT-((totalRM/100)*totalHT)))
  
      articlesProps.current = [];
      for (const key in articlesAdded[0]) {
        articlesProps.current.push(key);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    handlePrinting();
  },[dataInfo.articlesAdded])

  const numberWithSpaces = (num) => {
    return (Math.floor(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  };

  return (
    <>
      <div className={style['body-container']}>
        <div className={style['main-title-container']}>
          <p className={style['title-number']}>{type} N°</p>
          <p className={style['title-number']}>{dataInfo.rowInfo.code} /{dataInfo.rowInfo.date.substring(0,4)}</p>
        </div>
        <div className={style['info-container-type2']}>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {<><p className={style['head-lines-type1']}>Fournisseur</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.fournisseur_name}</p></>}
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Date</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.date.substring(0,10)}</p>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
              {dataInfo.rowInfo.fournisseur_address && <>
              <p className={style['head-lines-type1']}>Adresse</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type3']}>{dataInfo.rowInfo.fournisseur_address}</p></>}
            </div>
            <div className={style['head-lines-container']}>
            </div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['client-info-container']}></div>
            <div className={style['bar-code']}>Bar Code</div>
          </div>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Page</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type1']}>{pageNumber}/{pageNumbers}</p>
            </div>
          </div>
        </div>
        <div className={style['table-container']}>
          <table className={style['table']}>
            <colgroup>
              <col style={{width: '3.5%'}} className={style['col']}/>
              {thead.map((obj)=>{
                  return <col style={{width: obj.width}} className={style['col1']}/>
              })}
            </colgroup>
            <thead>
              <tr>
                <th key={-1} style={{borderBottom: "0.5px solid black"}} ></th>
                {thead.map((head, index)=>{
                    return(
                        <th key={index} className={style['table-head']}>
                            {head.name} 
                        </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {dataInfo.articlesAdded.map((values, index) => {
                  return(
                    <tr key={index} style={{height: "20px"}}>
                      <td className={style['check-box-container']}>
                        <div className={style['check-box']}></div>
                      </td>
                      {
                        articlesProps.current.map((key, index) => {
                          return(
                              <td className={style['td-cell']} key={index}>
                                {values[key]}
                              </td>
                            )
                        })
                      }
                    </tr>
                  )
                })}
                <tr key={9999} style={{height: "100%"}}></tr>
            </tbody>
          </table>
        </div>
        <div className={style['amounts-container']}>
          <div className={style['amounts-letters']}>
            <p className={style['head-lines-type1']}>Arrete la Presente Facture a la Somme de:</p>
            <p className={style['head-lines-type3']}><NumberConverter ref={wordRef} /></p>
          </div>
          <div className={style['amounts-numbers']}>
            <div className={style['head-lines-container-type2']}>
                <p className={style['head-lines-type1']}>Total HT</p>
                <p className={style['head-lines-type2']}>:</p>
                <p className={style['head-lines-type6']}>{numberWithSpaces(totalHT)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
              <p className={style['head-lines-type1']}>Total Remise</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type6']}>{numberWithSpaces(totalRemise)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
              <p className={style['head-lines-type1']}>Total TTC</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type6']}>{numberWithSpaces(totalTTC)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style['page-footer']}>
        <div className={`${style['Line']} ${style['footer-line']}`}>
          <div className={style['first-line']}></div>
          <div className={style['second-line']}></div>
        </div>
        <div className={style['head-lines-container']}>
          {dataInfo.saisiPar &&<>
          <p className={style['head-lines-type4']}>Saisi par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.saisiPar}</p></>}
          {dataInfo.validerPar &&<>
          <p className={style['head-lines-type4']}>Valider par</p>
          <p className={style['head-lines-type4']}>:</p>
          <p className={style['head-lines-type5']}>{dataInfo.validerPar}</p></>}
        </div>
      </div>
      </>
  )
})

export default PrintingAchat

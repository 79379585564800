export const logo = require('../logo.svg').default;

export const bgImgs = {
    article: {
        main: require('./rectangle-article-blue.svg').default,
        secondary: require('./rectangle-article-white.svg').default
    },
    vents: {
        main: require('./rectangle-vente-blue.svg').default,
        secondary: require('./rectangle-vente-white.svg').default
    },
    achat: {
        main: require('./rectangle-achat-blue.svg').default,
        secondary: require('./rectangle-achat-white.svg').default
    },
    facture: {
        main: require('./rectangle-facture-blue.svg').default,
        secondary: require('./rectangle-facture-white.svg').default
    },
    delete:{
        main: require('./delete-inactive.svg').default,
        secondary: require('./delete-active.svg').default
    },
    edit:{
        main: require('./edit-inactive.svg').default,
        secondary: require('./edit-active.svg').default
    },
    production: {
        main: require('./default.svg').default,
        secondary: require('./variant2.svg').default
    },
    validateEdit: require('./edit-validate.svg').default,
    cancelEdit: require('./edit-cancel.svg').default,
    defaultProfilePic: require('./default-profile-pic.svg').default
}

export const icons = {
    achat: require('./achat-white.svg').default,
    facture: require('./facture-white.svg').default,
    select: require('./select-icon.svg').default,
    article: require('./article-white.svg').default,
    exit: require('./exit.svg').default,
    listIndicatorIcon: require('./list-indicator-icon.svg').default,
    insere: {
        main: require('./ajouter-n-a-blue.svg').default,
        secondary: require('./ajouter-n-a-white.svg').default
    },
    impr: {
        main: require('./impr-blue.svg').default,
        secondary: require('./impr-white.svg').default
    },
    exporter: {
        main: require('./excel-blue.svg').default,
        secondary: require('./excel-white.svg').default
    },
    reglementClient: {
        main: require('./reglement-client-blue.svg').default,
        secondary: require('./reglement-client-white.svg').default
    },
    reglementFournisseur: {
        main: require('./reglement-fournisseur-blue.svg').default,
        secondary: require('./reglement-fournisseur-white.svg').default
    },
    ajouterFournisseur: {
        main: require('./ajouter-fournisseur-blue.svg').default,
        secondary: require('./ajouter-fournisseur-white.svg').default
    },
    checkboxActive: require('./check-box-active.svg').default,
    checkboxInactive: require('./check-box-inactive.svg').default,
    search: require('./search-icon.svg').default,
    goBack: require('./go-back-icon.svg').default,
    edit2: require('./edit-2-icon.svg').default,
    email: require('./email-icon.svg').default,
    phone: require('./phone-icon.svg').default,
    add: require('./add-icon.svg').default,
    app: require('./app-icon.svg').default,
    loading: require('./loading-icon.svg').default
}

export const prints = {
    logo: require('./logo.svg').default
}

import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './articlesVenduGrouperSousFamille.module.scss'
import { icons} from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'

const ArticlesVenduGrouperSousFamille = () => { 

  
  const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Stock",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Prix Grossiste",width: 190},
    {name:"PPA",width: 190}
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Articles No Vendu Par Famille'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'50%'} tbody={search} setInputValue={setInputValue} inputValue={inputValue}
             labelPosition={true} holderText={"Famille"}  label={'Famille'}
            margin={'0px 0px 0px 15px'}
            icon={icons.search}/>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          <div className={styles['periode']}>
            <div style={{display: "flex" , width:"50%"}}>
                <InputText width={'25%'} type={"date"}  label={'Jour 1'}
                       margin={'0px 0px 0px 15px'}/>
            <InputText width={'25%'} type={"date"}  label={'Jour 2'}
                       margin={'0px 0px 0px 15px'}/>
            </div>
            <div style={{display: "flex" , width:"50%" , justifyContent: "flex-end"}}>
                <InputText width={'70%'} holderText={"Chercher"}  label={'Chercher'}
                margin={'0px 15px 0px 0px'}
                icon={icons.search}/>
            </div>
            
          </div>
          <div className={styles['table']}>
            <Table thead={thead} inputValue={inputValue} tbody={search} edit={true} remove={true} />
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Des Ventes'}
                margin={'0px 15px 0px 15px'}/>
            </div>
            <div>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Des Achats'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            <div style={{display: "flex"}}>
               
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Benefice'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default ArticlesVenduGrouperSousFamille

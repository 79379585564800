import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatAchatFournisseurGlobal.json'
import Button from '../../components/button/Button'
import styles from './etatAchatFournisseurGlobal.module.scss'
import bonsAchatFinder from '../../api/bonAchatsFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatAchatFournisseurGlobal = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('');
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

    const getSelectedFournisseur = useRef();
    const total_achat = useRef();
    const total_retour = useRef();
    const total_versement = useRef();
    const sold = useRef();
    const global_amount = useRef();

    const displayData = async (fournisseur)=>{

      const bons_achat = await bonsAchatFinder.get(`/`);
      const bons_avoir = await bonsAvoirFinder.get('/achat');
      const detteFournisseur = await detteFournisseurFinder.get(`/${fournisseur.id}`);

      const {total_verser} = detteFournisseur.data.data;
      const filtred_bons_achat = bons_achat.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
      const filtred_bons_avoir = bons_avoir.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
      
      const { max_id } = bons_achat.data.data.code;
      
      let bonsAchat = [], bonsAvoir = [];
      let montant_achat = 0.0;
      let montant_retour = 0.0;
      
      filtred_bons_achat.map(bon => {
        let montant = bon.amount * (1 - (bon.remise/100));

        montant_achat += montant;
        
        bonsAchat.push({
          id: bon.id,
          achat_av: 'Achat',
          code: bon.code,
          date: bon.date.substring(0,10),
          montant:montant.toFixed(2)
        });
      });
      
      filtred_bons_avoir.map(bon =>{
        let montant = bon.amount * (1 - (bon.remise/100));
        montant_retour += montant;
        let id = bon.id + max_id;
        bonsAvoir.push({
          id,
          achat_av: 'Avoir',
          code: bon.code,
          date: bon.date.substring(0,10),
          montant:montant.toFixed(2)
        });
      });

      let ga = montant_achat - montant_retour;
      let sold_total = (ga - total_verser).toFixed(2)
      total_achat.current?.setValue(montant_achat.toFixed(2));
      total_retour.current?.setValue(montant_retour.toFixed(2));
      global_amount.current?.setValue(ga.toFixed(2));
      total_versement.current?.setValue(total_verser);
      sold.current?.setValue(sold_total);

      setData([...bonsAvoir,...bonsAchat]);
  }

    useEffect(() => {
      const getFournisseurs = async () =>{
          const four = await fournisseurFinder.get("/");
          setSearchData(four.data.data.fournisseurs);
      }
      
      getFournisseurs();
    }, []);

  return (
    <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={'Etat des Achats Par Fournisseur ( Global )'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
              <InputText 
                icon={icons.search}
                id={'fournisseurs'}
                holderText={"fournisseur"}
                label={"Ajouter fournisseur"}
                width={"50%"}
                labelPosition={true}
                searchData={searchData}
                onSelectItem={displayData}
                ref={getSelectedFournisseur}
                displaySearchOption={true}
                path={"/ajouter_fournisseur"}
                btnName={'Ajouter Nouveau fournisseur'}
              />  
          <div className={styles['row-end']}>
            <Button 
              icon={icons.impr}
              width={"150px"}
              name={'Imprimer'} 
              />
            <Button 
              icon={icons.exporter}
              width={"250px"}
              name={'Exporter Vers Excel'} 
              />
            </div>
          </div>
          <div className={styles['row']}>
            <InputText  
              label={"Jour 1"}
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              reportChange={reportDate1Change}
              />
              <InputText label={"Jour 2"} 
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              reportChange={reportDate2Change}
              />
          </div>
          <div style={{height: "350px"}}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
              date1={date1} date2={date2}/>
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"}
              label={'Montant des Achats'}
              ref={total_achat}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant des Retours'}
              ref={total_retour}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant Global'}
              ref={global_amount}
              disabled
              />
          </div>
          <div className={styles['row-end']} style={{width:'100%'}}>
          <InputText  
              holderText={"Total Verser"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant Verser'}
              ref={total_versement}
              disabled
              />
            <InputText  
              holderText={"Solde"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Solde'} 
              ref={sold} 
              disabled
              />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatAchatFournisseurGlobal;
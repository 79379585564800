import React, { useEffect, useState } from 'react';
import styles from './listeTousArticlesVendusNonLivres.module.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import articleLivreFinder from '../../api/articleLivreFinder'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const ListeTousArticlesVendusNonLivres = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const theaderData = [
        {name:"N Bon",width: 190},
        {name:"Date",width: 190},
        {name:"Code Client",width: 190},
        {name:"Client",width: 190},
        {name:"Designation",width: 190},
        {name:"Quantite",width: 190},
        {name:"PU", width: 190},
        {name:"Montant",width: 190}
    ]
    
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchBonsAchat = async () => {
        const response = await articleLivreFinder.get('/');
        setData(response.data.data.map((article)=>{
            if(!article.delivered)
            return(
                {
                    id: article.id,
                    bon: article.code || 0,
                    date: article.bl_creation_ts.substring(0, 10),
                    codeClient: article.client_code,
                    Client: article.client_name,
                    designation: article.designation,
                    quantity: article.quantity,
                    PU: article.prix,
                    montant: (article.prix * article.quantity) * ( 1 - article.remise >= 1 ? article.remise/100 : article.remise)
                }
                );
        }))
        }
        fetchBonsAchat();
    },[]);

    const [inputValue, setInputValue] = useState('');
    const filterByArr = ['designation'];
    const handleSearch = (val) => {
        setInputValue(val);
    }

    return (
        <PageLayout icon={icons.achat} nameLayout={'Liste de tous les article vendus non livres'}>
            <div className={styles['container']}>
            <div className={`${styles['fill-width']} ${styles['upper-section']}`}>
                <InputText holderText={"Chercher Par Designation"}
                            label={'Chercher'}
                            type={"text"}
                            labelPosition={true}  
                            width={"50%"}  
                            icon={icons.search}
                            reportChange={(val) => handleSearch(val)} />

                <Button name='Imprimer' 
                        width={"250px"} 
                        icon={icons.impr}/>
            </div>
            <div className={styles['table-height']}>
                <Table thead={theaderData}
                        filterInput={inputValue}
                        filterByArr={filterByArr}
                        tbody={data} 
                        remove={user.permissions.includes(permissions.canDeleteFromTable)} />
            </div>
            </div>
        </PageLayout>
    )
}

export default ListeTousArticlesVendusNonLivres;

import React, { useEffect, useRef, useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './listArticleReceptionneeParTransfert.module.scss'
import articlesReceptionTransfereFinder from '../../api/articlesReceptionTransfereFinder'

const ListArticleReceptionneeParTransfert = () => {  
  const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Quantitie",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Total",width: 190}
  ]

  let [data, setData] = useState([]);

  useEffect(()=>{
    const getArticlesTransfere = async ()=>{
      try{
        const articles = await articlesReceptionTransfereFinder.get("/");
              setData(articles.data.data.map((article)=>{
                console.log(article)
                return{
                  id: article.id,
                  nbBon: article.BonDetailes.code,
                  codeArt: article.articleInfo.code,
                  designation: article.articleInfo.designation,
                  quantity: article.quantity,
                  prixAchat: article.prix_achat,
                  total: article.quantity * article.prix_achat
                }
              }))
      }catch(err){
        console.log(err)
      }
      
    }
  getArticlesTransfere();
},[])

  const tableRef = useRef();
  const filterByArr = ['designation'];
  const handleSearch = (val) => tableRef.current.setFilterInput(val)
                
  return (
        <PageLayout icon={icons.article} nameLayout={'List Des Article Rèceptionnèe par Transfert'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'50%'}
                        labelPosition={true} holderText={"Chercher Par Designation"}  label={'Chercher'}
                        margin={'0px 0px 0px 15px'}
                        icon={icons.search}
                        reportChange={(val) => handleSearch(val)}
            />
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
            </div>
          </div>
          <div className={styles['periode-input-container']}>
            <div className={styles['periode']}>
                <InputText 
                  width={'max-content'} 
                  type={"date"}  
                  label={'Periode du'}
                  margin={'0px 0px 0px 15px'}/>
                <InputText 
                  width={'max-content'} 
                  type={"date"}  
                  label={'Au'}
                  margin={'0px 0px 0px 15px'}/>
            </div>
            <div className={styles['input']}>
                <div></div>
            <   InputText width={'40%'} holderText={"chercher"}  label={'Chercher'} margin={"0px 15px 0px 0px"}
            />
            </div>
          </div>
          <div className={styles['table']}>
            <Table thead={thead} 
                    tbody={data} 
                    defaultSearchBy={filterByArr}
                    ref={tableRef}
                    edit={true} remove={true} />
          </div>
        </div>
        </PageLayout>
  )
}

export default ListArticleReceptionneeParTransfert
import React, { useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './creditClientWilayaSuiv.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import SelectInput from '../../components/select-input/SelectInput'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'

const CreditClientWilayaSuiv = () => { 
  const { state: authState } = useAuth();
  const { user } = authState;

    const options =[
        {
          value : "Espece",
          name : "Tout Les Client"
        },
        {
          value : "Cheque",
          name : "Cheque"
        }
      ]
  const thead = [
    {name:"Code",width: 190},
    {name:"Code Familly",width: 190},
    {name:"Designation",width: 490},
    {name:"Stock",width: 190},
    {name:"Pu Achat",width: 190},
    {name:"Prix Grossiste",width: 190},
    {name:"PPA",width: 190}
  ]

  let [search, setSearch] = useState([]);
  const [inputValue, setInputValue] = useState('');
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Credit Des Client par Wilaya'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'30%'} tbody={search} setInputValue={setInputValue} inputValue={inputValue}
             labelPosition={true} holderText={"Wilaya"}  label={'Wilaya'}
            margin={'0px 0px 0px 15px'}/>
            <div className={styles['operations-buttons']}>
                <Button path={'/credit_client_wilaya'} name={'Fournisseur-Client'} height={'100%'} width={"200px"}/>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          <div className={styles['periode']}>
            <div style={{display: "flex" , width:"50%"}}>
                <InputText width={'60%'} tbody={search} setInputValue={setInputValue} inputValue={inputValue}
                labelPosition={true} holderText={"Chercher"}  label={'Chercher'}
                margin={'0px 0px 0px 15px'}/>
            </div>
            <div style={{display: "flex" , width:"30%" , justifyContent: "flex-end"  ,
                        marginRight: "15px"}}>
                <SelectInput options={options}/>
            </div>
            
          </div>
          <div className={styles['table']}>
            <Table thead={thead} inputValue={inputValue} tbody={search} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
            />
          </div>
          <div className={styles['mantant-chargers']}>
            <div>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total BL'}
                margin={'0px 15px 0px 15px'}/>
            </div>
            <div>
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Verser'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            <div style={{display: "flex"}}>
               
                <InputText width={'100%'}type={"number"} holderText={"Montant"} label={'Total Rester'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default CreditClientWilayaSuiv

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationdevis.module.scss'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ConsultationDevis = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead_bonLivraison = [
    {name:"N° Bon",width: 190},
    {name:"Date",width: 190},
    {name:"Montant",width: 190},
    {name:"Code Client",width: 190},
    {name:"Client",width: 190},
    {name:"Solde",width: 190},
    {name:"Total Remise",width: 190},
    {name:"Saisie Par",width: 190},
    {name:"Bon Bloquè ?",width: 190},
    {name:"Observation 1",width: 490},
    {name:"Observation 2",width: 490}
  ]
    const thead_detailleBon = [
    {name:"Code",width: 190},
    {name:"Quantitè",width: 190},
    {name:"PU",width: 190},
    {name:"RM%",width: 190},
    {name:"Total",width: 190},
    {name:"Dèsingation",width: 490},
    {name:"Qte Stock",width: 190},
    {name:"Saisie Par",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation Devis'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Chercher"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Liste des Devis"} thead={thead_bonLivraison} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
              <div className={styles['row-end']} style={{width:'100%'}}><br/><br/></div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Détail du Bon"} thead={thead_detailleBon} inputValue={inputValue} tbody={search} />
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ConsultationDevis;
import React, { useEffect, useState, useRef } from 'react'
import styles from './achats.module.scss'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'
import PageLayout from '../../components/page-layout/PageLayout'
import Table from '../../components/table/Table'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import InputText from '../../components/input-text/InputText'
import SelectInput from '../../components/select-input/SelectInput'
import fournisseurFinder from '../../api/fournisseurFinder';
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import articlesFinder from '../../api/articlesFinder'
import { useNavigate } from 'react-router-dom'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import SubInfo from '../../components/sub-info/SubInfo'
import metaData from './achats.json';
import depotFinder from '../../api/depotFinder'
import depotsStockFinder from '../../api/depotsStockFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import Printing from '../../components/printing/Printing'
import { useAuth } from '../../contexts/AuthContext'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'
const Achats = () => {
  
  const { state: authState } = useAuth();
  const navigate = useNavigate();
  
  const [fournisseurs, setFournisseurs] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);
  const [depots, setDepots] = useState([]);
  
  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const depot = useRef(null);
  const paymentInput = useRef(null);
  const articlesRef = useRef();
  // const remiseType = useRef();
  useEffect(()=>{
    metaData.table.find(el => el.name === "Livre ?").children 
                        = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
    // metaData.table.find(el => el.name === "Type Remise").children 
    //                     = [{id: 0, name: 'DZD'}, {id: 1, name: '%'}];
  },[]);
  
  useEffect(() => {
    dateInput.current.setValue(new Date().toISOString().substring(0, 10));

    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots); 
      }
      catch (err) {
        console.log(err);
      }
    }


    
    const fetchBonsAchat = async () => {
      try {

        const response = await bonAchatsFinder.get('/');
        
        let code = 1;
        if(response.data.data.code.max) {
          code = parseInt(response.data.data.code.max) + 1;
        }
        codeInput.current.setValue(code);
      }
      catch (err) {
        console.log(err);
      }
    }

    const fetchFournisseurs = async () => {
      try {
        const response = await fournisseurFinder.get('/');
        setFournisseurs(response.data.data.fournisseurs);
      }
      catch (err) {
        console.log(err);
      }
    }

    const getArticles = async () => {
      try {
        const response = await articlesFinder.get("/");
        const filtredArticles = response.data.data.articles.filter((article)=>{
          return article.type === 'matierepremiere'
        }).map(({ type, ...rest }) => rest);
        articlesRef.current = filtredArticles;
        articleAdderTable.current.setAllData(filtredArticles);
      }
      catch (err) {
        console.log(err);
      }
    }

    fetchDepots();
    fetchBonsAchat();
    fetchFournisseurs();
    getArticles();
  }, []);

  const displayDialog = useRef(null);
  const articleAdderTable = useRef(null);
  const totalDept = useRef()

  const addSelectedArticles = async () => {
    const depotId = parseInt(depot.current.getSelectedItem().id);
    const articles = [];
    let quantityDepot;
    const data = [...tableRef.current.getAllData(),...articleAdderTable.current.sendSelectedItems()];
    for(let i=0;i<data.length;i++){
      let temp = data[i];
      
      depotId && (quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId)}/${parseInt(temp.id)}`))
      const quantityD = depotId && quantityDepot.data.data?.quantity
      // console.log(temp);
      articles.push({
        id: temp.id,
        codeArticle: temp.codeArticle,
        designation: temp.designation,
        lot_nbr: temp.lot_nbr || "",
        quantityart: temp.quantityart || 0,
        puAchat: temp.puAchat,
        // remiseType: temp.remiseType || metaData.table.find(el => el.name === "Type remise").children[0].name,
        remise: temp.remise || 0,
        ug: temp.ug,
        total: (temp.puAchat * temp.quantityart - ((temp.puAchat * temp.quantityart)*temp.remise)/100) || 0,
        quantity: temp.quantity,
        quantiteLocal: quantityD || 0,
        prixVenteGros: temp.prixVenteGros,
        prixVenteDemiGros: temp.prixVenteDemiGros,
        puDetail: temp.puDetail,
        puSpecial: temp.puSpecial,
        weight: temp.weight,
        volume: temp.volume,
        delivered:temp.delivered || 'Non',
        datePeremption: temp.datePeremption || new Date().toISOString().substring(0,10),
        observation: temp.observation
      });
    }
    setSelectedArticlesData(articles);
  }

  const handleDepot = async (depotId)=>{
    const articles = [];
    let quantityDepot;
    const data = tableRef.current.getAllData();
    for(let i=0;i<data.length;i++){
      let temp = data[i];
      quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId.id)}/${parseInt(temp.id)}`)
      const quantityD = quantityDepot.data.data?.quantity 
      articles.push({
        id: temp.id,
        codeArticle: temp.codeArticle,
        designation: temp.designation,
        lot_nbr: temp.lot_nbr,
        quantityart: temp.quantityart,
        puAchat: temp.puAchat,
        remise: temp.remise,
        ug: temp.ug,
        total: temp.total ,
        quantity: temp.quantity,
        quantiteLocal: quantityD || 0,
        prixVenteGros: temp.prixVenteGros,
        prixVenteDemiGros: temp.prixVenteDemiGros,
        puDetail: temp.puDetail,
        puSpecial: temp.puSpecial,
        weight: temp.weight,
        volume: temp.volume,
        delivered: temp.delivered,
        datePeremption: temp.datePeremption,
        observation: temp.observation
      });
    }
      tableRef.current.setAllData(articles);
  }
  
  const getSelectedFournisseur = useRef();
  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const tableRef = useRef(null);
  const totalSansRM = useRef();
  const total = useRef();

  const handelValidate = () => {
    displayConfirmationDialog.current.display();
  }

  const checkCode = async (value)=>{

    const response = await bonAchatsFinder.get(`/${value}/code`)
    // console.log(response.data)
    if(response.data.data.bon?.code === value){
      return true
    }else
      return false
  }

  const checkLotNbr = (articles)=>{
    return articles.filter((article)=>{
      return !article.lot_nbr.trim()
    }).map((article)=>{
      return {name:"Lot",message:`Assurez que Nombre de Lot de code d'article ${article.codeArticle} est bien selectionez`};
    });

  }
  const checkErrs = async ()=>{
    const alerts = [];
    
    const code = document.getElementById("code").value;
    const fournisseur = document.getElementById("fournisseur").value;
    const id_fournisseur = fournisseur && getSelectedFournisseur.current.getSelectedItem().id;
    const remise = document.getElementById("remise").value
    const depots = document.getElementById("depot").value
    
    if(!remise){
      alerts.push({name:'remise',message:'Assurez que la remise is bien saisez'});
      remiseR.current.setBorderRed();
    }
    if(!code){
      alerts.push({name:'code',message:'Assurez que le code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(code && (await checkCode(code) === true)){
      alerts.push({name:'code',message:'le NÂ° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(!id_fournisseur){
      alerts.push({name:'fournisseur' ,message:'Assurez que la fournisseur est bien selectionez'});
      getSelectedFournisseur.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0){
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    }
    if(!depots){
      alerts.push({name:'depot' ,message:'Assurez que le Depot est bien selectionez'});
      depot.current.setBorderRed();
    }
    let checkLot = checkLotNbr(tableRef.current.getAllData());
    if( checkLot.length > 0){
      alerts.push(...checkLot);
    }
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return;
    }
    
    handelValidate();
  }

  const handleSubmit = async (event)=>{
    event.preventDefault();

    try {
      const depotId = parseInt(depot.current.getSelectedItem().id);
      
      const response = await bonAchatsFinder.post("/", {
        id_fournisseur : getSelectedFournisseur.current.getSelectedItem().id,
        id_depot: depotId,
        code : parseInt(document.getElementById('code').value),
        date : new Date(dateInput.current.getValue()),
        payment_type: paymentInput.current.getSelectedOption(),
        remise: document.getElementById('remise').value,
        amount : total.current?.getValue()
      });
      
      tableRef.current.getAllData().forEach(async (articleData) => {
        //removing
        await depotsStockFinder.post(`/`, {
          id_depot: depotId,
          id_article: articleData.id,
          quantity: parseInt(articleData.quantityart) + parseInt((articleData.quantityart * articleData.ug) / 100),
          lot_nbr: articleData.lot_nbr,
          expire_date: new Date().toISOString().substring(0,10),
          purchase_date: new Date().toISOString().substring(0,10),
          operation: 'add'
        });
        //removing
        await articlesAcheteFinder.post('/', {
          id_stock: articleData.id, 
          id_depot: depotId,
          id_achat: response.data.data.id_achat, 
          prix_achat: parseInt(articleData.puAchat), 
          quantite: parseInt(articleData.quantityart),
          remise: parseInt(articleData.remise),
          ug: parseInt(articleData.ug),
          delivered: articleData.delivered === "Oui" ? true : false,
          date_peremtion: new Date(articleData.datePeremption),
          observation: articleData.observation,
          lot_nbr: articleData.lot_nbr,
        });

      });

      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);

    } catch (error) {
        console.log(error)
    }
  }
  const validateHandler = useRef([]);


  const handleFournisseur = async (fournisseur) => {
      const response = await detteFournisseurFinder.get(`/${fournisseur.id}`);
      totalDept.current.setValue(response.data.data.sold);
  }

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    articleAdderTable.current.addItem(articlesRef.current.find(item => item.id === itemToDeleteId));
  }
  const actOnValidation = async (obj) => {
    let amount= 0;
    let totalAvecRM = 0;
    tableRef.current.getAllData().filter((article)=>{
      amount += article.puAchat * article.quantityart
      return article.id !== obj.id
    }).map((article)=>{
      totalAvecRM += (article.puAchat * article.quantityart) *  (1 - (article.remise/100))
    });
    let totalPrice = (obj.puAchat * obj.quantityart) *  (1 - (obj.remise/100));
    totalAvecRM += totalPrice
    tableRef.current.updateRow({
      id: obj.id,
      codeArticle: obj.codeArticle,
      designation: obj.designation,
      lot_nbr: obj.lot_nbr,
      quantityart: obj.quantityart || 0,
      puAchat: obj.puAchat || 0,
      // remiseType:obj.remiseType,
      remise: obj.remise || 0,
      ug: obj.ug || 0,
      total:totalPrice,
      quantity: obj.quantity,
      quantiteLocal: obj.quantiteLocal,
      prixVenteGros: obj.prixVenteGros,
      prixVenteDemiGros: obj.prixVenteDemiGros,
      puDetail: obj.puDetail,
      puSpecial: obj.puSpecial,
      weight: obj.weight,
      volume: obj.volume,
      delivered: obj.delivered,
      datePeremption: obj.datePeremption,
      observation: obj.observation
    });
    updateRMInput(tableRef.current.getAllData())
    totalSansRM.current.setValue(amount);
    total.current?.setValue(totalAvecRM);
  }

  const updateTaxes = ()=>{
    let amount=0;
    const remise = document.getElementById('remise').value || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puAchat * article.quantityart - (article.puAchat * article.quantityart)*(remise/100))
      amount += article.quantityart * article.puAchat;
    })
    tableRef.current.updateRow({...articles, editable: false});
    totalSansRM.current.setValue(amount);
    total.current.setValue(amount-(amount*remise)/100)
  }
  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Qte",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getAllData();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const articlesAdded = rowInfo.map((article)=>{
      // console.log(allArticleInfo)
      return({
        code: article.codeArticle,
        designation: article.designation,
        quantity: article.quantityart,
        PUAchat: article.puAchat,
        remise: article.remise,
        total: article.total,
      })
    });
    // console.log(articlesAdded);
    const ROWS_PER_PAGE = 12; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: {
        code: document.getElementById("code").value,
        fournisseur_name: getSelectedFournisseur.current.getSelectedItem().name,
        date: document.getElementById("date").value,
        fournisseur_address: getSelectedFournisseur.current.getSelectedItem().address,
        payment_type: paymentInput.current.getSelectedOption(),
      },
      articlesAdded: pages,
      saisiPar: authState.user.name
    }
    // console.log(data)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Achat'}>
      <form onSubmit={handleSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row-between']}>
                  <InputText holderText={"Chercher un fournisseur"}
                             width={"50%"} 
                             labelPosition={true}
                             type={"text"} 
                             label={'Fournisseur*'}
                             id={'fournisseur'}
                             onSelectItem={handleFournisseur}
                             searchData={fournisseurs}
                             path={"/ajouter_fournisseur"}
                             btnName={'Ajouter Nouveau fournisseur'} 
                             ref={getSelectedFournisseur}
                             />
                  <SubInfo
                    ref={totalDept}
                    label={"Solde Fournisseur :"}
                    unit={"DZD"}
                    lined={true}
                    />
                  <Button 
                          path={"../reglement_fournisseur"} 
                          width={"300px"} 
                          name='Reglement Fournisseur' 
                          icon={icons.reglementFournisseur } />
              </div>

              <div className={styles['row-between']}>
                <Button width={"200px"} name={"Ajouter Article"} onclick={()=>displayDialog.current.display()} />
                <Button 
                    icon={icons.impr}
                    width={"24%"}
                    name={'Imprimer'}
                    onclick={handlePrint}
                  />
              </div>
              

              <div style={{height: "394px"}}>
                <Table thead={metaData.table} 
                        tbody={selectedArticlesData} 
                        ref={tableRef} 
                        confirmBeforeDeletion={false}
                        edit={true} 
                        remove={true}
                        actOnValidation={actOnValidation}
                        handleDelete={handleDelete} 
                />
              </div>
            </div>
          </div>
          
          <div className={styles['side-body']}>
            <PageSideBar validateFunc={checkErrs}>
              <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                  <InputText label={"N° du bon *"}
                             holderText={"0001"} 
                             width={"100%"} 
                             type={"number"} 
                             id={'code'}
                             ref={codeInput}
                             disabled
                             />

                  <InputText label={"Date de bon"} 
                             width={"100%"}
                             type={"date"}
                             id={'date'}
                             ref={dateInput}/>
                  
                  <SelectInput
                    label={"Type de paiement *"}
                    options={["Espece", "Cheque", "Verement", "Autre"]}
                    width={"100%"}
                    ref={paymentInput}
                  />
                  <InputText label={"Depot"} 
                             width={"100%"}
                             holderText={'Depot'}
                             id={'depot'}
                             onSelectItem={handleDepot}
                             searchData={depots}
                             ref={depot}/>
                </div>
                <div className={styles['col-evenly']}>
                  <SubInfo label={"Total sans remise"} ref={totalSansRM} unit={"DZD"}/>
                  <InputText label={"Remise*"}
                             currency={true}
                             currencyValue={"%"}
                             width={"100%"}
                             type={"number"}
                             holderText={"0.00"}
                             defaultValue={'0'}
                             id={'remise'}
                             ref={remiseR}
                             reportChange={updateTaxes}
                             />
                  <SubInfo ref={total} label={"Total"} unit={"DZD"} />
                </div>
              </div>
            </PageSideBar>
          </div>
          <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
            <AdderTable head={metaData.adderTable} ref={articleAdderTable} />
          </Dialog>
          
        </div>
        <ConfirmationButton ref={displayConfirmationDialog}/>
      </form>
      <ErrorContainer ref={errorsHandler}/>
      <ValidatedMessages ref={validateHandler}/>
    {isActive && <Printing type={"Bon D'Achat"} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    </PageLayout>
  )
}

export default Achats;
import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './etatVentesDetailleParArticle.json'
import styles from './etatVentesDetailleParArticle.module.scss'
import Button from '../../components/button/Button'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVentesDetailleParArticle = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const [ inputValue , setInputValue ] = useState('')
    const [data,setData] = useState([]);

    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const total_vente = useRef();
    const total_achat = useRef();
    const total_benifice = useRef();

  useEffect(() =>{
    let articlesVendue = [], articlesAvoir = [];
    let montant_achat = 0.0;
    let montant_vente = 0.0;
    let montant_retour = 0.0;
    let remise_vente = .0;
    let remise_avoir= .0;

    let max_id= -1;
    let id_bon = -1;

    const fetchArticlesAcheteAvoir = async () =>{
      const articles_vente = await articleLivreFinder.get('/');
      const articles_avoir = await articlesAvoirFinder.get('/vente');
      const bons_achat = await bonAchatsFinder.get('/');
      
      // GET THE TOTAL AMOUNT OF PURCHASES
      bons_achat.data.data.bons.map(bon =>{
        montant_achat += bon.amount;
      });
      // 

      // GET THE MAX ID OF VENTE RECEIPT 
      max_id = articles_vente.data.data.max_id;
      // 

      articles_vente.data.data.articles.map((element)=>{ 
        
        //  CALCULATE THE FREE UNITIES 
        let quantityUg = Math.round(element.quantity * (1 - (element.ug / 100)));
        // 
        
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (element.prix * quantityUg) * (1 - (element.remise / 100));
        if(parseInt(id_bon) !== parseInt(element.id_bon_livraison)){
          remise_vente += element.bl_remise;
          id_bon = element.id_bon_livraison;
        }
        montant_vente += montant;
        // 

        articlesVendue.push({
          id: element.id,
          Vente_av: 'vente',
          code: element.code,
          lot:element.lotNbr,
          date: element.bl_creation_date.substring(0,10),
          qtyVendue:element.quantity,
          pu_achat:element.pu_achat,
          pu_vente:element.prix_vente_gros,
          pu_detaille: element.prix,
          benifice: montant - element.pu_achat ,
          designation:element.designation,
          });
        }
      );
      
      id_bon = -1;
      articles_avoir.data.data.articles.map((element)=>{

        let montant = (element.article_avoir_prix_achat * element.article_avoir_quantity) * (1 - (element.article_avoir_remise / 100));
        if(parseInt(id_bon) !== parseInt(element.id_bon_avoir)){
          remise_avoir += element.bon_avoir_remise;
          id_bon = element.id_bon_avoir;
        }
        montant_retour += montant;

        // ADD THE MAX ID FROM THE VENTE TABLE TO THE ARTICLE ID
        let id = element.id + max_id;
        // 
        
        articlesAvoir.push({
          id,
          Vente_av: 'avoir',
          lot:element.lotNbr,
          code: element.code,
          date: element.expire_date?.substring(0,10),
          qtyVendue:element.article_avoir_quantity,
          pu_achat:element.pu_achat,
          prix_vente_gros:element.prix_vente_gros,
          pu_detaille:element.article_avoir_prix_achat,
          benifice:montant - element.pu_achat,
          designation:element.designation
        });
      });
      montant_vente = montant_vente * (1 - (remise_vente/100));
      montant_retour = montant_retour * (1 - (remise_avoir/100));

      total_vente.current?.setValue(montant_vente.toFixed(3));
      total_achat.current?.setValue(montant_achat.toFixed(3));
      total_benifice.current?.setValue(((montant_vente - montant_retour) - montant_achat).toFixed(3));
      setData([...articlesAvoir,...articlesVendue]);

    }
    fetchArticlesAcheteAvoir();
  },[]);


  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Dètaillè par Article'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
              <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Article"}
                label={"Article"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']}>
                  <Button 
                    icon={icons.impr}
                    width={"250px"}
                    name={'Imprimer'}
                    />
                </div>
              </div>
              <div className={styles['row']}>
                  <InputText label={"Jour 1"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                </div>
                <div style={{height: '300px'}}>
                  <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
                    edit={user?.permissions.includes(permissions.canEditInTable)} 
                    remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                    date1={date1} date2={date2}/>
                </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
                   <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Ventes'}
                  ref={total_vente}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant des Achat'}
                  ref={total_achat}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total du Bénéfice'}
                  ref={total_benifice}
                  disabled
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatVentesDetailleParArticle
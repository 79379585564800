import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import styles from './versementClient.module.scss';
import InputText from '../../components/input-text/InputText';
import Taxes from '../../components/Taxes/Taxes';
import Button from '../../components/button/Button';
import PageSideBar from '../../components/page-side-bar/PageSideBar';
import SelectInput from '../../components/select-input/SelectInput';
import Table from '../../components/table/Table';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';

const VersementClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const total = 9999999;

  const options = [
    {
      value: 0,
      name: 'Espece' 
    },
    {
      value: 1,
      name: 'Cheque'
    },
    {
      value: 2,
      name: 'Virement'
    },
    {
      value: 3,
      name: 'Autre'
    }
  ]
  
  const thead = [
    {name:"Code Cli.", width: 190},
    {name:"Client", width: 190},
    {name:"Date Vers.", width: 190},
    {name:"Montant", width: 190},
    {name:"N de Cheque", width: 190},
    {name:"Observation", width: 390}
  ]

  return (
    <PageLayout icon={icons.achat} nameLayout={"Versement Des Client"}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['actions-container']}>
            <div className={styles['row-evenly']}>
              <InputText  holderText={"Fournisseur"} labelPosition={true} setInputValue={setInputValue} inputValue={inputValue}
              width={"50%"} type={"text"} label={'Ajouter Fournisseur'} icon={icons.search}/>
              <Button name='Imprimer' width={"200px"} icon={icons.impr}/>
            </div>
            <div style={{height: "450px"}}>
              <Table thead={thead} inputValue={inputValue} tbody={data} 
                edit={user.permissions.includes(permissions.canEditInTable)} />
            </div>
          </div>
        </div>
        <div className={styles['side-body']}>
          <PageSideBar >
              <div className={styles['col-around']}>
                  <div className={styles['col-evenly']}>
                  <InputText label={"Period du"} width={"100%"} type={"date"}/>
                  <InputText label={"Au"}  width={"100%"} type={"date"}/>
                  <SelectInput options={options} label={"Type de paiement"} width={"100%"} />
                  </div>
                  <div className={styles['col-evenly']} style={{marginTop: "200px"}}>
                      <Taxes total={total} name={"Total"} side={true} currency={"DZD"}/>
                  </div>
              </div>
          </PageSideBar>  
        </div>
      </div>
    </PageLayout>
  )
}

export default VersementClient;

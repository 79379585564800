import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVenteDetaille.module.scss'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import metaData from './EtatVenteDetaille.json'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVenteDetaille = () => {
    const { state: authState } = useAuth();
    const { user } = authState;
  
    const [ inputValue , setInputValue ] = useState('');
    
    const [data,setData] = useState([]);
    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const total_vente = useRef();
    const total_achat = useRef();
    const total_benifice = useRef();
    
  useEffect(() =>{
    let articlesAchete = [], articlesAvoir = [];
    let montant_achat = 0.0;
    let montant_vente = 0.0;
    let montant_retour = 0.0;
    let remise_vente = .0;
    let remise_avoir = .0;
    let max_id= -1;
    let id_bon = -1;

    const fetchArticlesAcheteAvoir = async () =>{
      const articles_vente = await articleLivreFinder.get('/');
      const articles_avoir = await articlesAvoirFinder.get('/vente');
      const bons_achat = await bonAchatsFinder.get('/');
      
      // GET THE TOTAL AMOUNT OF PURCHASES
      bons_achat.data.data.bons.map(bon =>{
        montant_achat += bon.amount;
      });
      // 

      // GET THE MAX ID OF VENTE RECEIPT
      max_id = articles_vente.data.data.max_id;
      // 

      articles_vente.data.data.articles.map((element)=>{

        //  CALCULATE THE FREE UNITIES 
        let quantityUg = element.quantity * (1 - (element.ug / 100));
        // 
        
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (element.prix * quantityUg) * (1 - (element.remise / 100));
        if(parseInt(id_bon) !== parseInt(element.id_bon_livraison)){
          // montant_vente =   montant_vente * (1 - (element.remise/100));
          remise_vente += element.remise; 
          id_bon = element.id_bon_livraison;
        }
        montant_vente += montant;
        // 

        articlesAchete.push({
          id: element.id,
          lot:element.lotNbr,
          code: element.code,
          num_bon: element.bl_code,
          date: element.bl_creation_date.substring(0,10),
          Vente_av: 'vente',
          designation:element.designation,
          qtyVendue:element.quantity,
          pu_vente:element.prix,
          montant,
          pu_achat:element.pu_achat,
          benifice: montant - element.pu_achat ,
          codeClient: element.client_code,
          Client: element.client_name
          });
        }
      );
      
      id_bon = -1;
      articles_avoir.data.data.articles.map((element)=>{

        let montant = (element.article_avoir_prix_achat * element.article_avoir_quantity) * (1 - (element.article_avoir_remise / 100));
        if(parseInt(id_bon) !== parseInt(element.id_bon_avoir)){
          // montant_retour =   montant_retour * (1 - (element.bon_avoir_remise/100));
          remise_avoir += element.bon_avoir_remise;
          id_bon = element.id_bon_avoir;
        }
        montant_retour += montant;

        // ADD THE MAX ID FROM THE VENTE TABLE TO THE ARTICLE ID
        let id = element.id + max_id;
        // 

        articlesAvoir.push({
          id,
          lot:element.lotNbr,
          code: element.code,
          codeBon:element.bon_avoir_code,
          date: element.bon_avoir_date?.substring(0,10) || '',
          Vente_av: 'avoir',
          designation:element.designation,
          qtyVendue:element.article_avoir_quantity,
          pu_vente:element.article_avoir_prix_achat,
          montant,
          pu_achat:element.pu_achat,
          benifice:montant - element.pu_achat,
          codeClient: element.client_code,
          Client: element.client_name
        });
      });
      montant_vente = montant_vente * (1 - remise_vente/100);
      montant_retour = montant_retour * (1 - remise_avoir/100);
      
      total_vente.current?.setValue(montant_vente);
      total_achat.current?.setValue(montant_achat);
      total_benifice.current?.setValue(parseFloat(montant_vente - montant_retour) - parseFloat(montant_achat));
      setData([...articlesAvoir,...articlesAchete]);

    }
    fetchArticlesAcheteAvoir();
  },[]);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes détaillé'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Chercher"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']} style={{width: 'max-content'}}>
                  <Button 
                        icon={icons.impr}
                        width={"150px"}
                        name={'Imprimer'} 
                    />
                    <Button 
                        icon={icons.exporter}
                        width={"max-content"}
                        name={'Exporter Vers Excel'} 
                    />
                </div>
              </div>
              <div className={styles['row']}>
                  <InputText
                    label={"Jour 1"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                </div>
                <div style={{height:'300px'}}>
                    <Table thead={metaData.table} inputValue={inputValue} tbody={data} 
                      edit={user?.permissions.includes(permissions.canEditInTable)} 
                      remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                      date1={date1} date2={date2}/>
                </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
                   <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total de Ventes'}
                  ref={total_vente}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total de Achat'}
                  ref={total_achat}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Total des Bénéfice'}
                  ref={total_benifice}
                  disabled
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  );
}

export default EtatVenteDetaille;;
import React from 'react'
import styles from './outputText.module.scss'

export default function OutputText(props) {
    const {value , label , width , labelWidth} = props;
  return (
    <div className={styles['container']} >
        <label className={styles['label-container']}>{label}</label>
        <div className={styles['output-text-container']} >
            <p>{value}</p>
        </div>
    </div>
  )
}

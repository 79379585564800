import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import styles from './bonAvoirAchat.module.scss';
import InputText from '../../components/input-text/InputText';
import SubInfo from '../../components/sub-info/SubInfo';
import Button from '../../components/button/Button';
import PageSideBar from '../../components/page-side-bar/PageSideBar';
import SelectInput from '../../components/select-input/SelectInput';
import Table from '../../components/table/Table';
import bonAvoirFinder from '../../api/bonAvoirFinder';
import Dialog from '../../components/dialog/Dialog';
import AdderTable from '../../components/adder-table/adderTable';
import fournisseurFinder from '../../api/fournisseurFinder';
import articlesAvoirFinder from '../../api/articlesAvoirFinder';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';
import metaData from './bonAvoirAchat.json';
import depotFinder from '../../api/depotFinder';
import depotsStockFinder from '../../api/depotsStockFinder';
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages';

const BonAvoirAchat = () => {
  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  const [articlesData, setArticlesData] = useState([]);
  const [ fourList, setFourList ] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);

  const displayDialog = useRef([]);
  const articleTable = useRef([]);
  const getSelectedFournisseur = useRef();
  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const tableRef = useRef(null);
  const paymentTypeInput = useRef(null);
  const totalSansRM = useRef();
  const articlesRef = useRef();
  const total = useRef();

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const depotId = parseInt(depot.current.getSelectedItem().id)
    setDate(document.getElementById("date").value);
    try {
        const response = await bonAvoirFinder.post("/",
        {
          id_cf: getSelectedFournisseur.current.getSelectedItem().id,
          id_depot: depotId,
          code: document.getElementById("code").value,
          type: "achat",
          code_bon: parseInt(document.getElementById("bon").value),
          payment_type:  paymentTypeInput.current.getSelectedOption(),
          remise: parseInt(document.getElementById("remise").value),
          date: date
        });

        tableRef.current.getAllData().forEach(async (articleData) => {
          await depotsStockFinder.post(`/`, {
            id_depot: depotId,
            id_depot_stock: articleData.id,
            quantity: parseInt(articleData.quantityart),
            lot_nbr: articleData.lot_nbr,
            operation: 'sub'
          })

          await articlesAvoirFinder.post('/',{
            id_bonAvoir: response.data.data.bon.id, 
            id_depot_stock: articleData.id, 
            quantity: parseInt(articleData.quantityart), 
            prix_achat: parseInt(articleData.puAchat), 
            remise: parseInt(articleData.remise),
            observation:articleData.observation
            })
        });
        validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
        setTimeout(()=> {
          navigate(-1)
        }, 1000);
    } catch (error) {
        console.log(error)
    }
  } 
  const validateHandler = useRef([]);

  
  const [articlesInfo, setArticlesInfo] = useState([]);
  useEffect(()=>{
    dateInput.current.setValue(date.toISOString().substring(0, 10));
    
    const fetchFournisseurs = async () => {
      const response = await fournisseurFinder.get('/');
      setFourList(response.data.data.fournisseurs);
    }

    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots); 
      }
      catch (err) {
        console.log(err);
      }
    }
    
    const getArticles = async ()=>{
      const response = await depotsStockFinder.get("/")
      const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierepremiere'
      }).map(({ type, ...rest }) => rest);
      articlesRef.current = filtredArticles;
      setArticlesInfo(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }));
      articleTable.current.setAllData(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }))
   } 
    const fetchBonAvoir = async () => {

      const response = await bonAvoirFinder.get('/achat');
       let code=1;
      if(response.data.data.code.max){
        code = parseInt(response.data.data.code.max) + 1

      }
      codeInput.current.setValue(code);
    }

    fetchDepots()
    fetchBonAvoir();
    fetchFournisseurs();
    getArticles();
  },[]);

  const addSelectedArticles = async () => {
    try{
      const articles = [];
      const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
      for(let i=0;i<data.length;i++){
        let temp = data[i];
          articles.push({
            id: temp.id,
            codeArticle: temp.codeArticle,
            designation: temp.designation,
            quantityart: temp.quantityart || 0,
            puAchat: temp.puAchat,
            remise: temp.remise || 0,
            lot_nbr: temp.lot_nbr || "",
            total: (temp.puAchat * temp.quantityart - ((temp.puAchat * temp.quantityart)*temp.remise)/100)|| 0,
            quantity: temp.quantity,
            observation: temp.observation || ""
        })
      }
      setSelectedArticlesData(articles)
    }catch(err){
      console.log(err)
    }
  }

  const actOnValidation = async (obj) => {

    tableRef.current.updateRow({
      id: obj.id,
      codeArticle: obj.codeArticle,
      designation: obj.designation,
      quantityart: obj.quantityart || 0,
      puAchat: obj.puAchat || 0,
      remise: obj.remise || 0,
      lot_nbr: obj.lot_nbr,
      total: (obj.puAchat * obj.quantityart - ((obj.puAchat * obj.quantityart)*obj.remise)/100),
      quantity: obj.quantity,
      observation: obj.observation
    });
    
    calculateTotal()
  }
  const calculateTotal = (data) => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puAchat * article.quantityart, 0);
    totalSansRM.current.setValue((amountBeforeDiscounts));

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
      const puRemise = (article.puAchat - (article.puAchat * (article.remise / 100)));
      return accumulator += (((article.puAchat - puRemise) * article.quantityart));
    }, 0);


    // Display the final amount
    total.current.setValue((amountBeforeDiscounts - articlesDiscountsTotal));

    updateRMInput(articles)
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puAchat * article.quantityart - ((article.puAchat * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const [depots, setDepots] = useState([]);
  const depot = useRef(null);
  
  const handelValidate= () => {
    displayConfirmationDialog.current.display();
  }

  const checkCode = async (value)=>{
    try{
      const response = await bonAvoirFinder.get(`/${value}/achat`)
      if(response.data.data[0]?.code === value){
        return true
      }else
        return false
    }catch(err){
      console.log(err)
    }
  }

  const checkQuantityStq = (articles)=>{
    return articles.filter((article)=>{
      return parseInt(article.quantityart) > parseInt(article.quantity)
    }).map((article)=>{
      return {name:'remise',message:`la quantité de code d'article ${article.codeArticle} que vous voulez avoir est supérieure à la quantité que vous avez`};
    })
  }

  const checkErrs = async ()=>{
    let alerts = [];
    setDate(document.getElementById("date").value);
    let code = document.getElementById("code").value;
    let fournisseur = document.getElementById("fournisseur").value;
    let id_fournisseur = fournisseur && getSelectedFournisseur.current.getSelectedItem().id;
    let remise = document.getElementById("remise").value
    
    if(!remise){
      alerts.push({name:'remise',message:'Assurez que la remise is bien saisez'});
      remiseR.current.setBorderRed();
    }
    if(!code){
      alerts.push({name:'code',message:'Assurez que la code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(!id_fournisseur){
      alerts.push({name:'fournisseur' ,message:'Assurez que la fournisseur est bien selectionez'});
      getSelectedFournisseur.current.setBorderRed();
    }
    if( await checkCode(code) === true){
      alerts.push({name:'code',message:'le N° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0){
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    }
    let checkQuantity = checkQuantityStq(tableRef.current.getAllData());
    if( checkQuantity.length > 0){
      alerts.push(...checkQuantity)
    }
    
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    handelValidate();
  }

  const totalTax = useRef()
  const handleFournisseur = async (fournisseur) => {
    // const response = await reglementFournisseurFinder.get(`/${fournisseur.id}`);
    // const bonsAchatsF = await articlesAcheteFinder.get(`/${fournisseur.id}/reglementFournisseur`)

    // const amount = response.data.data.reglementsFournisseur.reduce((accumulator, currentItem) => {
    //   return accumulator + currentItem.montant;
    // }, 0);

    // totalTax.current.setValue(String(Math.abs(amount - bonsAchatsF.data.data.amount)))
}

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    const article = articlesRef.current.find(item => item.id === itemToDeleteId)
    articleTable.current.addItem({
        id: article.id,
        codeArticle: article.code,
        codeFamille: article.famille_code || "",
        designation: article.designation,
        depotName: article.depot_name,
        lotNbr: article.ds_lot_nbr,
        quantity: article.ds_quantity || 0,
        ug: article.ug,
        puAchat: article.pu_achat,
        prixVenteGros: article.prix_vente_gros,
        puDetail: article.pu_detailer,
        prixVenteDemiGros: article.prix_vente_semi_gros,
        puSpecial: article.pu_special,
        unitMes: article.unit_mes,
        qtyCart: article.qte_cart,
        nbCart: article.nb_cart,
        puFact: article.pu_fournisseur,
        tva: article.tva,
        stkInit: article.stq_init,
        prixAchatInit: article.prix_achat_init,
        prixVenteInit: article.prix_vente_init,
        stockAlert: article.stock_alert,
        weight: article.weight,
        volume: article.volume,
        blocked: article.blocked ? 'Oui' : 'No',
        observation: article.observation
    });
  }

  const handleDepot =(selectedDepot)=>{
    articleTable.current.setAllData(articlesInfo.filter((article)=>{
      return (selectedDepot.name === article.depotName)
    }))
    if(selectedDepot.id !== depot.current.getSelectedItem().id)
      tableRef.current.setAllData([]);
  }
  const checkDepot = () =>{
    const depots = document.getElementById('depot').value
    if(!depots){
      errorsHandler.current.errorsHandler([{name:'depot' ,message:'Assurez que le Depot est bien selectionez'}]);
      depot.current.setBorderRed();
      return
    }
    displayDialog.current.display()
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={"Bon d'avoir Achat"}>
    <form onSubmit={handleSubmit}>
    <div className={styles['body-container']}>
     <div className={styles['main-body']}>
      <div className={styles['actions-container']}>
        <div className={styles['row-evenly']}>
          <InputText 
            holderText={"Ajouter fournisseur"} 
            width={"50%"} 
            labelPosition={true}
            type={"text"} 
            label={'Fournisseur'}
            id={'fournisseur'}
            searchData={fourList}
            onSelectItem={handleFournisseur}
            path={"/ajouter_fournisseur"}
            btnName={'Ajouter Nouveau fournisseur'} 
            ref={getSelectedFournisseur}
          />
          <SubInfo 
            lined={true}
            label={"Sold Fournisseur"}
            unit={"DZD"}
            ref={totalTax}
          />
          <Button 
            path={"../reglement_fournisseur"} 
            name={'Reglement Fournisseur'} 
            width={"300px"} 
            icon={icons.reglementFournisseur}
          />
        </div>

        <div className={styles['row-evenly']}>
          <Button 
            width={"200px"} 
            name={"Ajouter Article"} 
            onclick={checkDepot} 
          />
          <InputText
              holderText={"Code Bon Vente"} 
              width={"295px"}
              type={"text"}
              id={'bon'}
            />
        </div>
        <div style={{height: "435px"}}>
          <Table 
            thead={metaData.table} 
            tbody={selectedArticlesData} 
            confirmBeforeDeletion={false}
            edit={true} 
            remove={true} 
            ref={tableRef}
            actOnValidation={actOnValidation}
            handleDelete={handleDelete} 
          />
        </div>
        
      </div>
      
    </div>
    <div className={styles['side-body']}>
    <PageSideBar validateFunc={checkErrs}>
            <div className={styles['col-around']}>
              <div className={styles['col-evenly']}>
                <InputText 
                  id={"code"}
                  label={"N° du bon"} 
                  holderText={"0001"} 
                  width={"100%"} 
                  type={"number"}
                  ref={codeInput}
                  disabled={true}
                />

                <InputText 
                  id={'date'}
                  label={"Date de bon"} 
                  width={"100%"} 
                  type={"date"}
                  ref={dateInput}
                />

                <SelectInput 
                  options={["Espece", "Cheque", "Verement", "Autre"]} 
                  label={"Type de paiement"} 
                  width={"100%"}
                  ref={paymentTypeInput}
                />
                <InputText label={"Depot"} 
                             width={"100%"}
                             holderText={'Depot'}
                             id={'depot'}
                             onSelectItem={handleDepot}
                             searchData={depots}
                             ref={depot}/>

              </div>

              <div className={styles['col-evenly']} >
                <SubInfo label={"Total sans remise"} unit={"DZD"} ref={totalSansRM} />

                <InputText 
                  id={"remise"}
                  label={"Remise"} 
                  currency={true}  
                  currencyValue={"%"} 
                  defaultValue={'0'}
                  width={"100%"} 
                  type={"number"} 
                  holderText={"0.00"}
                  ref={remiseR}
                  reportChange={updateTaxes}
                />

                <SubInfo label={"Total"} unit={"DZD"} ref={total}/>
              </div>
            </div>
          </PageSideBar>  
    </div>
    <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
      <AdderTable head={metaData.adderTable} body={articlesData} ref={articleTable} />
    </Dialog>
    </div>
    <ConfirmationButton ref={displayConfirmationDialog}/>
   </form>
   <ErrorContainer ref={errorsHandler}/>
   <ValidatedMessages ref={validateHandler}/>
   </PageLayout>
  )
}

export default BonAvoirAchat

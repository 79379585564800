import React, { useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import styles from './ficheStockDetaille.module.scss'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import { useAuth } from '../../contexts/AuthContext'

const FicheStockDetaille = () => {
    const { state: authState } = useAuth();
    const { user } = authState;
    
       const thead = [
                {name:"Code",width: 190},
                {name:"Code Familly",width: 190},
                {name:"Designation",width: 490},
                {name:"Stock",width: 190},
                {name:"Pu Achat",width: 190},
                {name:"Prix Grossiste",width: 190},
                {name:"PPA",width: 190}
            ]
    const [ inputValue , setInputValue ] = useState('')
    const [search, setSearch] = useState([]);
    const [checked , setChecked ] = useState(false);
    const handelCheckbox = ()=>{
        setChecked(!checked);
    }
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Fiche de Stock Detaillè'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-end']}>
                <Button 
                    name={"Imprimer"}
                    icon={icons.impr}
                    width={"max-content"}
                    />
            </div>
                <div>
                    <DetailsSectionContainer name={"Détail de l'article"} table={true}>
                        <div className={styles['row-between']}>
                            <InputText label={"Code"} width={"25%"} type={"text"} holderText={"001"}/>
                            <InputText label={"Désignation"} width={"70%"} type={"text"} holderText={"Désignation"}/>
                        </div>
                    </DetailsSectionContainer>
                </div>
                <div>
                    <DetailsSectionContainer name={"Détail de stock et prix"} addOption={true}>
                        <InputText label={"Stock initial"} width={"100%"} type={"number"} holderText={"001"}/>
                        <InputText label={"Prix d'achat initaial"} width={"100%"} type={"number"} holderText={"001"}/>
                        <InputText label={"Quantité en Stock "} width={"100%"} type={"text"} holderText={"001"}/>
                    </DetailsSectionContainer>
                </div>
                <div>
                    <DetailsSectionContainer name={"Détail des achats et transférer"} addOption={true}>
                        <InputText label={"Quantité globale achetéess"} width={"100%"} type={"number"} holderText={"001"}/>
                        <InputText label={"Quantité globale transférer"} width={"100%"} type={"text"} holderText={"Désignation"}/>
                    </DetailsSectionContainer>
                </div>
                <div>
                    <DetailsSectionContainer name={"Détail des ventes et transférer"} addOption={true}>
                        <InputText label={"Quantité globale Vendues"} width={"100%"} type={"number"} holderText={"001"}/>
                        <InputText label={"Quantité globale réception transférer"} width={"100%"} type={"text"} holderText={"Désignation"}/>
                    </DetailsSectionContainer>
                </div>
                <InputText 
                    icon={icons.search}
                    type={"text"}
                    holderText={"Chercher"}
                    label={"Chercher"}
                    width={"30%"}
                    />
                <div style={{height: '300px'}}>
                    <Table thead={thead} inputValue={inputValue} tbody={search} />
                </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default FicheStockDetaille
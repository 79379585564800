import React from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import axios from 'axios';

const SignUp = () => {
    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios.post('http://localhost:4010/api/v1/auth/register', {
            name: document.getElementById('name').value,
            address: document.getElementById('address').value,
            username: document.getElementById('email').value,
            password: document.getElementById('password').value
        });

        console.log('sending is done');
    }

    return (
        <>
            <h1>Entreprise Register Form</h1>

            <br></br>

            <form onSubmit={handleSubmit}>
                <InputText 
                    width={'30%'}
                    label={'Name *'} 
                    id={'name'}
                />

                <InputText 
                    width={'30%'}
                    label={'Address'} 
                    id={'address'}
                />

                <InputText 
                    width={'30%'}
                    label={'Email *'} 
                    id={'email'}
                />

                <InputText 
                    width={'30%'}
                    label={'Password *'} 
                    id={'password'}
                />

                <br></br>

                <Button 
                    width={'30%'}
                    name={'submit'}
                    type={'submit'} 
                />
            </form>
        </>
    );
}

export default SignUp;

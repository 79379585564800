import React, { useRef, useState, useEffect } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatVentesGlobal.json'
import styles from './etatVentesGlobal.module.scss'
import Button from '../../components/button/Button'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatVentesGlobal = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const [ inputValue , setInputValue ] = useState('');
    const [data,setData] = useState([]);

    const total_vente = useRef();
    const total_retour = useRef();
    const total_comptoir = useRef();
    const global_amount = useRef();
    
    useState(()=>{
      const fetchArticlesVenteAvoir = async ()=>{
      const bons_vente = await bonVentesFinder.get(`/`);
      const bons_avoir = await bonsAvoirFinder.get('/vente');

      // get the max id from achat receipts
      let max_id = bons_vente.data.data.max;
      let bonsAchat = [], bonsAvoir = [];
      let montant_ventes = 0.0;
      let montant_retour = 0.0;

      bons_vente.data.data.bons.map(bon => {
        let montant = bon.amount * (1 - (bon.remise/100));
        montant_ventes += montant;
        bonsAchat.push({
          id: bon.id,
          code: bon.code,
          date: bon.date.substring(0,10),
          achat_av: 'Vente',
          montant,
          code_client: bon.client_code,
          client : bon.client_name
      });
    });
      bons_avoir.data.data.bons.map(bon =>{

        let montant = bon.amount * (1 - (bon.remise/100));
        montant_retour += montant;

        let id = bon.id + max_id;
        bonsAvoir.push({
          id,
          code: bon.code,
          date: bon.date.substring(0,10),
          achat_av: 'Avoir',
          montant
        });
      });

      total_vente.current?.setValue(montant_ventes);
      total_retour.current?.setValue(montant_retour);  
      total_comptoir.current?.setValue('0');
      global_amount.current?.setValue(montant_ventes - montant_retour);
      setData([...bonsAvoir,...bonsAchat]);
      }
      fetchArticlesVenteAvoir();
    },[]);  

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Global'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <div className={styles['row']}>
                  <InputText label={"Jour 1"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Jour 2"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                </div>
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                    />
                </div>
              </div>
              <div style={{height: "300px"}}>
              <Table thead={metaData.table} tbody={data} 
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                date1={date1} date2={date2} />
              </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
                   <InputText  
                    holderText={"Montant"} 
                    setInputValue={setInputValue} 
                    inputValue={inputValue} 
                    type={"number"} 
                    label={'Montant des Ventes'}
                    ref={total_vente}
                    disabled
                    />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant des Retours'}
                  ref={total_retour}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant des Comptoirs'}
                  ref={total_comptoir}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  setInputValue={setInputValue} 
                  inputValue={inputValue} 
                  type={"number"} 
                  label={'Montant Global'}
                  ref={global_amount}
                  disabled
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatVentesGlobal

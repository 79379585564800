import React, { useEffect, useRef, useState } from 'react';
import styles from './addUser.module.scss';
import InputText from '../../../components/input-text/InputText';
import rolesFinder from '../../../api/rolesFinder';
import Button from '../../../components/button/Button';
import authFinder from '../../../api/authFinder';
import SettingsPage from '../SettingsPage';

const AddUser = () => {
    const [ roles, setRoles ] = useState([]);

    useEffect(() => {
        const fetchRoles = async () => {
            const response = await rolesFinder('/');
            setRoles(response.data.data.roles);
        }

        fetchRoles();

    }, []);

    const roleInputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        checkErrors();

        try {
            await authFinder.post('/register', {
                firstName: document.getElementById('fname').value,
                lastName: document.getElementById('lname').value,
                birthday: document.getElementById('bday').value,
                address: document.getElementById('address').value,
                phone: document.getElementById('phone').value,
                idRole: roleInputRef.current?.getSelectedItem().id,
                username: document.getElementById('email').value,
                password: document.getElementById('pw').value
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    const checkErrors = () => {
        const pw = document.getElementById('pw').value;
        const re_pw = document.getElementById('retyped-pw').value;

        if (pw !== re_pw) {
            console.log(`Passwords don't match`);
            return;
        }
    }

    return (
        <SettingsPage>
            <form onSubmit={handleSubmit}>
                
                <div className={styles['form']}>
                    <InputText 
                        label={'Nom'} 
                        holderText={'Nom'}
                        width={'75%'}
                        id={'fname'} 
                    />

                    <InputText 
                        label={'Prenom'} 
                        holderText={'Prenom'}
                        width={'75%'}
                        id={'lname'} 
                    /> 

                    <InputText 
                        label={'Date de naissance'} 
                        holderText={'Nom'}
                        width={'75%'}
                        id={'bday'} 
                        type={'date'} 
                    /> 

                    <InputText 
                        label={'Address'} 
                        holderText={'Address'}
                        width={'75%'}
                        id={'address'} 
                    />

                    <InputText 
                        label={'Telephone'} 
                        holderText={'0611223344'}
                        width={'75%'}
                        id={'phone'} 
                    /> 

                    <InputText 
                        label={'Role'} 
                        holderText={'Role'}
                        width={'75%'}
                        searchData={roles} 
                        autoComplete={'off'}
                        ref={roleInputRef}
                        id={'role'}
                    /> 

                    <InputText 
                        label={'Email'} 
                        holderText={'example@gmail.com'}
                        width={'75%'}
                        id={'email'} 
                    />

                    <InputText 
                        label={'Mot de passe'} 
                        holderText={''}
                        width={'75%'}
                        type={'password'}
                        id={'pw'}
                    /> 

                    <InputText 
                        label={'Retaper le mot de passe'} 
                        holderText={''}
                        width={'75%'}
                        type={'password'}
                        id={'retyped-pw'} 
                    /> 
                </div>

                <div className={styles['submit-btn']}>
                    <Button 
                        width={'25%'} 
                        name={'Submit'} 
                        type={'submit'}
                    />
                </div>

            </form>
        </SettingsPage>
    );
}

export default AddUser;

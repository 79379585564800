import React, { useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import SelectInput from '../../components/select-input/SelectInput'
import styles from './ficheStockProduit.module.scss'
import { useAuth } from '../../contexts/AuthContext'

const FicheStockProduit = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data , setData] = useState([])
  const [search, setSearch] = useState([])
  const [value, SetValue] = useState(0);
    const options =["Les Vents","les Achats","les Transferts", "les Receptions Des Transferts"
  ]
    const thead = [
    [
      {name:"Oper",width: 190},
      {name:"Date",width: 190},
      {name:"Qte",width: 190},
      {name:"Prix",width: 190},
      {name:"Client",width: 490},
      {name:"N° BL",width: 190},
      {name:"Code Cli",width: 190}
    ],
    [
      {name:"Oper",width: 190},
      {name:"Date",width: 190},
      {name:"Qte",width: 490},
      {name:"Prix",width: 190},
      {name:"Fournisseur",width: 190},
      {name:"N° BL",width: 190},
      {name:"Code Fou",width: 190},
      {name:"Date de Peremption",width: 190}
    ],
    [
      {name:"Oper",width: 190},
      {name:"Date",width: 190},
      {name:"Qte",width: 490},
      {name:"Prix",width: 190},
      {name:"Client",width: 190},
      {name:"N° BL",width: 190},
      {name:"Code Cli",width: 190}
    ],
    [
      {name:"Oper",width: 190},
      {name:"Date",width: 190},
      {name:"Qte",width: 490},
      {name:"Prix",width: 190},
      {name:"Client",width: 190},
      {name:"N° BL",width: 190},
      {name:"Code Cli",width: 190}
    ]
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={"Fiche de Stock de produit"}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <DetailsSectionContainer name={"Détail de l'articl"} stockProduit={true}>
                    <div className={`${styles['grid-space-between']} ${styles['grid-line-1-5']}`}>
                        <Button name={"Ajouter un Article"} />
                        <Button path={'/fiche_periodique'} name={"Fiche périodique"}/>
                    </div>
                    <div className={`${styles['row-around']} ${styles['grid-line-1-5']}`}>
                        <InputText label={"Code"} type={"number"} holderText={"001"}/>
                        <InputText label={"Désignation"} type={"text"} holderText={"Désignation"}/>
                    </div>
                </DetailsSectionContainer>

                <DetailsSectionContainer name={"Détail de l'articl"} stockProduit={true}>
                    <InputText label={"Quantité en Stock"} type={"number"} holderText={"001"}/>
                    <InputText label={"Stock initial"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Prix d'achat"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Prix de Vente"} type={"number"} holderText={"Montant"}/>
                </DetailsSectionContainer>
            </div>
                <div>
                <DetailsSectionContainer name={"Détail des achats et transférer"} stockProduit={true}>
                    <InputText label={"Quantité globale achetée"} type={"number"} holderText={"001"}/>
                    <InputText label={"Quantité globale transférer"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Montant des achats"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Montant des transfert"} type={"number"} holderText={"Montant"}/>
                </DetailsSectionContainer>
                </div>
                <div>
                <DetailsSectionContainer name={"Détail des ventes et transférer"} stockProduit={true}>
                    <InputText label={"Quantité globale Vendues"} type={"number"} holderText={"001"}/>
                    <InputText label={"Montant des Ventes"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Montant des Ventes"} type={"number"} holderText={"Montant"}/>
                    <InputText label={"Montant des réception transférer"} type={"number"} holderText={"Montant"}/>
                </DetailsSectionContainer>
                </div>
                <div className={`${styles['grid-space-between']}`} style={{paddingTop: '2rem'}}>
                    <InputText label={"Recherche par Clien"} type={"text"} holderText={"Client"} labelPosition={true}/>
                    <SelectInput options={options} setOption={SetValue} width={'33.3%'}/>
                </div>
                <div style={{height: '300px'}}>
                  <Table thead={thead[value]} tbody={search} />
                </div>
          </div>
    </PageLayout>
  )
}

export default FicheStockProduit
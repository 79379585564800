import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationProduction.module.scss'
import productionFinder from '../../api/productionFinder'
import { useState } from 'react'
import metaData from './consultationProduction.json'
import Printing from '../../components/printing/Printing'
import articlesProduits from '../../api/articlesProduits'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';
import { dateFormatter } from '../../utils/dateFormatter'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const ConsultationProduction = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);

  const handleDelete =  (key,obj)=> {
    try {
      const deletedArticle = async (id)=>{
        const pro = productionFinder.delete(`/${id}`);
        console.log('in delete'+pro)
        return pro;
      }
      const article = deletedArticle(key);
      
      const updatedData = data.filter((item) => item['id'] !== key);
      setData(updatedData);

      } catch (err) {
        console.log(err);
      }
  }  
  const actOnValidation = async (obj) => {
    console.log('ss')
  }

  const actOnValidationArticle = async (obj) => {
    const date = new Date(obj.date_peremtion);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);
    
    try{
      const updatedArticle = async (id) =>{
        return await articlesProduits.put(`${parseInt(obj.id)}`,{
          quantity: obj.quantity,
          expireDate: obj.PUAchat,
          observation: obj.observation
        });
      }
      const article = updatedArticle(obj.id);
    }catch(err){
      console.log(err);
    }

  }

  const handleDeleteArticle =  (key)=> {
    try {
      const deletedArticle = async (id)=>{
        return await articlesProduits.delete(`/${id}`);
      }
      const article = deletedArticle(key);
    } catch (err) {
      
    }
  }


  useEffect(() => {
    const fetchOperations = async () => {
      const response = await productionFinder.get('/');
      setBonsInfo(response.data.data.productions);
      let empty = '-';
      console.log(response.data.data.productions)
      setData(response.data.data.productions.map((bon)=>{
        return(
          {
            id: bon.id,
            code: bon.code,
            operation: bon.operation === 'matierepremiere' ? 'Matiere Premiere': bon.operation === 'fini' ? 'Produit Fini' :'Produit Semi Fini',
            date: dateFormatter(bon.date, 0),
            saisiPar: bon.saisiPar,
            observation: bon.observation || empty,
          }
        );
      }))
    }

    try {
      fetchOperations();
    } catch (err) {
      console.log(err);
    }
    
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([]);
      return
    }
    console.log(bonsInfo)
    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id)
        setDataSuiv(
         element.articlesAdded.map((article,index)=>{
          return {
            id: article.id || index,
            code: article.code,
            designation: article.designation,
            lotNbr: article.lotNbr,
            expire_date: String(article.expire_date.substring(5,7)+'/'+article.expire_date.substring(0,4)),
            quantity: article.quantity,
            observation: article.observation || ''
          }
        })
      )
    })

  }

  // Here you should check how the field are named comming from the backend
  const filterByArr = ['code','operation'];
  
  const handleSearch = (val) => tableRef.current.setFilterInput(val)

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "65%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "10%"},
    {name: "Qte",width: "10%"},
  ]
  
  const [printHeader, setPrintHeader] = useState('');
  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const printRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    setPrintHeader(selectedBon.operation === 'matierePremiere' ? 'Matiere Premiere': selectedBon.operation === 'fini' ? 'Produit Fini' :'Produit Semi Fini')
    const articlesAdded = tableRefSuiv.current.getAllData().map((article)=>{
      return({
            code: article.code,
            designation: article.designation,
            lotNbr: article.lotNbr,
            expire_date: article.expire_date,
            quantity: article.quantity,
        })
    });
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation de Production'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  type={"text"}
                  holderText={"Rechercher"}
                  label={"Rechercher"}
                  labelPosition={true}
                  width={"30%"}
                  reportChange={(val) => handleSearch(val)}
                  />  
                <div className={styles['row-end']}>
                  <Button 
                    icon={icons.impr}
                    width={"300px"}
                    name={'Imprimer'}
                    onclick={handlePrint}
                  />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Liste des operation"} 
                        thead={metaData.table} 
                        tbody={data} 
                        sendSelectedRowInfo={handleClick}
                        singular={true}
                        defaultSearchBy={filterByArr}
                        edit={true} 
                        ref={tableRef}
                        remove={true}
                        handleDelete={handleDelete}
                        actOnValidation={actOnValidation}
                        />
              </div>
              <div><br/><br/></div>
              <div style={{height: '300px'}}>
                <Table 
                  tableTitle={"Detail d'operation"} 
                    ref={tableRefSuiv} 
                    thead={metaData.secondeTable} 
                    tbody={dataSuiv}
                    handleDelete={handleDeleteArticle}
                    actOnValidation={actOnValidationArticle}
                    />
              </div>
            </div>
    </div>
    {isActive && <Printing type={`${printHeader}`} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationProduction;
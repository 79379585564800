import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './chargePeriodique.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';

const ChargePeriodique = () => {
  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  
  const thead = [
    {name:"Data", width: 150},
    {name:"Heure", width: 190},
    {name:"Charge", width: 190},
    {name:"Montant", width: 190},
    {name:"Une Charge", width: 190},
    {name:"Saissie Par", width: 290}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Charge Periodique'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
            labelPosition={true} holderText={"Chercher"}  label={'chercher'}
          margin={'0px 0px 0px 15px'}
          icon={icons.search}/>
          <div className={styles['operations-buttons']}>
              <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
          </div>
        </div>
        <div className={styles['periode']}>
          <InputText width={'12.5%'} type={"date"}  label={'Periode du'}
                      margin={'0px 0px 0px 15px'}/>
          <InputText width={'12.5%'} type={"date"}  label={'Au'}
                      margin={'0px 0px 0px 15px'}/>
        </div>
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} edit={true} remove={true} />
        </div>
        <div className={styles['mantant-chargers']}>
          <div></div>
          <InputText width={'25%'} holderText={"Montant Chargers"} label={'Montant Charges'}
          margin={'0px 15px 0px 0px'}/>
        </div>
      </div>
    </PageLayout>
  )
}

export default ChargePeriodique;

import React from 'react'
import styles from'./versmentRetraitBanque.module.scss'
import InputText from '../../components/input-text/InputText'
import SelectInput from '../../components/select-input/SelectInput'
import PageLayout from '../../components/page-layout/PageLayout'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'

const VersmentRetraitBanque = () => {
    const options =[
        {
          value : "Espece",
          name : "Espèce"
        },
        {
          value : "Cheque",
          name : "Cheque"
        }
      ]
  return (
    <PageLayout nameLayout={'Versment / Retrait de la Banque'} icon={icons.reglementClient.secondary}>
        <div className={styles['ajouter-client-container']}>
        <div className={styles['ajouter-client']}>
            <div className={styles['ajouter-client-line-1']}>
                <SelectInput options={options} width={"100%"} label={"Compte Bancaire"} />
            </div>
            <div className={styles['ajouter-client-line-1']}>
                <SelectInput options={options} width={"100%"} label={"Operation"} />
            </div>
            <div className={styles['ajouter-client-line-1']}>
                <InputText width={"30%"} label={"Date"}type={"date"} />
                <InputText width={"70%"} label={"Montant"} holderText={"Montant"} type={"number"} />
            </div>
            <div className={styles['ajouter-client-line-1']}>
                <InputText width={"100%"} label={"Observation"} holderText={"Observation"} />
            </div>
        </div>
        <div className={styles['ajouter-client-operation-container']}>
            <div></div>
            <div className={styles['ajouter-client-operation']}>
                <Button name={'Annuler'} switchbtn={true} width={120}/>
                <Button name={'Ajouter'} width={150}/>
            </div>
        </div>
        </div>
    </PageLayout>
  )
}

export default VersmentRetraitBanque

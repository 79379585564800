import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import styles from './depote.module.scss';
import Button from '../../components/button/Button';
import depotFinder from '../../api/depotFinder';
import Dialog from '../../components/dialog/Dialog';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';
import SelectInput from '../../components/select-input/SelectInput'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'

const Depote = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const displayDialog = useRef();

  useEffect(() => {
    const getDepots = async () => {
      try {
        const response = await depotFinder.get('/');

        const depotsArr = response.data.data.depots.map((depot)=>{
          return(
              {
                  id:depot.id,
                  name: depot.name,
                  address: depot.address,
                  tel: depot.phone
              }
          )
      })

        setData(depotsArr);
      } catch (err) {
        console.log(err);
      }
    }
    getDepots();
  }, []);

  const thead = [
    {name:"Nom", width: 290},
    {name:"Adresse", width: 490},
    {name:"Tel",width: 490}
  ]

  const getDepots = async () => {
    try {
      const response = await depotFinder.get('/');
      const depots = response.data.data.depots.map((depot)=>{
        return(
          {
            name: depot.name,
            address: depot.address,
            tel: depot.phone
          }
        )
      })
      setData(depots);
    } catch (err) {
      console.log(err);
    }
  }
  const errorsHandler= useRef([]);
  const nameR = useRef();

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const alert= [];
    const name = (document.getElementById('name').value).trim();
    const type = depotType.current.getSelectedOption().toLowerCase()
    if(!name){
      alert.push({name:'name',message:'Assurez que le Nom is bien saisez'});
      nameR.current.setBorderRed();
    }
    if(type === 'main'){
      const exist = await depotFinder.get('/exist');
      if(exist.data.data.exist){
        alert.push({name:'name',message:'Vous avez déjà un dépôt principal'});
      }
    }
    if(alert.length > 0) {
      errorsHandler.current.errorsHandler(alert);
      displayDialog.current.display();
      return;
    }
    await depotFinder.post('/',
    {
      name: document.getElementById('name').value,
      address: document.getElementById('address').value,
      tell: document.getElementById('tell').value,
      type: depotType.current.getSelectedOption().toLowerCase()
    })
    getDepots();
    try {
    } catch (error) {
        console.log(error)
    }
}
const options = [
  "Main",
  "Normal"
]
const depotType = useRef();
const filterByArr = ['name','address'];
const [inputValue, setInputValue] = useState('');
  const handleSearch = (val) => {
    setInputValue(val);
  }
    
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Les Dépôts'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Chercher"}
                labelPosition={true}
                reportChange={(val) => handleSearch(val)}
                width={"50%"}
                />
            <div className={styles['row-end']} style={{width: '40%'}}>
              <Button 
                  width={"250px"}
                  name={'Ajouter Dépôts'}
                  onclick={()=>displayDialog.current.display()}
              />
            </div>
          </div>
          <div style={{height: '400px'}}>
              <Table thead={thead}
                      filterInput={inputValue}
                      filterByArr={filterByArr} 
                      tbody={data} 
                      edit={user?.permissions.includes(permissions.canEditInTable)} 
                      remove={user?.permissions.includes(permissions.canDeleteFromTable)}/>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
            <Dialog width={'500px'} ref={displayDialog} type={true}>
                <InputText 
                    id={"name"}
                    width={'100%'} 
                    height={'100%'}
                    ref={nameR}
                    label={'Nom depot'} 
                    holderText={'Depot'}
                  />
                  <InputText 
                    id={"address"}
                    width={'100%'} 
                    height={'100%'}
                    label={'Address'} 
                    holderText={'Address'}
                  />
                  <InputText 
                    id={"tell"}
                    width={'100%'} 
                    height={'100%'}
                    label={'phone'} 
                    holderText={'phone'}
                  />
                  <SelectInput 
                    id={"main"}
                    options={options} 
                    width={'50%'} 
                    label={'Type de Depot'}
                    ref={depotType}
                />
            </Dialog>
            </form>
      </div>
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default Depote
import React from 'react';
import Mod from '../mod/Mod';
import styles from './mods.module.scss';
import gestions from '../../routes/home/homeData';
import { useAuth } from '../../contexts/AuthContext';

const Mods = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
  return (
    <div className={styles['mods-container-2-line']}>
      <div className={styles['mods-container']}>
        {
          gestions.filter((item)=>{
            if (item.assignedPermissions && item.assignedPermissions.length > 0) {
              return item.assignedPermissions.find(permission => user?.permissions.includes(permission));
            }
            else return true;
          })
          .map((gestion, index) => <Mod key={index} modInfo={gestion} />)
        }
      </div>
    </div>
  );
}

export default Mods;

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import styles from './subInfo.module.scss'
import { spacedNumFormat } from '../../utils/numberFormatter';

const taxesContainer = forwardRef((props, ref) => {
  const {
    label,
    unit = "DZD",
    lined
  } = props;

  const [value,setValue]= useState(0);
  
  useImperativeHandle(ref, () => ({
    getValue: () => value,
    setValue: (val) => setValue(val)
  }));
      
  return (
    <div className={ lined && styles['container-lined'] || styles['container']}>
      <p>{label}</p>
      <div className={styles['end']}>
        <p id='val'>{typeof value === 'number' ? spacedNumFormat(value) : spacedNumFormat(Number(value))}</p>
        <p id='unit'>{unit}</p>
      </div>
    </div>
  )
})
export default taxesContainer


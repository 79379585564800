import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatParAgentCommercial.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EtatParAgentCommercial = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const thead = [
    {name:"Type Bon",width: 190},
    {name:"N° Bon",width: 190},
    {name:"Date",width: 190},
    {name:"Montant",width: 190},
    {name:"Code client",width: 190},
    {name:"Client",width: 490},
    {name:"Wilaya",width: 190},
    {name:"Adresse",width: 490}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat Par Agent Commercial'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <InputText 
                  icon={icons.search}
                  type={"text"}
                  holderText={"Agent Commercial"}
                  label={"Agent Commercial"}
                  width={"40%"}
                  />
                
                <div className={styles['row']}>
                  <InputText
                    label={"Joure 1"}
                    width={"max-content"}
                    type={"date"}
                    />
                    <InputText
                    label={"Joure 2"}
                    width={"max-content"}
                    type={"date"}
                    />
              </div>
              <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={search} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
            <div className={styles['row-end']} style={{width: '100%'}}>
                <InputText
                    label={"Montant des Ventes"}
                    holderText={"Montant des Ventes"}
                    width={"max-content"}
                    type={"text"}
                    />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatParAgentCommercial
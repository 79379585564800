import React from 'react';
import styles from './loadingPage.module.scss';
import { icons } from '../../media/mediaHolder';

const LoadingPage = () => {
  return (
    <div className={styles['container']}>
      <section className={styles['message-container']}>
        <div className={styles['loader-container']}>
            <img src={icons.loading} alt="loading" className={styles['loading-icon']} />
            <img src={icons.app} alt="loading" className={styles['app-icon']} />
        </div>
        <h1 className={styles['message']}>Loading</h1>
      </section>
    </div>
  );
};

export default LoadingPage;

import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationboncommande.module.scss'
import bonCommandeFinder from '../../api/bonCommandeFinder'
import articlesCommanderFinder from '../../api/articlesCommanderFinder'
import { useState } from 'react'
import metaData from './consultationBonCommande.json'
import Printing from '../../components/printing/Printing'
import Dialog from '../../components/dialog/Dialog'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'; 
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const ConsultationBonCommande = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);
  useEffect(() => {
    const fetchBonsCommande = async () => {
      const response = await bonCommandeFinder.get('/');
      console.log(response.data.data)
      setBonsInfo(response.data.data.bons);
      let empty = '-';
      setData(response.data.data.bons.map((bon)=>{
        return(
          {
            id: bon.id,
            code: bon.code,
            date: bon.date.substring(0, 10) || empty,
            codeFournisseur: bon.fournisseur_code || empty,
            Fournisseur: bon.fournisseur_name || empty,
            blocked: bon.articlesAdded[0].fournisseurs_blocked ? 'Oui' : 'Non',
            saisiPar: bon.user_name || empty,
            observation: ''
          });
      }))
    }
    fetchBonsCommande();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([]);
      return
    }

    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id)
        setDataSuiv(
         element.articlesAdded.map((article,index)=>{
          return {
            id: article.id || index,
            code: article.code,
            designation: article.designation,
            quantity: article.quantity,
            PUAchat: article.prix,
            total: article.prix * article.quantity,
            observation: article.observation,
          }
        })
      )
    })
  }

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['code'];
  const handleSearch = (val) => {
      setInputValue(val);
  }
  const PrintingColumntype1 = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Qte",width: "5%"},
    {name: "PU",width: "5%"},
    {name: "Total",width: "10%"},
  ]
  const PrintingColumntype2 = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "80%"},
    {name: "Qte",width: "10%"},
  ]
  const [PrintingColumns, setPrintingColumns] = useState(PrintingColumntype1);

  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = (type)=>{
    const rowInfo = tableRef.current.getSelectedItems();
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    const articlesAdded = type === "prix" ? tableRefSuiv.current.getAllData().map((article)=>{
      return({
            code: article.code,
            designation: article.designation,
            quantity: article.quantity,
            PUAchat: article.PUAchat,
            total: article.total,
        })
    }):tableRefSuiv.current.getAllData().map((article)=>{
      return({
            code: article.code,
            designation: article.designation,
            quantity: article.quantity,
        })
    });
    setPrintingColumns(type === "prix" ? PrintingColumntype1: PrintingColumntype2)
    let data = {}
    const ROWS_PER_PAGE = 15; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      type: type === "prix" ? true : false,
      validerPar: rowInfo[0].saisiPar
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    displayDialog.current.noDisplay()
    
  }
  const errorsHandler= useRef([]);
  const displayDialog = useRef([]);
  const check = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    displayDialog.current.display()
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Bon Commande'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Par N° Bon"}
                label={"Chercher"}
                labelPosition={true}
                width={"30%"}
                reportChange={(val) => handleSearch(val)}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  onclick={()=>check()}
                  />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Liste Bon Commande"} 
                        thead={metaData.thead_bonCommande} 
                        tbody={data} 
                        sendSelectedRowInfo={handleClick}
                        singular={true}
                        ref={tableRef}
                        filterInput={inputValue}
                        filterByArr={filterByArr}
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)}/>
              </div>
              <div><br/><br/></div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Détail de Bon"} ref={tableRefSuiv} thead={metaData.thead_detailleBon} tbody={dataSuiv} />
              </div>
            </div>
    </div>
    {isActive && <Printing type={'Bon De Commande'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <Dialog width={"fit-content"} ref={displayDialog} switchStyle={true}>
      <div style={{display: 'flex', gap: "20px", marginBottom: '10px'}}>
        <Button 
          width={"250px"}
          name={'Imprimer Avec Prix'} 
          onclick={()=>handlePrint('prix')}
          />
        <Button 
          width={"250px"}
          name={'Imprimer Sans Prix'} 
          onclick={()=>handlePrint('sansPrix')}
          />
      </div>
    </Dialog>
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationBonCommande;
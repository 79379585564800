import React, { useEffect, useRef, useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './listFournisseur.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import fournisseurFinder from '../../api/fournisseurFinder'
import reglementFournisseurFinder from '../../api/reglementFournisseurFinder';
import Printing from '../../components/printing/Printing.js';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js'
import metaData from './metaData.json';
import wilayasFinder from '../../api/wilayasFinder.js';

const ListFournisseur = () => {
  const [ data, setData ] = useState([]);

  const { state:authState } = useAuth();
  const { user } = authState;
  
  useEffect(()=>{
    const getWilayas = async ()=>{
      const response = await wilayasFinder.get("/");
      metaData.table.find(el => el.name === "Wilaya").children = response.data.data.wilayas;
    }
    const getFournisseurs = async ()=>{
      const response = await fournisseurFinder.get("/");
      setData(response.data.data.fournisseurs);
    }
    getWilayas();
    getFournisseurs();
  },[])


  const handleDelete =  (key)=> {
    fournisseurFinder.delete(`/${key}`);
    const updatedData = data.filter((item) => item['id'] !== key);
    setData(updatedData);
  }

  useEffect(()=>{
    metaData.table.find(el => el.name === "Bloque?").children = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
  },[]);

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['name'];
  const handleSearch = (val) => {
      setInputValue(val);
  }

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Fournisseur",width: "50%"},
    {name: "Address",width: "40%"},
  ]
  
  const tableRef = useRef();
  const printRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id){
      return
    }
    setIsActive(true);
    const fournisseursAdded = tableRef.current.getSelectedItems().map((fournisseur)=>{
      return({
            code: fournisseur.code,
            name: fournisseur.name,
            address: fournisseur.address === "" ? "/" : fournisseur.address,
        })
    });
    const ROWS_PER_PAGE = 30; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(fournisseursAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      fournisseursAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  const actOnValidation = async (obj) => {
    console.log(obj)
    console.log(obj.personne_contact)
    await fournisseurFinder.put(`${parseInt(obj.id)}`,{
      fournisseur: obj.name, 
      personne_contact: obj.personne_contact,
      address: obj.address,
      wilaya: obj.wilaya,
      tel: obj.tel,
      fax: obj.fax,
      mobile: obj.mobile,
      email: obj.email,
      code_client: obj.code_client,
      client: obj.client,
      blocked: obj.blocked === "Non" ? false : true,
    })
  }
                 
  return (
    <PageLayout icon={icons.article} nameLayout={'List Fournisseur'}>
      <div className={styles['main-search-bar']}>
        <InputText width={"45%"} holderText={"Chercher Par Nom"}  label={'Chercher Fourniseur'}
                    margin={'0px 0px 0px 15px'} labelPosition={true}
                    icon={icons.search}
                    reportChange={(val) => handleSearch(val)}
                    />
        <div className={styles['operations-buttons']}>
          <Button link={true} path={"../ajouter_fournisseur"} width={"35%"} name={'Insere Nouveau Fournisseur'} icon={icons.insere}/>
          <Button name={'Imprimer'} icon={icons.impr} width={"35%"} onclick={handlePrint} />
          <Button name={'Exporter Vers Excel'} icon={icons.exporter} width={"35%"}/>
        </div>
      </div>
      <div className={styles['table']}>
        <Table thead={metaData.table} handleDelete={handleDelete} 
                tbody={data} 
                ref={tableRef}
                filterInput={inputValue}
                filterByArr={filterByArr}
                actOnValidation={actOnValidation}
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)}/>
      </div>
    {isActive && <Printing type={'List des Fournisseur'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    </PageLayout>
  )
}

export default ListFournisseur;

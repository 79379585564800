import React, { useRef, useState } from 'react'
import styles from './button.module.scss';
import { useNavigate } from 'react-router-dom';

const Button = (props) => {
    const {
      name, 
      icon, 
      switchbtn, 
      width, 
      borderStyle, 
      height, 
      margin, 
      onclick = () => {}, 
      color, 
      path = '',
      type = 'button'
    } = props;
    
    const [ Icon, setIcon ] = useState(icon?.main);
    const [ textClass, setTextClass ] = useState('button-name-main');

  const timeoutRef = useRef();
  const navigate = useNavigate();

  const handleMouseOver = () => {
    if(icon) setIcon(icon.secondary)
    setTextClass('button-name-secondary')
  }

  const handleMouseLeave = () => {
    if(icon) setIcon(icon.main)
    setTextClass('button-name-main')
  }

  const handleClick = () => {
    /* Check that there would be no conflicts when executing the the onclick function and 
      re-routing afterwards */
    onclick();
    if (path) navigate(path);
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setTextClass('button-name-main')
    }, 1000);
  }

  return (
    <>
      {
        switchbtn &&
        <button style={{width, height, margin}} 
                className={styles['custom-button-cancel']}  
                onClick={handleClick}
                type={type}
                >
          <div>
            <p className={styles['cancel-text']} style={{color}}>{name}</p>
          </div>
        </button>
      }
      {
        !switchbtn &&
        <div style={{width, margin, height}} 
             onMouseOver={handleMouseOver} 
             onMouseLeave={handleMouseLeave} 
             onClick={handleClick}
             className={styles['custom-button-container']}
             >
          <button className={styles['custom-button']} 
                  style={{borderStyle,height}} 
                  type={type}
                  onClick={onclick}
                  >
              {icon && <img src={Icon} alt='icon' className={styles['button-icon']} />}
              <p className={styles[textClass]} style={{color}}>{name}</p>
          </button>
        </div>
      }
    </>
  );
}

export default Button;

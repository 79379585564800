export const dateFormatter = (date, formatType = 0) => {
    if (typeof date === 'string') date = new Date(date);

    if (formatType === 0) {
        // Get day, month, and year components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear(); // Get the last two digits of the year
      
        // Ensure that day and month have two digits
        const formattedDay = (day < 10) ? `0${day}` : day;
        const formattedMonth = (month < 10) ? `0${month}` : month;
      
        // Construct the formatted date string with 'dd/mm/yy' pattern
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

        return formattedDate;
    }
    else if (formatType === 1) {
        // Get month and year components
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Construct the formatted date string with 'MMM YYYY' pattern
        const formattedDate = `${month} ${year}`;

        return formattedDate;
    }
    else if (formatType === 2) {
        // Get month, and year components
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear() % 100; // Get the last two digits of the year
      
        // Ensure that month have two digits
        const formattedMonth = (month < 10) ? `0${month}` : month;
      
        // Construct the formatted date string with 'mm/yy' pattern
        const formattedDate = `${formattedMonth}/${year}`;
    
        return formattedDate;
    }
    else if (formatType === 3) {
        //get the date in format of DD/MM/YYYY
        return new Intl.DateTimeFormat('en-US').format(date);
    }
}

import React from 'react';
import SettingsPage from '../SettingsPage';

const MarginsAndTaxes = () => {
    return (
        <SettingsPage>
            <h1>Margins And Taxes</h1>
        </SettingsPage>
    );
}

export default MarginsAndTaxes;

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import Button from '../../components/button/Button'
import styles from './ControleDatePreremption.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ControleDatePreremption = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const thead = [
    {name:"Code Art.",width: 190},
    {name:"Designation",width: 490},
    {name:"Qte en Stk",width: 190},
    {name:"Date De Premption",width: 190},
    {name:"Nb Jour Depassè",width: 190},
    {name:"PU Achat",width: 190},
    {name:"PU Vente",width: 190},
    {name:"Monatnt en PU Achat",width: 190},
    {name:"Monatnt en PU Vente",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={'Controle de la Date de Prèremption'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
            <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher"}
                label={"Chercher"}
                labelPosition={true}
                width={"50%"}
                />  
                <div className={styles['row-end']}>
                <Button
                  icon={icons.exporter}
                  width={"250px"}
                  name={'Exporter Vers Excel'} 
                  />
                </div>
              </div>
              <div className={styles['row']}>
                  <InputText
                    label={"Date Actuelle"}
                    labelPosition={true}
                    width={"max-content"}
                    type={"date"}
                    />
                </div>
                <div style={{height: "300px"}}>
                  <Table thead={thead} inputValue={inputValue} tbody={search} />
                </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ControleDatePreremption
import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'
import styles from './etatrecapitulatif.module.scss'

const EtatRecapitulatif = () => {
  return (
    <PageLayout icon={icons.article} nameLayout={'La Balance'}>
      <div className={styles['balance-container']}>
        <div className={styles['row-between']}>
            <div style={{display:'flex',gap:'20px'}}>
                <InputText width={'max-content'} labelPosition={true} type={"date"}  label={'Jour 1'}/>
                <InputText width={'max-content'} labelPosition={true} type={"date"}  label={'Jour 2'}/>
            </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
        </div>
        

        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant des Ventes'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant des Achats'}/>
        </div>
        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant des Remises (Par Montant)'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant du Bènifice BRUT'}/>
        </div>
        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant des Encaissements'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant du Sortie de Caisse'}/>
        </div>
        <div className={styles['balance']}>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant des Charge'}/>
            <InputText width={'50%'} type={"number"} holderText={"Montant"} label={'Montant du Bènifice Net'}/>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatRecapitulatif

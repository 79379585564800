import React, { useRef, forwardRef, useState, useImperativeHandle } from 'react'
const NumberConverter = forwardRef((props, ref) => {
    
  const [word, setWord] = useState('');

    const convertToFrenchWords = (num) => {
        // French number words up to 19
        const units = [
          'zéro', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf',
          'dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'
        ];
    
        // French number words for tens
        const tens = [
          '', '', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante', 'quatre-vingt', 'quatre-vingt'
        ];
    
        // Separate integer and decimal parts
        const integerPart = Math.floor(num);
        const decimalPart = Math.round((num - integerPart) * 100);
    
        const convertIntegerToWords = (integer) => {
          // let result = '';
    
          // Handle millions, billions, etc.
          const scales = ['', 'mille', 'million', 'milliard', 'billion', 'billiard']; // Extend as needed
    
          const unitsToString = (number) => {
            return units[number];
          };
    
          const tensToString = (number) => {
            const tensDigit = Math.floor(number / 10);
            const unitDigit = number % 10;
    
            if (tensDigit < 2) {
              return units[number];
            } else if (unitDigit === 0) {
              return tens[tensDigit];
            } else if (tensDigit === 7 || tensDigit === 9) {
              return tens[tensDigit] + '-' + units[unitDigit + 10];
            } else {
              return tens[tensDigit] + '-' + units[unitDigit];
            }
          };
    
          const scalesToString = (number, scale) => {
            return number === 1 ? scale : scale + 's';
          };
    
          const groupToWords = (number, scale) => {
            const hundred = Math.floor(number / 100);
            number %= 100;
    
            let result = '';
    
            if (hundred !== 0) {
              result += unitsToString(hundred) + ' cent ';
            }
    
            if (number !== 0) {
              if (number < 20) {
                result += unitsToString(number);
              } else {
                result += tensToString(number);
              }
            }
    
            if (scale) {
              result += ' ' + scalesToString(number, scale);
            }
    
            return result.trim();
          };
    
          if (integer === 0) {
            return 'zéro';
          }
    
          let result = '';
          let count = 0;
    
          while (integer > 0) {
            if (integer % 1000 !== 0) {
              result = groupToWords(integer % 1000, scales[count]) + ' ' + result;
            }
    
            integer = Math.floor(integer / 1000);
            count++;
          }
    
          return result.trim();
        };
    
        const wordsForInteger = convertIntegerToWords(integerPart);
    
        // Convert the decimal part to words
        const wordsForDecimal = decimalPart > 0 ? `,et ${convertIntegerToWords(decimalPart)}` : '';

        setWord(wordsForInteger + (decimalPart > 0 ? `${wordsForDecimal} centimes` : ''))
        // return wordsForInteger + (decimalPart > 0 ? `${wordsForDecimal} centimes` : '');
      };

      useImperativeHandle(ref, () => ({
        convert: (num) => convertToFrenchWords(num)
      }));

  return (
    <>
      {word}
    </>
  )
})

export default NumberConverter

import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './clientOuFournisseurTransfer.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';

const ClientOuFournisseurTransfer = () => {
  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  
  const thead = [
    {name:"Code", width: 190},
    {name:"Client", width: 190},
    {name:"Adresse", width: 290},
    {name:"Wilaya", width: 190},
    {name:"TEL", width: 190},
    {name:"FAX", width: 190},
    {name:"N Registre", width: 190},
    {name:"N Article", width: 190},
    {name:"N Fiscal", width: 190},
    {name:"N NIS", width: 190},
    {name:"N NIF", width: 190},
    {name:"Bloque?", width: 190}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Client ou Fournisseurs du Tranfert'}>
      <div style={{width: "100%" ,height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
            labelPosition={true} holderText={"Chercher"}  label={'Chercher'}
          margin={'0px 0px 0px 15px'}
          icon={icons.search}/>
          <div className={styles['operations-buttons']}>
            <Button name={'Insere Nov Client'} width={"250px"}/>
          </div>
        </div>
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} edit={true} remove={true} />
        </div>
      </div>
    </PageLayout>
  )
}

export default ClientOuFournisseurTransfer

import React, { useState } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './versementFournisseur.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import { useAuth } from '../../contexts/AuthContext.js';
import permissions from '../../utils/permissions.js';

const VersementFournisseur = () => { 
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  
  const thead = [
    {name:"Code Four.", width: 190},
    {name:"Fournisseur", width: 190},
    {name:"Date Vers.", width: 190},
    {name:"Montant", width: 190},
    {name:"N de Cheque", width: 190},
    {name:"Observation",width: 390}
  ]
                
  return (
    <PageLayout icon={icons.article} nameLayout={'Versement des Fournisseur'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
            labelPosition={true} holderText={"Chercher"}  label={'Chercher'}
          margin={'0px 0px 0px 15px'}
          icon={icons.search}/>
          <div className={styles['operations-buttons']}>
              <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
          </div>
        </div>
        <div className={styles['periode']}>
          <div style={{display: "flex" , width:"50%"}}>
              <InputText width={'25%'} type={"date"}  label={'Periode Du'}
                      margin={'0px 0px 0px 15px'}/>
          <InputText width={'25%'} type={"date"}  label={'Au'}
                      margin={'0px 0px 0px 15px'}/>
          </div>
          <div style={{display: "flex" , width:"50%" , justifyContent: "flex-end"}}>
              <InputText width={'70%'} holderText={"Chercher"}  label={'Chercher'}
              margin={'0px 15px 0px 0px'}
              icon={icons.search}/>
          </div>
          
        </div>
        <div className={styles['table']}>
          <Table thead={thead} inputValue={inputValue} tbody={data} 
            edit={user.permissions.includes(permissions.canEditInTable)} 
            remove={user.permissions.includes(permissions.canDeleteFromTable)} />
        </div>
        <div className={styles['mantant-chargers']}>
          <div></div>
          <div style={{width: '30%' , marginRight:"15px"}}>
              <InputText width={'100%'}type={"number"} holderText={"Mantant"} label={'Montant Verser'}
              margin={'0px 15px 0px 0px'}/>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default VersementFournisseur;

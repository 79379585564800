import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './suivieBanque.module.scss'
import Button from '../../components/button/Button'
import SubInfo from '../../components/sub-info/SubInfo'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const SuivieBanque = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const thead = [
    {name:"Opèr.",width: 190},
    {name:"Date",width: 190},
    {name:"N° Chèque",width: 190},
    {name:"Montant",width: 190},
    {name:"Sold",width: 190},
    {name:"Observation",width: 490}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Suivie de la Banque'}>
        <div className={styles['body-container']} style={{height: '100vh'}}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                    <InputText 
                        icon={icons.search}
                        type={"text"}
                        holderText={"Chercher"}
                        label={"Compte bencaire"}
                        width={"40%"}
                        labelPosition={true}
                        />
                        <SubInfo 
                            name={"Montant Initial :"} 
                            inline
                            unit                                                                                                ={"DZD"}
                        /> 
                        <div className={styles['row-end']}>
                            <Button 
                                icon={icons.impr}
                                width={"250px"}
                                name={'Imprimer'}
                            />
                        </div>
                    </div>
                    <div className={styles['row']}>
                        <InputText
                            label={"Periode De"}
                            labelPosition={true}
                            width={"max-content"}
                            type={"date"}
                            />
                        <InputText label={"Au"} 
                            labelPosition={true}
                            width={"max-content"}
                            type={"date"}
                            />
                </div>
                    <div style={{height: '30vh'}}>
                        <Table thead={thead} inputValue={inputValue} tbody={search} 
                            edit={user.permissions.includes(permissions.canEditInTable)} 
                            remove={user.permissions.includes(permissions.canDeleteFromTable)}/>
                    </div>
                    <div className={styles['row-between']}>
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Total des Chèques Clients"}
                            
                            width={"30%"}
                            />
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Total des Versement Esp"}
                            
                            width={"30%"}
                            />
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Total des Sortie Bancaire"}
                            
                            width={"30%"}
                            />
                    </div>
                    <div className={styles['row-between']}>
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Total des Chèques Fournisseur"}
                            
                            width={"30%"}
                            />
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Total des Retraits Esp"}
                            
                            width={"30%"}   
                            />
                        <InputText 
                            type={"number"}
                            holderText={"Montant"}
                            label={"Solde Banque"}
                            
                            width={"30%"}
                            />
                    </div>
                  </div>
            </div>
        
    </PageLayout>
  )
}

export default SuivieBanque
import React, { useEffect, useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './articlesNoVenduFamille.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import articlesFinder from '../../api/articlesFinder'

const ArticlesNoVenduFamille = () => { 

  
  const thead = [
    {name:"Code Article",width: 190},
    {name:"Article",width: 190},
    {name:"Date",width: 190},
    {name:"Qtè en Stk.",width: 190},
    {name:"Prix Achat",width: 490},
    {name:"Prix Grossiste",width: 190},
    {name:"Montant Achat",width: 190},
    {name:"Montant Vente",width: 190}
  ]

  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([])
  useEffect(() => {
    const fetchArticles = async () =>{
      const response = await articlesFinder.get('/');
      
      setData(response.data.data.articles.map((element) =>
       {
        return {
        id:element.id,
        codeArticle:element.codeArticle,
        article:element.designation,
        qtyStk:element.quantity,
        prix_achat:element.puAchat,
        prix_vente_gros:element.prixVenteGros,
        prixAchat:0,
        prixVente:element.prixVenteInit,
        venteAmount:0
      }
      }));
    }
    fetchArticles();
  }, []);
                
  return (
        <PageLayout icon={icons.article} nameLayout={'Articles No Vendu Par Famille'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'50%'} tbody={data} setInputValue={setInputValue} inputValue={inputValue}
             labelPosition={true} holderText={"Famille"}  label={'Famille'}
            margin={'0px 0px 0px 15px'}
            icon={icons.search}/>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
                <Button name={'Exporter Vers Excel'} icon={icons.exporter} width={"250px"}/>
            </div>
          </div>
          <div className={styles['periode']}>
            <InputText width={'12.5%'} type={"date"}  label={'Jour 1'}
                       margin={'0px 0px 0px 15px'}/>
            <InputText width={'12.5%'} type={"date"}  label={'Jour 2'}
                       margin={'0px 0px 0px 15px'}/>
          </div>
          <div className={styles['table']}>
            <Table thead={thead} inputValue={inputValue} tbody={data} edit={true} remove={true} />
          </div>
          <div className={styles['mantant-chargers']}>
            <div></div>
            <div style={{display: "flex"}}>
                <InputText width={'50%'}type={"number"} holderText={"Montant"} label={'Montant Prix Vente'}
                margin={'0px 15px 0px 0px'}/>
                <InputText width={'50%'}type={"number"} holderText={"Montant"} label={'Montant en Prix Achat'}
                margin={'0px 15px 0px 0px'}/>
            </div>
            
          </div>
        </div>
        </PageLayout>
  )
}

export default ArticlesNoVenduFamille

import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import styles from './table.module.scss';
import { bgImgs, icons } from '../../media/mediaHolder';
import InputText from '../input-text/InputText';
import { spacedNumFormat } from '../../utils/numberFormatter';
import ConfirmationButton from '../confimation-button/ConfirmationButton';

const Table = forwardRef((props, ref) => {
  const {
    tableTitle,
    thead = [], 
    tbody = [],
    sendSelectedRowInfo = () => {},
    handleDelete = (id, obj = undefined) => {},
    actOnValidation = (hotRow, prevRowVals = undefined) => {},
    edit = false,
    remove = false,
    checkBox = true,
    singular = false,
    defaultSearchBy = [],
    datePropName = 'date',
    date1,
    date2,
    allowDoubleClick = true,
    confirmBeforeDeletion = true
  } = props;

  const [data, setData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const tempData = useRef([]);
  const rowsToUpdate = useRef([]);

  const [filterInput, setFilterInput] = useState('');
  const [filterByArr, setFilterByArr] = useState(defaultSearchBy);

  const patterns = {
    integer: /^-?(0|[1-9]\d*)$/,
    float: /^-?(0|[1-9]\d*)(\.?)\d*$/,
    // text: /^(?!\s)(.*\S\s?)*\S(?<!\s)$/,
    any: /^[\s\S]*$/
  }

  // This will act as a temporary holder for changes made on table values
  if (!tempData.current.length) tempData.current = data;

  /* Set the initial state */
  useEffect(() => {
    // Set all isChecked in the array to false initially and copy all data to the state
    setData(tbody.map(item => ({...item, isChecked: false, editable: false})));

  }, [tbody]);

  // Handle with selecting and de-selecting rows
  const handleRowClick = ({ target }, id, wasChecked) => {
    // These the elements that blocks the selection of a row
    if (target.id === 'edit-btn' || target.id === 'delete-btn') return;

    if (!wasChecked) sendSelectedRowInfo(data.find(el => el.id === id));
    else sendSelectedRowInfo(undefined);
    
    switchCheckboxState(id);
    setLastActiveItem(id);
  }

  const switchCheckboxState = (id) => {
    if (!singular) {
      setData(data.map((item) => {
        if (item.id === id) {
          item.isChecked = !item.isChecked;

          if (!item.isChecked && item.editable) {
            item.editable = false;
          }
        }

        return item;
      }));
    }
    else {
      setData(data.map((item) => {
        item.isChecked = item.id === id ? !item.isChecked : false;

        if (!item.isChecked && item.editable) {
          item.editable = false;
        }

        return item;
      }));
    }

    /* This line is to handle when all items are checked and all of the sudden
      one of the items gets unchecked */
    if (checkAll) setCheckAll(false);
  };

  // Handle the registering of the last selected item
  const lastActiveItem = useRef(undefined);

  const setLastActiveItem = (id) => {
    const targetItem = data.find(el => el.id === id);

    if (targetItem.isChecked) {
      lastActiveItem.current = targetItem;
    }
    else {
      if (lastActiveItem.current && lastActiveItem.current.id === id) {
        lastActiveItem.current = undefined;
      }
    }
  }
  // ----------------------------------------------

  const handleDoubleClick = (e, rowId, rowIsChecked, rowIsEditable) => {
    if (!allowDoubleClick || rowIsEditable) return;
    
    if (!rowIsChecked) handleRowClick(e, rowId, rowIsChecked);
    if (edit) switchEditability(rowId);
  }

  // Switch all check states at once
  const switchCheckAllState = () => {
    setCheckAll(!checkAll);
    setData(data.map((item) => ({ ...item, isChecked: !checkAll })));
  };

  const switchEditability = (id) => {
    setData(data.map((item) =>
        item.id === id ? { ...item, editable: !item.editable } : item
      )
    );
  }

  const validateRowModification = (id) => {
    // Select the row where changes are made
    const prevRowVals = tempData.current.find(item => item.id === id);
    const hotRow = data.find(item => item.id === id);

    // Make tempData up to date with data state
    tempData.current = tempData.current.map(item => {
      return item.id === id ? {...hotRow, editable: false}
                            : item;
    })

    // Make data state up to date with tempData property (editable: false) so changes take effect on UI
    setData(data.map(item => {
      return item.id === id ? {...tempData.current.find(el => el.id === id)}
                            : item;
    }));

    // Add to the list of rows with confirmed changes
    rowsToUpdate.current.push(hotRow);

    actOnValidation(hotRow, prevRowVals);
  }

  const cancelRowModification = (id) => {
    // Revert back to the changes before switching the editable property
    setData(data.map(item => {
      return item.id === id ? {...tempData.current.find(el => el.id === item.id), isChecked: true, editable: false}
                            : item;
    }));
  }

  // Handle any changes made to the inputs in the targeted row
  const handleRowChange = (val, id, propName) => {
    setData(data.map((item) => {
      if (item.id === id) {
        const newItem = item;
        newItem[propName] = val;
        return newItem;
      }
      else {
        return item;
      }
    }));
  }

  // ---
  const confimationDialogRef = useRef();

  const handleRowDeletion = (row) => {
    if (confirmBeforeDeletion) {
      if (confimationDialogRef.current) {
        confimationDialogRef.current?.display(true);
        confimationDialogRef.current?.setConfirmationFunc(() => handleDelete(row.id, row));
      }
    }
    else {
      handleDelete(row.id, row);
    }
  }

  /* This provides the ability to get the selected items from outside this component */
  const selectedItems = useRef([]);

  // Set the selected items table after the re-rendering triggered by data change
  useEffect(() => {
    selectedItems.current = data.filter(item => item.isChecked);
  }, [data]);

  // This gives the ability to access the state from the outside
  useImperativeHandle(ref, () => ({
    getSelectedItems: () => selectedItems.current,
    getAllData: () => data,
    setAllData: (newData) => {
      setData(newData);
      tempData.current = newData;
    },
    reset: () => {
      setData(data.map(item => ({...item, isChecked: false, editable: false})));
      tempData.current = data;
    },
    updateRow: (changedItem) => {
      setData(data.map(item => {
        if (changedItem.id !== item.id) {
          return item;
        }
        else {
          return changedItem;
        }
      }));

      tempData.current = tempData.current.map(item => {
        return item.id === changedItem.id ? changedItem : item;
      })
    },
    addItems: (items) => {
      const newItems = items.filter(item => !data.find(el => el.id === item.id));
      setData([...data, ...newItems]);
      tempData.current = [...tempData.current, ...newItems];
    },
    getRowsToUpdate: () => rowsToUpdate.current,
    unsavedChangesExists: () => data.filter(el => el.editable).length > 0,
    setFilterInput: (val) => setFilterInput(val),
    setFilterByArr: (arr) => setFilterByArr(arr)
  }));
  // ----------------------------------------------------------------------------------

  return (
    <>
      {tableTitle && <h1 className={styles['table-title']}>{tableTitle}</h1>}
      
      <div className={styles['container']}>
        <table className={styles['custom-table']}>
          <thead>
              <tr className={styles['table-head-container']} >

                {/* Render the first header cell which includes the check all box */}
                {
                  <th className={`${styles['table-head']} ${styles['checkbox-col']}`}>
                    {
                      checkBox && !singular &&
                      <img src={checkAll ? icons.checkboxActive : icons.checkboxInactive} 
                          className={styles['checkbox-icon']} 
                          alt=''
                          onClick={switchCheckAllState} />
                    }
                  </th>
                }  
                {/* Render all the other header cells */}
                {thead.map((head, index)=>{
                    return(
                        <th key={index} /*style={{minWidth: head.width}}*/>
                            <span className={styles['table-head']}>
                              {head.name}
                            </span>
                        </th>
                    )
                })}

              </tr>
          </thead>

          <tbody>
            {/* Filter through the data fed to the table component depending on the searchbar input */}
            {data.filter((item) => {
              let isItemReturned = true;

              if (filterInput && filterByArr.length > 0) {
                // Since there is term to filter by we start with the false value
                isItemReturned = false;

                if (filterByArr.includes('*')) {
                  // Choose to filter through all columns
                  for (const key in item) {
                    if (item[key]?.toString().toLowerCase().includes(filterInput.toLowerCase())) {
                      isItemReturned = true;
                      break
                    }
                  }
                }
                else {
                  // Choose to filter through specific columns
                  for (let i = 0; i < filterByArr.length; i++) {
                    if (item[filterByArr[i]]?.toString().toLowerCase().includes(filterInput.toLowerCase())) {
                      isItemReturned = true;
                      break
                    }
                  }
                }
              }

              // If both dates exists both gotta be true else either one gonna be assest alone
              if (date1 && date2) {
                isItemReturned = new Date(item[datePropName]) >= new Date(date1) &&
                                new Date(item[datePropName]) <= new Date(date2);
              }
              else {
                if (date1) isItemReturned = new Date(item[datePropName]) === new Date(date1);
                if (date2) isItemReturned = new Date(item[datePropName]) === new Date(date2);
              }

              return isItemReturned;
              // Loop through the filtered data and render table rows
              }).map((row, index) => {
                const values = [];

                // Here we set the field that are going to be asseigned to the table (columns)
                for (const key in row) {
                  if (key !== 'id' && key !== 'isChecked' && key !== 'editable') values.push({propName: key, val: row[key]});
                }

                return(
                    <tr key={row.id} 
                        // A possible solution is use row.isChecked to return different functions
                        // Maybe additional one
                        onClick={(e) => {if (row.editable) return; handleRowClick(e, row.id, row.isChecked)}} 
                        onDoubleClick={(e) => handleDoubleClick(e, row.id, row.isChecked, row.editable)}
                        className={`${styles['table-body-row']} 
                                    ${row.isChecked ? styles['selected-row'] 
                                                    : index % 2 === 0 ? styles['row-bg-1'] 
                                                                      : styles['row-bg-2']}`}
                    >
                      {/* Render the checkbox of the current row */}
                      {
                        (checkBox) &&
                        <td className={styles['td-cell']}>
                          {
                            <img src={row.isChecked ? icons.checkboxActive : icons.checkboxInactive} 
                            className={styles['checkbox-icon']} 
                            alt=''
                            id='checkbox' />
                          }
                        </td> 
                      }
                      {
                        // Render the rest of the cells that belongs to the current row
                        values.map((col, index) => {
                          return(
                              // Here where is descided to either show a text or input in table cells
                              <td className={styles['td-cell']} key={index}>
                                {
                                  (!row.editable || (row.editable && thead[index] && !thead[index].writeAccess)) && 
                                    <span className={`${styles['cell-padding']}`}>{thead[index]?.type !== 'float' ? col.val : spacedNumFormat(col.val)}</span>
                                }
                                {
                                  row.editable && thead[index] && thead[index].writeAccess &&
                                    <InputText width={'100%'}
                                              height={'32px'}
                                              type={thead[index]?.type || 'text'}
                                              pattern={patterns.hasOwnProperty(thead[index]?.type) ? patterns[thead[index].type] : patterns['any']}
                                              defaultValue={String(col.val)}
                                              onblur={(val) => handleRowChange(val, row.id, col.propName)}
                                              searchData={thead[index]?.children || []} />
                                }
                              </td>
                            )
                        })
                      }
                      {/* Display the edit and delete buttons of the current row */}
                      {
                        (edit || remove) &&
                        <td className={styles['operation-container']}>
                          <div className={styles['operation']}>
                            {
                              (row.isChecked && row.editable && 
                                <>
                                  <img src={bgImgs.cancelEdit} 
                                      alt='' 
                                      className={`${styles['row-btn']}  ${styles['clickable']}`} 
                                      onClick={() => cancelRowModification(row.id)} />

                                  <img src={bgImgs.validateEdit} 
                                        alt='' 
                                        className={`${styles['row-btn']}  ${styles['clickable']}`} 
                                        onClick={() => validateRowModification(row.id)} />
                                </>
                              )
                            }
                            {
                              (edit &&
                                (
                                  (row.isChecked && !row.editable &&
                                    <img src={bgImgs.edit.secondary} 
                                        alt='' 
                                        className={`${styles['row-btn']}  ${styles['clickable']}`} 
                                        id='edit-btn'
                                        onClick={() => switchEditability(row.id)} />) 

                                  ||

                                  (!row.isChecked && <img src={bgImgs.edit.main} 
                                                          alt='' 
                                                          className={styles['row-btn']} />)
                                )
                              )
                            }
                            {
                              (remove && 
                                (
                                  (row.isChecked && <img src={bgImgs.delete.secondary}
                                                          alt=''
                                                          className={`${styles['row-btn']}  ${styles['clickable']}`}
                                                          id='delete-btn'
                                                          onClick={() => handleRowDeletion(row)} />) ||
                                  (!row.isChecked && <img src={bgImgs.delete.main}
                                                          alt=''
                                                          className={styles['row-btn']} />)
                                )  
                              )
                            }
                          </div>
                        </td>
                      }
                    </tr>
                  )
              })}
          </tbody>
        </table>
      </div>
      
      <ConfirmationButton 
        message={'Voulez-vous confirmer la suppression ?'} 
        submitOnConfirm={false} 
        ref={confimationDialogRef} 
      />
    </>
  )
})

export default Table;

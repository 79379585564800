import React, { createContext, useContext, useReducer, useRef } from 'react';
import authFinder from '../api/authFinder';

const AuthContext = createContext();

const initialState = {
    user: null,
    isLoading: true
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
                isLoading: false
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                isLoading: false
            };
        case 'LOADING':
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};

const AuthProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, initialState);
    const initialFetch = useRef();
    
    if (!initialFetch.current) {
        const checkUser = async () => {
            try {
                const response = await authFinder.get('/is-authenticated');
                const user = response.data.data?.user;
                
                if (user?.email) {
                    user.name = user.firstName + ' ' + user.lastName;
                    dispatch({ type: 'LOGIN', payload: user });
                }
                else {
                    dispatch({ type: 'LOADING', payload: false });
                }
            }
            catch (err) {
                console.log(err);
                console.log('Failed to authenticate user!');
                dispatch({ type: 'LOADING', payload: false });
            }
        }
        
        checkUser();
        initialFetch.current = true;
    }

    const login = async ({ username, password }) => {
        try {
            dispatch({ type: 'LOADING', payload: true });

            const response = await authFinder.post('/login', {
                username,
                password
            });

            const user = response.data.data?.user;
            
            if (user?.email) {
                user.name = user.firstName + ' ' + user.lastName;
                dispatch({ type: 'LOGIN', payload: user });
            }
        }
        catch (err) {
            console.log('Login attempt failed!')
            console.log(err);
            dispatch({ type: 'LOADING', payload: false });
        }
    };

    const logout = async () => {
        try {
            dispatch({ type: 'LOADING', payload: true });
            const response = await authFinder.get('/logout');

            if (response.data.status === 'success') dispatch({ type: 'LOGOUT' });
        }
        catch (err) {
            console.log('Logout attempt failed!');
            console.log(err);
            dispatch({ type: 'LOADING', payload: false });
        }
    };

    return (
        <AuthContext.Provider value={{ state, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) throw new Error('useAuth must be used within an AuthProvider');
    
    return context;
};

export { AuthProvider, useAuth };

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import SelectInput from '../../components/select-input/SelectInput'
import styles from './sortieBanque.module.scss'
import Button from '../../components/button/Button'

const SortieBanque = () => {
      const options =[
    {
      value : "none",
      name : "Compte Boncaire"
    }
    ]
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Sortie de la Banque'}>
        <div className={styles['body-container']} style={{height: '100vh'}}>
            <div className={styles['main-body']}>
              <InputText
                type={"text"}
                holderText={"Code Compte"}
                label={"Code Compte"}
                width={"70%"}
                    />
              <SelectInput label={"Compte Bencaire"} options={options} width={"70%"}/>
              <div className={styles['row-between']} style={{width: '70%'}}>
                <InputText
                  type={"date"}
                  label={"Date"}
                  width={"20%"}
                  />
                <InputText
                  type={"number"}
                  holderText={"Montant"}
                  label={"Montant"}
                  width={"70%"}
                  />
              </div>
              <InputText
                type={"text"}
                holderText={"Observation"}
                label={"N° Cheque"}
                width={"70%"}
              />
              <InputText
                type={"text"}
                holderText={"Observation"}
                label={"Observation"}
                width={"70%"}
              />
              <div className={styles['ajouter-caisse']}>
                  <Button name={'Annuler'} switchbtn={true} width={120} />
                  <Button name={'Valider'} width={150} />
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default SortieBanque
import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatAchatGlobal.module.scss'
import Button from '../../components/button/Button'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import metaData from './etatAchatGlobal.json';
import permissions from '../../utils/permissions'
import { useAuth } from '../../contexts/AuthContext'

const EtatAchatGlobal = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data,setData] = useState([]);
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const total_achat = useRef();
  const total_retour = useRef();
  const global_amount = useRef();
  
  useEffect(() => {
    let id_bon = -1;
    let montant_achat = 0.0;
    let montant_retour = 0.0;
    let remise_achat = .0;
    let remise_avoir = .0;

    let articlesAchete = [], articlesAvoir = [];

    const fetchArticlesAcheteAvoir = async () =>{
      const articles_achete = await articlesAcheteFinder.get('/');
      const articles_avoir = await articlesAvoirFinder.get('/achat');
      const { max_id } = articles_achete.data.data;
      
      articles_achete.data.data.articles.map((article)=>{
        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING THE ARTICLE PROMOTION
        let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF THE PURCHASES INCLUDING RECEIPTS PROMOTION
        if(parseInt(id_bon) !== parseInt(article.id_bon_achat)){
          remise_achat += article.bon_achat_remise;
          id_bon = article.id_bon_achat;
        }
        montant_achat += montant;
        // 
        console.log(article)
        articlesAchete.push({
              id: article.id,
              achat_avoir: 'Achat',
              lot:article.lotNbr,
              codeArt: article.code,
              code: article.bon_achat_code,
              date: article.bon_achat_date.substring(0,10),
              disgnation: article.designation,
              qtyEnStk: article.ds_quantity,
              qtyAchete: article.quantity,
              prix: article.prix,
              montant,
              codeFournisseur: article.fournisseur_code,
              Fournisseur: article.fournisseur_name,
              observation: article.observation
            });
        }
      );

      id_bon = -1;
      articles_avoir.data.data.articles?.map((article)=>{
        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING THE ARTICLE PROMOTION
        let montant = (article.article_avoir_quantity * article.article_avoir_prix_achat) * (1 - (article.article_avoir_remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF THE RETURNS INCLUDING RECEIPTS PROMOTION
        if(parseInt(id_bon) !== parseInt(article.id_bon_avoir)){
          remise_avoir += article.bon_avoir_remise;
          id_bon = article.id_bon_avoir;
        }
        montant_retour += montant;
        // 

        let id = article.id + max_id;
        articlesAvoir.push({
          id,
          achat_avoir: 'Avoir',
          lot:article.lotNbr,
          codeArt: article.code,
          code: article.bon_avoir_code,
          date: article.bon_avoir_date.substring(0,10),
          disgnation: article.designation,
          qtyEnStk: article.ds_quantity,
          qtyAchetee: article.article_avoir_quantity,
          prix: article.article_avoir_prix_achat,
          montant,
          codeFournisseur: article.fournisseur_code,
          Fournisseur: article.fournisseur_name,
          observation: article.article_avoir_observation
        });
      });
      setData([...articlesAvoir,...articlesAchete]);
      montant_achat = montant_achat * (1 - remise_achat/100);
      montant_retour = montant_retour * (1 - remise_avoir/100);

      total_achat.current?.setValue(montant_achat.toFixed(3));
      total_retour.current?.setValue(montant_retour.toFixed(3));
      global_amount.current?.setValue((montant_achat - montant_retour).toFixed(3));
    }
    fetchArticlesAcheteAvoir();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achats Global'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <div className={styles['row']}>
                  <InputText label={"Date 1"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate1Change}
                    />
                    <InputText label={"Date 2"} 
                    labelPosition={true}
                    width={"max-content"} 
                    type={"date"}
                    reportChange={reportDate2Change}
                    />
                </div>
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                    />
                </div>
              </div>
              <div style={{height: "350px"}}>
                <Table thead={metaData.table} tbody={data} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                  date1={date1} date2={date2} />
              </div>
            
            <div className={styles['row-between']} style={{width:'100%'}}>
                <InputText  
                  holderText={"Montant"}
                  type={"number"} 
                  label={'Montant des Achats'}
                  ref={total_achat}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"} 
                  type={"number"} 
                  label={'Montant des Retours'}
                  ref={total_retour}
                  disabled
                  />
                <InputText  
                  holderText={"Montant"}
                  type={"number"} 
                  label={'Montant Global'}
                  ref={global_amount}
                  disabled
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EtatAchatGlobal
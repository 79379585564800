import React, { useEffect, useRef, useState } from 'react';
import styles from './listCharge.module.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import metaData from './listCharge.json';
import chargesFinder from '../../api/chargesFinder';
import InputText from '../../components/input-text/InputText';
import Dialog from '../../components/dialog/Dialog';

const ListCharge = () => {
    const [ data, setData ] = useState([]);
    const [ searchTerm, setSearchTerm ] = useState('');

    const popUpDialog = useRef();

    // Pop Up Inputs
    const observInputRef = useRef();
    const montantInputRef = useRef();
    const dateInputRef = useRef();
    const timeInputRef = useRef();

    useEffect(() => {
        const fetchCharges = async () => {
            const resopnse = await chargesFinder.get('/');
            setData(resopnse.data.data.charges.map((charge) => {
                const userDate = handleTimeZoneDiff(charge.date);

                return {
                    id: charge.id,
                    observation: charge.observation,
                    amount: charge.amount,
                    date: userDate.substring(0, 10),
                    time: userDate.substring(11, 17),
                    enteredBy: charge.entered_by
                };
            }));
        }

        fetchCharges();

    }, []);

    // Transforms the UTC date coming from database to local timezone
    const handleTimeZoneDiff = (date, displayStyle = 'en-GB') => {
        if (typeof date === 'string') date = new Date(date);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return date.toLocaleString(displayStyle, {timeZone: userTimeZone});
    }

    const handleSearch = (val) => setSearchTerm(val);

    const displayPopUp = () => {
        popUpDialog.current.display(true);

        observInputRef.current.setValue('');
        montantInputRef.current.setValue('');
        dateInputRef.current.setValue(new Date().toISOString().substring(0, 10));
        timeInputRef.current.setValue(new Date().toISOString().substring(11, 16));
    }

    const handleDelete = (id) => {
        chargesFinder.delete(`/${id}`);
        setData(data.filter(item => item.id !== id));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const response = await chargesFinder.post('/', {
            observation: document.getElementById('observation').value,
            amount: document.getElementById('amount').value,
            date: new Date(`${document.getElementById('date').value} ${document.getElementById('time').value}`),
            entered_by: 'administrator'
        });

        const userDate = handleTimeZoneDiff(response.data.data.charge.date);

        setData([...data, {
            id: response.data.data.charge.id,
            observation: response.data.data.charge.observation,
            amount: response.data.data.charge.amount,
            date: userDate.substring(0, 10),
            time: userDate.substring(11, 17),
            enteredBy: response.data.data.charge.entered_by
        }]);
    }
    
    return (
        <PageLayout icon={icons.article} nameLayout={'Charge Periodique'}>
            <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
                
                <div className={styles['main-search-bar']}>
                    <InputText width={'50%'} 
                               holderText={"Chercher"} 
                               label={'chercher'} 
                               margin={'0px 0px 0px 15px'}
                               icon={icons.search}
                               reportChange={(val) => handleSearch(val)} />

                    <div className={styles['operations-buttons']}>
                        <Button name={'Ajouter'} width={"250px"} onclick={displayPopUp} />
                        <Button name={'Imprimer'} icon={icons.impr} width={"250px"} />
                    </div>
                </div>

                <div className={styles['periode']}>
                    <InputText width={'12.5%'} type={"date"} label={'Periode du'} margin={'0px 0px 0px 15px'} />
                    <InputText width={'12.5%'} type={"date"} label={'Au'} margin={'0px 0px 0px 15px'} />
                </div>

                <div className={styles['table']}>
                    <Table thead={metaData.table} 
                           inputValue={searchTerm} 
                           tbody={data} 
                           remove={true}
                           handleDelete={handleDelete} />
                </div>

                <div className={styles['mantant-chargers']}>
                    <div></div>
                    <InputText width={'25%'} 
                               holderText={"Montant Chargers"} 
                               label={'Montant Charges'}
                               margin={'0px 15px 0px 0px'}/>
                </div>

            </div>

            <form onSubmit={handleSubmit}>
                <Dialog width={'50%'} ref={popUpDialog} type={true}>
                    <div className={styles['dialog-flex']}>
                        <InputText width={'60%'}
                                holderText={''}
                                label={'Observation'}
                                id={'observation'}
                                ref={observInputRef} />

                        <InputText width={'40%'}
                                holderText={'0'}
                                label={'Montant'}
                                id={'amount'}
                                ref={montantInputRef} />
                    </div>
                    <div className={styles['dialog-flex']}>
                        <InputText width={'45%'}
                                holderText={'0'}
                                label={'Date'}
                                type={'date'}
                                id={'date'}
                                ref={dateInputRef} />

                        <InputText width={'45%'}
                                holderText={''}
                                label={'Time'}
                                type={'time'}
                                id={'time'}
                                ref={timeInputRef} />
                    </div>
                </Dialog>
            </form>
        </PageLayout>
    )
}

export default ListCharge;

import React, { useState } from 'react'
import styles from './SortiesDeEncaissement.module.scss'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import Dialog from '../../components/dialog/Dialog'
import InputText from '../../components/input-text/InputText'
import { useAuth } from '../../contexts/AuthContext'

const SortiesDeEncaissement = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const thead = [
        {name:"Date",width: 150},
        {name:"Heur",width: 190},
        {name:"Montant",width: 150},
        {name:"Saisi Par",width: 150},
        {name:"Observation",width: 690}
      ]
    let [search, setSearch] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [ handelBool, setHandelBool] = useState(true);
    const [ handel, setHandel] = useState('noDisplay');
    const handelClick = () =>{
        handelBool ? display() :noDisplay() 
    }
    const display =()=>{
        setHandel('');
        setHandelBool(!handelBool);
        checked && handelCheckbox();
    }
    const noDisplay = ()=>{
        setHandel('noDisplay');
        setHandelBool(!handelBool);
    }

    const [checked , setChecked ] = useState(false);
    const handelCheckbox = ()=>{
        setChecked(!checked);
    }
  return (
    <PageLayout nameLayout={'Sorties de Encaissement'} icon={icons.article}>
        <div className={styles['header-container']}>
        <div className={styles['header']}>
            <div className={styles['input-container']}>
                <InputText setInputValue={setInputValue} inputValue={inputValue} width={'100%'} holderText={'Chercher'} label={'Chercher par Charge'} labelPosition={true}/>
            </div>
            <div className={styles['operations-container']}>
                <Button name={'Insere (Sortie de Caisse)'} icon={icons.insere} onclick={handelClick}
                />
                <Button name={'Les Encaissements'} path={"/encaissement"} icon={icons.reglementClient}/>
                <Button name={'Imprimer'} icon={icons.impr}/>
            </div>
        </div>
        <div className={styles['sub-header']}>
            <InputText width={'100%'} type={'date'} label={'Journee du'}/>
        </div>
        <div style={{height: "400px"}}>
            <Table thead={thead} tbody={search} inputValue={inputValue} />
        </div>
        
          <div className={styles[`${handel}`]}>
            <Dialog>
                <InputText width={'40%'} height={'50px'} label={'Date Veresment'} type={'date'}/>
                <InputText width={'100%'} height={'50px'} label={'Montant'} holderText={'Montant'} type={'number'}
                currency={true} currencyValue={"DZD"}/>
                <InputText width={'100%'} height={'50px'} holderText={'Sortie de Caisse'} label={'Sortie de Caisse'}/>
                <div className={styles['suivie-charge']}>
                    <div className={styles['check-input-container']}>
                        <p className={styles['checkbox-name']}>Suivie de Charge</p>
                         <input type='checkbox' className={styles['check-input']} onClick={handelCheckbox} 
                         style={checked ? {backgroundImage: `url(${icons.checkboxActive})`}:
                         {backgroundImage: `url(${icons.checkboxInactive})`}} 
                         />
                    </div>
                    <div className={styles['change-bg']}>
                        {checked ? <InputText width={'100%'} height={'45px'} label={'Qeulle Charge?'} labelPosition={true}/>:
                        <div className={styles['checkbox-not-checked']}></div>
                        }
                    </div>
                </div>
                <div className={styles['validation-container']}>
                    <div></div>
                    <div className={styles['ajouter-caisse']}>
                        <Button name={'Annuler'} switchbtn={true} width={120} onclick={handelClick}/>
                        <Button name={'Valider'} width={150} onclick={handelClick}/>
                    </div>
                </div>
            </Dialog>
            </div>
      </div>
    </PageLayout>
  )
}

export default SortiesDeEncaissement

import React from 'react';
import SettingsPage from '../SettingsPage';

const PermissionsAndRoles = () => {
    return (
        <SettingsPage>
            <h1>Permissions And Roles</h1>
        </SettingsPage>
    );
}

export default PermissionsAndRoles;

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './sortieCaissePeriodique.module.scss'
import Button from '../../components/button/Button'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const SortieCaissePeriodique = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const thead = [
    {name:"Date",width: 190},
    {name:"heur",width: 190},
    {name:"Sortie De Caisse",width: 490},
    {name:"Montant",width: 190},
    {name:"Une Charge",width: 190},
    {name:"Code Charge",width: 190},
    {name:"Saisi Par",width: 190},
    {name:"Observation",width: 490}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Sortie de Caisse Périodique'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  type={"text"}
                  holderText={"Chercher"}
                  label={"Chercher"}
                  labelPosition={true}
                  width={"50%"}
                  />
                  <div className={styles['row-end']}>
                    <Button 
                        icon={icons.impr}
                        width={"250px"}
                        name={'Imprimer'} 
                        />
                  </div>
                </div>
                <div className={styles['row']}>
                  <InputText
                    label={"Joure 1"}
                    width={"max-content"}
                    type={"date"}
                    />
                    <InputText
                    label={"Joure 2"}
                    width={"max-content"}
                    type={"date"}
                    />
              </div>
              <div style={{height: "350px"}}>
                <Table thead={thead} inputValue={inputValue} tbody={search}
                  edit={user.permissions.includes(permissions.canEditInTable)} 
                  remove={user.permissions.includes(permissions.canDeleteFromTable)}/>
              </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default SortieCaissePeriodique
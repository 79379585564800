import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import Button from '../../components/button/Button';
import styles from './etatBenifice.module.scss';
import bonVentesFinder from '../../api/bonVentesFinder';
import bonAvoirFinder from '../../api/bonAvoirFinder';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'

const EtatBenifice = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('');
  
  const thead = [
    {name:"Code Client",width: 190},
    {name:"Client",width: 490},
    {name:"Montant Des Ventes",width: 190},
    {name:"Montant Du Benificie",width: 190},
    {name:"N° Tel",width: 190},
    {name:"Wilaya",width: 190},
    {name:"Adresse",width: 490}
  ]
  const [data,setData] = useState([])
  useEffect(() => {
    let bonsAchat = [], bonsAvoir = [];

    const fetchBonsAchat = async () =>{
      const response = await bonVentesFinder.get('/');
      console.log(response.data.data)
      response.data.data.bons.map((element)=>{
        bonsAchat.push(
            {
              id: element.id,
              code: element.articlesAdded[0].client_code,
              code: element.articlesAdded[0].client_name,
              achat_av: 'vente',
              montant_des_vente: 0,
              montant_des_benifice: 0,
              NTel:element.articlesAdded[0].client_phone,
              wilaya:element.articlesAdded[0].wilaya,
              address:element.articlesAdded[0].client_address
            });
        }
      );
      setData([...bonsAvoir,...bonsAchat])
    }
    
    const fetchBonsAvoir = async ()=>{
      const response = await bonAvoirFinder.get(`/${'vente'}`); 
      console.log(response.data)
      response.data.data.bons.map((element)=>{
          bonsAvoir.push({
            id: element.id,
            code: element.articlesAdded[0].client_code,
            code: element.articlesAdded[0].client_name,
            achat_av: 'vente',
            montant_des_vente: 0,
            montant_des_benifice: 0,
            NTel:element.articlesAdded[0].client_phone,
            wilaya:element.articlesAdded[0].wilaya,
            address:element.articlesAdded[0].client_address
          })
      });
      setData([...bonsAvoir,...bonsAchat])
    }

    fetchBonsAvoir();
    fetchBonsAchat();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat du bénéfice par Client'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
              <InputText 
              icon={icons.search}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />  
              <div className={styles['row-end']}>
              <Button 
                icon={icons.exporter}
                width={"max-content"}
                name={'Exporter Vers Excel'} 
                  />
              </div>
            </div>
            <div className={styles['row']}>
                <InputText
                  label={"Periode De"}
                  labelPosition={true}
                  width={"max-content"}
                  type={"date"}
                  />
                  <InputText label={"Au"} 
                  labelPosition={true}
                  width={"max-content"}
                  type={"date"}
                  />
              </div>
              <div style={{height: '300px'}}>
                <Table thead={thead} inputValue={inputValue} tbody={data} />
              </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatBenifice
import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './encaisementPeriodiqueGroupeParjournee.module.scss'
import Button from '../../components/button/Button'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const EncaisementPeriodiqueGroupeParJourrnee = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

    const thead = [
    {name:"Date",width: 190},
    {name:"Les Encaissement",width: 190},
    {name:"Les Sortie De Caisse",width: 490},
    {name:"Caisse Jour",width: 190},
    {name:"Solde Caisse",width: 190}
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Encaissement Periodique Grouper Par Journee'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                  <div className={styles['row']} >
                  <InputText
                    label={"Journée du "}
                    width={"max-content"}
                    type={"date"}
                    labelPosition={true}
                    />
                    <InputText
                    label={"Au"}
                    width={"max-content"}
                    type={"date"}
                    labelPosition={true}
                    />
                    </div>
                    <div className={styles['row']}>
                        <Button 
                        icon={icons.impr}
                        width={"150px"}
                        name={'Imprimer'}
                        />
                        <Button 
                        icon={icons.exporter}
                        width={"250px"}
                        name={'Exporter Vers Excel'}
                        />
                    </div>
                  </div>
                  <div style={{height: "300px"}}>
                    <Table thead={thead} inputValue={inputValue} tbody={search} 
                      edit={user?.permissions.includes(permissions.canEditInTable)} 
                      remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                    />
                  </div>
           
            <div className={styles['row-between']} style={{gap: '2rem'}}>
                <InputText
                    label={"Total des Encaissements"}
                    holderText={"number"}
                    width={"max-content"}
                    type={"text"}
                    />
                <InputText
                    label={"Total de Sortie Caisse"}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                    />
                <InputText
                    label={"Caisse Période "}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                />
                <InputText
                    label={"Caisse Précédente"}
                    holderText={"Montant"}
                    width={"max-content"}
                    type={"number"}
                />
            </div>
            <div className={styles['row-end']} style={{width: '100%'}}>
              <InputText
                  label={"Caisse Net"}
                  holderText={"Montant"}
                  width={"max-content"}
                  type={"number"}
                  />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default EncaisementPeriodiqueGroupeParJourrnee
import React, { useEffect, useState,useRef } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './articlesAcheter.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import metaData from './articlesAcheter.json';
import articlesAcheteFinder from '../../api/articlesAcheteFinder.js';
import articlesAvoirFinder from '../../api/articlesAvoirFinder.js';

const ArticleAcheter = () => {
  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([])
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const total_achat = useRef();
  const total_retour = useRef();
  const amount_global = useRef();

  useEffect(() => {
    let montant_achat = 0.0;
    let montant_retour = 0.0;
    let remise_achat = .0;
    let remise_avoir = .0;

    let id_bon = -1;

    let articlesAchete = [], articlesAvoir = [];
    const fetchArticlesAcheteAvoir = async () =>{
      const articles_achete = await articlesAcheteFinder.get('/');
      const articles_avoir = await articlesAvoirFinder.get('/achat');

      const {max_id} = articles_achete.data.data;
      articles_achete.data.data.articles.map((element)=>{
        console.log(element);
        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
        let montant = (element.quantity * element.prix) * (1 - (element.remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF THE PURCHASES INCLUDING THE RECEIPTS PROMOTION
        if(parseInt(id_bon) !== parseInt(element.id_bon_achat)){
          // montant = montant * (1 - (element.bon_achat_remise/100));
          remise_achat += element.bon_achat_remise;
          id_bon = element.id_bon_achat;
        }
        montant_achat += montant;
        // 

        articlesAchete.push(
            {
              id:element.id,
              achat_avoir: 'achat',
              code_bon: element.bon_achat_code,
              code_article:element.code,
              disgnation: element.designation || '',
              quantity:element.quantity,
              montant: montant
            });
        }
      );

      
      
      id_bon = -1;
      articles_avoir.data.data.articles.map((element)=>{
        // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
        let montant = (element.article_avoir_quantity * element.article_avoir_prix_achat) * (1 - (element.article_avoir_remise/100));
        // 

        // CALCULATE THE TOTAL PRICE OF THE PURCHASES INCLUDING RECEIPTS PROMOTINO
        if(parseInt(id_bon) !== parseInt(element.id_bon_avoir)){
          // montant = montant * (1 - (element.bon_avoir_remise/100));
          remise_avoir += element.bon_avoir_remise;
          id_bon = element.id_bon_avoir;
        }
        montant_retour += montant;
        // 

        let id = element.id + max_id;
          articlesAvoir.push({
              id,
              achat_avoir: 'avoir',
              code_bon: element.bon_avoir_code,
              code_article: element.code,
              disgnation:element.designation,
              quantity: element.article_avoir_quantity,
              montant,
          });
      });
      montant_achat = montant_achat * (1 - (remise_achat/100));
      montant_retour = montant_retour * (1 - (remise_avoir/100));
      
      total_achat.current?.setValue(montant_achat);
      total_retour.current?.setValue(montant_retour);
      amount_global.current?.setValue(parseFloat(montant_achat) - parseFloat(montant_retour));
      setData([...articlesAvoir,...articlesAchete]);

    }
    fetchArticlesAcheteAvoir();
  }, []);

  return (
        <PageLayout icon={icons.article} nameLayout={'Les Articles Acheter '}>
        <div style={{width :"100%" , height: "100%" , padding: "1% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div className={styles['periode']}>
            <InputText 
              width={'15%'} 
              type={"date"}  
              label={'Periode du'}
              margin={'0px 0px 0px 15px'}
              reportChange={reportDate1Change}
            />
            <InputText 
              width={'15%'} 
              type={"date"}  
              label={'Au'}        
              margin={'0px 0px 0px 15px'}
              reportChange={reportDate2Change}
              />
          </div>
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
            </div>
          </div>
          
          <div className={styles['table']}>
            <Table thead={metaData.table} inputValue={inputValue} tbody={data} edit={true} remove={true} date1={date1} date2={date2}/>
          </div>
          <div className={styles['mantant-chargers']}>
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Achats'}
              margin={'0px 15px 0px 15px'}
              ref={total_achat}
              disabled
              />
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Retours'}
              margin={'0px 15px 0px 0px'}
              ref={total_retour}
              disabled
              />
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Global'}
              margin={'0px 15px 0px 0px'}
              ref={amount_global}
              disabled
              />
          </div>
        </div>
        </PageLayout>
  )
}

export default ArticleAcheter;

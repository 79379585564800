import React from 'react';
import { Link } from 'react-router-dom';
import styles from './menubar.module.scss';
import menubarItems from "./menubarData";
import MenubarItem from '../menubar-item/MenubarItem';
import { logo } from '../../media/mediaHolder';
import ProfileGate from '../profile-gate/ProfileGate';
import { useAuth } from '../../contexts/AuthContext';

const Menubar = () => {
  const { state: authState } = useAuth();
  const user = authState.user;

  return (
    user &&
    <header className={styles['container']}>

      <Link to=''>
        <img className={styles['logo']} src={logo} alt='logo' />
      </Link>
      
      <div className={styles['links-profile-gate']}>
        <div className={styles['links']}>
          {
            menubarItems.filter(item => {
              if (item.permissionsAssigned && item.permissionsAssigned.length > 0) {
                return item.permissionsAssigned.find(permission => user?.permissions.includes(permission));
              }
              else {
                return true;
              }
            })
            .map((item, index) => <MenubarItem 
                                    key={index} 
                                    name={item.name} 
                                    children={item.children} 
                                    dropToBottom={true}
                                    text={item.text}
                                  />)
          }
        </div>

        <div>
          <ProfileGate />
        </div>
      </div>

    </header>
  )
}

export default Menubar;

const permissions = {
    canCreateEnterprise: 'enterprise create',
    canCreateUser: 'user create',
    canEditUser: 'user edit',
    canDeleteUser: 'user delete',
    canBlockUser: 'user block',
    canEditInTable: 'table edit',
    canDeleteFromTable: 'table delete',
    canManageSales: 'sales manage',
    canManagePurchases: 'purchases manage',
    canManageProduction: 'production manage',
    canEvaluateFinishedMaterialCost: 'finished material cost evaluate',
    canManageSalesOrders: 'sales orders manage'
}

export default permissions;

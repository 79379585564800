import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import PageNotFound from "../../routes/page-not-found/PageNotFound";
import LoadingPage from "../../routes/loading-page/LoadingPage";

const ProtectedRoutes = ({ associatedPermissions }) => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const location = useLocation();

  /*
    I'm using PageNotFound as a fallback for when the user doesn't have 
      the required permissions to access a route.
  */

  return (
    authState.isLoading 
      ? <LoadingPage />
      : user?.permissions?.find(permission => {
          return (!associatedPermissions || !associatedPermissions?.length) 
            ? true 
            : associatedPermissions?.includes(permission)
        })
          ? <Outlet />
          : user
            ? <PageNotFound />
            : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;

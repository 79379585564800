import React, { useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './encaissement.module.scss'
import InputText from '../../components/input-text/InputText'
import { icons } from '../../media/mediaHolder'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import Dialog from '../../components/dialog/Dialog'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const Encaissement = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');
  const [ handelBool, setHandelBool ] = useState(true);
  const [ handel, setHandel] = useState('noDisplay');

  const thead = [
    {name:"Heur",width: 150},
    {name:"Observation",width: 490},
    {name:"Montant",width: 190},
    {name:"Saisir par",width: 420},
  ]
    
  const handelClick = () =>{
      handelBool ? display() :noDisplay() 
  }
  const display =()=>{
      setHandel('');
      setHandelBool(!handelBool);
  }
  const noDisplay = ()=>{
      setHandel('noDisplay');
      setHandelBool(!handelBool);
  }
  
  return (
    <PageLayout nameLayout={'Encaissement'} icon={icons.article}> 
      <div className={styles['header-container']}>
        <div className={styles['header']}>
            <div className={styles['input-container']}>
                <InputText setInputValue={setInputValue} inputValue={inputValue} width={'100%'} holderText={'Chercher'} label={'Chercher'} labelPosition={true}/>
            </div>
            <div className={styles['operations-container']}>
                <Button name={'Insere un Encaissement'} icon={icons.insere} onclick={handelClick}/>
                <Button name={'Sortie de Caisse'} path={"/sorties_de_encaissement"} icon={icons.reglementClient}/>
                <Button name={'Imprimer'} icon={icons.impr}/>
            </div>
        </div>
        <div className={styles['sub-header']}>
            <InputText width={'100%'} type={'date'} label={'Journee du'}/>
        </div>
        <div style={{height: '400px'}}>
          <Table thead={thead} tbody={data} inputValue={inputValue} />
        </div>
        <div className={styles[`${handel}`]}>
            <Dialog>
                <InputText width={'40%'} height={'50px'} label={'Date Veresment'} type={'date'}/>
                <InputText width={'100%'} height={'50px'} label={'Montant'} holderText={'Montant'} type={'number'}
                currency={true} currencyValue={"DZD"}/>
                <InputText width={'100%'} height={'50px'} holderText={'Observation'} label={'Observation'}/>
                <div className={styles['validation-container']}>
                    <div></div>
                    <div className={styles['ajouter-caisse']}>
                        <Button name={'Annuler'} switchbtn={true} width={120} onclick={handelClick}/>
                        <Button name={'Valider'} width={150} onclick={handelClick}/>
                    </div>
                </div>
            </Dialog>
          </div>
      </div>
    </PageLayout>
  )
}

export default Encaissement;

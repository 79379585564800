import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './ajouterFournisseur.module.scss'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import { icons } from '../../media/mediaHolder'
import fournisseurFinder from '../../api/fournisseurFinder'
import { useNavigate } from 'react-router-dom'
import wilayasFinder from '../../api/wilayasFinder'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const AjouterFournisseur = () => {
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await fournisseurFinder.post('/',{
            code: document.getElementById('code').value,
            name: document.getElementById('fournisseur').value, 
            address: document.getElementById('address').value,
            id_wilaya: getSlectedWilaya.current.getSelectedItem().id,
            contact_person: parseInt(document.getElementById('contact_fournisseur').value),
            phone: document.getElementById('phone').value,
            fax: document.getElementById('fax').value,
            mobile: document.getElementById('mobile').value,
            email: document.getElementById('email').value
        });
        goBack();
    }

    const errorsHandler= useRef([]);
    const displayConfirmationDialog = useRef();
    const codeInput = useRef();
    const fournisseurR = useRef();
    const addressR = useRef();
    const phoneR = useRef();
    const getSlectedWilaya = useRef();

    useEffect(()=>{
        const fetchFournisseurs = async () => {
            const response = await fournisseurFinder.get('/');
            let code=1;
            if(response.data.data.fournisseurs.length !== 0){
            code = parseInt(response.data.data.maxCode.max) + 1
            }
            codeInput.current.setValue(code);
        }
        const getwilaya = async ()=>{
            const response = await wilayasFinder.get("/");
            setSearchData(response.data.data.wilayas);
        }
        getwilaya();
        fetchFournisseurs();
    },[]);

    const checkCode = async (value)=>{
        const response = await fournisseurFinder.get(`/${value}`)
        if(response.data.data[0]?.code == value){
          return true
        }else
          return false
    }

    const checkErrs = async ()=>{
        let alerts = [];
        let code = document.getElementById('code').value
        let fournisseur = document.getElementById('fournisseur').value
        
        if(!code){
            alerts.push({name:'code',message:'Assurez que le Code est bien saisez'});
            codeInput.current.setBorderRed();
        }
        if( await checkCode(code) === true){
            alerts.push({name:'code',message:'le N° du bon est deja exister'});
            codeInput.current.setBorderRed();
        }
        if(!fournisseur){
            alerts.push({name:'fournisseur',message:'Assurez que Fournisseur est bien saisez'});
            fournisseurR.current.setBorderRed();
        }
        
        if(alerts.length > 0) {
            errorsHandler.current.errorsHandler(alerts);
            return
        }
        handelValidate();
    } 
    const handelValidate= () => {
        displayConfirmationDialog.current.display();
    }

    const goBack = () => navigate(-1);

  return (
    <PageLayout icon={icons.ajouterFournisseur.secondary} nameLayout={'Ajouter Fournisseur'}>
        <form onSubmit={handleSubmit} >
            <div className={styles['ajouter-fournisseur-container']}>
                <div className={styles['ajouter-fournisseur']}>
                    <div className={styles['ajouter-fournisseur-1-and-3-line']}>
                        <InputText  
                            width={'40%'} 
                            id={"code"} 
                            holderText={''} 
                            type={'number'} 
                            label={'Code*'}
                            ref={codeInput}
                            disabled
                        />
                        <InputText width={'60%'}
                            id={"fournisseur"} 
                            holderText={'Fournisseur'} 
                            ref={fournisseurR} 
                            type={'text'} 
                            label={'Fournisseur*'}
                            />
                    </div>
                    <div className={styles['ajouter-fournisseur-2-line']}>
                        <InputText 
                            width={'100%'} 
                            id={"address"} 
                            ref={addressR} 
                            holderText={'Addresse'} 
                            type={'text'} 
                            label={'Address*'}
                            />
                    </div>
                    <div className={styles['ajouter-fournisseur-1-and-3-line']}>
                        <InputText 
                            width={'40%'} 
                            id={"id_wilaya"} 
                            holderText={'Wilaya'} 
                            type={'text'} 
                            label={'Wilaya*'}
                            searchData={searchData}
                            ref={getSlectedWilaya}
                            displaySearchOption={true}
                            />
                        <InputText 
                            width={'60%'} 
                            id={"contact_fournisseur"} 
                            holderText={'Contact Fournisseur'} 
                            type={'text'} 
                            label={'Contact Fournisseur'}
                            />
                    </div>
                    <div className={styles['ajouter-fournisseur-4-and-5-line']}>
                        <div className={styles['input-1']}>
                            <InputText 
                                width={'60%'} 
                                id={"phone"} 
                                ref={phoneR} 
                                holderText={'Telephone'} 
                                type={'number'} 
                                label={'Telephone*'}
                                />
                            <InputText 
                                width={'40%'} 
                                id={"fax"} 
                                holderText={'Fax'} 
                                type={'number'} 
                                label={'Fax'}
                            />
                        </div>
                        <div className={styles['input-1']}>
                            <InputText 
                                width={'60%'} 
                                id={"mobile"} 
                                holderText={'Mobile'} 
                                type={'number'} 
                                label={'Mobile'}
                                />
                            <InputText 
                                width={'40%'} 
                                id={"email"} 
                                holderText={'E-mail'} 
                                type={'mail'} 
                                label={'E-mail'}
                                />
                        </div>
                    </div>
                </div>
                <div className={styles['ajouter-fournisseur-operation-container']}>
                    <div></div>
                    <div className={styles['ajouter-fournisseur-operation']}>
                        <Button name={'Annuler'} onclick={goBack} switchbtn={true} width={120}/>
                        <Button name={'Ajouter'} onclick={checkErrs} width={150}/>
                    </div>
                </div>
            </div>
            <ConfirmationButton ref={displayConfirmationDialog}/>
        </form>
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default AjouterFournisseur

import React from 'react'
import Button from '../button/Button'
import styles from './detailssectioncontainer.module.scss'
const DetailsSectionContainer = (props) => {
    const { name , stockProduit , table} = props ;
  return (
    <>
        <h1 className={styles['boldy']}>{name}</h1>
        <section className={stockProduit ? styles['fiche-stock-produit-container'] : table ? styles['table-details-container'] : styles['article-details-container']} >
            {props.children}
        </section>
    </>
  )
}

export default DetailsSectionContainer
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingPage from "../../routes/loading-page/LoadingPage";

const NonAuthRoutes = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const location = useLocation();

  return (
    authState.isLoading 
      ? <LoadingPage />
      : user
          ? <Navigate to="/" state={{ from: location }} replace />
          : <Outlet />
  );
};

export default NonAuthRoutes;

import React from 'react';
import styles from './pageOutliner.module.scss';

/* 
    The main purpose of this component is to accomodate for certain distances
    like main menu bar height (indicated here by header-height)
    the thought process is to keep certain padding dynamically stored
    so there won't be a need to copy it everywhere. In the contrary
    some page won't need such thing so unnecessary padding
    won't be included all together 
*/
const PageOutline = (props) => {
    return (
        <div className={styles['container']}>
            {props.children}
        </div>
    );
}

export default PageOutline;

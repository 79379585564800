import React, { useEffect, useState } from 'react';
import styles from './parametersPage.module.scss';
import PageOutline from '../page-outliner/PageOutliner';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../../media/mediaHolder';

const ParametersPage = (props) => {
    const {
        title,
        basePath = '',
        children,
        pagesStruct = [],
        ucsAdditionalClasses,
        addUpperElements
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const [curPath, setCurPath] = useState(null);

    useEffect(() => {
        const nestedPath = location.pathname.substring(location.pathname.indexOf('/', 1));
        setCurPath(nestedPath);
    }, [location.pathname]);

    const goBack = () => navigate(-1);

    const handleNavigation = (path) => {
        if (path) {
            let completePath = '';

            if (basePath && basePath[0] !== '/') completePath += '/' + basePath;

            completePath += basePath;

            if (path[0] !== '/') path = '/' + path;

            completePath += path;

            navigate(completePath);
            setCurPath(path);
        } 
    }

    return (
        <PageOutline>
            <div className={styles['container']}>
                <div className={styles['side-page']}>
                    <p className={styles['title']}>{title}</p>
                    {
                        pagesStruct.map((list, index) => {
                            return (
                                <div key={index}>
                                    {list.header && <p className={styles['header']}>{list.header}</p>}
                                    {list.pages.map((page, index) => <button 
                                                                        key={index} 
                                                                        className={page.path !== curPath ? `${styles['page-name']}`
                                                                                                         : `${styles['page-name']} ${styles['active-btn']}`}
                                                                        onClick={() => handleNavigation(page.path)}
                                                                    >
                                                                        {page.name}
                                                                    </button>)}
                                </div>
                            );
                        })
                    }
                </div>
                <div className={styles['content-side']}>
                    <div className={`${styles['upper-content-side']} ${ucsAdditionalClasses}`}>
                        <div className={styles['go-back']}>
                            <button onClick={goBack}>
                                <img src={icons.goBack} alt='go back' />
                            </button>
                            <span>Go back</span>
                        </div>
                        <div>
                            {/* <img src={icons.edit2} alt='edit profile' /> */}
                            {addUpperElements}
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </PageOutline>
    );
}

export default ParametersPage;

import React from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import styles from './consultationventescomptoirs.module.scss'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'

const ConsultationVentesComptoirs = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const thead = [
    [
      {name:"Date",width: 190},
      {name:"Montant",width: 190},
      {name:"Bon Bloque ?",width: 900}
    ],
    [
      {name:"Code",width: 190},
      {name:"Qte",width: 190},
      {name:"PU",width: 190},
      {name:"Total",width: 190},
      {name:"Designation",width: 490},
      {name:"Qte En Stock",width: 190},
      {name:"Observation",width: 490},
      {name:"Saisi Par",width: 190}
    ]
  ]
    const [ inputValue , setInputValue ] = useState('')
    let [search, setSearch] = useState([]);
  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Ventes Comptoirs'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <h1 style={{fontSize: '22px', fontWeight: '600',paddingBottom: '10px'}}>Les Ventes</h1>
              <br/>
                <div className={styles['row']}>
                    <InputText label={"Period du"} 
                      labelPosition={true} 
                      width={"max-content"} 
                      type={"date"}
                      />
                    <InputText label={"Au"} 
                      labelPosition={true} 
                      width={"max-content"} 
                      type={"date"}
                      /> 
                  </div>
            <div style={{height: '300px'}}> 
              <Table thead={thead[0]} inputValue={inputValue} tbody={search} 
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)}
              />
            </div>
            <div className={styles['row-end']} style={{width: '100%'}}>
            </div>
            <div style={{height: '300px'}}>
              <Table tableTitle={"Détail du Ventes"} thead={thead[1]} inputValue={inputValue} tbody={search} />
            </div>
            </div>
        
    </div>
    </PageLayout>
  )
}

export default ConsultationVentesComptoirs;
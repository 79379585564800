import React, { useEffect, useRef, useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './metaData.json'
import styles from './listClient.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import clientsFinder from '../../api/clientsFinder'
import Printing from '../../components/printing/Printing.js'
import wilayasFinder from '../../api/wilayasFinder.js'

const ListClient = () => {
  const [ data, setData ] = useState([]);
  useEffect(()=>{
    metaData.table.find(el => el.name === "Bloque?").children = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
  },[]);
  useEffect(()=>{
    const fetchData = async ()=>{
      try {
        const response = await clientsFinder.get('/');
        setData(response.data.data.clients)
        } catch (error) {
        console.log(error)
      } 
    }
    const getWilayas = async ()=>{
      const response = await wilayasFinder.get("/");
      metaData.table.find(el => el.name === "Wilaya").children = response.data.data.wilayas;
    }
    
    getWilayas();
    fetchData();
  },[])
  
  const handleDelete = (val)=>{
    clientsFinder.delete(`/${val}`);
    const updatedData = data.filter((item)=> item['id'] !== val);
    setData(updatedData);
  }
  const actOnValidation = async (obj) => {
    // console.log(obj);
    const wilaya = await wilayasFinder.get(`/`);
    let selectedWilaya = wilaya.data.data.wilayas.filter(el => el.name === obj.id_wilaya);
    
    await clientsFinder.put(`${parseInt(obj.id)}`,{
      "id_wilaya":selectedWilaya[0]?.id,
      "code": obj.code,
      "name": obj.name,
      "address": obj.address,
      "activity": obj.activity,
      "fax": obj.fax,
      "phone": obj.phone,
      "email": obj.email,
      "n_register": obj.n_register,
      "n_article": obj.n_article,
      "n_fiscal": obj.n_fiscal,
      "nis": obj.nis,
      "nif": obj.nif,
      "type_client": obj.type_client,
      "blocked": obj.blocked,
      "credit_max": obj.credit_max,
      "delais_vers_max": obj.delais_vers_max
    })
  }
  const thead = [
    {name:"Code Client", width: 190},
    {name:"Client", width: 190},
    {name:"Address", width: 290},
    {name:"Wilaya", width: 190},
    {name:"Tel", width: 190},
    {name:"Fax", width: 190},
    {name:"E-mail", width: 190},
    {name:"N Registre", width: 190},
    {name:"N Article", width: 190},
    {name:"N Fiscal", width: 190},
    {name:"NIS", width: 190},
    {name:"NIF", width: 190},
    {name:"Type Client", width: 190},
    {name:"Code Fournisseur", width: 190},
    {name:"Fournisseur", width: 190},
    {name:"Bloque?", width: 190},
    {name:"Activite", width: 190},
    {name:"Credit Max", width: 190},
    {name:"Delais Vers Max", width: 190}
  ]

  const [inputValue, setInputValue] = useState('');
  const filterByArr = ['name'];
  const handleSearch = (val) => {
      setInputValue(val);
  }

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Client",width: "50%"},
    {name: "Address",width: "40%"},
  ]
  
  const tableRef = useRef();
  const printRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id){
      return
    }
    setIsActive(true);
    const clientsAdded = tableRef.current.getSelectedItems().map((client)=>{
      return({
            code: client.code,
            name: client.name,
            address: client.address === "" ? "/" : client.address,
        })
    });
    const ROWS_PER_PAGE = 30; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(clientsAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
    clientsAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }
                
  return (
    <PageLayout icon={icons.article} nameLayout={'List Client'}>
      <div className={styles['main-search-bar']}>
        <InputText width={'35%'} 
          holderText={"Chercher Par Nom"}  
          label={'Client'} 
          labelPosition={true}
          margin={'0px 0px 0px 15px'}
          icon={icons.search}
          tbody={data} 
          reportChange={(val) => handleSearch(val)}
          />
        <div className={styles['operations-buttons']}>
          <Button link={true} path={"../ajouter_client"} name={'Insere Nouveau Client'} icon={icons.insere} width={250}/>
          <Button name={'Imprimer'} icon={icons.impr} width={150} onclick={handlePrint} />
          <Button name={'Exporter Vers Excel'} icon={icons.exporter} width={250}/>
        </div>
      </div>
      <div className={styles['table']}>
        <Table thead={metaData.table} 
                tbody={data} 
                filterInput={inputValue}
                filterByArr={filterByArr}
                ref={tableRef}
                edit={true} remove={true} 
                actOnValidation={actOnValidation}
                handleDelete={handleDelete}/>
      </div>
    {isActive && <Printing type={'List des Clients'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    </PageLayout>
  )
}

export default ListClient

import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import styles from './encaissementPeriodique.module.scss';
import Button from '../../components/button/Button';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'

const EncaissementPeriodique = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue, setInputValue ] = useState('');

  const thead = [
    {name:"Date", width: 190},
    {name:"Heure", width: 190},
    {name:"Montant", width: 190},
    {name:"Saisie Par", width: 190},
    {name:"Observation", width: 490}
  ]

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Les Encaissements Périodique'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
            <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              type={"text"}
              holderText={"Utilisateur"}
              label={"Utilisateur"}
              labelPosition={true}
              width={"50%"}
              />
              <div className={styles['row-end']}>
                <Button 
                    icon={icons.impr}
                    width={"250px"}
                    name={'Imprimer'} 
                    />
              </div>
            </div>
            <div className={styles['row']}>
              <InputText
                label={"Joure 1"}
                width={"max-content"}
                type={"date"}
                />
                <InputText
                label={"Joure 2"}
                width={"max-content"}
                type={"date"}
                />
          </div>
          <div style={{height: "350px"}}>
            <Table thead={thead} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EncaissementPeriodique
import React, { useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './consultationDesBonsInventaire.module.scss';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'

const ConsultationDesBonsInventaire = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ data, setData ] = useState([]);
  const [ inputValue , setInputValue ] = useState('')
    
  const thead1 = [
    {name:"N Bon", width: 190},
    {name:"Date", width: 190},
    {name:"Code Depot", width: 190},
    {name:"Adresse", width: 390},
    {name:"Montant PU Ach.", width: 190},
    {name:"Montant PU Ven.", width: 190},
    {name:"Saisie Par", width: 190}
  ]

  const thead2 = [
    {name:"Code", width: 190},
    {name:"Quatite", width: 190},
    {name:"Designation", width: 390},
    {name:"UM", width: 190},
    {name:"PU Ach.", width: 190},
    {name:"PU Ven.", width: 190},
    {name:"Total Ach.", width: 190},
    {name:"Total Ven.", width: 190},
    {name:"Saisie Par", width: 190},
  ]
    
  return (
    <PageLayout icon={icons.achat} nameLayout={"Consultation des Bons d'Inventaire"}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
            icon={icons.search}
            type={"text"}
            holderText={"Chercher"}
            label={"Chercher"}
            labelPosition={true}
            width={"50%"}
            />  
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
              />
            </div>
          </div>
          <div style={{height: "300px"}}>
            <Table tableTitle={"Bons d'Inventaire"} thead={thead1} inputValue={inputValue} tbody={data} 
              edit={user?.permissions.includes(permissions.canEditInTable)} 
              remove={user?.permissions.includes(permissions.canDeleteFromTable)}
            />
          </div>
          <div className={styles['solde']}>
            <div></div>
          </div>
        
        <div className={styles['row-end']} style={{width:'100%'}}>
        </div>
        <div style={{height: "300px"}}>
            <Table tableTitle={"Détail de Bon d'Inventaire"} thead={thead2} inputValue={inputValue} tbody={data} />
        </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default ConsultationDesBonsInventaire;
